import { IData, INote, IRequestResponse } from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { NotesState } from "./state";

const mutation: MutationTree<NotesState> = {
  setResponse(state: NotesState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
  setNotes(state: NotesState, data?: IData): void {
    if (data) {
      state.data.notes = data.data as INote[];

      state.pages.notes.currentPage.value = data.current_page;
      state.pages.notes.lastPage.value = data.last_page;
    } else {
      state.data.notes = [];
    }
  },
  setNote(state: NotesState, data?: INote | null): void {
    if (data !== undefined) {
      state.data.note = data;

      state.requests.note = true;
    } else {
      state.data.note = null;

      state.requests.note = false;
    }
  },
  setPendingNotes(state: NotesState, data?: number | null): void {
    if (data) {
      state.data.pendingNotes = data;

      state.requests.pendingNotes = true;
    } else {
      state.data.pendingNotes = null;

      state.requests.pendingNotes = false;
    }
  },
};

export default mutation;
