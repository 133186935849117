import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/mis-notificaciones",
    name: "NotificationsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Notifications List" */ "@/views/notifications/NotificationsList.vue"
      ),
  },
];
