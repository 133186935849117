import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { ICareCenter, IRequestResponse } from "@/interfaces/global/interfaces";
import { IPages } from "@/interfaces/customs/interface";
import {
  CareCentersData,
  CareCentersList,
  CareCentersPages,
} from "@/store/atentioncenter/state";

export const useAtentionCenters = () => {
  const store = useStore<StateInterface>();
  return {
    // STATE ############################################
    atentionCentersList: computed<Array<object>>(
      () => store.state.atentioncenter.atentionCenterList
    ),

    atentionCenterCurrentPage: computed(
      () => store.state.atentioncenter.atentionCenterCurrentPage
    ),

    atentionCenterLastPage: computed(
      () => store.state.atentioncenter.atentionCenterLastPage
    ),

    // Care Centers V.2
    careCenters: computed<ICareCenter[]>(
      () => store.state.atentioncenter.data.careCenters
    ),
    careCentersPages: computed<IPages>(
      () => store.state.atentioncenter.pages.careCenters
    ),
    data: computed<CareCentersData>(() => store.state.atentioncenter.data),
    pages: computed<CareCentersPages>(() => store.state.atentioncenter.pages),
    response: computed<IRequestResponse>(
      () => store.state.atentioncenter.response
    ),

    // GETTERS ##########################################
    atentionCenterName: computed<string>(
      () => store.getters["atentioncenter/getSpecialtesName"]
    ),

    // Care Centers V.2
    getCareCentersList: computed<CareCentersList[]>(
      () => store.getters["atentioncenter/getCareCentersList"]
    ),

    // ACTIONS ###########################################
    getAtentionCenters: (token: string, page: number | null = null) =>
      store.dispatch("atentioncenter/getAtentionCenters", { token, page }),
    getAtentionCentersWithoutPag: (token: string) =>
      store.dispatch("atentioncenter/getAtentionCentersWithoutPag", token),
    changeAtentionCenterStatus: (token: string, id: number) =>
      store.dispatch("atentioncenter/changeAtentionCenterStatus", {
        token,
        id,
      }),
    createAtentionCenter: (token: any, atentionCenterRequest: any) =>
      store.dispatch("atentioncenter/createAtentionCenter", {
        token,
        atentionCenterRequest,
      }),
    getAtentionCentersById: (token: string, id: string | string[]) =>
      store.dispatch("atentioncenter/getAtentionCentersById", { token, id }),
    updateAtentionCenter: (atentionCenter: any, token: any) =>
      store.dispatch("atentioncenter/editAtentionCenter", {
        atentionCenter,
        token,
      }),

    // Care Centers V.2
    loadCareCenters: (params: {
      status: string;
      page: number;
      name?: string;
    }) => store.dispatch("atentioncenter/loadCareCenters", params),

    // MUTATIONS #########################################
    // Care Centers V.2
    setCareCenters: () => store.commit("atentioncenter/setCareCenters"),
  };
};
