import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";

export const useGynecologistsBackground = () => {
  const store = useStore<StateInterface>();
  return {
    // State

    yes_or_no: computed(() => [
      { label: "SI", value: true },
      { label: "NO", value: false },
    ]),
    gynecologistsBackgroundValues: computed(
      () => store.state.telemedicine.gynecologistsBackgroundData
    ),

    // Mutation
    setGynecologistsBackground: (details: object) =>
      store.commit("telemedicine/setGynecologistsBackground", details),
  };
};
