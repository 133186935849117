import {
  ICreateMedicineFormV4,
  IUpdateMedicineFormV4,
  IFilterMedicineByParams,
  IMedicine,
  MedicinesEdit,
  MedicinesList,
  IGetAllMedicinesByParamsV5,
} from "@/interfaces/global/interfaces";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { useStore } from "vuex";

export const useMedicinesStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    // Medicines V.3
    medicines: computed(() => store.state.medicines.data.medicines),
    medicinesPages: computed(() => store.state.medicines.pages.medicines),
    requests: computed(() => store.state.medicines.requests),
    data: computed(() => store.state.medicines.data),
    pages: computed(() => store.state.medicines.pages),
    response: computed(() => store.state.medicines.response),
    medicinesListSelector: computed(
      () => store.state.medicines.medicinesListSelector
    ),

    // Getters
    medicineCode: computed<string>(
      () => store.getters["medicines/getMedicineCode"]
    ),
    medicineDescription: computed<string>(
      () => store.getters["medicines/getMedicineDescription"]
    ),
    pbsValue: computed(() => store.getters["medicines/getPbsValue"]),

    // Medicines V.3
    getMedicinesList: computed<MedicinesList[]>(
      () => store.getters["medicines/getMedicinesList"]
    ),
    getMedicinesEdit: computed<MedicinesEdit | null>(
      () => store.getters["medicines/getMedicinesEdit"]
    ),

    // Create Medicines V.4
    filteredMedicineData: computed(
      () => store.state.medicines.filteredMedicineData
    ),
    medicineData: computed(() => store.state.medicines.medicineFormData),
    medicineDataById: computed(() => store.state.medicines.medicineDataById),

    // Actions
    changeMedicineStatusById: (token: string, id: number) =>
      store.dispatch("medicines/changeMedicineStatusById", { token, id }),

    loadMedicineById: (id: any) =>
      store.dispatch("medicines/LoadMedicineById", id),

    // Medicines V.2
    isGetMedicinesV2: (data: any) =>
      store.dispatch("medicines/getMedicinesV2", data),

    medicinesListWithoutPaginate: (params: object | null) =>
      store.dispatch("medicines/medicinesListWithoutPaginate", params),

    // Medicines V.5
    loadMedicines: (
      params:
        | {
            status: string;
            page: number;
            full_reference?: string;
            care_center_id?: number;
          }
        | {
            care_center_id: null | number;
            paginate?: number;
            page?: number;
            reference_center_id: null | number;
            rows?: number;
            search?: string;
            status?: string;
          },
      version = 1
    ) => store.dispatch("medicines/loadMedicines", { params, version }),
    loadMedicine: (id: number) => store.dispatch("medicines/loadMedicine", id),
    createMedicine: (bodyRequest: {
      care_center_id: number | null;
      code: string;
      description: string;
      pbs: 0 | 1;
      reference_center_id: number | null;
    }) => store.dispatch("medicines/createMedicine", bodyRequest),
    updateMedicine: (
      id: number,
      bodyRequest: {
        _method: "PATCH";
        code: string;
        description: string;
        pbs: 0 | 1;
      }
    ) => store.dispatch("medicines/updateMedicine", { id, bodyRequest }),
    changeMedicineStatus: (id: number) =>
      store.dispatch("medicines/changeMedicineStatus", id),
    deleteMedicine: (id: number) =>
      store.dispatch("medicines/deleteMedicine", id),

    // Mutations
    // Medicines V.3
    setMedicines: () => store.commit("medicines/setMedicines"),
    setMedicine: (data?: IMedicine) =>
      store.commit("medicines/setMedicine", data),

    // Medicines V.4:
    // Create:
    _createMedicineV4: (bodyRequest: ICreateMedicineFormV4) =>
      store.dispatch("medicines/createMedicine", bodyRequest),
    // Read:
    _getMedicineFilterByParams: (params: IFilterMedicineByParams) =>
      store.dispatch("medicines/getMedicineFilterByParams", params),
    _getMedicineById: (id: number) =>
      store.dispatch("medicines/getMedicineById", id),
    _listMedicineV5: (params: IGetAllMedicinesByParamsV5) =>
      store.dispatch("medicines/listMedicinesV5", params),
    // Update:
    _updateMedicineV4: (bodyRequest: IUpdateMedicineFormV4) =>
      store.dispatch("medicines/updateMedicineV4", bodyRequest),
    // Export medicines v1:
    _exportMedicine: (params: IFilterMedicineByParams) =>
      store.dispatch("medicines/exportMedicine", params),
    // Delete medicines v2:
    _deleteMedicineV2: (id: number) =>
      store.dispatch("medicines/deleteMedicineV2", id),
    // Change medicine status v2:
    _changeMedicineStatusV2: (id: number) =>
      store.dispatch("medicines/changeMedicineStatusV2", id),

    // Non - async functions - actions:
    _setMedicineFormData: (data: ICreateMedicineFormV4) =>
      store.commit("medicines/setMedicineFormData", data),
  };
};
