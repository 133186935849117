import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/reporte-voluntades-anticipadas",
    name: "AdvanceDirectiveReportView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Advance Directives List" */ "@/views/advance-directives-report/AdvanceDirectiveReportView.vue"
      ),
  },
];
