import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/telexperticias",
    name: "TelexpertisesList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Teleexpertises List" */ "@/views/teleexpertises/TeleexpertisesList.vue"
      ),
  },
  {
    path: "/telexperticias/crear-telexperticias",
    name: "TelexpertisesCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Teleexpertises Create" */ "@/views/teleexpertises/sub-pages/teleexpertises-create/TeleexpertisesCreate.vue"
      ),
  },
  {
    path: "/telexperticias/ver-telexperticias/:id(\\d+)",
    name: "TelexpertisesView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Teleexpertises View" */ "@/views/teleexpertises/sub-pages/teleexpertises-view/TeleexpertisesView.vue"
      ),
  },
  {
    path: "/telexperticias/responder-telexperticias/:id(\\d+)",
    name: "TelexpertisesRespond",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Teleexpertises Respond" */ "@/views/teleexpertises/sub-pages/teleexpertises-respond/TeleexpertisesRespond.vue"
      ),
  },
];
