import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
    {
      path: "/control-liquidos",
      name: "LiquidControlList",
      beforeEnter: [isAuthenticatedGuard],
      component: () =>
        import(
          /* webpackChunkName: "Liquid Control List" */ "@/views/liquid-control/list/LiquidControlList.vue"
        ),
    },
    {
      path: "/control-liquidos/control-liquidos-crear",
      name: "LiquidControlCreate",
      beforeEnter: [isAuthenticatedGuard],
      component: () =>
        import(
          /* webpackChunkName: "Liquid Control List" */ "@/views/liquid-control/create/LiquidControlCreate.vue"
        ),
    },
    {
      path: "/control-liquidos/control-liquidos-ver/:id(\\d+)",
      name: "LiquidControlView",
      beforeEnter: [isAuthenticatedGuard],
      component: () =>
        import(
          /* webpackChunkName: "Liquid Control List" */ "@/views/liquid-control/view/LiquidControlView.vue"
        ),
    },
]