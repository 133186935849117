import { MutationTree } from "vuex";
import { PhotographsState } from "@/interfaces/global/interfaces";

const mutations: MutationTree<PhotographsState> = {
  setFile(state: PhotographsState, file: File | null): void {
    state.file = file;
  },

  setUrlBlob(state: PhotographsState, url: string | null): void {
    state.urlBlob = url;
  },
};

export default mutations;
