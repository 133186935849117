import { MutationTree } from "vuex";
import {
  Tab,
  Data,
  DataList,
  Datum,
  TeleexpertisesState,
  Telexpertises,
  TelexpertisesList,
} from "./state";
import { IAllResource } from "@/interfaces/global/interfaces";

const mutation: MutationTree<TeleexpertisesState> = {
  setTeleexperticeSchedule(state, teleexperticeInfo) {
    state.teleexperticeInfo = teleexperticeInfo;
  },

  getTeleexpertises(state: TeleexpertisesState, data: Data): void {
    if (data) {
      if (data.data.length >= 1) {
        state.teleexpertisesList = data.data.map(
          ({
            id,
            patient,
            priority,
            type_teleexpertise,
            status,
            is_qualification,
            //
            scheduled_at,
            url_meeting,
            final_hour,
            url_pdf,
            technical_annex_pdf,
            date_status,
            created_by,
          }: Datum): DataList => ({
            id,
            careCenter: patient.care_center?.name as string,
            patient: `${patient?.name} ${patient?.last_name}`,
            document: patient.document,
            priority,
            dateStatus: date_status,
            date: scheduled_at,
            type: type_teleexpertise?.name,
            status,
            //
            dateInfoYear: scheduled_at,
            meetURL: url_meeting,
            finalHour: final_hour,
            initialHour: scheduled_at,
            telexperticeType: "jobListResponse",
            telexperticeStatus: "isLoading",
            baseURL: "/response/telemedicine-response.html",
            titleResponse: "TELEXPERTICIA VIDEO RESPUESTA",
            baseMeetURL: `${process.env.VUE_APP_TELEXPERTISE_MEET}/`,
            dateTelexpertice: scheduled_at,
            pdfURL: url_pdf,
            technical_annex_pdf: technical_annex_pdf,
            is_qualification: is_qualification,
            created_by: created_by,
            informedConsentUrl: patient.url_informed_consent ?? null,
          })
        );
      } else state.teleexpertisesList = [];

      state.teleexpertisesCurrentPage = data.current_page;
      state.teleexpertisesLastPage = data.last_page;
    }
  },
  // Resources -------------------------------------------------------------
  setResources(state: TeleexpertisesState, resources: IAllResource): void {
    state.resourcesTelexpertises = resources;
  },
  // Tabs -------------------------------------------------------------
  setTab(state: TeleexpertisesState, tab: Tab): void {
    state.tab = tab;
  },
  // Patient Details -------------------------------------------------------------
  setPatientDetails(state: TeleexpertisesState, details: any): void {
    state.dataPatientDetail = details;
    if (details) {
      state.dataPatientDetail = details;
    }
  },
  // Scales -------------------------------------------------------------
  setScales(state: TeleexpertisesState, data: any): void {
    state.dataScales = data;
  },

  setInterpretations(state: TeleexpertisesState, data: any): void {
    state.dataInterpretations = data;
  },

  setScalesOptions(state: TeleexpertisesState, data: any): void {
    state.optionScales = data;
  },
  // Reason Consultations --------------------------------------------------------
  setReasonConsultation(
    state: TeleexpertisesState,
    reasonConsultations: any
  ): void {
    state.dataReasonConsultation = reasonConsultations;
  },
  // Nursing Records --------------------------------------------------------
  setNursingRecords(state: TeleexpertisesState, nursingRecord: any): void {
    state.dataNursingRecord = nursingRecord;
  },
  // Vaccination ------------------------------------------------------------------
  setDataTableVaccination(state: TeleexpertisesState, dataTable: any): void {
    if (dataTable) {
      state.dataTableVaccination = dataTable;

      state.vaccinationCurrentPage = dataTable.current_page;
      state.vaccinationLastPage = dataTable.last_page;
    }
  },
  setVaccination(state: TeleexpertisesState, vaccines: any): void {
    state.dataVaccination = vaccines;
    if (vaccines) {
      state.dataVaccination = vaccines;
    }
  },
  // Background --------------------------------------------------------------------
  setDataTableBackground(state: TeleexpertisesState, dataTable): void {
    if (dataTable) {
      state.dataTableBackground = dataTable;

      state.backgroundCurrentPage = dataTable.current_page;
      state.backgroundLastPage = dataTable.last_page;
    }
  },
  setBackgrounds(state: TeleexpertisesState, backgrounds: any): void {
    state.dataBackgrounds = backgrounds;
    if (backgrounds) {
      state.dataBackgrounds = backgrounds;
    }
  },
  // History Background
  setBackgroundHistory(state: TeleexpertisesState, backgroundsHistory): void {
    if (backgroundsHistory) {
      state.backgroundsHistory = backgroundsHistory;

      state.backgroundsHistoryCurrentPage = backgroundsHistory.current_page;
      state.backgroundsHistoryLastPage = backgroundsHistory.last_page;
    }
  },
  // Clinical Laboratory -------------------------------------------------------------
  setDataTableClinicalLaboratory(state: TeleexpertisesState, dataTable): void {
    if (dataTable) {
      state.dataTableClinicalLaboratory = dataTable;
      state.clinicalLaboratoryCurrentPage = dataTable.current_page;
      state.clinicalLaboratoryLastPage = dataTable.last_page;
    }
  },
  // History Medicines Patient -------------------------------------------------------------
  setDataTableHistoryMedicinesPatient(
    state: TeleexpertisesState,
    dataTable
  ): void {
    if (dataTable !== undefined) {
      state.dataTableHistoryMedicinesPatient = dataTable;
      state.dataTableHistoryMedicinesPatientCurrentPage =
        dataTable.current_page;
      state.dataTableHistoryMedicinesPatientLastPage = dataTable.last_page;
    }
  },
  // History Procedures Patient -------------------------------------------------------------
  setDataTableHistoryProceduresPatient(
    state: TeleexpertisesState,
    dataTable
  ): void {
    if (dataTable !== undefined) {
      state.dataTableHistoryProceduresPatient = dataTable;
      state.dataTableHistoryProceduresPatientCurrentPage =
        dataTable.current_page;
      state.dataTableHistoryProceduresPatientLastPage = dataTable.last_page;
    }
  },
  setClinicalLaboratoryPagination(state: TeleexpertisesState, pages): void {
    if (pages) {
      state.clinicalLaboratoryCurrentPage = pages.current_page;
      state.clinicalLaboratoryLastPage = pages.last_page;
    }
  },
  setClinicalLaboratory(
    state: TeleexpertisesState,
    clinicalLaboratory: any
  ): void {
    state.dataClinicalLaboratory = clinicalLaboratory;
    if (clinicalLaboratory) {
      state.dataClinicalLaboratory = clinicalLaboratory;
    }
  },
  // History Clinical Laboratory
  setClinicalLaboratoryHistory(
    state: TeleexpertisesState,
    clinicalLaboratoryHistory
  ): void {
    if (clinicalLaboratoryHistory) {
      state.clinicalLaboratoryHistory = clinicalLaboratoryHistory;

      state.clinicalLaboratoryHistoryCurrentPage =
        clinicalLaboratoryHistory.current_page;
      state.clinicalLaboratoryHistoryLastPage =
        clinicalLaboratoryHistory.last_page;
    }
  },
  // Medical Exams -------------------------------------------------------------
  setDataTableMedicalExams(state: TeleexpertisesState, dataTable): void {
    if (dataTable) {
      state.dataTableMedicalExams = dataTable;

      state.medicalExamsCurrentPage = dataTable.current_page;
      state.medicalExamsLastPage = dataTable.last_page;
    }
  },
  setMedicalExams(state: TeleexpertisesState, medicalExams: any): void {
    state.dataMedicalExams = medicalExams;
    if (medicalExams) {
      state.dataMedicalExams = medicalExams;
    }
  },
  setMedicalExamsDocuments(
    state: TeleexpertisesState,
    medicalExamsDocuments: any
  ): void {
    state.medicalExamsDocuments = medicalExamsDocuments;
    if (medicalExamsDocuments) {
      state.medicalExamsDocuments = medicalExamsDocuments;
    }
  },
  setClinicalLaboratoriesDocuments(
    state: TeleexpertisesState,
    clinicalLaboratoriesDocuments: any
  ): void {
    state.clinicalLaboratoriesDocuments = clinicalLaboratoriesDocuments;
    if (clinicalLaboratoriesDocuments) {
      state.clinicalLaboratoriesDocuments = clinicalLaboratoriesDocuments;
    }
  },
  // History Medical Exams
  setMedicalExamsHistory(
    state: TeleexpertisesState,
    medicalExamsHistory
  ): void {
    if (medicalExamsHistory) {
      state.medicalExamsHistory = medicalExamsHistory;

      state.medicalExamsHistoryCurrentPage = medicalExamsHistory.current_page;
      state.medicalExamsHistoryLastPage = medicalExamsHistory.last_page;
    }
  },
  // Vital Signs -----------------------------------------------------------------
  setVitalSigns(state: TeleexpertisesState, vitalSigns): void {
    state.dataVitalSigns = vitalSigns;
    if (vitalSigns) {
      state.dataVitalSigns = vitalSigns;
    }
  },
  // Vital Signs Nursing Notes-----------------------------------------------------------------
  setVitalSignsNursingNotes(state: TeleexpertisesState, vitalSigns): void {
    state.dataVitalSignsNursingNotes = vitalSigns;
    if (vitalSigns) {
      state.dataVitalSignsNursingNotes = vitalSigns;
    }
  },
  // Physical Exams --------------------------------------------------------------
  setPhysicalExams(state: TeleexpertisesState, physicalExams): void {
    state.dataPhysicalExams = physicalExams;
    if (physicalExams) {
      state.dataPhysicalExams = physicalExams;
    }
  },
  // Diagnostic -----------------------------------------------------------------
  setDataTableDiagnostic(state: TeleexpertisesState, dataTable): void {
    if (dataTable) {
      state.dataTableDiagnostic = dataTable;

      state.diagnosticCurrentPage = dataTable.current_page;
      state.diagnosticLastPage = dataTable.last_page;
    }
  },
  setDiagnostic(state: TeleexpertisesState, diagnostic: any = null): void {
    state.dataDiagnostic = diagnostic;
  },
  // History Diagnostic
  setDiagnosticHistory(state: TeleexpertisesState, diagnosticHistory): void {
    if (diagnosticHistory) {
      state.diagnosticHistory = diagnosticHistory;

      state.diagnosticHistoryCurrentPage = diagnosticHistory.current_page;
      state.diagnosticHistoryLastPage = diagnosticHistory.last_page;
    }
  },
  // History Vaccome
  setVaccineHistory(state: TeleexpertisesState, vaccineHistory): void {
    if (vaccineHistory) {
      state.vaccineHistory = vaccineHistory;

      state.diagnosticHistoryCurrentPage = vaccineHistory.current_page;
      state.diagnosticHistoryLastPage = vaccineHistory.last_page;
    }
  },
  // History Medicine
  setMedicineHistory(state: TeleexpertisesState, medicineHistory): void {
    if (medicineHistory) {
      state.medicineHistory = medicineHistory;

      state.medicineHistoryCurrentPage = medicineHistory.current_page;
      state.medicineHistoryLastPage = medicineHistory.last_page;
    }
  },
  // History Procedure
  setProcedureHistory(state: TeleexpertisesState, procedureHistory): void {
    if (procedureHistory) {
      state.procedureHistory = procedureHistory;

      state.procedureHistoryCurrentPage = procedureHistory.current_page;
      state.procedureHistoryLastPage = procedureHistory.last_page;
    }
  },
  // Documents
  setDocuments(state: TeleexpertisesState, documents: any): void {
    state.dataDocuments = documents;
    if (documents) {
      if (documents.data) {
        state.dataDocuments = documents.data;
      } else {
        state.dataDocuments = documents;
      }
      state.documentsCurrentPage = documents.current_page;
      state.documentsLastPage = documents.last_page;
    }
  },
  setDataTableDocuments(state: TeleexpertisesState, dataTable): void {
    state.dataTableDocuments = dataTable;
    if (dataTable) {
      state.dataTableDocuments = dataTable;

      state.documentsCurrentPage = dataTable.current_page;
      state.documentsLastPage = dataTable.last_page;
    }
  },
  // Response
  setResponse(state: TeleexpertisesState, request: any): void {
    if (request !== undefined) {
      state.dataResponseTelexpertice = request?.data ? request?.data : null;
      state.dataResponseTelexperticeTable = request?.table
        ? request?.table
        : null;
      state.dataResponseCurrentPage = request?.current_page
        ? request?.current_page
        : 1;
      state.dataResponseLastPage = request?.last_page ? request?.last_page : 1;
    }
  },

  setTeleexpertiseById(state: TeleexpertisesState, request: any): void {
    state.telexperticeById = request;
  },
  // Medicines
  setTabMedicines(state: TeleexpertisesState, request: any): void {
    if (request) {
      state.dataTabMedicines = request;
    }
  },
  setDataTableHistoryMedicines(state: TeleexpertisesState, dataTable): void {
    if (dataTable) {
      state.dataTableHistoryMedicinesPatient = dataTable;
      state.dataTableHistoryMedicinesPatientCurrentPage =
        dataTable.current_page;
      state.dataTableHistoryMedicinesPatientLastPage = dataTable.last_page;
    }
  },
  // Procedures
  setTabProcedures(state: TeleexpertisesState, request: any): void {
    if (request) {
      state.dataTabProcedures = request;
    }
  },
  setDataTableHistoryProcedures(state: TeleexpertisesState, dataTable): void {
    if (dataTable) {
      state.dataTableHistoryProceduresPatient = dataTable;
      state.dataTableHistoryProceduresPatientCurrentPage =
        dataTable.current_page;
      state.dataTableHistoryProceduresPatientLastPage = dataTable.last_page;
    }
  },
  // Request
  setRequest(state: TeleexpertisesState, request: any): void {
    state.dataRequest = request;
    if (request) {
      state.dataRequest = request;
    }
  },
  // Documents Response
  setDocumentsResponse(
    state: TeleexpertisesState,
    documentsResponse: any
  ): void {
    state.dataDocumentsResponse = documentsResponse;
    if (documentsResponse) {
      state.dataDocumentsResponse = documentsResponse;
    }
  },
  setDataTableDocumentsResponse(state: TeleexpertisesState, dataTable): void {
    if (dataTable) {
      state.dataTableDocumentsResponse = dataTable;

      state.documentsResponseCurrentPage = dataTable.current_page;
      state.documentsResponseLastPage = dataTable.last_page;
    }
  },
  // Specialty
  setSpecialte(state: TeleexpertisesState, request: any): void {
    state.specialtySelected = request;
    if (request) {
      state.specialtySelected = request;
    }
  },
  // Query Type
  setQueryType(state: TeleexpertisesState, request: any): void {
    state.queryTypeSelected = request;
    if (request) {
      state.queryTypeSelected = request;
    }
  },
  // Document Patient
  setDocumentPatient(state: TeleexpertisesState, request: any): void {
    state.documentPatient = request;
    if (request) {
      state.documentPatient = request;
    }
  },
  // Technica lAnnex
  setTechnicalAnnex(state: TeleexpertisesState, request: any): void {
    state.dataTechnicalAnnex = request;
    if (request) {
      state.dataTechnicalAnnex = request;
    }
  },
  // Get Telexpertises by Patient Id
  getTelexpertisesByPatient(
    state: TeleexpertisesState,
    data: Telexpertises[]
  ): void {
    if (data) {
      if (data.length >= 1) {
        state.telexpertisesListWP = data.map(
          ({
            id,
            center,
            patient,
            priority,
            application_date: date,
            type_teleexpertise,
            status,
          }: Telexpertises): TelexpertisesList => ({
            id,
            careCenter: center?.name,
            patient: patient?.name,
            document: patient?.document,
            priority,
            date,
            type: type_teleexpertise?.name,
            status,
          })
        );
      } else state.telexpertisesListWP = [];
    }
  },

  // Attached Documents
  setAttachedDocuments(
    state: TeleexpertisesState,
    documentsAttached: any
  ): void {
    state.dataAttachedDocuments = documentsAttached;
    if (documentsAttached) {
      state.dataAttachedDocuments = documentsAttached;
    }
  },
  setDataTableAttachedDocuments(state: TeleexpertisesState, dataTable): void {
    if (dataTable) {
      state.dataTableAttachedDocuments = dataTable;

      state.attachedDocumentsCurrentPage = dataTable.current_page;
      state.attachedDocumentsLastPage = dataTable.last_page;
    }
  },
};

export default mutation;
