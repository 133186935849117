import {
  IData,
  IRequestResponse,
  IResolutionReport,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { ResolutionReportsState } from "./state";

const mutation: MutationTree<ResolutionReportsState> = {
  setResolutionReports(state: ResolutionReportsState, data?: IData): void {
    if (data) {
      state.resolutionReports = data.data as IResolutionReport[] | [];

      state.resolutionReportsPages.currentPage.value = data.current_page;
      state.resolutionReportsPages.lastPage.value = data.last_page;

      state.request.resolutionReports = true;
    } else {
      state.resolutionReports = [];

      state.request.resolutionReports = false;
    }
  },
  setResolutionReport(
    state: ResolutionReportsState,
    data?: IResolutionReport | null
  ) {
    if (data !== undefined) {
      state.resolutionReport = data;
      state.request.resolutionReport = true;
    } else {
      state.resolutionReport = null;
      state.request.resolutionReport = false;
    }
  },
  setResponse(state: ResolutionReportsState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
