import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { IDataTable } from "@/interfaces/customs/interface";

export const useEmergencies = () => {
  const store = useStore<StateInterface>();
  return {
    // State
    emergenciesList: computed(() => store.state.emergencies.emergenciesList),
    emergenciesListCurrentPage: computed(
      () => store.state.emergencies.emergenciesCurrentPage
    ),
    emergenciesListLastPage: computed(
      () => store.state.emergencies.emergenciesLastPage
    ),
    states: computed(() => store.state.emergencies.states),
    urgencyResponse: computed(() => store.state.emergencies.urgencyResponse),
    _backgrounds: computed(() => store.state.emergencies.backgrounds),
    _diagnostics: computed(() => store.state.emergencies.diagnostics),

    // Actions
    _getListEmergencies: (filters: object | null, page: number) =>
      store.dispatch("emergencies/getEmergencies", {
        filters,
        page,
      }),
    _removeUrgency: (idTriage: number, reason: string) =>
      store.dispatch("emergencies/removeUrgency", { idTriage, reason }),
    _getUrgencyById: (lastPatientUrgencyId: number) =>
      store.dispatch("emergencies/getUrgencyById", { lastPatientUrgencyId }),
    _createAttentionUrgency: (idTriage: number, bodyRequest: object) =>
      store.dispatch("emergencies/createAttentionUrgency", {
        idTriage,
        bodyRequest,
      }),
    _getBackgrounds: (idUrgency: number | string, page: number = 1) =>
      store.dispatch("emergencies/getBackgrounds", { idUrgency, page }),
    _getDiagnostics: (idUrgency: number | string, page: number = 1) =>
      store.dispatch("emergencies/getDiagnostics", { idUrgency, page }),

    //Mutations
    _setListUrgency: (data: IDataTable) =>
      store.commit("emergencies/setEmergencies", data),
  };
};
