import { MutationTree } from 'vuex';
import { AttendanceUI } from './state';

const mutation: MutationTree<AttendanceUI> = {

    setAttendanceCounterCards(state, infoCards) {
        state.attendanceCounterCards = infoCards
    },

    setAttendanceTable(state, attendanceRow) {
        state.attendanceTable = attendanceRow
    },

    //year options
    setAttendanceYearSelected(state, yearSelected) {
        state.attendanceYearSelected = yearSelected
    },

    setAttendanceYearLoaders(state, status) {
        state.attendanceYearLoaders = status
    },

    setAttendanceYearOptions(state, yearOptions) {
        state.attendanceYearOptions = yearOptions
    },

    //attendance options
    setServiceTypeSelected(state, yearOptions) {
        state.serviceTypeSelected = yearOptions
    },

    //patient lists info
    setPatientListLoaders(state, status) {
        state.patientListLoaders = status
    },
    setPatientListOptions(state, patientOptions) {
        state.patientListOptions = patientOptions
    },

    setAgePatients(state, options) {
        state.agePatients = options
    },

    setAgePatientsMessage(state, message) {
        state.agePatientsMessage = message
    },

    setAttendancePatients(state, options) {
        state.attendancePatients = options

    },

    setAttendancePatientsMessage(state, message) {
        state.attendancePatientsMessage = message
    },

    setAttendancesDistribution(state, options) {
        state.attendancesDistribution = options
    },

    setAttendancesDistributionMessage(state, message) {
        state.attendancesDistributionMessage = message
    },

    setUnattendancesGender(state, options) {
        state.unattendancesGender = options
    },

    setUnattendancesGenderMessage(state, message) {
        state.unattendancesGenderMessage = message
    },

    // cards and patiends URLS
    setAttendanceCardURL(state, goURL) {
        state.attendanceCardURL = goURL
    },
    setAttendanceListURL(state, goURL) {
        state.attendanceListURL = goURL
    },

    setAttendanceYearURL(state, goURL) {
        state.attendanceYearURL = goURL
    },
}

export default mutation;