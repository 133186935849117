import {
  IData,
  INumeration,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { NumerationsState } from "./state";

const mutation: MutationTree<NumerationsState> = {
  setResponse(state: NumerationsState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
  setNumerations(state: NumerationsState, data?: IData): void {
    if (data) {
      state.data.numerations = data.data as INumeration[];

      state.pages.numerations.currentPage.value = data.current_page;
      state.pages.numerations.lastPage.value = data.last_page;
    } else {
      state.data.numerations = [];
    }
  },
  setNumeration(state: NumerationsState, data?: INumeration | null): void {
    if (data !== undefined) {
      state.data.numeration = data;

      state.requests.numeration = true;
    } else {
      state.data.numeration = null;

      state.requests.numeration = false;
    }
  },
};

export default mutation;
