import apiBase from "@/apis/AxiosIntance";

export const centersApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/centers`;

  return apiBase;
};

export const centersApiV2 = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/centers/v2`;

  return apiBase;
};