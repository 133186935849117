import { IPages } from "@/interfaces/customs/Pages";
import { IBank, IRequestResponse } from "@/interfaces/global/interfaces";

type BanksRequests = Record<"bank", boolean>;
type BanksPages = Record<"banks", IPages>;

export interface BanksList {
  id: IBank["id"];
  type: IBank["type"];
  description: IBank["description"];
  account: IBank["account"];
  status: IBank["status"];
}

export interface BanksEdit {
  reference_center_id: IBank["reference_center_id"];
  type: IBank["type"];
  description: IBank["description"];
  account: IBank["account"];
}

interface BanksData {
  banks: IBank[];
  bank: IBank | null;
}

export interface BanksState {
  requests: BanksRequests;
  data: BanksData;
  pages: BanksPages;
  response: IRequestResponse;
}

function state(): BanksState {
  return {
    requests: {
      bank: false,
    },
    data: {
      banks: [],
      bank: null,
    },
    pages: {
      banks: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
