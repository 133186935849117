import { MutationTree } from "vuex";
import { AdministratorsState } from "@/interfaces/global/interfaces";
import { IDataTable } from "@/interfaces/customs/interface";
import { IAdministrator } from "@/interfaces/global/interfaces";

const mutations: MutationTree<AdministratorsState> = {
  setAdministrator(state: AdministratorsState, list: IAdministrator): void {
    state.administratorRow = list;
  },
  setAdministratorsList(state: AdministratorsState, list: IDataTable): void {
    state.administratorsList = list?.data;

    state.administratorsCurrentPage = list?.current_page || 1;
    state.administratorsLastPage = list?.last_page || 1;
  },
};

export default mutations;
