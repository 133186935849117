import { ActionTree } from 'vuex';
import { HealthcareManagementUI } from './state';
import { StateInterface } from '../index';


const actions: ActionTree<HealthcareManagementUI, StateInterface> = {

   //

}

export default actions;

function showError({ message, response }: {
    message: string | undefined, response: {
        data: {
            message: string | undefined

        } | undefined
    } | undefined
}) {
    if (response) { return response.data?.message ? response.data?.message : '¡Error sin definir!' }
    else { return message }
}

