import { GetterTree } from "vuex";
import { NursingNotesState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<NursingNotesState, StateInterface> = {
    getListNursingNotes(state) {
      return state.nursingNotesList;
    },
};

export default getters;