import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/bodegas",
    name: "WarehouseList",
    beforeEnter: [isAuthenticatedGuard],
    component: () => import("@/views/warehouse/WarehouseList.vue"),
  },
  {
    path: "/bodegas/crear-bodegas",
    name: "WarehouseCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        "@/views/warehouse/sub-pages/warehouse-create/WarehouseCreate.vue"
      ),
  },
  {
    path: "/bodegas/editar-bodega/:id(\\d+)",
    name: "WarehouseEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import("@/views/warehouse/sub-pages/warehouse-edit/WarehouseEdit.vue"),
  },
];
