import { IDataTable } from "@/interfaces/customs/DataTable";
import { MutationTree } from "vuex";
import { LiquidControlState } from "./state";

const mutation: MutationTree<LiquidControlState> = {
  setLiquidsControl(state: LiquidControlState, list: IDataTable): void {
    state.liquidControlList = list?.data;

    state.liquidControlCurrentPage = list?.current_page;
    state.liquidControlLastPage = list?.last_page;
  },
  setTypeList(
    state: LiquidControlState,
    list: Array<{ id: number; name: string }>
  ): void {
    state.types = list;
  },
  setWaysList(
    state: LiquidControlState,
    list: Array<{ id: number; liquid_control_type_id: number; name: string }>
  ): void {
    state.ways = list;
  },
  setDataHydricBalance(state: LiquidControlState, data): void {
    state.dataHydricBalance = data;
  },
  setDataTableHydricBalance(state: LiquidControlState, dataTable): void {
    state.dataTable = dataTable;
  },
};

export default mutation;
