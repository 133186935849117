import { Module } from "vuex";
import { StateInterface } from "../index";

import state, { HighCostAccounts2463State } from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const highCostAccounts2463Module: Module<HighCostAccounts2463State, StateInterface> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default highCostAccounts2463Module;
