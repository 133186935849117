import {
  IRequestResponse,
  IResolutionReport,
} from "@/interfaces/global/interfaces";

export interface ResolutionReportsRequest {
  resolutionReports: boolean;
  resolutionReport: boolean;
}

export interface ResolutionReportsPages {
  currentPage: { value: number };
  lastPage: { value: number };
}

export interface ResolutionReportsList {
  id: IResolutionReport["id"];
  fromDate: IResolutionReport["from_date"];
  toDate: IResolutionReport["to_date"];
  createdDate: IResolutionReport["created_at"];
  requestedBy: IResolutionReport["user"]["name"];
  referenceCenter: IResolutionReport["reference_center"]["name"];
  careCenter: IResolutionReport["care_center"]["name"];
  status: IResolutionReport["status"];
  document: IResolutionReport["url"];
}

export interface ResolutionReportView {
  fromDate: IResolutionReport["from_date"];
  toDate: IResolutionReport["to_date"];
  createdDate: IResolutionReport["created_at"];
  eps: IResolutionReport["eps"]["name"];
  referenceCenter: IResolutionReport["reference_center"]["name"];
  careCenter: IResolutionReport["care_center"]["name"];
  regime: IResolutionReport["regime"]["name"];
  requestedBy: IResolutionReport["user"]["name"];
  status: IResolutionReport["status"];
  document: IResolutionReport["url"];
}

export interface ResolutionReportsState {
  request: ResolutionReportsRequest;
  resolutionReports: IResolutionReport[] | [];
  resolutionReportsPages: ResolutionReportsPages;
  resolutionReport: IResolutionReport | null;
  response: IRequestResponse;
}

function state(): ResolutionReportsState {
  return {
    request: {
      resolutionReports: false,
      resolutionReport: false,
    },
    resolutionReports: [],
    resolutionReportsPages: {
      currentPage: { value: 0 },
      lastPage: { value: 0 },
    },
    resolutionReport: null,
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
