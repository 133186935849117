import { GetterTree } from "vuex";
import { ProceduresState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<ProceduresState, StateInterface> = {
  getProcedureCode(state) {
    return state.procedureCode;
  },
  getProcedureDescription(state) {
    return state.procedureDescription;
  },

  // Procedures V.2
  // getProceduresList(state) {
  //   return state.data.procedures.length >= 1
  //     ? state.data.procedures.map(({ id, code, description, status }) => ({
  //         id,
  //         code,
  //         description,
  //         status,
  //       }))
  //     : [];
  // },
  getProceduresList(state) {
    return state.data.procedures.length >= 1
      ? state.data.procedures.map(
          ({
            id,
            code,
            description,
            reference_center,
            care_center,
            status,
            default: isDefault,
          }) => ({
            id,
            code,
            description,
            referenceCenter: reference_center?.name,
            careCenter: care_center?.name,
            status,
            isDefault,
          })
        )
      : [];
  },
  getProceduresEdit(state) {
    if (state.data.procedure) {
      const {
        care_center_id: careCenter,
        code,
        description,
        reference_center_id: referenceCenter,
      } = state.data.procedure;

      return {
        careCenter,
        code,
        description,
        referenceCenter,
      };
    }

    return null;
  },
};

export default getters;
