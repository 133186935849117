import { ActionTree } from 'vuex';
import { StateInterface } from '../index';
import { TemplatesApi } from '@/apis';

const actions: ActionTree<any, StateInterface> = {

    async getListTemplate( { }, data ) {

        const { token, params } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            },
            params
        };
        try{
            const { data } = await TemplatesApi.get('', config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async createdTemplates( { }, data ) {

        const { token, formData } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
            }
        };

        try{
            const { data } = await TemplatesApi.post('', formData, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async editTemplates( { }, data ) {

        const { token, formData, id } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
            }
        };

        try{
            const { data } = await TemplatesApi.post(`/${id}`, formData, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async getIdTemplates( { }, data ) {
        const { token, params, id, type } = data;
        let config = {};
        if(params){
            config = {
                headers: {
                'Authorization': `Bearer ${token}`
                },
                params
            };
        }else{
            config = {
                headers: {
                'Authorization': `Bearer ${token}`
                }
            };
        }
        try{
            if(type){
            const { data } = await TemplatesApi.get(`/${id}/${type}`, config);
            return data;
            }else{
            const { data } = await TemplatesApi.get(`/${id}`, config);    
            return data;
            }
        }catch(error:any){
            return errorExeption(error); 
        }
    },
    async deleteTemplates( { }, data ) {

        const { id, token } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const { data } = await TemplatesApi.delete(`/${id}`, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async getDigitalSignature( { }, data:any ) {
        const { token, id } = data;
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const { data } = await TemplatesApi.get(`/get/${id}`, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    }

}

function errorExeption(error: any) {
    
    //No hay conexion
    if(error.message == 'Network Error'){
        const success = false;
        const { message } = error;
        return { success, message }; 
    }            
    const { data } = error.response;
    const { success, message } = data; 
    return { success, message };
}

export default actions;