import { Module } from "vuex";
import { StateInterface } from "../index";

import state, { Indicators256State } from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const indicators256Module: Module<Indicators256State, StateInterface> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default indicators256Module;
