import { ref } from "vue";
const mainLoaderStatus = ref<boolean>(false);
const titleLoader = ref<string>("Cargando");
const loaderMessage = ref<string>("Por favor, espere un momento");

export const useMainLoader = () => {
  const loaderTiming = ref<number | null>(null);

  function clearPending() {
    if (loaderTiming.value) {
      clearTimeout(loaderTiming.value);
    }
  }

  function openMainLoader(value: boolean) {
    if (value) {
      mainLoaderStatus.value = value;
    } else {
      setTimeout(() => {
        mainLoaderStatus.value = value;
      }, 300);
    }
  }

  function setOpenFakeLoader(time = 600) {
    mainLoaderStatus.value = true;
    setTimeout(() => {
      mainLoaderStatus.value = false;
    }, time);
  }

  function setMainLoader(title: string, message: string, status: boolean) {
    mainLoaderStatus.value = true;
    if (status) return;
    titleLoader.value = title;
    loaderMessage.value = message;
    mainLoaderStatus.value = true;

    clearPending();

    loaderTiming.value = setTimeout(() => {
      mainLoaderStatus.value = false;
    }, 3500);
  }

  return {
    openMainLoader,
    titleLoader,
    mainLoaderStatus,
    loaderMessage,
    setOpenFakeLoader,
    setMainLoader,
  };
};
