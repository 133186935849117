import { isAuthenticatedGuard } from "@/router/auth-guard";

export default[
    
    {
        path: '/motivos-cancelacion',
        name: 'CancellationReason',
        beforeEnter: [ isAuthenticatedGuard ],
        component: () => import('@/views/cancellation-reasons/CancellationReason.vue'),
    },
    /* Create reasons */
    {
        path: '/motivos-cancelacion/crear-motivo',
        name: 'CreateReason',
        beforeEnter: [ isAuthenticatedGuard ],
        component: () => import('@/views/cancellation-reasons/subpages/create-reason/CreateReason.vue')
    },
    /* ** Edit reason ** */
    {
        path: '/motivos-cancelacion/editar-motivo/:id(\\d+)',
        name: 'EditReason',
        beforeEnter: [ isAuthenticatedGuard ],
        component: () => import('@/views/cancellation-reasons/subpages/edit-reason/EditReason.vue')
    },
]