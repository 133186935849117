import { MutationTree } from "vuex";
import { SpecialtesState } from "./state";
import {
  IData,
  IRequestResponse,
  ISpecialty,
} from "@/interfaces/global/interfaces";

const mutation: MutationTree<SpecialtesState> = {
  getSpecialtes(state: SpecialtesState, list: any): void {
    if (list) {
      state.specialtesList = list.data.data;

      state.specialtiesCurrentPage = list.data.current_page;
      state.specialtiesLastPage = list.data.last_page;
    }
  },
  getAllSpecialties(state: SpecialtesState, list: any): void {
    if (list) {
      state.specialtesList = list;
    }
  },
  changeSpecialteStatus(state: SpecialtesState, status: object): void {
    if (status) {
      state.specialtesStatus = status;
    }
  },
  getSpecialteId(
    state: SpecialtesState,
    specialte: { description: string }
  ): void {
    if (specialte) {
      const { description } = specialte;

      state.specialteDescription = description;
    }
  },
  getSpecialtiesByCenter(state: SpecialtesState, specialties): void {
    if (specialties) {
      state.specialtiesByCenter = specialties;
    }
  },
  updateSpecialteName(state: SpecialtesState, description: string) {
    state.specialteDescription = description;
  },

  // Specialties V.2
  setSpecialties(state: SpecialtesState, data?: IData): void {
    if (data) {
      state.data.specialties = data.data as ISpecialty[];

      state.pages.specialties.currentPage.value = data.current_page;
      state.pages.specialties.lastPage.value = data.last_page;
    } else {
      state.data.specialties = [];
    }
  },
  setResponse(state: SpecialtesState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
