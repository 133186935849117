import { ISurveyAbsenceCustom } from "@/interfaces/customs/ISurveysCustom";

function state(): ISurveyAbsenceCustom {
  return {
    surveyInfo: {
      key: "Sin definir",
      patient: "Sin definir",
      user: "Sin definir",
      specialty: "Sin definir",
      scheduledAtformatted: "Sin definir",
      type:'sin definir',
      status: 'survey/standby'
    },
    surveyOptions: [],
  };
}

export default state;
