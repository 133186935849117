import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/reporte-de-telemedicina",
    name: "TelemedicineReportList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Telemedicine Report List" */ "@/views/telemedicine-report/list/TelemedicineReportList.vue"
      ),
  },
];
