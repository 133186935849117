import { IBank, IData, IRequestResponse } from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { BanksState } from "./state";

const mutation: MutationTree<BanksState> = {
  setResponse(state: BanksState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
  setBanks(state: BanksState, data?: IData): void {
    if (data) {
      state.data.banks = data.data as IBank[];

      state.pages.banks.currentPage.value = data.current_page;
      state.pages.banks.lastPage.value = data.last_page;
    } else {
      state.data.banks = [];
    }
  },
  setBank(state: BanksState, data?: IBank | null): void {
    if (data !== undefined) {
      state.data.bank = data;

      state.requests.bank = true;
    } else {
      state.data.bank = null;

      state.requests.bank = false;
    }
  },
};

export default mutation;
