import apiBase from "@/apis/AxiosIntance";

export const foliosApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/patients/folio`;
  return apiBase;
};


export const foliosApiV1 = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/patients/folio/v1`;
  return apiBase;
};

