import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { LoginsState } from "./state";

const URL_PATH: { [key: number]: string } = {
  1: "/api/users/monitor/logins",
};

const URL_PATH_DOCUMENTS: { [key: number]: string } = {
  1: '/api/documents/monitor/logins'
}

const actions: ActionTree<LoginsState, StateInterface> = {
  async loadLogins({ commit }, params) {
    commit("setLogins");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH[1]}`, {
        params,
      });
      const { success, message, data } = response;

      commit("setLogins", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadAgents({ commit }) {
    commit("setAgents");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH[1]}/agents`);
      const { success, message, data } = response;

      commit("setAgents", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadReport({ commit }, formData) {
    commit("setReport");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().post(`${URL_PATH_DOCUMENTS[1]}/report`, formData);
      const { success, message, data } = response;

      commit("setReport", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
};

export default actions;
