import { PreparationsState } from "@/interfaces/global/interfaces";

function state(): PreparationsState {
  return {
    preparationsList: [],
    preparationRow: null,
    preparationsCurrentPage: 1,
    preparationsLastPage: 1,
    states: [
      { label: "Todos", value: "" },
      { label: "Activo", value: "Activo" },
      { label: "Inactivo", value: "Inactivo" },
    ],
    types: [
      { label: "Todos", value: "" },
      { label: "Especialidad", value: "Especialidad" },
      { label: "Procedimiento", value: "Procedimiento" },
    ],
    typesWithoutAll: ["Especialidad", "Procedimiento"],
    data_form: null,
    data_table: [],
  };
}

export default state;
