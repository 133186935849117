import { GetterTree } from "vuex";
import { TelemedicineState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<TelemedicineState, StateInterface> = {
  getTelemedicineMeetInfo(state) {
    return state.telemediceMeetInfo;
  },

    getListTelemedicine(state) {
      return state.telemedicineList;
    },
    getGynecologistsBackground(state) {
      return state.gynecologistsBackgroundData;
    },
    getDisabilities(state) {
      return state.disabilitiesData;
    },
};

export default getters;