// vuex
import { Module } from "vuex";
import state from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

// interfaces
import { StateInterface } from "../index";
import { ISupportCustom } from "@/interfaces/customs/ISupportCustom";

const supportModule: Module<ISupportCustom, StateInterface> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default supportModule;
