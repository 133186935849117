import { ActionTree } from "vuex";
import { ResolutionReportsState } from "./state";
import { StateInterface } from "../index";
import { resolutionReportsApi } from "@/apis";
import errorException from "@/functions/ErrorException";

// const CONFIG = {
//   headers: {
//     "Content-Type": "multipart/form-data",
//   },
// };

const actions: ActionTree<ResolutionReportsState, StateInterface> = {
  async loadResolutionReports({ commit }, { careCenter, page }) {
    commit("setResponse");

    try {
      const { data: response } = await resolutionReportsApi().get(
        `/${careCenter}?page=${page}`
      );
      const { success, message, data } = response;

      commit("setResolutionReports", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async loadResolutionReport({ commit }, id) {
    commit("setResponse");

    try {
      const { data: response } = await resolutionReportsApi().get(`/get/${id}`);
      const { success, message, data } = response;

      commit("setResolutionReport", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async createResolutionReport({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await resolutionReportsApi().post("", bodyRequest);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async cancelResolutionReport({ commit }, id) {
    commit("setResponse");

    try {
      const { data } = await resolutionReportsApi().post(`/cancel/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
};

export default actions;
