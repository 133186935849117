import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/programas-atencion/ver-programa/:id(\\d+)",
    name: "CampaignsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Campaigns List" */ "@/views/campaigns/CampaignsList.vue"
      ),
  },
  {
    path: "/programas-atencion/ver-programa/:id(\\d+)/ver-campania/:campaignId(\\d+)",
    name: "CampaignsView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Campaigns View" */ "@/views/campaigns/sub-pages/campaigns-view/CampaignsView.vue"
      ),
  },
];
