import apiBase from "@/apis/AxiosIntance";

export const telemedicineApi = (filter: any | null = null, page = 1) => {
  const urlBase = new URL(
    `${process.env.VUE_APP_URL}/api/telemedicine/telemedicine`
  );
  if (filter) {
    for (let property in filter) {
      if (filter[property]) {
        if (!filter["care_center_id"] && property == "care_center") {
          property = "care_center_id";
          filter[property] = filter["care_center"];
        } else {
          if (property == "care_center_id") {
            filter[property] = filter["care_center"];
          }
        }
        urlBase.searchParams.append(property, filter[property]);
      }
    }
  }
  if (page > 0) {
    urlBase.searchParams.append("page", `${page}`);
  }

  apiBase.defaults.baseURL = urlBase.href;

  return apiBase;
};
