import {
  IBodyRequestLevel,
  ILevel,
  ILoadLevel,
} from "@/interfaces/global/Level";
import { StateInterface } from "@/store";
import { LevelsEdit, LevelsList } from "@/store/levels/state";
import { computed, toRef } from "vue";
import { useStore } from "vuex";

export const useLevelsStore = () => {
  const store = useStore<StateInterface>();
  return {
    // State
    levels: toRef(store.state.levels.data, "levels"),
    levelsPages: toRef(store.state.levels.pages, "levels"),
    level: toRef(store.state.levels.data, "level"),
    requests: toRef(store.state.levels, "requests"),
    data: toRef(store.state.levels, "data"),
    pages: toRef(store.state.levels, "pages"),
    response: toRef(store.state.levels, "response"),
    excel: toRef(store.state.levels.data, "excel"),

    // Getters
    getLevelsList: computed<LevelsList[]>(
      () => store.getters["levels/getLevelsList"]
    ),
    getLevelsEdit: computed<LevelsEdit>(
      () => store.getters["levels/getLevelsEdit"]
    ),

    // Actions
    loadLevels: (params: ILoadLevel) =>
      store.dispatch("levels/loadLevels", params),
    loadLevel: (id: number) => store.dispatch("levels/loadLevel", id),
    createLevel: (bodyRequest: IBodyRequestLevel) =>
      store.dispatch("levels/createLevel", bodyRequest),
    updateLevel: (id: number, bodyRequest: IBodyRequestLevel) =>
      store.dispatch("levels/updateLevel", { id, bodyRequest }),
    deleteLevel: (id: number) => store.dispatch("levels/deleteLevel", id),
    changeLevelStatus: (id: number) =>
      store.dispatch("levels/changeLevelStatus", id),
    exportListLevels: (params: {
      reference_center_id: number;
      status: string;
    }) => store.dispatch("levels/exportListLevels", params),

    //Mutations
    setLevels: () => store.commit("levels/setLevels"),
    setLevel: (data?: ILevel) => store.commit("levels/setLevel", data),
  };
};
