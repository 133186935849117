import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [

  {
    path: "/gestion-de-reportes/RIPS",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "RIPS" */
        "@/views/managment-reports/ManagementReports.vue"
      ),
    children: [
      {
        path: '',
        name: 'RipsView',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(
          /* webpackChunkName: "RipsView" */
          "@/views/managment-reports/RIPS/listRips/RipsView.vue"),
      },

      {
        path: 'ver-rips/:id',
        name: 'ShowRipsView',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(
          /* webpackChunkName: "ShowRipsView" */
          "@/views/managment-reports/RIPS/showRips/ShowRipsView.vue"),
      },

      {
        path: 'solicitar-rips',
        name: 'RequestRIPSView',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(
          /* webpackChunkName: "RequestRIPSView" */
          "@/views/managment-reports/RIPS/request-rips/RequestRIPS.vue"),
      },

    ]
  },

  {
    path: "gestion-de-reportes/tableros-de-control",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "RIPS" */
        "@/views/managment-reports/ManagementReports.vue"
      ),
    children: [

      {
        path: "",
        name: "ControlPanel",
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(
          /* webpackChunkName: "ControlPanel" */
          "@/views/managment-reports/panel-controls/ControlPanel.vue"
        ),
      },
    ]
  },

  {
    path: "/gestion-de-reportes/indicadores-de-cumplimiento",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "RIPS" */
        "@/views/managment-reports/ManagementReports.vue"
      ),
    children: [

      {
        path: "",
        name: "ComplianceMetricsView",
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(
          /* webpackChunkName: "ControlPanel" */
          "@/views/managment-reports/compliance-metrics/ComplianceMetricsView.vue"
        ),
      },
    ]
  },

  {
    path: "/gestion-de-reportes/estadisticas",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "RIPS" */
        "@/views/managment-reports/ManagementReports.vue"
      ),
    children: [

      {
        path: "",
        name: "StatsView",
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(
          /* webpackChunkName: "ControlPanel" */
          "@/views/managment-reports/stats-view/StatsView.vue"
        ),
      },
    ]
  },
];
