import { ActionTree } from "vuex";
import { SpecialtesState } from "./state";
import { StateInterface } from "../index";
import { specialtesApi, executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";

const URL_PATH = "/api/settings/specialties";

const headers = {
  "Content-Type": "multipart/form-data",
};

const actions: ActionTree<SpecialtesState, StateInterface> = {
  async getSpecialtes({ commit }, { params, page }): Promise<object> {
    try {
      const { data } = await executeApi().get(`${URL_PATH}?page=${page}`, { params });
      const { success, message, data: specialtesData } = data;
      const { data: specialtesList } = specialtesData;

      commit("getSpecialtes", data);
      return { success, message, specialtesData };
    } catch (error) {
      return errorException(error);
    }
  },
  async getAllSpecialties({ commit }, { params }): Promise<object> {
    try {
      const { data } = await executeApi().get(`${URL_PATH}/withoutPaginate`, { params });
      // const { data } = await specialtesApi().get("/withoutPaginate");
      const { success, message, data: specialtiesList } = data;

      commit("getAllSpecialties", specialtiesList);
      return { success, message, specialtiesList };
    } catch (error) {
      return errorException(error);
    }
  },
  async changeSpecialtesStatus({ commit }, { token, id }): Promise<object> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await specialtesApi().post(
        `/changeStatus/${id}`,
        {},
        config
      );
      const { success, message, data: specialteStatus } = data;

      commit("changeSpecialteStatus", specialteStatus);

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async createSpecialte({ commit }, { token, bodyRequest }): Promise<object> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await specialtesApi().post("/", bodyRequest, config);
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getSpecialteById({ commit }, { token, id }): Promise<object> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await specialtesApi().get(`/${id}`, config);
      const { success, message, data: specialteData } = data;

      commit("getSpecialteId", specialteData);
      return { success, message, data };
    } catch (error) {
      return errorException(error);
    }
  },
  async getSpecialtiesByCenter({ commit }, { token, id }): Promise<object> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await specialtesApi().get(`/getByCenter/${id}`, config);
      const { success, message, data: specialtiesData } = data;

      commit("getSpecialtiesByCenter", specialtiesData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getSpecialteByIdContract(_, { token, id }): Promise<object> {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await specialtesApi().get(
        `/getByContract/${id}`,
        config
      );
      //commit("getSpecialteId", specialteData);
      return data;
    } catch (error: any) {
      return errorException(error);
    }
  },
  async updateSpecialte(
    { commit },
    { token, id, description }
  ): Promise<object> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    const newRequest = { id, description };

    try {
      const { data } = await specialtesApi().post("/", newRequest, config);
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },

  // Specialties V.2
  async loadSpecialties({ commit }, params) {
    commit("setSpecialties");
    commit("setResponse");

    try {
      const { data: response } = await specialtesApi().get("/", { params });
      const { success, message, data } = response;

      commit("setSpecialties", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
};

export default actions;
