import { IPages } from "@/interfaces/customs/Pages";
import {
  INumeration,
  IReferenceCenter,
  IRequestResponse,
} from "@/interfaces/global/interfaces";

type NumerationsRequests = Record<"numeration", boolean>;
type NumerationsPages = Record<"numerations", IPages>;

export interface NumerationsList {
  id: INumeration["id"];
  type: INumeration["type"];
  description: INumeration["description"];
  resolutionNumber: INumeration["resolution_number"];
  prefix: INumeration["prefix"];
  rangeFrom: INumeration["range_from"];
  rangeTo: INumeration["range_to"];
  startNumber: INumeration["start_number"];
  actualNumber: INumeration["actual_number"];
  startDate: INumeration["start_date"];
  endDate: INumeration["end_date"];
  status: INumeration["status"];
}

export interface NumerationsEdit {
  description: INumeration["description"];
  end_date: INumeration["end_date"];
  prefix?: INumeration["prefix"];
  range_from:  string;
  range_to: string;
  reference_center_id: INumeration["reference_center_id"];
  resolution_number?: INumeration["resolution_number"];
  start_date: INumeration["start_date"];
  start_number: string;
  status: INumeration["status"];
  type: INumeration["type"];
}

export interface NumerationsView {
  description: INumeration["description"];
  endDate: INumeration["end_date"];
  prefix?: INumeration["prefix"];
  rangeFrom: INumeration["range_from"];
  rangeTo: INumeration["range_to"];
  referenceCenter: IReferenceCenter["name"];
  resolutionNumber?: INumeration["resolution_number"];
  startDate: INumeration["start_date"];
  startNumber: INumeration["start_number"];
  actualNumber: INumeration["actual_number"];
  status: INumeration["status"];
  type: INumeration["type"];
}

interface NumerationsData {
  numerations: INumeration[];
  numeration: INumeration | null;
}

export interface NumerationsState {
  requests: NumerationsRequests;
  data: NumerationsData;
  pages: NumerationsPages;
  response: IRequestResponse;
}

function state(): NumerationsState {
  return {
    requests: {
      numeration: false,
    },
    data: {
      numerations: [],
      numeration: null,
    },
    pages: {
      numerations: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
