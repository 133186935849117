import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { IError } from "@/interfaces/customs/interface";
import { RateManualState } from "@/interfaces/global/interfaces";

const URL_PATH_RATE_MANUAL = "/api/settings/rate-manual";
const URL_PATH_RATE_MANUAL_EXPORT = "/api/documents/rate-manual/export";

const actions: ActionTree<RateManualState, StateInterface> = {
  async getRatesManual({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().get(URL_PATH_RATE_MANUAL, {
        params,
      });
      const { success, message, data: dataRequest } = data;

      commit("setList", dataRequest);

      return { success, message, dataRequest };
    } catch (error: IError | unknown) {
      return errorException(error);
    }
  },
  async getRateManualById({ commit }, id): Promise<object> {
    try {
      const { data } = await executeApi().get(`${URL_PATH_RATE_MANUAL}/${id}`);
      const { success, message, data: dataRateManual } = data;

      commit("setById", dataRateManual);

      return { success, message, dataRateManual };
    } catch (error: IError | unknown) {
      return errorException(error);
    }
  },
  async createAction({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().post(
        `${URL_PATH_RATE_MANUAL}`,
        params
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | unknown) {
      return errorException(error);
    }
  },
  async updateAction({ commit }, { params, id }): Promise<object> {
    try {
      const { data } = await executeApi().put(
        `${URL_PATH_RATE_MANUAL}/${id}`,
        params
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | unknown) {
      return errorException(error);
    }
  },
  async deleteAction({ commit }, id): Promise<object> {
    try {
      const { data } = await executeApi().delete(
        `${URL_PATH_RATE_MANUAL}/${id}`
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | unknown) {
      return errorException(error);
    }
  },
  async changeStatusAction({ commit }, id): Promise<object> {
    try {
      const { data } = await executeApi().put(
        `${URL_PATH_RATE_MANUAL}/${id}/change-status`
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | unknown) {
      return errorException(error);
    }
  },
  async exportXlsxAction({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().post(
        `${URL_PATH_RATE_MANUAL_EXPORT}`,
        params
      );
      const { success, message, data: dataRequest } = data;

      return { success, message, dataRequest };
    } catch (error: IError | unknown) {
      return errorException(error);
    }
  },
  async getContractsByRateManual({ commit }, { params }): Promise<object> {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH_RATE_MANUAL}/${params.id}/contracts/get`,
        {
          params,
        }
      );
      const { success, message, data: dataRequest } = data;

      commit("setListContract", dataRequest);

      return { success, message, dataRequest };
    } catch (error: IError | unknown) {
      return errorException(error);
    }
  },
  async getFeesByRateManual({ commit }, { params }): Promise<object> {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH_RATE_MANUAL}/${params.id}/service/get?search_in_relations[procedure][]=code&search_in_relations[procedure][]=description`,
        {
          params,
        }
      );
      const { success, message, data: dataRequest } = data;

      commit("setListFees", dataRequest);

      return { success, message, dataRequest };
    } catch (error: IError | unknown) {
      return errorException(error);
    }
  },
  async createServiceAction({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().post(
        `${URL_PATH_RATE_MANUAL}/${params.id}/service`,
        params
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | unknown) {
      return errorException(error);
    }
  },
  async deleteServiceAction({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().delete(
        `${URL_PATH_RATE_MANUAL}/${params.id}/service/${params.service_id}`
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | unknown) {
      return errorException(error);
    }
  },
  async updateServiceAction({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().put(
        `${URL_PATH_RATE_MANUAL}/${params.id}/service/${params.service_id}`,
        params
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | unknown) {
      return errorException(error);
    }
  },
  async changeStatusServiceAction({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().put(
        `${URL_PATH_RATE_MANUAL}/${params.id}/service/${params.service_id}/change-status`
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | unknown) {
      return errorException(error);
    }
  },
};

export default actions;
