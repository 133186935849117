import { GetterTree } from "vuex";
import { ICertificateStateCustom } from "@/interfaces/customs/ICertificatesCustom";
import { StateInterface } from "../index";

const getters: GetterTree<ICertificateStateCustom, StateInterface> = {
  getUserCertificateTable(state) {
    return state.userCertificateTable;
  },

  getUserCertificateInfo(state) {
    return state.userCertificateInfo;
  },

  getCertificateList(state) {
    return state.certificateList;
  },

  getFirstCertificatePages(state) {
    return state.firstCertificatePages;
  },

  getLastCertificatePages(state) {
    return state.lastCertificatePages;
  },

  getCertificateListStatus(state) {
    return state.certificateListStatus;
  },

  getCertificateStatus(state) {
    return state.certificateStatus;
  },
};

export default getters;
