import { MutationTree } from 'vuex';
import { MedicinesReportState } from './state';


const mutation: MutationTree<MedicinesReportState> = {
    setListMedicinesReport(state: MedicinesReportState, { data }) {
        state.dataTable.data.data = data.data;
    },

    setListMedicinesReportCurrentPage(state: MedicinesReportState, { data }){
        state.dataTable.current_page = data;
    },

    setListMedicinesReportLastPage(state: MedicinesReportState, { data }){
        state.dataTable.current_page = data;
    }
}


export default mutation;