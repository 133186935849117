import { IPages } from "@/interfaces/customs/interface";
import { ICareCenter, IDiagnostic, IReferenceCenter, IRequestResponse } from "@/interfaces/global/interfaces";

export interface Datum {
  id: number;
  created_at: null | string;
  updated_at: null | string;
  code: string;
  description: string;
  status: string;
}

export interface Links {
  url: null | string;
  label: string;
  active: boolean;
}

export interface Response {
  success: boolean;
  message: string;
}

// Diagnostics V.2
type DiagnosticsRequests = Record<"diagnostic", boolean>;
type DiagnosticsPages = Record<"diagnostics", IPages>;

export interface DiagnosticsList {
  id: IDiagnostic["id"];
  code: IDiagnostic["code"];
  description: IDiagnostic["description"];
  referenceCenter?: IReferenceCenter['name'] | null;
  careCenter?: ICareCenter['name'] | null;
  status: IDiagnostic["status"];
  isDefault: IDiagnostic["default"];
}

export interface DiagnosticsEdit {
  code: IDiagnostic["code"];
  description: IDiagnostic["description"];
  referenceCenter?: IReferenceCenter['id'] | null;
  careCenter?: ICareCenter['id'] | null;
}

interface DiagnosticsData {
  diagnostics: IDiagnostic[];
  diagnostic: IDiagnostic | null;
}

export interface DiagnosticsState {
  diagnosticCode: string;
  diagnosticDescription: string;

  // Diagnostics V.2
  requests: DiagnosticsRequests;
  data: DiagnosticsData;
  pages: DiagnosticsPages;
  response: IRequestResponse;
}

function state(): DiagnosticsState {
  return {
    diagnosticCode: "",
    diagnosticDescription: "",

    // Diagnostics V.2
    requests: {
      diagnostic: false
    },
    data: {
      diagnostics: [],
      diagnostic: null,
    },
    pages: {
      diagnostics: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
