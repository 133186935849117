import { PhotographsState } from "@/interfaces/global/interfaces";

function state(): PhotographsState {
  return {
    file: null,
    urlBlob: null,
  };
}

export default state;
