import {
  IData,
  IResolution4725,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { ResolutionReports4725State } from "./state";

const mutation: MutationTree<ResolutionReports4725State> = {
  setResolutionReports4725(
    state: ResolutionReports4725State,
    data?: IData
  ): void {
    if (data) {
      state.data.resolutionReports4725 = data.data as IResolution4725[];

      state.pages.resolutionReports4725.currentPage.value = data.current_page;
      state.pages.resolutionReports4725.lastPage.value = data.last_page;
    } else {
      state.data.resolutionReports4725 = [];
    }
  },
  setResolutionReport4725(
    state: ResolutionReports4725State,
    data?: IResolution4725 | null
  ) {
    if (data !== undefined) {
      state.data.resolutionReport4725 = data;
    } else {
      state.data.resolutionReport4725 = null;
    }
  },
  setResponse(state: ResolutionReports4725State, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
