import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { MedicationAdministratorState } from "@/interfaces/global/MedicationAdministrator";

export const useMedicalAdministrator = () => {
  const store = useStore<StateInterface>();
  return {
    // States
    medicationAdministratorList: computed(
      () => store.state.medicalAdministrator.medicationAdministratorList
    ),
    medicalAdministratorCurrentPage: computed(
      () => store.state.medicalAdministrator.medicalAdministratorCurrentPage
    ),
    medicalAdministratorLastPage: computed(
      () => store.state.medicalAdministrator.medicalAdministratorLastPage
    ),
    medicationAdministratorStates: computed(
      () => store.state.medicalAdministrator.states
    ),
    admission_list: computed(
      () => store.state.medicalAdministrator.admission_list
    ),
    presentation_type_list: computed(
      () => store.state.medicalAdministrator.presentation_type_list
    ),
    dosage_form_list: computed(
      () => store.state.medicalAdministrator.dosage_form_list
    ),
    route_administration_list: computed(
      () => store.state.medicalAdministrator.route_administration_list
    ),
    units_mass_list: computed(
      () => store.state.medicalAdministrator.units_mass_list
    ),
    units_time_list: computed(
      () => store.state.medicalAdministrator.units_time_list
    ),
    special_indicators_list: computed(
      () => store.state.medicalAdministrator.special_indicators_list
    ),
    dataTable: computed(() => store.state.medicalAdministrator.data_table),
    dataApi: computed(() => store.state.medicalAdministrator.data_api),
    dataForm: computed(() => store.state.medicalAdministrator.data_form),
    medicalAdministrator: computed(
      () => store.state.medicalAdministrator.medicalAdministrator
    ),

    // Actions
    _getListMedicationAdministrator: (params: object) =>
      store.dispatch(
        "medicalAdministrator/getListMedicationAdministrator",
        params
      ),
    _getByIdMedicationAdministrator: (id: number | string) =>
      store.dispatch("medicalAdministrator/getByIdMedicationAdministrator", id),
    _getMedicinesListByIdMedicationAdministrator: (
      id: number | string,
      page: number
    ) =>
      store.dispatch(
        "medicalAdministrator/getMedicinesListByIdMedicationAdministrator",
        { id, page }
      ),
    _createMedicationAdministrator: (params: {
      patient_id: number;
      from_date: string;
      to_date: string;
      admission: string;
      medicines: object[];
    }) =>
      store.dispatch(
        "medicalAdministrator/createMedicationAdministrator",
        params
      ),
    _createRegisterByAdministredMedicine: (
      params: {
        time: string;
        observation: string;
      },
      id: number | string
    ) =>
      store.dispatch(
        "medicalAdministrator/createRegisterByAdministredMedicine",
        { params, id }
      ),
    _getRegistersByAdministredMedicine: (id: number | string, page: number) =>
      store.dispatch("medicalAdministrator/getRegistersByAdministredMedicine", {
        id,
        page,
      }),
    _deleteRegistersByAdministredMedicine: (id: number | string) =>
      store.dispatch(
        "medicalAdministrator/deleteRegistersByAdministredMedicine",
        id
      ),
    _closeRegisterByAdministredMedicine: (id: number | string, reason: string) =>
      store.dispatch(
        "medicalAdministrator/closeRegisterByAdministredMedicine",
        {id, reason}
      ),

    // Mutation
    _setDataForm: (details: object | null) =>
      store.commit("medicalAdministrator/setDataForm", details),
    _setDataApi: (details: object | null) =>
      store.commit("medicalAdministrator/setDataApi", details),
    _setDataTable: (details: object | null) =>
      store.commit("medicalAdministrator/setDataTable", details),
    _setMedicalAdministrator: (details: MedicationAdministratorState["medicalAdministrator"] | null) =>
      store.commit("medicalAdministrator/setMedicalAdministrator", details),
  };
};
