import { useStore } from "vuex";
import { StateInterface } from '@/store/index';

export const useTimeInactivity = () => {

    const store = useStore<StateInterface>();

    return {
        // Actions
        isGetListTimeInactivity: (params:any) => store.dispatch('timeInactivity/getListTimeInactivity', params),
        isManageTimeInactivity: (data:any) => store.dispatch('timeInactivity/manageTimeInactivity', data),
        isEditTimeInactivity: (data:any) => store.dispatch('timeInactivity/editTimeInactivity', data),
        isGetTimeInactivity: (data:any) => store.dispatch('timeInactivity/getTimeInactivity', data),
    }
}