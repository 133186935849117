import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
    {
      path: "/notas-enfermeria",
      name: "NursingNotesList",
      beforeEnter: [isAuthenticatedGuard],
      component: () =>
        import(
          /* webpackChunkName: "Nursing Notes List" */ "@/views/nursing-notes/list/NursingNotesList.vue"
        ),
    },
    {
      path: "/notas-enfermeria/notas-enfermeria-crear",
      name: "NursingNotesCreate",
      beforeEnter: [isAuthenticatedGuard],
      component: () =>
        import(
          /* webpackChunkName: "Nursing Notes Create" */ "@/views/nursing-notes/create/NursingNotesCreate.vue"
        ),
    },
    {
      path: "/notas-enfermeria/notas-enfermeria-ver/:id(\\d+)",
      name: "NursingNotesView",
      beforeEnter: [isAuthenticatedGuard],
      component: () =>
        import(
          /* webpackChunkName: "Nursing Notes View" */ "@/views/nursing-notes/view/NursingNotesView.vue"
        ),
    },
]