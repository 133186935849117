import {
  IData,
  IReferenceCenter,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { CareCenter, Datum, ReferenceCentersState } from "./state";

const mutation: MutationTree<ReferenceCentersState> = {
  getAllReferenceCenters(state: ReferenceCentersState, data: Datum[]): void {
    if (data) {
      state.allReferenceCenters = data;
    }
  },
  getCareCentersByReferenceCenter(
    state: ReferenceCentersState,
    data: CareCenter[]
  ): void {
    if (data) {
      state.careCentersList = data;
    }
  },

  // Reference Centers V.2
  setReferenceCenters(state: ReferenceCentersState, data?: IData) {
    if (data) {
      state.data.referenceCenters = data.data as IReferenceCenter[];

      state.pages.referenceCenters.currentPage.value = data.current_page;
      state.pages.referenceCenters.lastPage.value = data.last_page;
    } else {
      state.data.referenceCenters = [];
    }
  },
  setReferenceCenter(
    state: ReferenceCentersState,
    data?: IReferenceCenter | null
  ) {
    if (data !== undefined) {
      state.data.referenceCenter = data;
    } else {
      state.data.referenceCenter = null;
    }
  },
  setResponse(state: ReferenceCentersState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
