import { ActionTree } from "vuex";
import { UnificationMedicalRecordsState, RequestResponse } from "./state";
import { StateInterface } from "../index";
import { unificationMedicalRecordsApi } from "@/apis";

const actions: ActionTree<UnificationMedicalRecordsState, StateInterface> = {
  async getUnificationMedicalRecords(
    { commit },
    {
      token,
      page,
      selects,
    }: {
      token: string;
      page: number;
      selects: { referenceCenter: number; careCenter: number; status: string };
    }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await unificationMedicalRecordsApi.get(
        `/list/${selects.referenceCenter}/${selects.careCenter}/${selects.status}?page=${page}`,
        config
      );
      const { success, message, data: unificationMedicalRecordData } = data;

      commit("getUnificationMedicalRecords", unificationMedicalRecordData);
      commit("clearUnificationMedicalRecordsData");
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getAllResources({ commit }, { token }): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await unificationMedicalRecordsApi.get(
        "/getAllResources",
        config
      );
      const { success, message, data: allResourceData } = data;

      commit("getAllResources", allResourceData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async rejectedUnificationMedicalRecord(
    { commit },
    { token, id, bodyRequest }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await unificationMedicalRecordsApi.post(
        `/rejected/${id}`,
        bodyRequest,
        config
      );
      const { success, message } = data;

      commit("clearUnificationMedicalRecordsList");
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async authorizedUnificationMedicalRecord(
    { commit },
    { token, id }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await unificationMedicalRecordsApi.post(
        `/authorize/${id}`,
        {},
        config
      );
      const { success, message } = data;

      commit("clearUnificationMedicalRecordsList");
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async createUnificationMedicalRecord(
    { commit },
    { token, bodyRequest }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      : {};

    try {
      const { data } = await unificationMedicalRecordsApi.post(
        "/",
        bodyRequest,
        config
      );
      const { success, message } = data;

      commit("clearUnificationMedicalRecordsList");
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getUnificationMedicalRecordById(
    { commit },
    { token, id }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await unificationMedicalRecordsApi.get(`/${id}`, config);
      const { success, message, data: unificationMedicalRecordData } = data;

      commit("getUnificationMedicalRecordById", unificationMedicalRecordData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
};

function errorException(error: any) {
  //No hay conexión
  if (error.message == "Network Error") {
    const success = false;
    const { message } = error;
    return { success, message };
  }
  const { data } = error.response;
  const { success, message } = data;
  return { success, message };
}

export default actions;
