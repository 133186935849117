import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/preparaciones",
    name: "PreparationsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () => import("@/views/preparations/list/PreparationsList.vue"),
  },
  {
    path: "/preparaciones/crear",
    name: "PreparationsCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import("@/views/preparations/create/PreparationCreate.vue"),
  },
  {
    path: "/preparaciones/editar/:id",
    name: "PreparationsEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () => import("@/views/preparations/edit/PreparationEdit.vue"),
  },
  {
    path: "/preparaciones/ver/:id",
    name: "PreparationsView",
    beforeEnter: [isAuthenticatedGuard],
    component: () => import("@/views/preparations/view/PreparationView.vue"),
  },
];
