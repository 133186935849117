// import axios from "axios";
import apiBase from "@/apis/AxiosIntance";

export const patientsApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/patients`;
  return apiBase;
};

//GET Index
export const indexApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/attendance`;
  return apiBase;
};

//Years with data
export const yearsWithDataApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/attendance/getYearsData`;
  return apiBase;
};

//Scheduled Patients
export const scheduledPatientsApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/attendance/scheduledPatients`;
  return apiBase;
};

