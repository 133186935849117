import { Module } from 'vuex';
import { StateInterface } from '../index';

import state, { AbsenceState } from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';


const absencesModule: Module<AbsenceState, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default absencesModule;