import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/cuentas-alto-costo-2463",
    name: "HighCostAccounts2463List",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "High Cost Accounts 2463 List" */ "@/views/high-cost-accounts-2463/HighCostAccounts2463List.vue"
      ),
  },
  {
    path: "/cuentas-alto-costo-2463/ver-cuentas-alto-costo-2463/:id(\\d+)",
    name: "HighCostAccounts2463View",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "High Cost Accounts 2463 View" */ "@/views/high-cost-accounts-2463/sub-pages/high-cost-accounts-2463-view/HighCostAccounts2463View.vue"
      ),
  },
  {
    path: "/cuentas-alto-costo-2463/solicitar-cuentas-alto-costo-2463",
    name: "HighCostAccounts2463Create",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "High Cost Accounts 2463 Create" */ "@/views/high-cost-accounts-2463/sub-pages/high-cost-accounts-2463-create/HighCostAccounts2463Create.vue"
      ),
  },
];
