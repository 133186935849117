import { GetterTree } from "vuex";
import { Indicators256State } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<Indicators256State, StateInterface> = {
  getIndicatorsList(state) {
    return state.data.indicators.length >= 1
      ? state.data.indicators.map(
          ({
            id,
            year,
            period,
            time,
            date_generation: date,
            requested_by: requestedBy,
            status,
            excel_url: file,
          }) => ({
            id,
            year,
            period,
            time,
            date,
            requestedBy,
            status,
            file,
          })
        )
      : [];
  },
  getIndicatorView(state) {
    if (state.data.indicator) {
      const {
        reference_center: referenceCenter,
        care_center_name: careCenter,
        year,
        period,
        time,
        requested_by: requestedBy,
        date_generation: date,
        excel_url: file,
        status,
      } = state.data.indicator;

      return {
        referenceCenter,
        careCenter,
        year,
        period,
        time,
        requestedBy,
        date,
        file,
        status:
          status == "cancel"
            ? "Cancelado"
            : status == "pending"
            ? "Pendiente"
            : status == "done"
            ? "Realizado"
            : "",
      };
    }

    return null;
  },
  getDetailsList(state) {
    return state.data.details.length >= 1
      ? state.data.details.map(
          ({
            domain,
            code,
            indicator_name: indicator,
            unit_name: unit,
            numerator,
            denominator,
            result,
          }) => ({
            domain,
            code,
            indicator,
            unit,
            numerator,
            denominator,
            result,
          })
        )
      : [];
  },
};

export default getters;
