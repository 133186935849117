import { isAuthenticatedGuard } from '@/router/auth-guard';

export default [
   
        {
            path: '/listado-reporte-de-medicamentos',
            name: 'MedicinesReportList',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/medicines-report/MedicinesReportList.vue'),
        },

        {
            path: '/crear-reporte-de-medicamentos',
            name: 'MedicinesReportCreate',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/medicines-report/subpages/medicines-report-create/MedicinesReportCreate.vue'),
        },

        {
            path: '/ver-reporte-de-medicamentos/:id',
            name: 'MedicinesReportView',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/medicines-report/subpages/medicines-report-view/MedicinesReportView.vue'),
        }
]