import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/resolucion-202",
    name: "ResolutionReportsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Resolution Reports List" */ "@/views/resolution-reports/ResolutionReportsList.vue"
      ),
  },
  {
    path: "/resolucion-202/ver-resolucion-202/:id(\\d+)",
    name: "ResolutionReportsView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Resolution Reports View" */ "@/views/resolution-reports/sub-pages/resolution-reports-view/ResolutionReportsView.vue"
      ),
  },
  {
    path: "/resolucion-202/solicitar-resolucion-202",
    name: "ResolutionReportsCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Resolution Reports Create" */ "@/views/resolution-reports/sub-pages/resolution-reports-create/ResolutionReportsCreate.vue"
      ),
  },
];
