import { GetterTree } from "vuex";
import { LiquidControlState} from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<LiquidControlState, StateInterface> = {
    getListLiquidsControl(state) {
      return state.liquidControlList;
    },
    getLiquidControlCurrentPage(state) {
      return state.liquidControlCurrentPage;
    },
    getLiquidControlLastPage(state) {
      return state.liquidControlLastPage;
    },
};

export default getters;