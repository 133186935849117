export interface PaginateCentersInterface {
    success: boolean;
    data:    Data;
    message: string;
}

export interface Data {
    current_page:   number;
    data:           Datum[];
    first_page_url: string;
    from:           number;
    next_page_url:  null;
    path:           string;
    per_page:       string;
    prev_page_url:  null;
    to:             number;
}

export interface Datum {
    id:               number;
    created_at:       null;
    updated_at:       null;
    name:             string;
    nit:              string;
    address:          string;
    phone:            string;
    email:            string;
    logo:             null;
    contact_name:     string;
    contact_phone:    string;
    status:           string;
    centers_types_id: number;
    center_types:     CenterTypes;
}

export interface CenterTypes {
    id:          number;
    created_at:  null;
    updated_at:  null;
    description: string;
}

export interface StateCenters {
    care_centers: PaginateCentersInterface;
    referenced_centers: PaginateCentersInterface; 
}

function state(): StateCenters {
    return {
        care_centers: {
            "success": false,
            "data": {
                "current_page": 0,
                "data": [
                    {
                        "id": 1,
                        "created_at": null,
                        "updated_at": null,
                        "name": "",
                        "nit": "",
                        "address": "",
                        "phone": "",
                        "email": "",
                        "logo": null,
                        "contact_name": "",
                        "contact_phone": "",
                        "status": "",
                        "centers_types_id": 0,
                        "center_types": {
                            "id": 1,
                            "created_at": null,
                            "updated_at": null,
                            "description": ""
                        }
                    },
                ],
                "first_page_url": "",
                "from": 0,
                "next_page_url": null,
                "path": "",
                "per_page": "0",
                "prev_page_url": null,
                "to": 2
            },
            "message": ""
        },
        referenced_centers: {
            "success": false,
            "data": {
                "current_page": 0,
                "data": [
                    {
                        "id": 1,
                        "created_at": null,
                        "updated_at": null,
                        "name": "",
                        "nit": "",
                        "address": "",
                        "phone": "",
                        "email": "",
                        "logo": null,
                        "contact_name": "",
                        "contact_phone": "",
                        "status": "",
                        "centers_types_id": 0,
                        "center_types": {
                            "id": 1,
                            "created_at": null,
                            "updated_at": null,
                            "description": ""
                        }
                    },
                ],
                "first_page_url": "",
                "from": 0,
                "next_page_url": null,
                "path": "",
                "per_page": "0",
                "prev_page_url": null,
                "to": 2
            },
            "message": ""
        }

    }
}

export default state;


