import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";

export const useConduct = () => {
  const store = useStore<StateInterface>();
  return {
    // State
    externalsCause: computed(() => [
      "Accidente de trabajo",
      "Accidente de tránsito",
      "Accidente rábico",
      "Accidente ofídico",
      "Otro tipo de accidente",
      "Evento catastrófico",
      "Lesión por agresión",
      "Lesión auto infligida",
      "Sospecha de maltrato físico",
      "Sospecha de abuso sexual",
      "Sospecha de violencia sexual",
      "Sospecha de maltrato emocional",
      "Enfermedad general",
      "Enfermedad profesional",
    ]),
    purposesConsultation: computed(() => [
      "Atención del parto (puerperio)",
      "Atención del recién nacido",
      "Atención en planificación familiar",
      "Detección de alteraciones de crecimiento y desarrollo del menor de diez años",
      "Detección de alteración del desarrollo joven",
      "Detección de alteraciones del embarazo",
      "Detección de alteraciones del adulto",
      "Detección de alteraciones de agudeza visual",
      "Detección de enfermedad profesional",
      "No aplica",
    ]),
    conductValues: computed(() => store.state.emergencies.conductValues),

    // Mutation
    setDataConduct: (details: object | null) =>
      store.commit("emergencies/setDataConduct", details),
  };
};
