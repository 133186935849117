import { IPages } from "@/interfaces/customs/Pages";
import { INote, IRequestResponse } from "@/interfaces/global/interfaces";

type NotesRequests = Record<"note" | "pendingNotes", boolean>;
type NotesPages = Record<"notes", IPages>;

export interface NotesList {
  id: INote["id"];
  description: INote["description"];
  createdAt: INote["created_at_formatted"];
  date: INote["date_formatted"];
  dueDays: INote["due_days"];
  status: INote["status"];
}

export interface NotesEdit {
  _method: "PATCH";
  status: INote["status"];
  date: INote["date_formatted"];
  mail_notification: 1 | 0;
  description: INote["description"];
}

interface NotesData {
  notes: INote[];
  note: INote | null;
  pendingNotes: number | null;
}

export interface NotesState {
  requests: NotesRequests;
  data: NotesData;
  pages: NotesPages;
  response: IRequestResponse;
}

function state(): NotesState {
  return {
    requests: {
      note: false,
      pendingNotes: false,
    },
    data: {
      notes: [],
      note: null,
      pendingNotes: null,
    },
    pages: {
      notes: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
