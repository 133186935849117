import { MutationTree } from 'vuex';
import { Resolution1604of2013State } from './state';


const mutation: MutationTree<Resolution1604of2013State> = {
    setListResolution1604of2013(state: Resolution1604of2013State, { data }) {
        state.dataTable.data.data = data.data;
    },

    setListResolution1604of2013CurrentPage(state: Resolution1604of2013State, { data }){
        state.dataTable.current_page = data;
    },

    setListResolution1604of2013LastPage(state: Resolution1604of2013State, { data }){
        state.dataTable.current_page = data;
    }
}


export default mutation;