import apiBase from "@/apis/AxiosIntance";

//View Indicators
export const viewIndicator = () => {
    apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/indicator-1552`;
    return apiBase;
}
// Indicator List
export const allIndicatorsReports = () => {
    apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/indicator-1552/list`;
    return apiBase;
}

//Indicator Cancel - Indicator List
export const cancelIndicatorsReports = () => {
    apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/indicator-1552/changeStatus`;
    return apiBase;
}

// Indicators Create - Available Years
export const indicatorsYearAvaibilities = () => {
    apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/indicator-1552/yearsAvailabilities`;
    return apiBase;
}

// Indicators Create 
export const indicatorsCreate = () => {
    apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/indicator-1552`;
    return apiBase;
}


