import { ActionTree } from "vuex";
import { Response, ReferenceCentersState } from "./state";
import { StateInterface } from "../index";
import { centersApi, centersApiV2 } from "@/apis";
import errorException from "@/functions/ErrorException";

const CONFIG = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const actions: ActionTree<ReferenceCentersState, StateInterface> = {
  async getAllReferenceCenters({ commit }, { token, type }): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await centersApi().get(`/getByTypeWP/${type}`, config);
      const { success, message, data: referenceCenterData } = data;

      commit("getAllReferenceCenters", referenceCenterData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getCareCentersByReferenceCenter(
    { commit },
    { token, id }
  ): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await centersApi().get(
        `/getCareByReference/${id}`,
        config
      );
      const { success, message, data: careCenterData } = data;

      commit("getCareCentersByReferenceCenter", careCenterData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async changeReferenceCenterStatusById(
    { commit },
    { token, id }
  ): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await centersApi().post(
        `/changeStatus/${id}`,
        {},
        config
      );
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getReferenceCenterById({ commit }, { token, id }): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await centersApi().get(`/${id}`, config);
      const { success, message, data: referenceCenterData } = data;

      commit("getReferenceCenterById", referenceCenterData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async updateReferenceCenter(
    { commit },
    { token, id, formData }
  ): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      : {};

    const newBodyRequest = { id, ...formData };

    try {
      const { data } = await centersApi().post("/", newBodyRequest, config);
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },

  // Reference Centers V.2
  async loadReferenceCenters({ commit }, params) {
    commit("setReferenceCenters");
    commit("setResponse");

    try {
      const { data: response } = await centersApi().get("/getByType/1/", {
        params,
      });
      const { success, message, data } = response;

      commit("setReferenceCenters", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadReferenceCenter({ commit }, id) {
    commit("setReferenceCenter");
    commit("setResponse");

    try {
      const { data: response } = await centersApi().get(`/${id}`);
      const { success, message, data } = response;

      commit("setReferenceCenter", data[0]);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createReferenceCenter({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await centersApiV2().post("", bodyRequest, CONFIG);
      const { success, message } = data;

      commit("setResponse", { success, message });
      return { success, message };
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
};

export default actions;
