import { IPages } from "@/interfaces/customs/Pages";
import {
  IReferenceCenter,
  IRequestResponse,
  ITypeDose,
  IVaccine,
} from "@/interfaces/global/interfaces";

type Requests = Record<"vaccine", boolean>;
type Pages = Record<"vaccines", IPages>;

export interface List {
  id: IVaccine["id"];
  name: IVaccine["name"];
  prevents: IVaccine["disease_it_prevents"];
  status: IVaccine["status"];
  referenceCenter: IVaccine["reference_center_id"];
  careCenter: IVaccine["care_center_id"];
}

export interface Edit {
  name: IVaccine["name"];
  disease_it_prevents: IVaccine["disease_it_prevents"];
  type_doses: number[];
}

export interface View {
  name: IVaccine["name"];
  prevents: IVaccine["disease_it_prevents"];
  doses: number[];
}

interface Data {
  vaccines: IVaccine[];
  vaccine: IVaccine | null;
  typeDoses: ITypeDose[];
}

export interface VaccinesState {
  requests: Requests;
  data: Data;
  pages: Pages;
  response: IRequestResponse;
}

function state(): VaccinesState {
  return {
    requests: {
      vaccine: false,
    },
    data: {
      vaccines: [],
      vaccine: null,
      typeDoses: [],
    },
    pages: {
      vaccines: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
