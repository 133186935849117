import { ActionTree } from "vuex";
import { WarehousesState } from "./state";
import { StateInterface } from "..";
import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";

const URL_PATH_WAREHOUSE = "/api/settings/warehouse";

const actions: ActionTree<WarehousesState, StateInterface> = {
  async loadWarehouses({ commit }, params) {
    commit("setWarehouses");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH_WAREHOUSE}`,
        {
          params,
        }
      );
      const { success, message, data } = response;

      commit("setWarehouses", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },

  async loadWarehouse({ commit }, id) {
    commit("setWarehouse");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH_WAREHOUSE}/${id}`
      );

      const { success, message, data } = response;

      commit("setWarehouse", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },

  async createWarehouse({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH_WAREHOUSE}`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error: any) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },

  async updateWarehouse({ commit }, { id, bodyRequest }) {
    commit("setResponse");

    try {
      const { data } = await executeApi().put(
        `${URL_PATH_WAREHOUSE}/${id}`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },

  async changeWarehouseStatus({ commit }, id) {
    commit("setWarehouses");
    commit("setResponse");

    try {
      const { data } = await executeApi().put(
        `${URL_PATH_WAREHOUSE}/${id}/change-status`
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
};

export default actions;
