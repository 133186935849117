import axios from "axios";
import apiBase from "@/apis/AxiosIntance";


export const surveysApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/survey`;
  return apiBase;
};

export const surveysBasicApi = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/telemedicine/survey`
})
