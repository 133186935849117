import { IMeetResponseFull } from "@/interfaces/customs/MeetResponseCustom";
import { IScheduledTo, ITelesupport } from "@/interfaces/global/interfaces";

export interface TelesupportsList {
  id: ITelesupport["id"];
  careCenter: ITelesupport["care_center"]["name"];
  patient: ITelesupport["patient"]["name"];
  document: ITelesupport["patient"]["document"];
  specialty: ITelesupport["specialty"]["description"];
  date: ITelesupport["created_at"];
  type: ITelesupport["type_telesupport"];
  status: ITelesupport["status"];
  pdf: ITelesupport["pdf_url"];
  createdBy: ITelesupport["created_by"];
  scheduledTo: IScheduledTo["id"];
}

export interface Telesupports {
  patient: ITelesupport["patient"];
  queryType: ITelesupport["query_type"];
  motive: ITelesupport["reason_for_consultation"];
  response: ITelesupport["response"];
  documents: ITelesupport["documents"];
}

export interface TelesupportsState {
  telesupportSchedule: IMeetResponseFull | object
  listRequest: boolean
  telesupportsList: ITelesupport[] | []
  telesupportsCurrentPage: number
  telesupportsLastPage: number
  telesupport: ITelesupport[] | []
}

function state(): TelesupportsState {
  return {
    telesupportSchedule: {},
    listRequest: false,
    telesupportsList: [],
    telesupportsCurrentPage: 0,
    telesupportsLastPage: 0,
    telesupport: [],
  };
}

export default state;
