import { medicalRecordsApiV1 } from "@/apis/medicalRecordsApi";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { MedicalRecordsState } from "./state";

const actions: ActionTree<MedicalRecordsState, StateInterface> = {
  // Medical Records V.2
  async loadPatient({ commit }, id): Promise<void> {
    commit("setPatient");
    commit("setResponse");

    try {
      const { data: response } = await medicalRecordsApiV1().get(
        `/getPatientData/${id}`
      );
      const { success, message, data } = response;

      commit("setPatient", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadFolios({ commit }, { id, page }): Promise<void> {
    commit("setFolios");
    commit("setResponse");

    try {
      const { data: response } = await medicalRecordsApiV1().get(
        `/getFolios/${id}`,
        { params: { page } }
      );
      const { success, message, data } = response;

      commit("setFolios", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadDiagnostics({ commit }, { id, page }): Promise<void> {
    commit("setResponse");
    commit("setDiagnostics");

    try {
      const { data: response } = await medicalRecordsApiV1().get(
        `/getDiagnostics/${id}`,
        { params: { page } }
      );
      const { success, message, data } = response;

      commit("setDiagnostics", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadBackgrounds({ commit }, { id, page }): Promise<void> {
    commit("setResponse");
    commit("setBackgrounds");

    try {
      const { data: response } = await medicalRecordsApiV1().get(
        `/getBackgrounds/${id}`,
        { params: { page } }
      );
      const { success, message, data } = response;

      commit("setBackgrounds", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadProcedures({ commit }, { id, page }): Promise<void> {
    commit("setResponse");
    commit("setProcedures");

    try {
      const { data: response } = await medicalRecordsApiV1().get(
        `/getProcedures/${id}`,
        { params: { page } }
      );
      const { success, message, data } = response;

      commit("setProcedures", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadManagementPlans({ commit }, { id, page }): Promise<void> {
    commit("setResponse");
    commit("setManagementPlans");

    try {
      const { data: response } = await medicalRecordsApiV1().get(
        `/getWorkingPlan/${id}`,
        { params: { page } }
      );
      const { success, message, data } = response;

      commit("setManagementPlans", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadDocuments({ commit }, { id, page }): Promise<void> {
    try {
      const { data: response } = await medicalRecordsApiV1().get(
        `/getDocuments/${id}`,
        { params: { page } }
      );
      const { success, message, data } = response;

      commit("setDocuments", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadClinicalLaboratories({ commit }, { id, page }): Promise<void> {
    commit("setResponse");
    commit("setClinicalLaboratories");

    try {
      const { data: response } = await medicalRecordsApiV1().get(
        `/getClinicalLaboratories/${id}`,
        { params: { page } }
      );
      const { success, message, data } = response;

      commit("setClinicalLaboratories", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadMedicalExams({ commit }, { id, page }): Promise<void> {
    commit("setResponse");
    commit("setMedicalExams");

    try {
      const { data: response } = await medicalRecordsApiV1().get(
        `/getMedicalExams/${id}`,
        { params: { page } }
      );
      const { success, message, data } = response;

      commit("setMedicalExams", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadVaccines({ commit }, { id, page }): Promise<void> {
    commit("setResponse");
    commit("setVaccines");

    try {
      const { data: response } = await medicalRecordsApiV1().get(
        `/getVaccines/${id}`,
        { params: { page } }
      );
      const { success, message, data } = response;

      commit("setVaccines", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadDisabilities({ commit }, { id, page }): Promise<void> {
    commit("setResponse");
    commit("setDisabilities");

    try {
      const { data: response } = await medicalRecordsApiV1().get(
        `/getDisabilities/${id}`,
        { params: { page } }
      );
      const { success, message, data } = response;

      commit("setDisabilities", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadGynecologist({ commit }, id): Promise<void> {
    commit("setGynecologist");
    commit("setResponse");

    try {
      const { data: response } = await medicalRecordsApiV1().get(
        `/getGynecologists/${id}`,
        { params: { paginate: false } }
      );
      const { success, message, data } = response;

      commit("setGynecologist", data[0]["id"] ? data[0] : undefined);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
};

export default actions;
