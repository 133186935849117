import { ActionTree } from "vuex";
import { NursingNotesState } from "./state";
import { StateInterface } from "../index";
import { nursingNotesApi, executeApi } from "@/apis";
import moment from "moment";
import errorException from "@/functions/ErrorException";

const CONFIG = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const URL_PATH = "/api/telemedicine/nursing-notes";
const URL_PATH_GENERATE_DOCUMENTS = "/api/documents/nursing-notes";

const actions: ActionTree<NursingNotesState, StateInterface> = {
  async getNursingNotes({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().get(`${URL_PATH}`, { params });
      const { success, message, data: dataRequest } = data;

      dataRequest.data.forEach((element: any, i: number) => {
        element[
          "patient_name"
        ] = `${element.patient.name} ${element.patient?.last_name}`;
        element["patient_document"] = element.patient.document;
        element["created_at"] = element.created_at
          ? moment(element.created_at).format("DD-MM-YYYY h:mm")
          : null;
      });

      commit("setNursingNotesList", data);
      return { success, message, dataRequest };
    } catch (error) {
      return errorException(error);
    }
  },
  async createNursingNotes({ commit }, { token, bodyRequest, documents }) {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      : {};

    try {
      const { data } = await nursingNotesApi.post(
        "/",
        {
          patient: bodyRequest.patient,
          event_date: bodyRequest.event_date,
          notes: bodyRequest.notes,
          vital_signs: JSON.stringify(bodyRequest.vital_signs),
          documents,
        },
        config
      );
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },

  async createNursingNotesV2({ commit }, bodyRequest) {
    try {
      const { data: response } = await executeApi().post(
        `${URL_PATH}/v2`,
        bodyRequest,
        CONFIG
      );
      const { success, message } = response;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },

  async getDocumentTypesForDownloadV2({ commit }): Promise<object> {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH_GENERATE_DOCUMENTS}/downloads`
      );
      const { success, message, data: dataRequest } = data;

      let id = 1;

      commit(
        "setDocumentTypesForDownload",
        Object.entries(dataRequest).map(([value, name]) => ({
          value,
          name,
          id: id++,
        }))
      );
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },

  async downloadOrSendEmailFiles({ commit }, { id, formData, action }) {
    try {
      const urlPath =
        action === "downloads"
          ? `${URL_PATH_GENERATE_DOCUMENTS}`
          : `${URL_PATH}`;
      const { data: response } = await executeApi().post(
        `${urlPath}/${id}/${action}`,
        formData,
        CONFIG
      );
      const { success, message } = response;

      return { success, message, data: response.data };
    } catch (error) {
      return errorException(error);
    }
  },

  async generateFile({ commit }, { id, url }) {
    try {
      const { data: response } = await executeApi().post(
        `${URL_PATH_GENERATE_DOCUMENTS}/${id}/${url}`
      );
      const { success, message } = response;

      return { success, message, data: response.data };
    } catch (error) {
      return errorException(error);
    }
  },
  async getListProceduresByNursingNoteID({ commit }, id): Promise<object> {
    try {
      const { data } = await executeApi().get(`${URL_PATH}/${id}/procedures`);
      const { success, message } = data;

      commit("setListProceduresByNursingNote", data.data);

      return { success, message, dataEntity: data.data };
    } catch (error) {
      return errorException(error);
    }
  },
  async getNursingNoteByID({ commit }, { token, id }) {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await nursingNotesApi.get(`/${id}`, config);
      const { success, message, data: dataRequest } = data;

      commit("setNursingNote", data);
      return { success, message, dataRequest };
    } catch (error) {
      return errorException(error);
    }
  },
};


export default actions;
