import { ISpecialty } from "@/interfaces/global/interfaces";
import { StateInterface } from "@/store/index";
import { SpecialtiesEdit, SpecialtiesList } from "@/store/specialties/state";
import { computed, toRef } from "vue";
import { useStore } from "vuex";

export const useSpecialtiesStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    specialties: toRef(store.state.specialties.data, "specialties"),
    specialtiesPages: toRef(store.state.specialties.pages, "specialties"),
    specialty: toRef(store.state.specialties.data, "specialty"),
    requests: toRef(store.state.specialties, "requests"),
    data: toRef(store.state.specialties, "data"),
    pages: toRef(store.state.specialties, "pages"),
    response: toRef(store.state.specialties, "response"),

    // Getters
    getSpecialtiesList: computed<SpecialtiesList[]>(
      () => store.getters["specialties/getSpecialtiesList"]
    ),
    getSpecialtiesEdit: computed<SpecialtiesEdit | null>(
      () => store.getters["specialties/getSpecialtiesEdit"]
    ),

    // Actions
    loadSpecialties: (params: {
      paginate?: number;
      page?: number;
      reference_center_id: null | number;
      rows?: number;
      search?: string;
      status?: string;
    }) => store.dispatch("specialties/loadSpecialties", params),
    loadSpecialty: (id: number) =>
      store.dispatch("specialties/loadSpecialty", id),
    createSpecialty: (bodyRequest: {
      description: string;
      reference_center_id: number | null;
    }) => store.dispatch("specialties/createSpecialty", bodyRequest),
    updateSpecialty: (
      id: number,
      bodyRequest: {
        _method: "PATCH";
        description: string;
      }
    ) => store.dispatch("specialties/updateSpecialty", { id, bodyRequest }),
    changeSpecialtyStatus: (id: number) =>
      store.dispatch("specialties/changeSpecialtyStatus", id),
    deleteSpecialty: (id: number) =>
      store.dispatch("specialties/deleteSpecialty", id),

    //Mutations
    setSpecialties: () => store.commit("specialties/setSpecialties"),
    setSpecialty: (data?: ISpecialty) =>
      store.commit("specialties/setSpecialty", data),
  };
};
