import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "main-loader"
}
const _hoisted_2 = { class: "container-image" }
const _hoisted_3 = { class: "loader-title" }
const _hoisted_4 = { class: "loader-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_spinner_dots = _resolveComponent("q-spinner-dots")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.getLoaderMainStatus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_q_img, {
                src: "/images/loader.png",
                class: "animated-image",
                alt: 'loading'
              })
            ]),
            _createVNode(_component_q_spinner_dots, {
              color: "primary",
              size: "4em"
            }),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.titleLoader), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.loaderMessage), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}