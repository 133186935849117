import { Module } from "vuex";
import { StateInterface } from "../index";

import state, { OxygenControlState } from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutation";

const oxygenControlModule: Module<OxygenControlState, StateInterface> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default oxygenControlModule;