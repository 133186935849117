import {
  IData,
  IInitialAttention,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { InitialAttentionsState } from "./state";

const mutation: MutationTree<InitialAttentionsState> = {
  setInitialAttentions(state: InitialAttentionsState, data?: IData): void {
    if (data) {
      state.initialAttentions = data.data as IInitialAttention[] | [];

      state.initialAttentionsPages.currentPage.value = data.current_page;
      state.initialAttentionsPages.lastPage.value = data.last_page;

      state.request.initialAttentions = true;
    } else {
      state.initialAttentions = [];

      state.request.initialAttentions = false;
    }
  },
  setInitialAttention(
    state: InitialAttentionsState,
    data?: IInitialAttention | null
  ) {
    if (data !== undefined) {
      state.initialAttention = data;
      state.request.initialAttention = true;
    } else {
      state.initialAttention = null;
      state.request.initialAttention = false;
    }
  },
  setResponse(state: InitialAttentionsState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
