import { IPages, IPagesV2 } from "@/interfaces/customs/Pages";
import {
  INotification,
  IRequestResponse,
  ITypeNotification,
} from "@/interfaces/global/interfaces";

export type NotificationsPages = Record<"notifications", IPages>;

export interface NotificationsList {
  id: INotification["id"];
  description: INotification["description"];
  type: INotification["type_notification"]["name"];
  date: INotification["created_at"];
  status: INotification["is_read"];
  url: INotification["type_notification"]["url_segment"];
  possibleId: INotification["possible_id"];
  typeId: INotification["type_notification_id"];
}

export interface NotificationsData {
  notifications: INotification[];
  typeNotifications: ITypeNotification[];
}

export interface NotificationsState {
  notificationList: INotification[];
  notificationPagination: IPagesV2;
  notificationLoader: boolean;
  data: NotificationsData;
  pages: NotificationsPages;
  response: IRequestResponse;
}

function state(): NotificationsState {
  return {
    notificationList: [],
    notificationPagination: {
      currentPage: 1,
      lastPage: 1,
    },

    notificationLoader: false,

    data: {
      notifications: [],
      typeNotifications: [],
    },
    pages: {
      notifications: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
