import { GetterTree } from "vuex";
import { AtentionCenterState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<AtentionCenterState, StateInterface> = {
  getSpecialtesName(state) {
    return state.atentionCenterName;
  },

  // Care Centers V.2
  getCareCentersList(state) {
    return state.data.careCenters.length >= 1
      ? state.data.careCenters.map(({ id, name, address, phone, status }) => ({
          id,
          name,
          address,
          phone,
          status,
        }))
      : [];
  },
};

export default getters;
