import { resolution4725Api } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { ResolutionReports4725State } from "./state";

const actions: ActionTree<ResolutionReports4725State, StateInterface> = {
  async loadResolutionReports4725({ commit }, params) {
    commit("setResolutionReports4725");
    commit("setResponse");

    try {
      const { data: response } = await resolution4725Api().get("/", { params });
      const { success, message, data } = response;

      commit("setResolutionReports4725", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadResolutionReport4725({ commit }, id) {
    commit("setResolutionReport4725");
    commit("setResponse");

    try {
      const { data: response } = await resolution4725Api().get(`/${id}`);
      const { success, message, data } = response;

      commit("setResolutionReport4725", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createResolutionReport4725({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await resolution4725Api().post("", bodyRequest);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async cancelResolutionReport4725({ commit }, id) {
    commit("setResponse");

    try {
      const { data } = await resolution4725Api().post(`/cancel/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async loadEPSWithData({ commit }, params) {
    commit("selectors/setResponse", undefined, { root: true });
    commit("selectors/setEPS", undefined, { root: true });

    try {
      const { data: response } = await resolution4725Api().get("/eps", {
        params,
      });
      const { success, message, data } = response;

      commit("selectors/setEPS", data, { root: true });
      commit(
        "selectors/setResponse",
        { success, message, of: "eps" },
        { root: true }
      );
    } catch (error) {
      const response = errorException(error);
      commit(
        "selectors/setResponse",
        { ...response, of: "eps" },
        { root: true }
      );
    }
  },
  async loadEPSWithReports({ commit }, params) {
    commit("selectors/setResponse", undefined, { root: true });
    commit("selectors/setEPS", undefined, { root: true });

    try {
      const { data: response } = await resolution4725Api().get("reports/eps", {
        params,
      });
      const { success, message, data } = response;

      commit("selectors/setEPS", data, { root: true });
      commit(
        "selectors/setResponse",
        { success, message, of: "eps" },
        { root: true }
      );
    } catch (error) {
      const response = errorException(error);
      commit(
        "selectors/setResponse",
        { ...response, of: "eps" },
        { root: true }
      );
    }
  },
};

export default actions;
