import { GetterTree } from "vuex";
import { AdvanceDirectivesState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<AdvanceDirectivesState, StateInterface> = {
  getAdvanceDirectivesList(state) {
    return state.data.advanceDirectives.length >= 1
      ? state.data.advanceDirectives.map(
          ({
            id,
            created_at_formatted: date,
            patient,
            status,
            pdf_url: url,
          }) => ({
            id,
            date,
            name: patient?.name,
            lastName: patient?.last_name,
            document: patient?.document,
            status,
            url,
            fullName:
              patient?.full_name ?? `${patient?.name} ${patient?.last_name}`,
          })
        )
      : [];
  },
  getCareDirectives(state) {
    return state.data.directives.length >= 1
      ? state.data.directives.filter(({ type }) => type == "care")
      : [];
  },
  getEOLDirectives(state) {
    return state.data.directives.length >= 1
      ? state.data.directives.filter(({ type }) => type == "eol")
      : [];
  },
  getDonationDirectives(state) {
    return state.data.directives.length >= 1
      ? state.data.directives.filter(({ type }) => type == "donation")
      : [];
  },
  getIndicationDirectives(state) {
    return state.data.directives.length >= 1
      ? state.data.directives.filter(({ type }) => type == "indication")
      : [];
  },
};

export default getters;
