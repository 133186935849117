import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";

export const useRateManual = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    rateManualById: computed(() => store.state.rateManual.rateManual),
    rateManualList: computed(() => store.state.rateManual.rateManualList),
    rateManualContractsList: computed(
      () => store.state.rateManual.rateManualContractsList
    ),
    rateManualFeesList: computed(
      () => store.state.rateManual.rateManualFeesList
    ),
    rateManualCurrentPage: computed(
      () => store.state.rateManual.rateManualCurrentPage
    ),
    rateManualLastPage: computed(
      () => store.state.rateManual.rateManualLastPage
    ),

    // Actions
    _getRatesManual: (params: object) =>
      store.dispatch("rateManual/getRatesManual", params),
    _getRateManualById: (id: number | string) =>
      store.dispatch("rateManual/getRateManualById", id),
    _createRateManual: (params: object) =>
      store.dispatch("rateManual/createAction", params),
    _createServiceAction: (params: {
      rate: string | number;
      value: string | number;
      procedure_id: number;
      subprogram_id: number;
      id: number;
    }) => store.dispatch("rateManual/createServiceAction", params),
    _updateServiceAction: (params: {
      rate: string | number;
      value: string | number;
      procedure_id: number;
      subprogram_id: number;
      id: number;
      service_id: number | string;
    }) => store.dispatch("rateManual/updateServiceAction", params),
    _deleteServiceAction: (params: { service_id: number; id: number }) =>
      store.dispatch("rateManual/deleteServiceAction", params),
    _changeStatusService: (params: { service_id: number; id: number }) =>
      store.dispatch("rateManual/changeStatusServiceAction", params),
    _updateRateManual: (params: object, id: number | string) =>
      store.dispatch("rateManual/updateAction", { params, id }),
    _deleteAction: (id: number | string) =>
      store.dispatch("rateManual/deleteAction", id),
    _changeStatusAction: (id: number | string) =>
      store.dispatch("rateManual/changeStatusAction", id),
    _exportXlsxAction: (params: object) =>
      store.dispatch("rateManual/exportXlsxAction", params),
    _getContractsByRateManual: (params: {
      paginate: boolean;
      rows_per_page: number;
      id: number | string;
      page: number;
    }) =>
      store.dispatch("rateManual/getContractsByRateManual", {
        params,
      }),
    _getFeesByRateManual: (params: object) =>
      store.dispatch("rateManual/getFeesByRateManual", {
        params,
      }),
  };
};
