// vue x
import { ActionTree } from "vuex";
import { StateInterface } from "../../index";

// api
import {
  ListDisabilities,
  FindByDocument,
  GetAllResources,
  CreateDisability,
  GetDisabilityInfoByID,
  SendPDFDisabilityID,
} from "@/apis/patients/disabilitiesApi";

// composables
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { useAlertV2 } from "@/composables/useAlertV2";

// interfaces
import { DisabilityUI } from "./state";
import {
  InfoDisabilitieUI,
  DiagnosticUI,
  PatientUI,
  DisabilitiesTableUI,
  DisabilityEditUI,
  DisabilityCreateUI,
} from "@/interfaces/global/Disabilities";
import { ErrorsUI } from "@/interfaces/customs/ErrorException";

const { showAlert } = useAlertV2();

const { openMainLoader } = useMainLoader();
const { showCatchError } = showErrorInTS();
const actions: ActionTree<DisabilityUI, StateInterface> = {
  async DisabilityUpdateTable(
    { commit },
    { token, goToURL }: { token: string; goToURL: string }
  ) {
    openMainLoader(true);
    if (!goToURL) {
      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit(
        "general/setAlertMessage",
        `¡No fue posible actualizar las listas de incapacidades!`,
        { root: true }
      );
      commit("general/setAlertType", "error", { root: true });
      openMainLoader(false);
    }

    try {
      const disabilitiesTable = await ListDisabilities.get(`?${goToURL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const tableData = disabilitiesTable.data.data.data.map(
        (tableInfo: DisabilitiesTableUI) => ({
          age: tableInfo.patient.age,
          cause: tableInfo.cause,
          careCenter: tableInfo.patient.care_center.name,
          careCenterId: tableInfo.patient.care_center.id,
          dateFrom: tableInfo.start_date.slice(0, 10),
          dateEnd: tableInfo.end_date.slice(0, 10),
          days: tableInfo.days,
          description: tableInfo.description,
          diagnosticId: tableInfo.diagnostic.id,
          diagnosticCode: tableInfo.diagnostic.code,
          diagnosticName: tableInfo.diagnostic.description,
          disabilityId: tableInfo.id,
          documentId: tableInfo.patient.document,
          documentType: tableInfo.patient.document_type.name,
          documentTypeId: tableInfo.patient.document_type.id,
          email: tableInfo.patient.email,
          epsId: tableInfo.patient.eps.id,
          epsName: tableInfo.patient.eps.name,
          epsCode: tableInfo.patient.eps.code,
          gender: tableInfo.patient.gender,
          grade: tableInfo.grade,
          patient: tableInfo.patient.name + " " + tableInfo.patient.last_name,
          patientId: tableInfo.patient.id,
          pdfURL: tableInfo.pdf_url,
          type: tableInfo.type,
        })
      );

      const desabilitiesCurrentPage = disabilitiesTable.data.data.current_page;
      const desabilitiesLastPage = disabilitiesTable.data.data.last_page;

      commit("setDisabilitiesTableInfo", tableData);
      commit("setDesabilitiesLastPage", desabilitiesLastPage);
      commit("setDesabilitiesCurrentPage", desabilitiesCurrentPage);

      // loader
      openMainLoader(false);

      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit(
        "general/setAlertMessage",
        `¡${
          tableData.length > 0
            ? "Se han listado las incapacidades"
            : "No se han generado incapacidades"
        }!`,
        { root: true }
      );
      commit("general/setAlertType", "success", { root: true });
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      // loader
      openMainLoader(false);
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async DisabilityView(
    { commit },
    { token, disabilityID }: { token: string; disabilityID: number }
  ) {
    openMainLoader(true);
    try {
      const disabilitiesTable = await GetDisabilityInfoByID.get(
        `/${disabilityID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const tableInfo: InfoDisabilitieUI = disabilitiesTable.data.data;
      const disabilityInfo = {
        age: tableInfo.patient.age,
        careCenter: tableInfo.patient.care_center.name,
        cause: tableInfo.cause,
        careCenterId: tableInfo.patient.care_center.id,
        dateEnd: tableInfo.end_date_formatted,
        dateFrom: tableInfo.start_date_formatted,
        days: tableInfo.days,
        diagnostic: tableInfo.diagnostic.description,
        disabilityId: tableInfo.id,
        document: tableInfo.patient.document,
        documentId: tableInfo.patient.document_type_id,
        documentType: tableInfo.patient.document_type.name,
        description: tableInfo.description,
        gender: tableInfo.patient.gender,
        email: tableInfo.patient.email,
        epsName: tableInfo.patient.eps.name,
        epsId: tableInfo.patient.eps.id,
        epsCode: tableInfo.patient.eps.code,
        grade: tableInfo.grade,
        patient: `${tableInfo.patient.name} ${tableInfo.patient.last_name}`,
        patientId: tableInfo.patient.id,
        pdfURL: tableInfo.pdf_url,
        type: tableInfo.type,
      };

      const disabilitiesMessage = disabilitiesTable.data.message;
      commit("setDisabilityInfoById", disabilityInfo);

      // loader
      openMainLoader(false);

      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", `¡${disabilitiesMessage}!`, {
        root: true,
      });
      commit("general/setAlertType", "success", { root: true });
      return "success";
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      // loader
      openMainLoader(false);
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async DisabilityCreate(
    { commit },
    {
      token,
      desabilityCreateRequest,
    }: { token: string; desabilityCreateRequest: DisabilityCreateUI }
  ) {
    openMainLoader(true);

    try {
      const disabilitiesCreate = await CreateDisability.post(
        "/",
        desabilityCreateRequest,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const disabilitiesCreateMessage = disabilitiesCreate.data.message;
      // loader
      openMainLoader(false);

      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", `${disabilitiesCreateMessage}`, {
        root: true,
      });
      commit("general/setAlertType", "success", { root: true });
      return "success";
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      // loader
      openMainLoader(false);
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
      return "error";
    }
  },

  async DisabilityUpdate(
    { commit },
    {
      token,
      desabilityEditRequest,
    }: { token: string; desabilityEditRequest: DisabilityEditUI }
  ) {
    openMainLoader(true);

    try {
      const disabilitiesUpdate = await CreateDisability.post(
        `/${desabilityEditRequest.disabilityId}`,
        desabilityEditRequest,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const disabilitiesCreateMessage = disabilitiesUpdate.data.message;
      // loader
      openMainLoader(false);

      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", `${disabilitiesCreateMessage}`, {
        root: true,
      });
      commit("general/setAlertType", "success", { root: true });
      return "success";
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      // loader
      openMainLoader(false);
      // alert
      showAlert(String(showCatchError(errorParse)), "error");

      return "error";
    }
  },

  async FindPatientByDocument(
    { commit },
    { token, userDocument }: { token: string; userDocument: string }
  ) {
    openMainLoader(true);
    try {
      const findDocument = await FindByDocument.get(`/${userDocument}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const findDocumentDataRef: PatientUI = findDocument.data.data;

      const findDocumentData = {
        name: findDocumentDataRef.name,
        document: findDocumentDataRef.document_type.name,
        documentNumber: findDocumentDataRef.document,
        documentId: findDocumentDataRef.document_type.id,
        age: findDocumentDataRef.age,
        gender: findDocumentDataRef.gender,
        eps: findDocumentDataRef.eps.name,
        epsId: findDocumentDataRef.eps.id,
        userId: findDocumentDataRef.id,
      }

      commit("setCurrentPatientSearch", findDocumentData);
      const findDocumentMessage: string = findDocument.data.message;

      // loader
      openMainLoader(false);

      // alert
      showAlert(`¡${findDocumentMessage}!`, "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      // loader
      openMainLoader(false);
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async FindDiagnostic({ commit }, { token }: { token: string }) {
    openMainLoader(true);
    try {
      const diagnostics = await GetAllResources.get(`/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const diagnosticOptions = diagnostics.data.data.map(
        (diagnostic: DiagnosticUI) => {
          return {
            label: diagnostic.description,
            id: diagnostic.id,
            code: diagnostic.code,
            status: diagnostic.code,
            fullReference: `${diagnostic.code} | ${diagnostic.description}`,
          };
        }
      );

      const diagnosticsMessage = diagnostics.data.message;

      commit("setDiagnosticOptions", diagnosticOptions);
      // loader
      openMainLoader(false);

      // alert
      showAlert(`¡${diagnosticsMessage}!`, "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      // loader
      openMainLoader(false);
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async SendPDFDisabilityID(
    { commit },
    { token, desabilityId }: { token: string; desabilityId: number }
  ) {
    commit("general/setPopupLoading", true, { root: true });
    commit("general/setPopupLoadingMessage", "Enviando Incapacidad", {
      root: true,
    });

    try {
      const disabilitiesSendEmail = await SendPDFDisabilityID.post(
        `/${desabilityId}/email`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const disabilitiesSendEmailMessage: string =
        disabilitiesSendEmail.data.message;

      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });

      // alert
      showAlert(`¡${disabilitiesSendEmailMessage}!`, "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });

      // alert
      showAlert(`¡${showCatchError(errorParse)}!`, "error");
    }
  },
};

export default actions;
