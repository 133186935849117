import { ICancellationReason } from "@/interfaces/global/ICancellationReason";

function state(): ICancellationReason {
  return {
    success: true || false,
    data: {
      current_page: null,
      data: [],
      first_page_url: "",
      from: null,
      last_page: null,
      last_page_url: "",
      links: [],
      next_page_url: null,
      path: "",
      per_page: null,
      prev_page_url: null,
      to: null,
      total: null,
    } || {
      id: null,
      reason: "",
      observation: "",
      status: "",
      created_at: "",
      updated_at: "",
      reference_center: {
        id: null,
        name: "",
        referenceCenter: null,
      },
      created_by: {
        id: null,
        user_info_id: null,
        name: "",
        details: {
          id: null,
          names: "",
          surnames: "",
        },
      },
    },
    message: "",
  };
}

export default state;
