import apiBase from "@/apis/AxiosIntance";

export const emergenciesPatientApi = (filter: any | null = null, page = 1) => {
  const urlBase = new URL(
    `${process.env.VUE_APP_URL}/api/telemedicine/patient-urgency`
  );
  if (filter) {
    for (const property in filter) {
      if (filter[property]) {
        urlBase.searchParams.append(property, filter[property]);
      }
    }
  }
  if (page > 0) {
    urlBase.searchParams.append("page", `${page}`);
  }

  apiBase.defaults.baseURL = urlBase.href;

  return apiBase;
};

export const emergenciesTriageApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/patient-triage`;
  return apiBase;
};
