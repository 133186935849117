import { GetterTree } from "vuex";
import { ScheduleReport, ScheduleReportsState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<ScheduleReportsState, StateInterface> = {
  getScheduleReportsList(state) {
    return state.scheduleReportsList;
  },
  getScheduleReportsCurrentPage(state) {
    return state.scheduleReportsCurrentPage;
  },
  getScheduleReportsLastPage(state) {
    return state.scheduleReportsLastPage;
  },
  getScheduleReport(state) {
    return [{ ...state.scheduleReport }].map(
      ({
        reference_center,
        care_center,
        type_teleexpertise,
        user,
        created_at_formatted: generatedDate,
        from_date_formatted: fromDate,
        status,
        patient,
        to_date_formatted: toDate,
        pdf_url: pdf,
        excel_url: excel,
      }): ScheduleReport => ({
        referenceCenter: reference_center?.name,
        careCenter: care_center?.name,
        type: type_teleexpertise?.name,
        requestedBy: user?.name,
        generatedDate,
        fromDate,
        status,
        patient: patient ? `${patient.name} ${patient.last_name}` : "Todos",
        toDate,
        pdf,
        excel,
      })
    )[0];
  },
  getReportTelepextisesList(state) {
    return state.reportTelexpertisesList;
  },
  getReportTelepextisesCurrentPage(state) {
    return state.reportTelexpertisesCurrentPage;
  },
  getReportTelepextisesLastPage(state) {
    return state.reportTelexpertisesLastPage;
  },
};

export default getters;
