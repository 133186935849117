import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/contratos/lista-de-contratos",
    name: "ContractView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Contract List View" */
        "@/views/contracts-view/ContractView.vue"
      ),
    children: [
      {
        path: '',
        name: 'ContractListView',
        beforeEnter: [isAuthenticatedGuard],
        component: () =>
          import(
            /* webpackChunkName: "Indicators Table List View" */
            "@/views/contracts-view/sub-pages/contracts-list/ContractListView.vue"
          ),
      },
      {
        path: "ver-contrato/:id",
        name: "ContractInfoView",
        beforeEnter: [isAuthenticatedGuard],
        component: () =>
          import(
            /* webpackChunkName: "Contract View" */
            "@/views/contracts-view/sub-pages/view-contract/ContractInfoView.vue"
          ),
      },
      {
        path: "editar-contrato/:id",
        name: "contractEditView",
        beforeEnter: [isAuthenticatedGuard],
        component: () =>
          import(
            /* webpackChunkName: "Contract Edit View" */ "@/views/contracts-view/sub-pages/edit-contract/ContractEditView.vue"
          ),
      },
      {
        path: "crear-contrato",
        name: "contractCreateView",
        beforeEnter: [isAuthenticatedGuard],
        component: () =>
          import(
            /* webpackChunkName: "Contract Create View" */ "@/views/contracts-view/sub-pages/create-contract/ContractCreateView.vue"
          ),
      },
    ],
  },
];
