import { executeApi } from "@/apis";
import { PreparationsState } from "@/interfaces/global/interfaces";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import errorException from "@/functions/ErrorException";

const URL_PATH = "/api/telemedicine/preparation";

const headers = {
  "Content-Type": "multipart/form-data",
};

const actions: ActionTree<PreparationsState, StateInterface> = {
  async getListPreparations({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().get(URL_PATH, { params });
      const { success, message } = data;

      commit("setPreparationsList", data);

      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async createPreparation({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().post(`${URL_PATH}`, params);
      const { success, message } = data;

      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async getPreparationsById({ commit }, id): Promise<object> {
    try {
      const { data } = await executeApi().get(`${URL_PATH}/${id}`);
      const { success, message } = data;

      commit("setPreparation", data.data);

      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async getRecomendationsByPreparationId(
    { commit },
    { params, id }
  ): Promise<object> {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH}/${id}/recomendations`,
        { params }
      );
      const { success, message } = data;

      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async editPreparation({ commit }, { params, id }): Promise<object> {
    try {
      const { data } = await executeApi().post(`${URL_PATH}/${id}`, params);
      const { success, message } = data;

      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async changeStatusPreparation({ commit }, id): Promise<object> {
    try {
      const { data } = await executeApi().post(`${URL_PATH}/${id}/toggle`);
      const { success, message } = data;

      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async searchExistingPreparation({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().post(`${URL_PATH}/search`, params);
      const { success, message } = data;

      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async deletePreparation({ commit }, id): Promise<object> {
    try {
      const { data } = await executeApi().delete(`${URL_PATH}/${id}`);
      const { success, message } = data;

      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },
};

export default actions;
