import { Module } from 'vuex';
import { StateInterface } from '../index';

import state from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { ICertificateStateCustom } from '@/interfaces/customs/ICertificatesCustom';

const contractsModule: Module<ICertificateStateCustom, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default contractsModule;