export interface JobListUI {
  jobList: {
    availableHour: string
    careCenter: string
    date: string
    document: string
    finalHour: string
    id: number
    meetURL: string
    name: string,
    patient: string
    priority: string
    status: string
    telexperticeType: string
    type: string
    serviceTypeId: number
  }[]



  jobListCurrentPage: number
  jobListLastPage: number

  jobCounterList: {
    title: string,
    count: number
  }[]
  telexperticeTypeSelector: number | null
}

function state(): JobListUI {
  return {
    jobList: [],
    jobListCurrentPage: 0,
    jobListLastPage: 0,
    jobCounterList: [],

    telexperticeTypeSelector: null,
  };
}

export default state;
