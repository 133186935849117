import { ActionTree } from "vuex";
import { UsersState } from "./state";
import store, { StateInterface } from "../index";
import { authApi, centersApi, executeApi } from "@/apis";
import { contractsApi } from "@/apis/contractApi";
import { availableCenterRefOptions } from "@/apis/availableApi";

import { IUserFullCustom } from "@/interfaces/customs/userCustom";
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";
import { useAlertV2 } from "@/composables/useAlertV2";
import { authApiV2 } from "@/apis/loginApi";
import { ErrorsUI } from "@/interfaces/customs/ErrorException";
import { IDasboardTrends } from "@/interfaces/global/IDashboard";
import { IDataInfoTrend } from "@/interfaces/customs/IDashboard";
import errorException from "@/functions/ErrorException";

const { openMainLoader } = useMainLoader();
const { showCatchError } = showErrorInTS();
const { showAlert } = useAlertV2();

const URL_PATH = "/api/users";
const CONFIG = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const actions: ActionTree<UsersState, StateInterface> = {
  async getUsersByIdRol({ commit }, data) {
    const { token, id, page, center_id = undefined } = data;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await authApi.get(
        `/getByRol/${id}?page=${page}&centers_id=${center_id}`,
        config
      );
      commit("setCenterAdmins", { data });
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getUsersByIdRolFilter({ commit }, data) {
    const { token, id, page, filters } = data;
    if (!filters) return;
    if (filters.status == 'all') filters.status = null;
    filters.centers_id;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        ...filters,
      }
    };

    try {
      const route = `/getByRol/${id}`;
      const { data } = await authApi.get(route, config);
      commit("setCenterAdmins", { data });
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getAdminCareByIdCc({ commit }, info) {
    const { token, id } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await authApi.get(`/getByCenter/${id}`, config);
      commit("setCenterAdmins", { data });
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getCentersForType({ commit }, info) {
    const { token, id } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await availableCenterRefOptions.get(`/${id}`, config);
      commit("setCenterAdmins", { data });
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getFullCenters({ commit }, info) {
    const { token, id } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const route = `/getByType/${id}?paginate=false`;
      const { data } = await centersApi().get(route, config);
      commit("setCenterAdmins", { data });
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getUserByDocument({ commit }, info) {
    const { token, id } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await authApi.get(`/getByDocument/${id}`, config);
      commit("setCenterAdmins", { data });
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getUserByDocumentV2({ commit }, info) {
    const { token, document, document_type_id  } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    try {
      const { data } = await authApi.get(`/getByDocument/v2/?document_type_id=${document_type_id}&document=${document}`, config);
      commit("setCenterAdmins", { data });
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getHomeInfo(_, data) {
    const { token } = data;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await authApi.get(`/getHomeInfo`, config);
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async LoadHomeInfo({ commit }) {
    openMainLoader(true);
    const infoTrendsUrl = `/getHomeInfo`;
    try {
      const loadInfoTrend = await authApiV2().get(infoTrendsUrl);
      const dataInfo: IDataInfoTrend[] = loadInfoTrend.data.data;
      const dataInfoMessage = loadInfoTrend.data.message;
      commit("setDataInfoTrend", dataInfo[0]);
      showAlert(dataInfoMessage, "success");
      openMainLoader(false);
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      openMainLoader(false);
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async LoadHomeTrends({ commit }) {
    openMainLoader(true);

    const dateTrendsUrl = `/getHomeTrends`;

    try {
      const loadTrends = await authApiV2().get(dateTrendsUrl);
      const infoTrends: IDasboardTrends = loadTrends.data.data[0];
      const setupDashboardGraphic = {
        monthly: {
          labels: infoTrends.monthlyTrend.labels,
          datasets: infoTrends.monthlyTrend.datasets.map((trend) => ({
            label: trend.label,
            backgroundColor: trend.backgroundColor,
            tension: trend.tension,
            data: trend.data,
            borderColor: trend.backgroundColor,
          })),
          maxCounter: infoTrends.monthlyTrend.max,
        },

        yearly: {
          labels: infoTrends.yearlyTrend.labels,
          datasets: infoTrends.yearlyTrend.datasets.map((trend) => ({
            label: trend.label,
            backgroundColor: trend.backgroundColor,
            tension: trend.tension,
            data: trend.data,
            borderColor: trend.backgroundColor,
          })),
          maxCounter: infoTrends.yearlyTrend.max,
        },
      };
      commit("setInfoTrends", setupDashboardGraphic);

      openMainLoader(false);
      showAlert(loadTrends.data.message, "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      openMainLoader(false);
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async editAdmin(_, info) {
    const { user, token } = info;
    const config = {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    };

    try {
      const { data } = await authApi.post(`/register`, { ...user }, config);
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async changeUserStatus(_, info) {
    const { id, token } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await authApi.post(`/changeStatus/${id}`, {}, config);
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getContractById(_, info) {
    const { id, token } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await contractsApi.get(`/${id}`, config);
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async registerUserByRol(_, info) {

    const { token, formData } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const { data } = await authApi.post('/register', formData, config);
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  /* Permissions */
  async getPermissions({ /* commit */ }, info) {
    const { token, role, params } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params
    };

    try {
      const { data } = await authApi.get(`/permission/${role}`, config);
      /* commit("setCenterAdmins", { data }); */
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getSearchPermissions({ /* commit */ }, info) {
    const { token, role, params } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params
    };

    try {
      const { data } = await authApi.get(`/permission/${role}`, config);
      /* commit("setCenterAdmins", { data }); */
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },
  /* Permissions */
  async updateProfileInformation(_, payload) {
    try {
      const { data } = await executeApi().post(
        `${URL_PATH}/update`,
        payload,
        CONFIG
      );
      const { success, message, data: dataUser } = data;

      store.commit("auth/updateProfile", dataUser);

      return { success, message, dataUser };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async addAuthOTP2FA(_, payload) {
    try {
      const { data } = await executeApi().post(
        `${URL_PATH}/update`,
        payload,
        CONFIG
      );
      const { success, message, data: dataUser } = data;

      store.commit("auth/updateProfile", dataUser);

      return { success, message, dataUser };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async getLoginOTPOptions({ commit }) {
    try {
      const { data } = await executeApi().get(`${URL_PATH}/getLoginOptions`);
      const { success, message } = data;

      commit("setListOTPOptions", data.data);
      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async getLoginOTPMethods({ commit }) {
    try {
      const { data } = await executeApi().get(`${URL_PATH}/getLoginMethods`);
      const { success, message } = data;

      commit("setListOTPMethods", data.data);
      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async getLoginOTPQuestions({ commit }) {
    try {
      const { data } = await executeApi().get(`${URL_PATH}/getLoginQuestions`);
      const { success, message } = data;

      commit("setLoginOTPQuestions", data.data);
      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async createOTPMethod({ commit }, payload) {
    try {
      const { data } = await executeApi().post(
        `${URL_PATH}/enable2fa`,
        payload,
        CONFIG
      );
      const { success, message } = data;

      return { success, message, data };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async disableMethodAuthOTP({ commit }, payload) {
    try {
      const { data } = await executeApi().post(
        `${URL_PATH}/disable2fa`,
        payload,
        CONFIG
      );
      const { success, message } = data;

      return { success, message, data };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async removeMethodAuthOTP({ commit }, payload) {
    try {
      const { data } = await executeApi().post(
        `${URL_PATH}/remove2fa`,
        payload,
        CONFIG
      );
      const { success, message } = data;

      return { success, message, data };
    } catch (error: any) {
      return errorException(error);
    }
  },
};

function errorExeption(error: any) {
  //No hay conexion
  if (error.message == "Network Error") {
    const success = false;
    const { message } = error;
    return { success, message };
  }
  const { data } = error.response;
  const { success, message } = data;
  return { success, message };
}

export default actions;
