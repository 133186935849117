import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";

export const useTelemedicineReport = () => {
  const store = useStore<StateInterface>();
  return {
    // States
    list: computed(() => store.state.telemedicineReport.list),
    currentPage: computed(() => store.state.telemedicineReport.currentPage),
    lastPage: computed(() => store.state.telemedicineReport.lastPage),
    years: computed(() => store.state.telemedicineReport.years),
    months: computed(() => store.state.telemedicineReport.months),

    // Actions
    _getList: (params: object) =>
      store.dispatch("telemedicineReport/listAction", params),
    _getListYearsSelector: (params: object) =>
      store.dispatch("telemedicineReport/listYearsSelectorAction", params),
    _getListMonthsSelector: (params: object) =>
      store.dispatch("telemedicineReport/listMonthsSelectorAction", params),
    _generateReportXLSX: (params: object) =>
      store.dispatch("telemedicineReport/generateReport", params),
  };
};
