import { AdvanceDirectiveReportState } from "@/interfaces/global/AdvanceDirectiveReport";
import { Module } from "vuex";
import { StateInterface } from "../index";

import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

const advanceDirectiveReportModule: Module<
  AdvanceDirectiveReportState,
  StateInterface
> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default advanceDirectiveReportModule;
