import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/cuentas-alto-costo-4725",
    name: "Resolution4725List",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Resolution 4725 List" */ "@/views/resolution-4725/Resolution4725List.vue"
      ),
  },
  {
    path: "/cuentas-alto-costo-4725/ver-cuentas-alto-costo-4725/:id(\\d+)",
    name: "Resolution4725View",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Resolution 4725 View" */ "@/views/resolution-4725/sub-pages/resolution-4725-view/Resolution4725View.vue"
      ),
  },
  {
    path: "/cuentas-alto-costo-4725/solicitar-cuentas-alto-costo-4725",
    name: "Resolution4725Create",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Resolution 4725 Create" */ "@/views/resolution-4725/sub-pages/resolution-4725-create/Resolution4725Create.vue"
      ),
  },
];
