import { GetterTree } from "vuex";
import { ResolutionReports4725State } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<ResolutionReports4725State, StateInterface> = {
  getResolutionReports4725List(state) {
    return state.data.resolutionReports4725.length >= 1
      ? state.data.resolutionReports4725.map(
        ({
          id,
          from_date_formatted: fromDate,
          to_date_formatted: toDate,
          created_at_formatted: date,
          user,
          care_center,
          reference_center,
          eps,
          status,
          url,
        }) => ({
          id,
          fromDate,
          toDate,
          date,
          requestedBy: user?.name,
          careCenter: care_center?.name,
          referenceCenter: reference_center?.name,
          eps: eps?.name,
          status,
          url,
        })
      )
      : [];
  },
  getResolutionReport4725View(state) {
    if (state.data.resolutionReport4725) {
      const {
        from_date_formatted: fromDate,
        to_date_formatted: toDate,
        created_at_formatted: date,
        user,
        care_center,
        reference_center,
        eps,
        status,
        regime,
        url
      } = state.data.resolutionReport4725;

      return {
        fromDate,
        toDate,
        date,
        requestedBy: user?.name,
        careCenter: care_center?.name,
        referenceCenter: reference_center?.name,
        eps: eps?.name,
        status,
        regime: regime?.name ?? "Todos",
        url
      };
    }

    return null;
  },
};

export default getters;
