import { App } from "vue";
import { AblyService } from "@/services/ably.service";

export default {
  install: (app: App): void => {
    const initializeAbly = () => {
      const ablyService = AblyService.getInstance();
      if (ablyService) {
        app.provide("ablyService", ablyService);
        app.config.globalProperties.$ably = ablyService;
      }
    };

    initializeAbly();

    app.config.globalProperties.$reinitializeAbly = initializeAbly;
  },
};
