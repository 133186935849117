import { ICertificateStateCustom } from "@/interfaces/customs/ICertificatesCustom";
import { MutationTree } from "vuex";

const mutation: MutationTree<ICertificateStateCustom> = {
  setUserCertificateTable(state, infoTable) {
    state.userCertificateTable = infoTable;
  },

  setUserCertificateInfo(state, info) {
    state.userCertificateInfo = info;
  },

  setCertificateList(state, list) {
    state.certificateList = list;
  },

  setFirstCertificatePages(state, firstPage) {
    state.firstCertificatePages = firstPage;
  },

  setLastCertificatePages(state, lastPage) {
    state.lastCertificatePages = lastPage;
  },

  setCertificateListStatus(state, status) {
    state.certificateListStatus = status;
  },

  setCertificateStatus(state, status) {
    state.certificateStatus = status;
  },
};

export default mutation;
