import { dataCharts } from "./interfaces/boardChartData";

export interface ControlBoardsState {
    patients: dataCharts | null,
    teleexpertises: dataCharts | null,
    diagnostics: dataCharts | null,
    specialities: dataCharts | null,
    telemedicines: dataCharts | null,
    schedules: dataCharts | null,
}

function state(): ControlBoardsState {
    return {
        patients: null,
        teleexpertises: null,
        diagnostics: null,
        specialities: null,
        telemedicines: null,
        schedules: null
    }
}

export default state;