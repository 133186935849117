import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/video-tutoriales",
    name: "TutorialVideosView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Triage List" */ "@/views/tutorial-videos-view/TutorialVideosView.vue"
      ),
  },
 
];
