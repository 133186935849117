import { MutationTree } from "vuex";
import { JobListUI } from "./state";

const mutation: MutationTree<JobListUI> = {
  setJobList(state, rowList) {
    state.jobList = rowList;
  },

  setJobListCurrentPage(state, currentPage) {
    state.jobListCurrentPage = currentPage;
  },

  setJobListLastPage(state, lastPage) {
    state.jobListLastPage = lastPage;
  },

  setJobCounterList(state, counterList) {
    state.jobCounterList = counterList;
  },

  setTelexperticeTypeSelector(state, selected) {
    state.telexperticeTypeSelector = selected;
  },
};

export default mutation;
