import { GetterTree } from "vuex";
import { CareProgramsState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<CareProgramsState, StateInterface> = {
  getCareProgramsList(state) {
    return state.careProgramsList;
  },
  getCareProgramsCurrentPage(state) {
    return state.careProgramsCurrentPage;
  },
  getCareProgramsLastPage(state) {
    return state.careProgramsLastPage;
  },
  getCareProgramById(state) {
    return [{ ...state.careProgram }].map(
      ({ reference_center, care_center, name, description }) => ({
        reference_center: reference_center ? reference_center.name : "",
        reference_center_id: reference_center ? reference_center.id : "",
        care_center: care_center ? care_center.name : "",
        care_center_id: care_center ? care_center.id : "",
        name,
        description,
      })
    )[0];
  },
  getCareProgram(state) {
    return [{ ...state.careProgram }].map(
      ({
        reference_center,
        care_center,
        name,
        total_patients,
        total_campaigns,
        total_campaigns_programed,
        total_campaigns_done,
      }) => ({
        reference_center: reference_center ? reference_center.name : "",
        care_center: care_center ? care_center.name : "",
        name,
        total_patients,
        total_campaigns,
        total_campaigns_programed,
        total_campaigns_done,
      })
    )[0];
  },
};

export default getters;
