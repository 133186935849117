import { MutationTree } from "vuex";
import { Data, DataList, Datum, CareProgramsState } from "./state";

const mutation: MutationTree<CareProgramsState> = {
  getCarePrograms(state: CareProgramsState, data: Data): void {
    if (data) {
      if (data.data.length >= 1) {
        state.careProgramsList = data.data.map(
          ({
            id,
            reference_center,
            care_center,
            name: program,
            description,
            total_patients: patients,
            total_campaigns: campaigns,
            status,
          }: Datum): DataList => ({
            id,
            referenceCenter: reference_center ? reference_center.name : "",
            careCenter: care_center ? care_center.name : "",
            program,
            description,
            patients,
            campaigns,
            status,
          })
        );
      }

      state.careProgramsCurrentPage = data.current_page;
      state.careProgramsLastPage = data.last_page;
    }
  },
  getCareProgramById(state: CareProgramsState, data: Datum) {
    if (data) {
      state.careProgram = data;
    }
  },
  clearCareProgramsList(state: CareProgramsState) {
    state.careProgramsList = [];
  },
  clearCareProgramsData(state) {
    state.careProgram = {
      id: 0,
      reference_center_id: 0,
      care_center_id: 0,
      name: "",
      description: "",
      created_at: "",
      updated_at: "",
      status: "",
      patients: [],
      care_center: {
        id: 0,
        created_at: null,
        updated_at: null,
        name: "",
        nit: "",
        address: "",
        phone: "",
        email: "",
        logo: null,
        contact_name: "",
        contact_phone: "",
        status: "",
        centers_types_id: 0,
      },
      reference_center: {
        id: 0,
        created_at: null,
        updated_at: null,
        name: "",
        nit: "",
        address: "",
        phone: "",
        email: "",
        logo: null,
        contact_name: "",
        contact_phone: "",
        status: "",
        centers_types_id: 0,
      },
      total_patients: 0,
      total_campaigns: 0,
      total_campaigns_programed: 0,
      total_campaigns_done: 0,
      total_campaigns_cancel: 0,
    };
  },
};

export default mutation;
