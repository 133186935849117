import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { AdvanceDirectivesState } from "./state";

const URL_PATH_ADVANCE_DIRECTIVES = "/api/patients/patient-advance-directive";
const URL_PATH_DIRECTIVES = "/api/patients/advance-directive";
const CONFIG = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const actions: ActionTree<AdvanceDirectivesState, StateInterface> = {
  async loadAdvanceDirectives({ commit }, params) {
    commit("setAdvanceDirectives");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH_ADVANCE_DIRECTIVES}`,
        {
          params,
        }
      );
      const { success, message, data } = response;

      commit("setAdvanceDirectives", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadAdvanceDirective({ commit }, id) {
    commit("setAdvanceDirective");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH_ADVANCE_DIRECTIVES}/${id}`
      );
      const { success, message, data } = response;

      commit("setAdvanceDirective", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadDirectives({ commit }, params) {
    commit("setDirectives");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH_DIRECTIVES}`,
        {
          params,
        }
      );
      const { success, message, data } = response;

      commit("setDirectives", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createAdvanceDirective({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH_ADVANCE_DIRECTIVES}`,
        bodyRequest,
        CONFIG
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async updateAdvanceDirective({ commit }, { id, bodyRequest }) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH_ADVANCE_DIRECTIVES}/${id}`,
        bodyRequest,
        CONFIG
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async deleteAdvanceDirective({ commit }, id) {
    commit("setResponse");

    try {
      const { data } = await executeApi().delete(
        `${URL_PATH_ADVANCE_DIRECTIVES}/${id}`
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async changeAdvanceDirective({ commit }, { id, bodyRequest }) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH_ADVANCE_DIRECTIVES}/${id}/status`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
};

export default actions;
