export interface CdasState {
    xmlString: string;
    teleexpertisesList: any;
}

function state(): CdasState {
    return {
        xmlString: "",
        teleexpertisesList: []
    }
}

export default state;