import { MutationTree } from "vuex";
import { IndicatorsUI } from "./state";

const mutation: MutationTree<IndicatorsUI> = {
  setIndicatorsLoader(state, statusLoader) {
    state.indicatorsLoader = statusLoader;
  },
  setRowIndicators(state, tableList) {
    state.rowIndicators = tableList;
  },

  setIndicatorsCurrentPage(state, currentPage) {
    state.indicatorsCurrentPage = currentPage;
  },

  setIndicatorsLastPage(state, lastPage) {
    state.indicatorsLastPage = lastPage;
  },

  setIndicatorListLoader(state, status) {
    state.indicatorListLoader = status;
  },
  // view indicators
  setViewIndicatorsURL(state, docsURL) {
    state.viewIndicatorsURL = docsURL;
  },

  setViewCardIndicator(state, cardInfo) {
    state.viewCardIndicator = cardInfo;
  },

  setViewIndicatorListLoader(state, status) {
    state.viewIndicatorListLoader = status;
  },
  setRowViewIndicators(state, rowInfo) {
    state.rowViewIndicators = rowInfo;
  },
  setViewIndicatorsCurrentPage(state, currentPage) {
    state.viewIndicatorsCurrentPage = currentPage;
  },
  setViewIndicatorsLastPage(state, lastPage) {
    state.viewIndicatorsTotalPage = lastPage;
  },

  //create
  setIndicatorsYears(state, availableYears) {
    state.indicatorsYears = availableYears;
  },

  // redirect
  setIndicatorsRedirectTo(state, redirectTo) {
    state.indicatorsRedirectTo = redirectTo;
  },
};

export default mutation;
