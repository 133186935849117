import { MutationTree } from "vuex";
import { TelemedicineState } from "./state";
import { IDataTable } from "@/interfaces/customs/interface";
import {
  ITelemedicine,
  IDisability,
  IScale,
} from "@/interfaces/global/interfaces";

const mutation: MutationTree<TelemedicineState> = {
  setTelemedicineMeetInfo(state, meetInfo) {
    state.telemediceMeetInfo = meetInfo;
  },

  setTelemedicineList(state: TelemedicineState, list: IDataTable): void {
    state.telemedicineList = list?.data;

    state.telemedicineCurrentPage = list?.current_page || 1;
    state.telemedicineLastPage = list?.last_page || 1;
  },
  setTelemedicine(state: TelemedicineState, data: ITelemedicine | null): void {
    state.telemedicine = data;
  },
  setGynecologistsBackground(
    state: TelemedicineState,
    data: object | null
  ): void {
    state.gynecologistsBackgroundData = data;
  },
  setDisabilities(state: TelemedicineState, data: IDisability | null): void {
    state.disabilitiesData = null;
    if (data) {
      state.disabilitiesData = data;
    }
  },
  setInformedConsent(state: TelemedicineState, value: boolean): void {
    state.informed_consent = value;
  },
  setNotifySend(state: TelemedicineState, value: boolean): void {
    state.notify_send = value;
  },
  setNotifyType(state: TelemedicineState, value: string[] | null): void {
    state.notify_type = value;
  },
  setConsentThroughType(state: TelemedicineState, value: string): void {
    state.consent_through_type = value;
  },
  setDataUsage(state: TelemedicineState, value: boolean): void {
    state.data_usage = value;
  },
  setSignature(
    state: TelemedicineState,
    sign: { value: File | null; url: string | null } | null
  ): void {
    if (sign) {
      state.signature = sign.value;
      state.signature_url = sign.url;
    } else {
      state.signature = null;
      state.signature_url = null;
    }
  },
};

export default mutation;
