import { computed } from "vue";
import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { ScheduleReport, DataList } from "@/store/schedule-reports/state";

export const useScheduleReportsStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State

    // Getters
    scheduleReportsList: computed<DataList[]>(
      () => store.getters["scheduleReports/getScheduleReportsList"]
    ),
    scheduleReportsCurrentPage: computed<number>(
      () => store.getters["scheduleReports/getScheduleReportsCurrentPage"]
    ),
    scheduleReportsLastPage: computed<number>(
      () => store.getters["scheduleReports/getScheduleReportsLastPage"]
    ),
    scheduleReport: computed<ScheduleReport>(
      () => store.getters["scheduleReports/getScheduleReport"]
    ),
    reportTelepextisesList: computed<DataList[]>(
      () => store.getters["scheduleReports/getReportTelepextisesList"]
    ),
    reportTelepextisesCurrentPage: computed<number>(
      () => store.getters["scheduleReports/getReportTelepextisesCurrentPage"]
    ),
    reportTelepextisesLastPage: computed<number>(
      () => store.getters["scheduleReports/getReportTelepextisesLastPage"]
    ),

    // Actions
    getScheduleReports: (
      token: string,
      page: number,
      selects: { referenceCenter: number; careCenter: number | string }
    ) =>
      store.dispatch("scheduleReports/getScheduleReports", {
        token,
        page,
        selects,
      }),
    createScheduleReport: (
      token: string,
      bodyRequest: {
        reference_center: string | number;
        care_center: string | number;
        type: string | number;
        patient: string | number;
        status: string;
        from_date: string;
        to_date: string;
      }
    ) =>
      store.dispatch("scheduleReports/createScheduleReport", {
        token,
        bodyRequest,
      }),
    getScheduleReportById: (token: string, id: number) =>
      store.dispatch("scheduleReports/getScheduleReportById", { token, id }),
    getTelexpertisesByReport: (token: string, page: number, id: number) =>
      store.dispatch("scheduleReports/getTelexpertisesByReport", {
        token,
        page,
        id,
      }),

    //Mutations
    clearScheduleReportsList: () =>
      store.commit("scheduleReports/clearScheduleReportsList"),
  };
};
