import { GetterTree } from 'vuex';
import { AuthState } from './state';
import { StateInterface } from '../index';


const getters: GetterTree<AuthState, StateInterface> = {

    isLoginUser(state) {
        return !!state.data.authorization.token;
    }
}

export default getters;