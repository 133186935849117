import { useStore } from "vuex";
import { StateInterface } from '@/store/index';

export const useTemplates = () => {

    const store = useStore<StateInterface>();

    return {
        // Actions
        isGetListTemplate: (params:any) => store.dispatch('backgroundsTemplates/getListTemplate', params),
        isStatusLisTemplateBackground: (data:any) => store.dispatch('backgroundsTemplates/statusBackgroundTemplates', data),
        isDeleteTemplates: (data:any) => store.dispatch('backgroundsTemplates/deleteTemplates', data),
        isGetIdTemplates: (data:any) => store.dispatch('backgroundsTemplates/getIdTemplates', data),
        isEditTemplates: (data:any) => store.dispatch('backgroundsTemplates/editTemplates', data),
        isCreatedTemplates: (data:any) => store.dispatch('backgroundsTemplates/createdTemplates', data),
    }
}