import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { NumerationsState } from "./state";

const URL_PATH = "/api/settings/numerations";

const actions: ActionTree<NumerationsState, StateInterface> = {
  async loadNumerations({ commit }, params) {
    commit("setNumerations");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH}`, {
        params,
      });
      const { success, message, data } = response;

      commit("setNumerations", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadNumeration({ commit }, id) {
    commit("setNumeration");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH}/${id}`
      );
      const { success, message, data } = response;

      commit("setNumeration", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createNumeration({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(`${URL_PATH}`, bodyRequest);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async updateNumeration({ commit }, { id, bodyRequest }) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH}/${id}`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async changeNumerationStatus({ commit }, id) {
    commit("setNumerations");
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH}/changeStatus/${id}`,
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async deleteNumeration({ commit }, id) {
    commit("setNumerations");
    commit("setResponse");

    try {
      const { data } = await executeApi().delete(`${URL_PATH}/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
};

export default actions;
