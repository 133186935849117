import {  IPagesV2 } from "@/interfaces/customs/Pages";
import { StateInterface } from "@/store/index";
import { NotificationsList } from "@/store/notifications/state";
import { computed } from "vue";
import { useStore } from "vuex";

export const useNotificationsStore = () => {
  const { state, commit, getters, dispatch } = useStore<StateInterface>();

  return {
    // State
    notifications: computed(() => state.notifications.data.notifications),
    notificationsPages: computed(() => state.notifications.pages.notifications),
    typeNotifications: computed(
      () => state.notifications.data.typeNotifications
    ),
    data: computed(() => state.notifications.data),
    pages: computed(() => state.notifications.pages),
    response: computed(() => state.notifications.response),

    // Getters
    getNotificationsList: computed<NotificationsList[]>(
      () => getters["notifications/getNotificationsList"]
    ),

    getSearchNotifications: computed<NotificationsList[]>(
      () => getters["notifications/getSearchNotifications"]
    ),

    getNotificationPagination: computed<IPagesV2>(
      () => getters["notifications/getNotificationPagination"]
    ),

    getNotificationLoader: computed<boolean>(
      () => getters["notifications/getNotificationLoader"]
    ),

    //Mutations
    setNotifications: () => commit("notifications/setNotifications"),
    setTypeNotifications: () => commit("notifications/setTypeNotifications"),
    setNotificationLoader: (status: boolean) => commit("notifications/setNotificationLoader", status),

    // Actions
    loadBySearchNotifications: (params: {
      notification: boolean;
      type?: number | null;
      search?: string;
      from_date?: string;
      to_date?: string;
      status?: string;
      page?: number;
    }) => {
      dispatch("notifications/LoadBySearchNotifications", params);
    },

    loadNotifications: (params: {
      notification: boolean;
      type?: number | null;
      search?: string;
      from_date?: string;
      to_date?: string;
      status?: string;
      page?: number;
    }) => dispatch("notifications/loadNotifications", params),
    loadTypeNotifications: () =>
      dispatch("notifications/loadTypeNotifications"),
    readNotification: (id: number) =>
      dispatch("notifications/readNotification", id),
  };
};
