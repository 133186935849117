import {
  IData,
  IRequestResponse,
  ITypeDose,
  IVaccine,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { VaccinesState } from "./state";

const mutation: MutationTree<VaccinesState> = {
  setResponse(state: VaccinesState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
  setVaccines(
    state: VaccinesState,
    data?: IData<IVaccine[]> | IVaccine[]
  ): void {
    if (data) {
      if (Array.isArray(data)) {
        state.data.vaccines = data;
      } else {
        state.data.vaccines = data.data;

        state.pages.vaccines.currentPage.value = data.current_page;
        state.pages.vaccines.lastPage.value = data.last_page;
      }
    } else {
      state.data.vaccines = [];
    }
  },
  setVaccine(state: VaccinesState, data?: IVaccine | null) {
    if (data !== undefined) {
      state.data.vaccine = data;

      state.requests.vaccine = true;
    } else {
      state.data.vaccine = null;

      state.requests.vaccine = false;
    }
  },
  setTypeDoses(state: VaccinesState, data?: ITypeDose[]): void {
    if (data) {
      state.data.typeDoses = data;
    } else {
      state.data.typeDoses = [];
    }
  },
};

export default mutation;
