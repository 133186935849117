import { GetterTree } from "vuex";
import { PatientsState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<PatientsState, StateInterface> = {
  // Patients V.3
  getPatientsList(state) {
    return state.data.patients.length >= 1
      ? state.data.patients.map(
          ({
            id,
            name,
            last_name: lastName,
            document,
            phone,
            email,
            eps,
            admission_status: admission,
            status,
          }) => ({
            id,
            name,
            lastName,
            document,
            phone,
            email,
            eps: eps?.name,
            admission,
            status,
          })
        )
      : [];
  },
  getPatientsEdit(state) {
    if (state.data.patient) {
      const {
        id,
        name,
        last_name,
        care_center_id,
        birthday,
        document_type_id,
        document,
        eps_id,
        arl_id,
        prepaid_id,
        sex_id,
        email,
        gender,
        marital_status,
        phone,
        department_code,
        city_code,
        address,
        regime_id,
        membership_id,
        scholarship,
        vital_state,
        companion_document,
        companion_phone,
        companion_name,
        occupation,
        rh_group,
        type_of_population,
        care_programs,
      } = state.data.patient;

      return {
        id,
        document_type_id,
        document,
        name,
        last_name,
        gender,
        marital_status,
        type_of_population,
        membership_id,
        regime_id,
        vital_state,
        birthday,
        eps_id,
        department_code,
        city_code,
        address,
        phone,
        email,
        occupation,
        arl_id,
        prepaid_id,
        care_center_id,
        care_programs: care_programs.length >= 1 ? care_programs.map(({ id }) => id) : null,
        rh_group: rh_group ? rh_group.toUpperCase() : null,
        sex_id,
        companion_document,
        companion_name,
        companion_phone,
        scholarship,
        name_center_reference: null,
      };
    }

    return null;
  },
  getImportedPatientsList(state) {
    return state.data.importedPatients.length >= 1
      ? state.data.importedPatients.map(
          ({
            id,
            care_center_name: careCenter,
            status,
            patients,
            success,
            corrections,
            requested_by: requestedBy,
            date,
            url_errors,
            name_file,
          }) => ({
            id,
            careCenter,
            status,
            patients,
            success,
            corrections,
            requestedBy,
            date,
            url: url_errors ?? undefined,
            fileName: url_errors ? name_file : undefined,
          })
        )
      : [];
  },
};

export default getters;
