import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/telemedicine",
    name: "TelemedicineList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Telemedicine List" */ "@/views/telemedicine/list/TelemedicineList.vue"
      ),
  },
  {
    path: "/telemedicine/ver-telemedicine/:id(\\d+)",
    name: "TelemedicineView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Telemedicine View" */ "@/views/telemedicine/view/TelemedicineView.vue"
      ),
  },
  {
    path: "/telemedicine/crear-telemedicine/:id(\\d+)",
    name: "TelemedicineCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Telemedicine View" */ "@/views/telemedicine/create/TelemedicineCreate.vue"
      ),
  },
];
