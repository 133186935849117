import { Module } from "vuex";
import { StateInterface } from "../index";

import state from "./state";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

import { CentersSelectorsState } from "@/interfaces/global/interfaces";

const selectorsModule: Module<CentersSelectorsState, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};

export default selectorsModule;
