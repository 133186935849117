import {
  IRequestResponse,
  IInitialAttention,
} from "@/interfaces/global/interfaces";
import { StateInterface } from "@/store/index";
import {
  InitialAttentionsList,
  InitialAttentionsPages,
  InitialAttentionsRequest,
  InitialAttentionView,
} from "@/store/initial-attentions/state";
import { computed } from "vue";
import { useStore } from "vuex";

export const useInitialAttentionsStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    initialAttentions: computed<IInitialAttention[]>(
      () => store.state.initialAttentions.initialAttentions
    ),
    initialAttentionsPages: computed<InitialAttentionsPages>(
      () => store.state.initialAttentions.initialAttentionsPages
    ),
    initialAttention: computed<IInitialAttention | null>(
      () => store.state.initialAttentions.initialAttention
    ),
    request: computed<InitialAttentionsRequest>(
      () => store.state.initialAttentions.request
    ),
    response: computed<IRequestResponse>(
      () => store.state.initialAttentions.response
    ),

    // Getters
    getInitialAttentionsList: computed<InitialAttentionsList[]>(
      () => store.getters["initialAttentions/getInitialAttentionsList"]
    ),
    getInitialAttentionView: computed<InitialAttentionView | null>(
      () => store.getters["initialAttentions/getInitialAttentionView"]
    ),

    // Actions
    loadInitialAttentions: (params: {
      care_center: number | null;
      status: string;
      from_date: string;
      to_date: string;
      page: number;
    }) => store.dispatch("initialAttentions/loadInitialAttentions", params),
    loadInitialAttention: (id: number) =>
      store.dispatch("initialAttentions/loadInitialAttention", id),
    closeInitialAttention: (requestData: {
      id: null | number;
      reason: string;
    }) =>
      store.dispatch("initialAttentions/closeInitialAttention", requestData),
    getPatientByIdInitialAttention: (id: number) =>
      store.dispatch("initialAttentions/getPatientByIdInitialAttention", id),
    createInitialAttention: (bodyRequest: {
      modify_register_date: null | number;
      registered_date?: string;
      registered_time?: string;
      observation: string;
      condition: string;
      attendant: {
        document: string;
        name: string;
        phone: string;
        relationship: string;
      };
      patient: {
        id?: number;
        document_type_id: null | number;
        document: string;
        name: string;
        last_name: string;
        nationality: string;
        document_expedition_location: string;
        gender: string;
        marital_status: string;
        type_of_population: string;
        membership_id: number | null;
        regime_id: number | null;
        vital_state: string;
        birthday: string;
        eps_id: number | null;
        department_code: string | null;
        city_code: string | null;
        address: string;
        phone: string;
        secondary_phone: string;
        email: string;
        occupation: string;
        arl_id: number | null;
        prepaid_id: number | null;
        care_center_id: number | null;
        care_programs: [] | number[] | string[];
        rh_group: string;
        scholarship: string;
        sex_id: number | null;
        companion: boolean;
        companion_document: string | null;
        companion_name: string | null;
        companion_phone: string | null;
        relationship_companion: string | null;
        relationship_companion_patient: string | null;
        phone_companion_patient: string | null;
        name_companion_patient: string | null;
      };
    }) =>
      store.dispatch("initialAttentions/createInitialAttention", bodyRequest),

    //Mutations
    setInitialAttentions: () =>
      store.commit("initialAttentions/setInitialAttentions"),
    setInitialAttention: (data?: IInitialAttention) =>
      store.commit("initialAttentions/setInitialAttention", data),
  };
};
