import { GetterTree } from "vuex";
import { TeleexpertisesState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<TeleexpertisesState, StateInterface> = {
  getTeleexperticeSchedule(state) {
    return state.teleexperticeInfo;
  },

  getTeleexpertisesCurrentPage(state) {
    return state.teleexpertisesCurrentPage;
  },
  getTeleexpertisesLastPage(state) {
    return state.teleexpertisesLastPage;
  },
  // Patient Detail
  getPatientDetails(state) {
    return state.dataPatientDetail;
  },
  // Reason Consultation
  getReasonConsultation(state) {
    return state.dataReasonConsultation;
  },
  // Reason Consultation
  getNursingRecord(state) {
    return state.dataNursingRecord;
  },
  // Vaccination
  getVaccination(state) {
    return state.dataVaccination;
  },
  getDataTableVaccination(state) {
    return state.dataTableVaccination;
  },
  // Background
  getDataTableBackground(state) {
    return state.dataTableBackground;
  },
  getBackgrounds(state) {
    return state.dataBackgrounds;
  },
  // Clinical Laboratory
  getDataTableClinicalLaboratory(state) {
    return state.dataTableClinicalLaboratory;
  },
  getClinicalLaboratory(state) {
    return state.dataClinicalLaboratory;
  },
  // Medical Examns
  getDataTableMedicalExams(state) {
    return state.dataTableMedicalExams;
  },
  getMedicalExams(state) {
    return state.dataMedicalExams;
  },
  getMedicalExamsDocuments(state) {
    return state.medicalExamsDocuments;
  },
  getClinicalLaboratoriesDocuments(state) {
    return state.clinicalLaboratoriesDocuments;
  },
  // VitalSigns
  getVitalSigns(state) {
    return state.dataVitalSigns;
  },
  // VitalSigns Nursing Notes
  getVitalSignsNursingNotes(state) {
    return state.dataVitalSignsNursingNotes;
  },
  // PhysicalExams
  getPhysicalExams(state) {
    return state.dataPhysicalExams;
  },
  // Diagnostic
  getDataTableDiagnostic(state) {
    return state.dataTableDiagnostic;
  },
  getDiagnostic(state) {
    return state.dataDiagnostic;
  },
  // Documents
  getDocuments(state) {
    return state.dataDocuments;
  },
  getDataTableDocuments(state) {
    return state.dataTableDocuments;
  },
  // Requests
  getRequests(state) {
    return state.dataRequest;
  },
  // Documents Response
  getDataResponse(state) {
    return state.dataResponseTelexpertice;
  },
  // Documents Response
  getDocumentsResponse(state) {
    return state.dataDocumentsResponse;
  },
  getDataTableDocumentsResponse(state) {
    return state.dataTableDocumentsResponse;
  },
  // Technical Annex
  getTechnicalAnnex(state) {
    return state.dataTechnicalAnnex;
  },
  // Attached Documents
  getDataTableAttachedDocuments(state) {
    return state.dataTableAttachedDocuments;
  },
  getDataAttachedDocuments(state) {
    return state.dataAttachedDocuments;
  },
};

export default getters;
