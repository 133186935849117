import { ActionTree } from "vuex";
import { PatientsState } from "./state";
import { StateInterface } from "../index";
import { patientsApi, executeApi } from "@/apis";
import { IError } from "@/interfaces/customs/interface";
import errorException from "@/functions/ErrorException";

const CONFIG = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const URL_PATH = "/api/patients";

const actions: ActionTree<PatientsState, StateInterface> = {
  async getPatients({ commit }, { token, id, page }): Promise<object> {
    try {
      const { data } = await patientsApi().get(
        `/careCenterId/${id}?page=${page}`
      );
      const { success, message, data: patientsData } = data;

      commit("getPatientList", data);
      return { success, message, patientsData };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async getEpsAll({ commit }, { token }): Promise<object> {
    try {
      const { data } = await patientsApi().get(`/eps`);
      const { success, message, data: epsData } = data;

      return { success, message, epsData };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async getDocumentTypes({ commit }, { token }): Promise<object> {
    try {
      const { data } = await patientsApi().get(`/documentTypes`);
      const { success, message, data: documentTypeData } = data;

      return { success, message, documentTypeData };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async getDepartmentAndCities({ commit }, { token }): Promise<object> {
    try {
      const { data } = await patientsApi().get(`/utils/departmentsAndCities`);
      const { success, message, data: deptoCitiesData } = data;

      commit("setDepartmentWithCities", deptoCitiesData);

      return { success, message, deptoCitiesData };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async getRegimes({ commit }, { token }): Promise<object> {
    try {
      const { data } = await patientsApi().get(`/regimes`);
      const { success, message, data: regimeData } = data;

      return { success, message, regimeData };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async getMemberships({ commit }, { token }): Promise<object> {
    try {
      const { data } = await patientsApi().get(`/memberships`);
      const { success, message, data: membershipsData } = data;

      return { success, message, membershipsData };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async changePatientStatus(
    { commit },
    { token, objectRequest }
  ): Promise<object> {
    try {
      const { data } = await patientsApi().post(
        `/changeStatus/${objectRequest.id}`,
        objectRequest
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async searchPatientByDocument(
    { commit },
    { token, document, queryParams }
  ): Promise<object> {
    try {
      const { data } = await patientsApi().get(
        queryParams
          ? `/by-document/${document}${queryParams}`
          : `/by-document/${document}`
      );

      const { success, message, data: patient } = data;

      commit("setPatientDetails", patient);

      return { success, message, patient };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async emptyDataPatientByDocument({ commit }) {
    commit("setPatientDetails", []);
  },

  async getArls({ commit }, { token }): Promise<object> {
    try {
      const { data } = await patientsApi().get(`/arl`);
      const { success, message, data: arlData } = data;

      return { success, message, arlData };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async getSexes({ commit }, { token }): Promise<object> {
    try {
      const { data } = await patientsApi().get(`/sex`);
      const { success, message, data: sexData } = data;

      return { success, message, sexData };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async getBloodTypes({ commit }, { token }): Promise<object> {
    try {
      const { data } = await patientsApi().get(`/blood-types`);
      const { success, message, data: bloodTypesData } = data;

      return { success, message, bloodTypesData };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async getPrepaidWithoutPaginate() {
    try {
      const { data } = await patientsApi().get(`/prepaid`);
      const { success, message, data: prepaidList } = data;

      return { success, message, prepaidList };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  // Patients V.3
  async loadPatients({ commit }, { careCenter, params }) {
    commit("setPatients");
    commit("setResponse");

    try {
      const { data: response } = await patientsApi().get(
        `/careCenterId/${careCenter}`,
        { params }
      );
      const { success, message, data } = response;

      commit("setPatients", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadPatient({ commit }, id) {
    commit("setPatient");
    commit("setResponse");
    try {
      const { data: response } = await patientsApi().get(`/${id}`);
      const { success, message, data } = response;

      commit("setPatient", data);
      commit("setResponse", { success, message });
      return { success, message, data };
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadPatientByDocument({ commit }, { document, params }) {
    commit("setPatient");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH}/by-document/${document}`,
        { params }
      );
      const { success, message, data } = response;

      commit("setPatient", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadImportedPatients(
    { commit },
    { referenceCenter, careCenter, params }
  ) {
    commit("setImportedPatients");
    commit("setResponse");

    try {
      const { data: response } = await patientsApi().get(
        `/loadMassive/patients/${referenceCenter}/${careCenter}`,
        { params }
      );
      const { success, message, data } = response;

      commit("setImportedPatients", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createPatients({ commit }, formData) {
    commit("setImportedPatients");
    commit("setResponse");

    try {
      const { data: response } = await patientsApi().post(
        "/loadMassive/patients",
        formData,
        CONFIG
      );
      const { success, message, data } = response;

      commit("setImportedPatients", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createPatient({ commit }, { patientRequest }) {
    commit("setPatient");
    commit("setResponse");

    try {
      const { data: response } = await patientsApi().post(
        "/",
        patientRequest,
        CONFIG
      );
      const { success, message, data } = response;

      commit("setResponse", { success, message });
      return { success, message, patientStored: data };
    } catch (error) {
      const response = errorException(error);

      commit("setResponse", response);
      return response;
    }
  },
  async createPatientV2({ commit }, { patientRequest, id }) {
    commit("setPatient");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().post(
        `${URL_PATH}/v2/${id}`,
        patientRequest,
        CONFIG
      );
      const { success, message, data } = response;

      commit("setResponse", { success, message });
      return { success, message, patientStored: data };
    } catch (error) {
      const response = errorException(error);

      commit("setResponse", response);
      return response;
    }
  },
  async loadImportedPatientsCards({ commit }, { referenceCenter, careCenter }) {
    commit("setImportedPatientsCards");
    commit("setResponse");

    try {
      const { data: response } = await patientsApi().get(
        `/loadMassive/patients/cards/${referenceCenter}/${careCenter}`
      );
      const { success, message, data } = response;

      commit("setImportedPatientsCards", data);
      commit("setResponse", {
        success,
        message: "Información traída con éxito",
      });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async searchConsecutiveByCountry({ commit }, code) {
    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH}/document-by-country?country_code=${code}`
      );
      const { success, message, data } = response;

      return { success, message, consecutive: data };
    } catch (error) {
      const response = errorException(error);

      commit("setResponse", response);
      return response;
    }
  },
};

// function errorException(error: IError) {
//   let success = false;
//   if (error.message == "Network Error") {
//     const { message } = error;
//     return { success, message };
//   }
//   const data = error.response?.data;
//   if (data) {
//     const { success, message } = data;
//     return { success, message };
//   }
//   const message = "Error";
//   return { success, message };
// }

export default actions;
