import { MedicationAdministratorState } from "@/interfaces/global/interfaces";
import { Module } from "vuex";
import { StateInterface } from "../index";

import state from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const proceduresReportModule: Module<MedicationAdministratorState, StateInterface> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default proceduresReportModule;
