import { ActionTree } from 'vuex';
import { ControlBoardsState } from './state';
import { StateInterface } from '../index';
import { controlBoardsApi } from '@/apis/controlBoardsApi';

const actions: ActionTree<ControlBoardsState, StateInterface> = {

    async getDataBoards( { }, data ) {
        const { token, path, params } = data;
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            },
            params
        };

        try{
            const route = `${path}`
            const { data } = await controlBoardsApi.get(route, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }

    },

    async setDataBoards( { commit }, datum ) {
        const { data, path } = datum;
        commit('setDataControlBoards', { data, path });
    },
}

function errorExeption(error: any) {
    
    //No hay conexion
    if(error.message == 'Network Error'){
        const success = false;
        const { message } = error;
        return { success, message }; 
    }            
    const { data } = error.response;
    const { success, message } = data; 
    return { success, message };
}

export default actions;