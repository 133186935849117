import {
  ResourcesState,
  IMedicineResource,
  IProcedure,
  ISubprogram
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";

const mutations: MutationTree<ResourcesState> = {
  setDocumentTypes(state: ResourcesState, data: object[]) {
    state.documentTypes = data;
  },
  setMedicineResources(state: ResourcesState, data: IMedicineResource) {
    state.classes = data.classes.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    state.administration_routes = data.administration_routes.map((item) => ({
      value: item,
      label: item,
    }));
    state.types = data.types.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    state.measurement_units = data.measurement_units.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    state.dispensing_units = data.dispensing_units.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    state.provision_types = data.provision_types.map((item) => ({
      value: item,
      label: item,
    }));
    state.pharmaceutical_forms = data.pharmaceutical_forms.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    state.tax_types = data.tax_types.map((item) => ({
      value: item,
      label: item,
    }));
    state.manufacturers = data.manufacturers.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  },
  setProceduresV2(state: ResourcesState, data: IProcedure[]) {
    state.proceduresV2 =
      data.map((item) => ({
        value: item.id,
        label: item.description,
      })) ?? [];
  },
  setSubprograms(state: ResourcesState, data: ISubprogram[]) {
    state.subprograms =
      data.map((item) => ({
        value: item.id,
        label: item.name,
      })) ?? [];
  },
  setUsersSystem(
    state: ResourcesState,
    data: { value: number; names: string; document: string }[]
  ) {
    state.user_registered = [];
    state.user_registered = data.map((user) => ({
      label: user.names,
      value: user.value,
    }));
  },
};

export default mutations;
