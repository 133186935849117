import { isAuthenticatedGuard } from '@/router/auth-guard';

export default [
   
        {
            path: '/listado-resolucion-2175-de-2015',
            name: 'Resolution2175of2015List',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/resolution2175of2015/Resolution2175of2015List.vue'),
        },

        {
            path: '/solicitar-resolucion-2175-de-2015',
            name: 'Resolution2175of2015Request',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/resolution2175of2015/subpages/Resolution2175of2015-request/Resolution2175of2015Request.vue'),
        },
]