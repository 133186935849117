import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { CustomComponentsState } from "./state";

const URL_PATH_SETTINGS = "/api/settings";
const URL_PATH_CENTERS = URL_PATH_SETTINGS + "/centers";

const actions: ActionTree<CustomComponentsState, StateInterface> = {
  async loadCenters({ commit }) {
    commit("setCenters", { center: "careCenters"});
    commit("setCenters", { center: "referenceCenters"});
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH_CENTERS}/referenceCenters`
      );
      const { success, message, data } = response;

      commit("setCenters", { center: "referenceCenters", data });
      commit("setResponse", { success, message, module: "rc" });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", { ...response, module: "rc" });
    }
  },
  async loadCareCenters({ commit }) {
    commit("setCenters", { center: "careCenters"});
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH_CENTERS}/getByTypeWP/2`);
      const { success, message, data } = response;

      commit("setCenters", { center: "careCenters", data });
      commit("setResponse", { success, message, module: "cc" });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", { ...response, module: "cc" });
    }
  },
};

export default actions;
