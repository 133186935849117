import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/gestion-de-reportes/indicadores-res-1552-de-2013",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Indicators" */
        "@/views/indicators-view/IndicatorsView.vue"
      ),
    children: [
      {
        path: '',
        name: 'IndicatorsList',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(
          /* webpackChunkName: "IndicatorsView" */
          "@/views/indicators-view/sub-pages/indicators-list/IndicatorList.vue")
      },

      {
        path: 'solicitar-indicadores-res-1552-de-2013',
        name: 'IndicatorReportRequest',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(
          /* webpackChunkName: "ShowRipsView" */
          "@/views/indicators-view/sub-pages/indicators-report-request/IndicatorReportRequest.vue"),
      },

      {
        path: 'ver-indicadores-res-1552-de-2013/:id',
        name: 'IndicatorShowReportRequest',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(
          /* webpackChunkName: "RequestIndicatorsView" */
          "@/views/indicators-view/sub-pages/indicators-show-report-request/IndicatorShowReportRequest.vue"),
      },
    ]
  },
];
