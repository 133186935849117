import { IPages } from "@/interfaces/customs/Pages";
import {
  IRequestResponse,
  IIndicators256,
IIndicators256Details,
} from "@/interfaces/global/interfaces";

export type Indicators256 = "indicators" | "indicator" | "years" | "details";

export type Indicators256Request = Record<Indicators256, boolean>;
export type Indicators256Pages = Record<"indicators" | "details", IPages>;

export interface Indicators256List {
  id: IIndicators256["id"];
  year: IIndicators256["year"];
  period: IIndicators256["period"];
  time: IIndicators256["time"];
  date: IIndicators256["date_generation"];
  requestedBy: IIndicators256["requested_by"];
  status: IIndicators256["status"];
  file: IIndicators256["excel_url"];
}

export interface Indicator256View {
  referenceCenter: IIndicators256["reference_center"];
  careCenter: IIndicators256["care_center_name"];
  year: IIndicators256["year"];
  period: IIndicators256["period"];
  time: IIndicators256["time"];
  requestedBy: IIndicators256["requested_by"];
  date: IIndicators256["date_generation"];
  file: IIndicators256["excel_url"];
  status: IIndicators256["status"];
}

export interface Indicators256DetailsList {
  domain: IIndicators256Details['domain'];
  code: IIndicators256Details['code'];
  indicator: IIndicators256Details['indicator_name'];
  unit: IIndicators256Details['unit_name'];
  numerator: IIndicators256Details['numerator'];
  denominator: IIndicators256Details['denominator'];
  result: IIndicators256Details['result'];
}

export interface Indicators256Data {
  indicators: IIndicators256[];
  indicator: IIndicators256 | null;
  years: string[];
  details: IIndicators256Details[];
}

export interface Indicators256State {
  request: Indicators256Request;
  data: Indicators256Data;
  pages: Indicators256Pages;
  response: IRequestResponse;
}

function state(): Indicators256State {
  return {
    request: {
      indicators: false,
      indicator: false,
      years: false,
      details: false
    },
    data: {
      indicators: [],
      indicator: null,
      years: [],
      details: [],
    },
    pages: {
      indicators: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      details: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
