import { GetterTree } from 'vuex';
import { Resolution1604of2013State } from './state';
import { StateInterface } from '../index';


const getters: GetterTree<Resolution1604of2013State, StateInterface> = {
    someGetter( /* state */ ) {
        // return true;
    }
}

export default getters;