import {
  IARL,
  ICareCenter,
  ICarePrograms,
  ICity,
  IDepartment,
  IDocumentType,
  IEPS,
  IMembership,
  IPrepaid,
  IReferenceCenter,
  IRegime,
  ISex,
} from "@/interfaces/global/interfaces";
import { StateInterface } from "@/store/index";
import {
  SelectorsRequest,
  SelectorsResponse,
  SelectorsValue,
  patientValue,
} from "@/store/selectors/state";
import { computed } from "vue";
import { useStore } from "vuex";

export const useSelectorsStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    values: computed<SelectorsValue>(() => store.state.selectors.values),
    request: computed<SelectorsRequest>(() => store.state.selectors.request),
    response: computed<SelectorsResponse>(() => store.state.selectors.response),
    referenceCenters: computed<IReferenceCenter[]>(
      () => store.state.selectors.centers.referenceCenters
    ),
    careCenters: computed<ICareCenter[]>(
      () => store.state.selectors.centers.careCenters
    ),
    resourcesPatient: computed<any>(() => store.state.selectors.patient),
    eps: computed<IEPS[]>(() => store.state.selectors.patient.eps),
    memberships: computed<IMembership[]>(
      () => store.state.selectors.patient.memberships
    ),
    documentTypes: computed<IDocumentType[]>(
      () => store.state.selectors.patient.documentTypes
    ),
    nationalities: computed<string[]>(
      () => store.state.selectors.patient.nationalities
    ),
    expedition_location: computed<string[]>(
      () => store.state.selectors.patient.expedition_location
    ),
    regimes: computed<IRegime[]>(() => store.state.selectors.patient.regimes),
    arls: computed<IARL[]>(() => store.state.selectors.patient.arls),
    prepaids: computed<IPrepaid[]>(
      () => store.state.selectors.patient.prepaids
    ),
    sexes: computed<ISex[]>(() => store.state.selectors.patient.sexes),
    departments: computed<IDepartment[]>(
      () => store.state.selectors.patient.departments
    ),
    cities: computed<ICity[]>(() => store.state.selectors.patient.cities),
    bloodTypes: computed<string[]>(
      () => store.state.selectors.patient.bloodTypes
    ),
    carePrograms: computed<ICarePrograms[]>(
      () => store.state.selectors.patient.carePrograms
    ),

    // Getters

    // Actions
    loadCenters: (type: number) =>
      store.dispatch("selectors/loadCenters", type),
    loadCareCenters: (id: number) =>
      store.dispatch("selectors/loadCareCenters", id),
    loadEPS: () => store.dispatch("selectors/loadEPS"),
    loadRegimes: () => store.dispatch("selectors/loadRegimes"),
    loadEPSWithData: (data: {
      referenceCenter: number | null;
      careCenter: number | null;
      fromDate: string;
      toDate: string;
    }) => store.dispatch("selectors/loadEPSWithData", data),
    loadCarePrograms: (params?: {
      referenceCenterId?: number | null;
      careCenterId?: number | null;
    }) => store.dispatch("selectors/loadCarePrograms", params),
    loadPatientResources: (query?: string) =>
      store.dispatch("selectors/loadPatientResources", query),
    loadEPSWithDataResolution2463: (params: {
      reference_center_id?: number;
      care_center_id?: number;
      form_date?: string;
      to_date?: string;
    }) => store.dispatch("selectors/loadEPSWithDataResolution2463", params),
    loadResolution2463EPS: (params: {
      reference_center_id?: number;
      care_center_id?: number;
    }) => store.dispatch("selectors/loadResolution2463EPS", params),

    //Mutations
    setCenters: (type: number, data?: IReferenceCenter[] | ICareCenter[]) =>
      store.commit("selectors/setCenters", { type, data }),
    setEPS: () => store.commit("selectors/setEPS"),
    setRegimes: () => store.commit("selectors/setRegimes"),
    setCities: (data?: ICity[]) => store.commit("selectors/setCities", data),
    setCarePrograms: (data?: ICarePrograms[]) =>
      store.commit("selectors/setCarePrograms", data),
    setPatientResources: () => store.commit("selectors/setPatientResources"),

    setCentersValue: (type: number, value: number | null = null) =>
      store.commit("selectors/setCentersValue", { type, value }),
    setEPSValue: (value: number | null = null) =>
      store.commit("selectors/setEPSValue", value),
    setRegimeValue: (value: number | null = null) =>
      store.commit("selectors/setRegimeValue", value),
    setPatientResourcesValues: (
      resource: patientValue,
      value: number | string | number[] | [] | null = null
    ) =>
      store.commit("selectors/setPatientResourcesValues", { resource, value }),
  };
};
