import { GetterTree } from 'vuex';

import { StateInterface } from '../index';
import { RipsUI } from '@/interfaces/customs/IRipsCustom';

const getters: GetterTree<RipsUI, StateInterface> = {
    getRowRips(state) {
        return state.rowRips
    },

    getRipsTableCurrentPage(state) {
        return state.ripsTableCurrentPage
    },
    getRipsTableLastPage(state) {
        return state.ripsTableLastPage
    },
    getViewRips(state) {
        return state.viewRips
    },

    getSpecialtiesRips(state) {
        return state.specialtiesRips
    },

    getCurrentRipSelected(state) {
        return state.currentRipSelected
    },

    getDownloadReportRipURL(state,) {
        return state.downloadReportRipURL
    }

}
export default getters;