import moment from 'moment'

export const useUtilsCalendarMethods = () => {
  const options_calendar_date_less_than_or_equal_to_the_current_date = (
    date: string
  ) => date <= moment().format('YYYY/MM/DD')

  return {
    options_calendar_date_less_than_or_equal_to_the_current_date,
  }
}
