import { IPages } from "@/interfaces/customs/interface";
import {
  ISocialSecurityInformation,
  ICompanionInformation,
} from "../../interfaces/global/Patient";
import {
  IPatient,
  IImportedPatients,
  IRequestResponse,
  IImportedPatientsCards,
  IPersonalInformation,
  IContactInformation,
  ICompanionPatientInformation,
  IPatientResourceSelectors,
} from "@/interfaces/global/interfaces";

// Patients V.3
export type PatientsPages = Record<"patients" | "importedPatients", IPages>;

export interface PatientsList {
  id: IPatient["id"];
  name: IPatient["name"];
  lastName: IPatient["last_name"];
  document: IPatient["document"];
  phone: IPatient["phone"];
  email: IPatient["email"];
  eps: IPatient["eps"]["name"];
  admission: IPatient["admission_status"];
  status: IPatient["status"];
}

export interface PatientsData {
  patients: IPatient[];
  patient: IPatient | null;
  importedPatients: IImportedPatients[];
  importedPatientsCards: IImportedPatientsCards;
}

export interface ImportedPatientsList {
  id: IImportedPatients["id"];
  careCenter: IImportedPatients["care_center_name"];
  status: IImportedPatients["status"];
  patients: IImportedPatients["patients"];
  success: IImportedPatients["success"];
  corrections: IImportedPatients["corrections"];
  requestedBy: IImportedPatients["requested_by"];
  date: IImportedPatients["date"];
  url: IImportedPatients["url_errors"];
  fileName: IImportedPatients["name_file"];
}

export interface PatientsState {
  isLoading: boolean;
  patientsList: Array<object>;
  patientData: IPatient | null;
  patientDetailForm: object | null;
  personalInformationForm: IPersonalInformation | null;
  contactInformationForm: IContactInformation | null;
  socialSecurityInformation: ISocialSecurityInformation | null;
  companionPatientInformation: ICompanionPatientInformation | null;
  companionInformation: ICompanionInformation | null;
  deptoCitiesData: Array<any>;
  patientsCurrentPage: number;
  patientsLastPage: number;
  selectorsList: IPatientResourceSelectors;

  // Patients V.3
  data: PatientsData;
  pages: PatientsPages;
  response: IRequestResponse;
}

function state(): PatientsState {
  return {
    isLoading: true,
    patientsList: [],
    patientData: null,
    patientDetailForm: null,
    personalInformationForm: null,
    contactInformationForm: null,
    socialSecurityInformation: null,
    companionInformation: null,
    companionPatientInformation: null,
    deptoCitiesData: [],
    patientsCurrentPage: 1,
    patientsLastPage: 1,
    selectorsList: {
      marital_status: [
        "Soltero(a)",
        "Casado(a)",
        "Unión Libre",
        "Divorciado(a)",
        "Viudo(a)",
        "No aplica",
      ],
      gender: ["Femenino", "Masculino"],
      education_level: [
        "Ninguno",
        "Primaria",
        "Secundaria",
        "Técnico",
        "Tecnólogo",
        "Profesional universitario",
        "Especialidad",
        "Maestría",
        "Doctorado",
      ],
      population: [
        "Ninguno",
        "ROM",
        "Negros",
        "Mulatos",
        "Palenqueros y raizales",
        "Blancos",
        "Mestizos",
        "Víctimas del conflicto armado",
        "Discapacidad",
      ],
      vital_states: ["Vivo", "Muerto"],
    },
    // PatiEnts V.3
    data: {
      patients: [],
      patient: null,
      importedPatients: [],
      importedPatientsCards: {
        success: 0,
        errors: 0,
      },
    },
    pages: {
      patients: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      importedPatients: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
