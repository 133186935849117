import { ActionTree } from 'vuex';
import { StateCenters } from './state';
import { StateInterface } from '../index';
import { centersApi } from '@/apis';
import { contractsApi } from '@/apis/contractApi';

const actions: ActionTree<StateCenters, StateInterface> = {

    async getCenters( { commit }, info ) {

        const { token, id } = info;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const { data } = await centersApi().get(`/getByType/${id}?paginate=false`, config);
            id == 1 ? commit('setReferencedCenters', { data }) : commit('setCareCenters', { data });
            return data;
        }catch(error:any){
            return errorExeption(error);
        }
    },

    async getCentersById( { }, info ) {

        const { token, id } = info;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const { data } = await contractsApi.get(`/getContractsbyReferenceCenter/${id}`, config);
            return data;
        }catch(error:any){
            return errorExeption(error);
        }
    },

    async getCareCentersByReferenced( { }, user:any ) {
        // a line to prevent linter errors
        const config = {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        };
        delete user.token;

        try{
            const { data } = await centersApi().get(`/getCareByReference/${user.id}`, config);
            return data;
        }catch(error:any){
            return errorExeption(error);
        }
    },
}

function errorExeption(error: any) {

    //No hay conexion
    if(error.message == 'Network Error'){
        const success = false;
        const { message } = error;
        return { success, message };
    }
    const { data } = error.response;
    const { success, message } = data;
    return { success, message };
}

export default actions;