import { ActionTree } from "vuex";
import { AtentionCenterState } from "./state";
import { StateInterface } from "../index";
import { atentionCenterApi, centersApi } from "@/apis";
import errorException from "@/functions/ErrorException";

const actions: ActionTree<AtentionCenterState, StateInterface> = {
  async getAtentionCenters({ commit }, { token, page }): Promise<object> {
    try {
      const { data } = await atentionCenterApi().get(
        page ? `/getByType/2?page=${page}` : "/getByType/2"
      );
      const { success, message, data: atentionCenterData } = data;
      const { data: atentionCenterList } = atentionCenterData;

      commit("getAtentionCenters", data);
      return { success, message, atentionCenterList };
    } catch (error) {
      return errorException(error);
    }
  },

  async getAtentionCentersWithoutPag(
    { commit },
    token: string
  ): Promise<object> {
    try {
      const { data } = await atentionCenterApi().get("/getByTypeWP/2");
      const { success, message, data: atentionCenterData } = data;
      const { data: atentionCenterList } = atentionCenterData;

      commit("getAtentionCenters", data);
      return { success, message, atentionCenterList };
    } catch (error) {
      return errorException(error);
    }
  },
  async changeAtentionCenterStatus({ commit }, { token, id }): Promise<object> {
    try {
      const { data } = await atentionCenterApi().post(
        `/changeStatus/${id}`,
        {}
      );
      const { success, message, data: atentionCenterStatus } = data;

      commit("changeAtentionCenterStatus", atentionCenterStatus);

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },

  async createAtentionCenter(
    { dispatch },
    {  atentionCenterRequest }
  ): Promise<object> {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const { data } = await atentionCenterApi().post(
        "/",
        atentionCenterRequest,
        config
      );
      const { success, message } = data;
      dispatch('general/LoadCenterRefSelectorOptions', null, { root: true })
      dispatch('general/LoadCareCenterSelectorOptions', null, { root: true })

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },

  async getAtentionCentersById({ commit }, { token, id }) {
    try {
      const { data } = await atentionCenterApi().get(`/${id}`);
      const { success, message, data: atentionCenterData } = data;

      return { success, message, atentionCenterData };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async editAtentionCenter({ commit }, info) {
    const { atentionCenter, token } = info;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      // delete atentionCenter.logo

      const { data } = await atentionCenterApi().post(
        ``,
        { ...atentionCenter },
        config
      );
      const { success, message } = data;
      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async updateSpecialte(
    { commit },
    { token, id, description }
  ): Promise<object> {
    const newRequest = { id, description };

    try {
      const { data } = await atentionCenterApi().post("/", newRequest);
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },

  // Care Centers V.2
  async loadCareCenters({ commit }, params) {
    commit("setCareCenters");
    commit("setResponse");

    try {
      const { data: response } = await centersApi().get("/getByType/2/", {
        params,
      });
      const { success, message, data } = response;

      commit("setCareCenters", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
};

export default actions;
