import { isAuthenticatedGuard } from '@/router/auth-guard';

export default [
   
        {
            path: '/tableros-de-control',
            name: 'ControlBoards',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/control-boards/ControlBoards.vue'),
        }
]