import axios from "axios";
// List disabilities
export const ListDisabilities = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/patients/disability`,
});

// Get disability info by ID
export const GetDisabilityInfoByID = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/patients/disability`,
});

// Create disability
export const CreateDisability = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/patients/disability`,
});

// Find by document
export const FindByDocument = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/patients/by-document`,
});

//Get all resources
export const GetAllResources = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/diagnostics/withoutPaginate`,
});

//Send PDF (via email) by Disability ID
export const SendPDFDisabilityID = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/patients/disability`,
});