import { ActionTree } from "vuex";
import { CareProgramsState, RequestResponse } from "./state";
import { StateInterface } from "../index";
import { careProgramsApi } from "@/apis";

const actions: ActionTree<CareProgramsState, StateInterface> = {
  async getCarePrograms(
    { commit },
    {
      token,
      page,
      selects,
    }: {
      token: string;
      page: number;
      selects: { referenceCenter: number; careCenter: number };
    }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await careProgramsApi().get(
        `/list/${selects.referenceCenter}/${selects.careCenter}?page=${page}`,
        config
      );
      const { success, message, data: careProgramData } = data;

      commit("getCarePrograms", careProgramData);
      commit("clearCareProgramsData");
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async changeCareProgramStatusById(
    { commit },
    { token, id }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await careProgramsApi().post(`/${id}`, {}, config);
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async createCareProgram(
    { commit },
    { token, bodyRequest }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await careProgramsApi().post("/", bodyRequest, config);
      const { success, message } = data;

      commit("clearCareProgramsList");
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getCareProgramById(
    { commit },
    { token, id }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await careProgramsApi().get(`/${id}`, config);
      const { success, message, data: careProgramData } = data;

      commit("getCareProgramById", careProgramData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async updateCareProgram(
    { commit },
    { token, id, bodyRequest }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await careProgramsApi().put(`/${id}`, bodyRequest, config);
      const { success, message } = data;

      commit("clearCareProgramsList");
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async addPatient({ commit }, { token, id, bodyRequest }): Promise<object> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await careProgramsApi().post(
        `/addPatients/${id}`,
        bodyRequest,
        config
      );
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getCareProgramsListSelect(
    { commit },
    {
      token,
      urlQueryParams,
    }: {
      token: string;
      urlQueryParams: unknown;
    }
  ): Promise<object> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await careProgramsApi().get(
        urlQueryParams
          ? `/without-paginate?${urlQueryParams}`
          : `/without-paginate`,
        config
      );
      const { success, message, data: careProgramData } = data;

      return { success, message, careProgramData };
    } catch (error) {
      return errorException(error);
    }
  },
};

function errorException(error: any) {
  //No hay conexión
  if (error.message == "Network Error") {
    const success = false;
    const { message } = error;
    return { success, message };
  }
  const { data } = error.response;
  const { success, message } = data;
  return { success, message };
}

export default actions;
