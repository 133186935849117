import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/pacientes",
    name: "PatientsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "PatientsList" */ "@/views/patients/list/PatientsListView.vue"
      ),
  },
  {
    path: "/pacientes/editar-pacientes/:id(\\d+)",
    name: "PatientsEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "PatientsEdit" */ "@/views/patients/edit/PatientsEditView.vue"
      ),
  },
  {
    path: "/pacientes/crear-pacientes",
    name: "PatientsCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "PatientsCreate" */ "@/views/patients/create/PatientsCreateView.vue"
      ),
  },

  {
    path: "/gestion-de-pacientes/asistencias",
    name: "AttendanceView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Patients AttendanceView" */ "@/views/patients/attendance/AttendanceView.vue"
      ),
  },

  {
    path: "/pacientes/importar",
    name: "PatientsImport",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "PatientsList" */ "@/views/patients/import/PatientsImport.vue"
      ),
  },

  {
    path: "/gestion-de-pacientes/incapacidades",
    name: "DisabilitiesView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Patients DisabilitiesView" */ "@/views/patients/disability/DisabilitiesView.vue"
      ),
    children: [
      {
        path: '',
        name: 'DisableListView',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(
          /* webpackChunkName: "RipsView" */
          "@/views/patients/disability/sub-pages/disable-list/DisableListView.vue"),
      },
      {
        path: 'editar-incapacidad',
        name: 'DisableEditView',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(
          /* webpackChunkName: "RipsView" */
          "@/views/patients/disability/sub-pages/disable-edit/DisableEditView.vue"),
      },
      {
        path: 'crear-incapacidad',
        name: 'DisableCreateView',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(
          /* webpackChunkName: "RipsView" */
          "@/views/patients/disability/sub-pages/disable-create/DisableCreateView.vue"),
      },
      {
        path: 'ver-incapacidad',
        name: 'DisableInfoView',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(
          /* webpackChunkName: "RipsView" */
          "@/views/patients/disability/sub-pages/disable-view/DisableInfoView.vue"),
      },

    ]
  }
]
