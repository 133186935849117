import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import store from "@/store";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAppInstance } from "@/main";

const { openMainLoader } = useMainLoader();

const apiBase = axios.create({
  baseURL: `${process.env.VUE_APP_URL}`,
});

const pendingRequests: any = {};

const getRequestKey = (config: { method: string; url: string }) =>
  `${config.method}-${config.url}`;

apiBase.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    const requestKey = getRequestKey(config);

    const token = sessionStorage.getItem("token");

    if (pendingRequests[requestKey]) {
      return Promise.reject(new axios.Cancel("REQUEST_ALREADY_IN_PROGRESS"));
    }

    pendingRequests[requestKey] = true;

    //Intercept the request and do something before it is sent
    if (token) {
      config.headers.Authorization = `Bearer ${sessionStorage.getItem(
        "token"
      )}`;
    }
    return config;
  },
  (error) => {}
);

apiBase.interceptors.response.use(
  (response: AxiosResponse) => {
    const requestKey = getRequestKey(response.config as any);
    delete pendingRequests[requestKey];
    return response;
  },
  async (error) => {
    if (!axios.isCancel(error)) {
      const requestKey = getRequestKey(error.config || {});
      delete pendingRequests[requestKey];
      openMainLoader(false);
      if (error.response.status === 401) {
        store.dispatch("auth/logOutUser", sessionStorage.getItem("token"));
        store.commit("selectors/setCenters", { type: 1, data: undefined });
        store.commit("selectors/setCenters", { type: 2, data: undefined });
        sessionStorage.clear();
        window.location.replace("/auth");

        setTimeout(() => {
          // Verificar y reinicializar el servicio Ably
          const app = getAppInstance();
          if (app && app.config.globalProperties.$reinitializeAbly) {
            (app.config.globalProperties.$reinitializeAbly as () => void)();
          }
        }, 1000);
      }
    }

    return Promise.reject(error);
  }
);

export default apiBase;
