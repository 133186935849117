import axios from "axios";
import apiBase from "@/apis/AxiosIntance";

// Indicators Create - Available Years
export const servicesTypeApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/availabilities/getServices`;
  return apiBase;
};

export const centerRefListApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/centers/getByTypeWP`;
  return apiBase;
};

export const careCenterListApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/centers/getByTypeWP`;
  return apiBase;
};

export const specialitiesByCenterRefApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/specialties/getByCenter`;
  return apiBase;
};

//Get Care Centers by Reference Center
export const CentersbyReferenceCenter = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/centers/getCareByReference`,
});

//get patient
export const meetsResponseApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/patients/folio/v1/getDataOut`;
  return apiBase;
};

// selector specialty doctors
export const doctorsByCenterSpecialtyApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/users/getDoctorsByCenterSpecialty`;
  return apiBase;
};

// selector specialty doctors V3
export const doctorsByCenterSpecialtyApiV3 = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/availabilities/getDoctorsAv`;
  return apiBase;
};

//get all EPS
export const allEPS = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/patients/eps`,
});

//get all Regimen
export const allRegimen = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/patients/regimes`,
});

// get all typeService
export const queryTypesApi = [
  "Primera Vez",
  "Control",
  "Control Prenatal",
  "Crónicos ( HTA- DM)",
  "Patologías De Alto Costo (VIH, ERC, Artritis Reumatoide)",
  "Enf. Huérfana",
  "Enfermedades Catastróficas",
  "Hemofilia",
  "Hepatitis",
  "Trasplantes",
  "Reemplazos Articulares",
  "Cáncer",
  "Manejos Quirúrgicos del Corazón",
  "SNC",
  "Gran Quemado",
  "Trauma Mayor",
];
