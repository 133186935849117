import { StateInterface } from "@/store/index";
import { computed, toRef } from "vue";
import { useStore } from "vuex";
import {
  NumerationsEdit,
  NumerationsList,
  NumerationsView,
} from "@/store/numerations/state";
import { INumeration } from "@/interfaces/global/interfaces";

export const useNumerationsStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    numerations: toRef(store.state.numerations.data, "numerations"),
    numerationsPages: toRef(store.state.numerations.pages, "numerations"),
    numeration: toRef(store.state.numerations.data, "numeration"),
    requests: toRef(store.state.numerations, "requests"),
    data: toRef(store.state.numerations, "data"),
    pages: toRef(store.state.numerations, "pages"),
    response: toRef(store.state.numerations, "response"),

    // Getters
    getNumerationsList: computed<NumerationsList[]>(
      () => store.getters["numerations/getNumerationsList"]
    ),
    getNumerationsEdit: computed<NumerationsEdit | null>(
      () => store.getters["numerations/getNumerationsEdit"]
    ),
    getNumerationsView: computed<NumerationsView | null>(
      () => store.getters["numerations/getNumerationsView"]
    ),

    // Actions
    loadNumerations: (params: {
      description?: string;
      end_date?: string;
      paginate?: boolean;
      page?: number;
      reference_center_id?: number;
      rows?: number;
      start_date?: string;
      status?: string;
      search?: string;
      type?: string;
    }) => store.dispatch("numerations/loadNumerations", params),
    loadNumeration: (id: number) =>
      store.dispatch("numerations/loadNumeration", id),
    createNumeration: (bodyRequest: {
      description: string;
      end_date: string;
      prefix?: string;
      range_from: string;
      range_to: string;
      reference_center_id: number | null;
      resolution_number?: string;
      start_date: string;
      start_number: string;
      status: string;
      type: string;
    }) => store.dispatch("numerations/createNumeration", bodyRequest),
    updateNumeration: (
      id: number,
      bodyRequest: {
        description: string;
        end_date: string;
        prefix?: string;
        range_from: string;
        range_to: string;
        reference_center_id: number | null;
        resolution_number?: string;
        start_date: string;
        start_number: string;
        status: string;
        type: string;
      }
    ) => store.dispatch("numerations/updateNumeration", { id, bodyRequest }),
    changeNumerationStatus: (id: number) =>
      store.dispatch("numerations/changeNumerationStatus", id),
    deleteNumeration: (id: number) =>
      store.dispatch("numerations/deleteNumeration", id),

    //Mutations
    setNumerations: () => store.commit("numerations/setNumerations"),
    setNumeration: (data?: INumeration) =>
      store.commit("numerations/setNumeration", data),
  };
};
