// vuex
import { ActionTree } from "vuex";
import { AuthState } from "./state";

//apis
import { authApiV2 } from "@/apis/loginApi";
import { authApi, executeApi } from "@/apis";

// composables
import { useAlertV2 } from "@/composables/useAlertV2";
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";

// interfaces
import { StateInterface } from "../index";
import { IErrors } from "@/interfaces/customs/IShowErrors";

// Functions
import errorException from "@/functions/ErrorException";

const { openMainLoader } = useMainLoader();
const { showAlert } = useAlertV2();
const { showCatchError } = showErrorInTS();

const URL_PATH = "/api/users";

const actions: ActionTree<AuthState, StateInterface> = {
  async UpdateProfile({ commit, state }, { infoUpdate, newPicture }) {
    openMainLoader(true);
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    const updateProfileURL = `/update`;

    try {
      const updateInfo = await authApiV2().post(
        updateProfileURL,
        { ...infoUpdate },
        { headers }
      );

      const user = state.data.user[0];
      const info = [
        {
          ...user,
          ...updateInfo.data.data,
        },
      ];

      const imageUpdate = newPicture;
      const message = updateInfo.data.message;

      commit("users/setImgUser", imageUpdate, { root: true });
      commit("setUpdateProfile", info);

      openMainLoader(false);
      showAlert(`${message}`, "success");
      return "success";
    } catch (e) {
      const error = e as IErrors;
      openMainLoader(false);
      showAlert(`${showCatchError(error)}`, "error");
      return "error";
    }
  },

  async loginUser({ commit }, user) {
    const { email, password, recaptcha } = user;
    try {
      const { data } = await authApi.post("/login", {
        email,
        password,
        "g-recaptcha-response": recaptcha,
      });
      const { success, message, data: datos } = data;
      if (success) {
        const {
          user,
          centers,
          authorization,
          first_login_or_not_change_password: first_login,
          notifications,
          menu,
        } = datos;
        const { token } = authorization;
        commit("loginUser", {
          user,
          token,
          first_login,
          centers,
          notifications,
          menu,
        });
        return { success, message, data };
      } else {
        return { success: false, message: message, data: null };
      }
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      const errorMessage = `¡${showCatchError(errorParse)}!`;
      return { success: false, message: errorMessage, data: {} };
    }
  },

  async logOutUser({ commit }, token: string) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await authApi.post("/logout", {}, config);
      commit("logOutUser");
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      const errorMessage = `¡${showCatchError(errorParse)}!`;
      return { success: false, message: errorMessage, data: {} };
    }
  },

  async changePassword(
    _,
    pass: {
      new_password: string;
      c_new_password: string;
      token: string;
      tokenChangePassword: string;
    }
  ) {
    const { new_password, c_new_password, token, tokenChangePassword } = pass;
    let config;

    if (token && token !== "") {
      config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }

    try {
      if (token && token !== "") {
        const { data } = await authApi.post(
          "/changePassword",
          { new_password, c_new_password },
          config
        );
        const { success, message } = data;
        return { success, message };
      } else {
        const { data } = await authApi.post(
          `/restorePassword/${tokenChangePassword}`,
          { new_password, c_new_password }
        );
        const { success, message } = data;
        return { success, message };
      }
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      const errorMessage = `¡${showCatchError(errorParse)}!`;
      return { success: false, message: errorMessage, data: {} };
    }
  },

  async forgotPassword(_, email: string) {
    try {
      const { data } = await authApi.post(`/forgotPassword/${email}`, {});
      const { success, message } = data;
      return { success, message };
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      const errorMessage = `¡${showCatchError(errorParse)}!`;
      return { success: false, message: errorMessage, data: {} };
    }
  },

  async recoveryPassword({ commit }, email): Promise<object> {
    try {
      const { data } = await executeApi().post(
        `${URL_PATH}/forgotPassword/${email}`
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IErrors | any) {
      return errorException(error);
    }
  },

  async firstChangePassword({ commit }, form): Promise<object> {
    try {
      const { data } = await executeApi().post(
        `${URL_PATH}/changePassword`,
        form
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IErrors | any) {
      return errorException(error);
    }
  },
};

export default actions;
