import { MutationTree } from 'vuex';
import { AvailableUI } from './state';


const mutation: MutationTree<AvailableUI> = {

    //availableReditect
    setGoRedirectTo(state, redirectTo) {
        state.goRedirectTo = redirectTo
    },

    // alert
    setShowAlert(state, status) {
        state.showAlert = status
    },
    setAlertTitle(state, title) {
        state.alertTitle = title
    },
    setAlertMessage(state, message) {
        state.alertMessage = message

    },
    setAlertType(state, alertType) {
        state.alertType = alertType
    },

    // popup
    setPopupOpen(state, status) {
        state.popupOpen = status
    },
    setPopupLoading(state, status) {
        state.popupLoading = status
    },
    setPopupLoadingMessage(state, message) {
        state.popupLoadingMessage = message
    },
    setPopupTitle(state, title) {
        state.popupTitle = title
    },
    setPopupMessage(state, message) {
        state.popupMessage = message
    },

    setShowContinue(state, status) {
        state.showContinue = status
    },
    setShowClose(state, status) {
        state.showClose = status
    },

    // breadcrumbs
    setCurrentBreadcrumb(state, breadcrumb) {
        state.currentBreadcrumb = breadcrumb
    },

    //global
    setCenterRefOptions(state, centerRefList) {
        state.centerRefOptions = centerRefList
    },

    setCenterRefSelected(state, centerSelected) {
        state.centerRefSelected = centerSelected
    },

    //table render
    setLoadingAvailableCreateList(state, status) {
        state.loadingAvailableCreateList = status
    },

    setAvailableCreateRow(state, createAvailableCreateRow) {
        state.availableCreateRow = createAvailableCreateRow
    },
    setAvailableSelectedDoctor(state, doctorInfo) {
        state.availableSelectedDoctor = doctorInfo
    },

    //render available table
    setAvailableRow(state, availableRow) {
        state.availableRow = availableRow
    },

    setAvailableBySpecialityRow(state, availablesBySpecialitues) {
        state.availableBySpecialityRow = availablesBySpecialitues
    },

    setDeleteAvailableDoctor(state, deleteAvailable) {
        state.deleteAvailableDoctor = deleteAvailable

    },

    setLoadingAvailableList(state, status) {
        state.loadingAvailableList = status
    },


    // Available Create Form
    setCreateAvailableForm(state, infoForm) {
        state.createAvailableForm = infoForm
    },

    // available list create
    setCurrentCreatePage(state, page) {
        state.currentCreatePage = page
    },
    setTotalCreatePage(state, page) {
        state.totalCreatePage = page
    },
    setCreateTo(state, page) {
        state.createTo = page
    },

    //reducer type

    setUnavailableInfo(state, availableCancelId) {
        state.unavailableInfo = availableCancelId
    },


    setAvailableReducerType(state, reducerType) {
        state.availableReducerType = reducerType
    },
    setAvailableInfo(state, info) {
        state.availableInfo = info
    },

    setDeleteAvailableId(state, availableInfo) {
        state.deleteAvailableId = availableInfo
    },


    // available detail table
    setLoadingAvailableTable(state, status) {
        state.loadingAvailableTable = status
    },

    // unavailable detail table
    setLoadingUnavailableTable(state, status) {
        state.loadingUnavailableTable = status
    },


    setInfoAvailableCard(state, info) {
        state.infoAvailableCard = info
    },

    setAvailableViewRow(state, rows) {
        state.availableViewRow = rows
    },

    setUnavailableViewRow(state, rows) {
        state.unavailableViewRow = rows
    },

    setUnavailableViewCurrentPage(state, page) {
        state.unavailableViewCurrentPage = page
    },
    setUnavailableViewLastPage(state, page) {
        state.unavailableViewLastPage = page
    },

    setAvailableViewCurrentPage(state, currentPage) {
        state.availableViewCurrentPage = currentPage
    },
    setAvailableViewLastPage(state, lastPage) {
        state.availableViewLastPage = lastPage
    },

    // available update edit
    setAvailableUpdateEditInfo(state, currentInfo) {
        state.availableUpdateEditInfo = currentInfo
    },

    setAvailableSelectedEdit(state, selectedInfo) {
        state.availableSelectedEdit = selectedInfo
    },

    setAvailableUpdateEditView(state, updateInfo) {
        state.availableUpdateEditView = updateInfo
    },

    setLoadAvailablePage(state, status) {
        state.loadAvailablePage = status
    },

    // available list 
    setCurrentAvailablePage(state, page) {
        state.currentAvailablePage = page
    },

    setCurrentAvailableCreatePage(state, page) {
        state.currentAvailableCreatePage = page
    },

    setTotalAvailableCreatePage(state, page) {
        state.totalAvailableCreatePage = page
    },


    setTotalAvailablePage(state, page) {
        state.totalAvailablePage = page
    },
    setAvailableTo(state, page) {
        state.availableTo = page
    }
}

export default mutation;