export interface RootObject extends RequestResponse {
  data: Data;
  service: string;
}

export interface RequestResponse {
  message: string;
  success: boolean;
}

export interface Data {
  current_page: number;
  data: Datum[] | Patients[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: Link[];
  next_page_url?: null | string;
  path: string;
  per_page: number;
  prev_page_url?: null | string;
  to: number;
  total: number;
}

export interface Link {
  url?: string;
  label: string;
  active: boolean;
}

export interface Datum {
  id: number;
  name: string;
  date: string;
  is_sms: boolean;
  is_email: boolean;
  email_subject: string;
  email_delivered?: any;
  care_program_id: number;
  email_batch_id: string;
  sms_message: string;
  email_message: string;
  sms_failed: number;
  sms_send: number;
  email_failed: number;
  email_send: number;
  status: string;
  total_patients: number;
  created_at: string;
  updated_at: string;
}

export interface Patients {
  id: number;
  name: string;
  last_name: string;
  gender: string;
  age: string;
  phone: string;
  email: string;
  status_sms: string;
  status_email: string;
}

export interface DataList {
  id: Datum["id"];
  campaign: Datum["name"];
  smsMessage: Datum["sms_message"];
  emailSubject: Datum["email_subject"];
  emailMessage: Datum["email_message"];
  patients: Datum["total_patients"];
  status: Datum["status"];
  date: Datum["date"];
}

export interface Campaign {
  id: Datum["id"];
  name: Datum["name"];
  date: Datum["date"];
  is_sms: Datum["is_sms"];
  is_email: Datum["is_email"];
  email_subject: Datum["email_subject"];
  sms_message: Datum["sms_message"];
  email_message: Datum["email_message"];
  sms_failed: Datum["sms_failed"];
  sms_send: Datum["sms_send"];
  email_failed: Datum["email_failed"];
  email_send: Datum["email_send"];
  total_patients: Datum["total_patients"];
}

export interface CampaignsState {
  campaignsList: DataList[] | [];
  campaignsCurrentPage: number;
  campaignsLastPage: number;
  campaign: Datum;
  patientsList: Patients[] | [];
  patientsCurrentPage: number;
  patientsLastPage: number;
}

function state(): CampaignsState {
  return {
    campaignsList: [],
    campaignsCurrentPage: 0,
    campaignsLastPage: 0,
    campaign: {
      id: 0,
      name: "",
      date: "",
      is_sms: false,
      is_email: false,
      email_subject: "",
      email_delivered: null,
      care_program_id: 0,
      email_batch_id: "",
      sms_message: "",
      email_message: "",
      sms_failed: 0,
      sms_send: 0,
      email_failed: 0,
      email_send: 0,
      status: "",
      total_patients: 0,
      created_at: "",
      updated_at: "",
    },
    patientsList: [],
    patientsCurrentPage: 0,
    patientsLastPage: 0,
  };
}

export default state;
