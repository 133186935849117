import {
  IRequestResponse,
} from "@/interfaces/global/interfaces";

export interface AppointmentConfirmationState {
  response: IRequestResponse;
}

function state(): AppointmentConfirmationState {
  return {
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
