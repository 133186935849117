import { IPages } from "@/interfaces/customs/Pages";
import {
  IIndicators256,
  IIndicators256Details,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { StateInterface } from "@/store/index";
import {
  Indicator256View,
  Indicators256Data,
  Indicators256DetailsList,
  Indicators256List,
  Indicators256Pages,
  Indicators256Request,
} from "@/store/indicators-256/state";
import { computed } from "vue";
import { useStore } from "vuex";

export const useIndicators256Store = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    indicators: computed<IIndicators256[]>(
      () => store.state.indicators256.data.indicators
    ),
    indicatorsPages: computed<IPages>(
      () => store.state.indicators256.pages.indicators
    ),
    indicator: computed<IIndicators256 | null>(
      () => store.state.indicators256.data.indicator
    ),
    years: computed<string[]>(() => store.state.indicators256.data.years),
    details: computed<IIndicators256Details[]>(
      () => store.state.indicators256.data.details
    ),
    detailsPages: computed<IPages>(
      () => store.state.indicators256.pages.details
    ),
    request: computed<Indicators256Request>(
      () => store.state.indicators256.request
    ),
    data: computed<Indicators256Data>(() => store.state.indicators256.data),
    pages: computed<Indicators256Pages>(() => store.state.indicators256.pages),
    response: computed<IRequestResponse>(
      () => store.state.indicators256.response
    ),

    // Getters
    getIndicatorsList: computed<Indicators256List[]>(
      () => store.getters["indicators256/getIndicatorsList"]
    ),
    getIndicatorView: computed<Indicator256View>(
      () => store.getters["indicators256/getIndicatorView"]
    ),
    getDetailsList: computed<Indicators256DetailsList[]>(
      () => store.getters["indicators256/getDetailsList"]
    ),

    // Actions
    loadIndicators: (
      centers: { referenceCenter: null | number; careCenter?: null | number },
      params: { status: string; page: number }
    ) =>
      store.dispatch("indicators256/loadIndicators", {
        centers,
        params,
      }),
    loadIndicator: (id: number) =>
      store.dispatch("indicators256/loadIndicator", id),
    createIndicator: (bodyRequest: {
      reference_center_id: number | null;
      care_center_id?: number | null;
      year: string;
      period: string;
      time: string;
    }) => store.dispatch("indicators256/createIndicator", bodyRequest),
    cancelIndicator: (id: number) =>
      store.dispatch("indicators256/cancelIndicator", id),
    loadYears: () => store.dispatch("indicators256/loadYears"),
    loadDetails: (id: number, page = 1) =>
      store.dispatch("indicators256/loadDetails", { id, page }),

    //Mutations
    setIndicators: () => store.commit("indicators256/setIndicators"),
    setIndicator: (data?: IIndicators256) =>
      store.commit("indicators256/setIndicator", data),
    setYears: () => store.commit("indicators256/setYears"),
    setDetails: () => store.commit("indicators256/setDetails"),
  };
};
