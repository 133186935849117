import { StateInterface } from "@/store/index";
import { BanksEdit, BanksList } from "@/store/banks/state";
import { computed, toRef } from "vue";
import { useStore } from "vuex";
import { IBank } from "@/interfaces/global/Bank";

export const useBanksStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    banks: toRef(store.state.banks.data, "banks"),
    banksPages: toRef(store.state.banks.pages, "banks"),
    bank: toRef(store.state.banks.data, "bank"),
    requests: toRef(store.state.banks, "requests"),
    data: toRef(store.state.banks, "data"),
    pages: toRef(store.state.banks, "pages"),
    response: toRef(store.state.banks, "response"),

    // Getters
    getBanksList: computed<BanksList[]>(
      () => store.getters["banks/getBanksList"]
    ),
    getBanksEdit: computed<BanksEdit>(
      () => store.getters["banks/getBanksEdit"]
    ),

    // Actions
    loadBanks: (params: {
      description?: string;
      paginate?: boolean;
      page?: number;
      reference_center_id?: number;
      rows?: number;
      status?: string;
      search?: string;
      type?: string;
    }) => store.dispatch("banks/loadBanks", params),
    loadBank: (id: number) => store.dispatch("banks/loadBank", id),
    createBank: (bodyRequest: {
      reference_center_id: number | null;
      type: string;
      account: string;
      description: string;
    }) => store.dispatch("banks/createBank", bodyRequest),
    updateBank: (
      id: number,
      bodyRequest: {
        reference_center_id: number | null;
        type: string;
        account: string;
        description: string;
      }
    ) => store.dispatch("banks/updateBank", { id, bodyRequest }),
    changeBankStatus: (id: number) =>
      store.dispatch("banks/changeBankStatus", id),
    deleteBank: (id: number) => store.dispatch("banks/deleteBank", id),

    //Mutations
    setBanks: () => store.commit("banks/setBanks"),
    setBank: (data?: IBank) => store.commit("banks/setBank", data),
  };
};
