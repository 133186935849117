import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { SpecialtiesState } from "./state";

const URL_PATH: { [key: number]: string } = {
  1: "/api/settings/specialties",
  2: "/api/settings/specialties/v2",
  3: "/api/settings/specialties/v3",
};

const actions: ActionTree<SpecialtiesState, StateInterface> = {
  async loadSpecialties({ commit }, params) {
    commit("setSpecialties");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH[3]}`, {
        params,
      });
      const { success, message, data } = response;

      commit("setSpecialties", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadSpecialty({ commit }, id) {
    commit("setSpecialty");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH[1]}/${id}`);
      const { success, message, data } = response;

      commit("setSpecialty", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createSpecialty({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(`${URL_PATH[2]}`, bodyRequest);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async updateSpecialty({ commit }, { id, bodyRequest }) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH[2]}/${id}`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async changeSpecialtyStatus({ commit }, id) {
    commit("setSpecialties");
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH[1]}/changeStatus/${id}`
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async deleteSpecialty({ commit }, id) {
    commit("setSpecialties");
    commit("setResponse");

    try {
      const { data } = await executeApi().delete(`${URL_PATH[1]}/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
};

export default actions;
