import { IPages } from "@/interfaces/customs/interface";
import { ICareCenter, IGroup, IProcedure, IReferenceCenter, IRequestResponse } from "@/interfaces/global/interfaces";

export interface Datum {
  id: number;
  created_at: null | string;
  updated_at: null | string;
  code: string;
  description: string;
  status: string;
}

export interface Links {
  url: null | string;
  label: string;
  active: boolean;
}

export interface Response {
  success: boolean;
  message: string;
}

// Procedures V.2
type ProceduresRequests = Record<"procedure", boolean>;
type ProceduresPages = Record<"procedures", IPages>;

export interface ProceduresList {
  id: IProcedure["id"];
  code: IProcedure["code"];
  description: IProcedure["description"];
  referenceCenter?: IReferenceCenter['name'] | null;
  careCenter?: ICareCenter['name'] | null;
  status: IProcedure["status"];
  isDefault: IProcedure["default"];
}

export interface ProceduresEdit {
  code: IProcedure["code"];
  description: IProcedure["description"];
  referenceCenter?: IReferenceCenter['id'] | null;
  careCenter?: ICareCenter['id'] | null;
}

export interface ProceduresData {
  procedures: IProcedure[];
  procedure: IProcedure | null
  groups: IGroup[];
}

export interface ProceduresState {
  procedureCode: string;
  procedureDescription: string;

  // Procedures V.2
  requests: ProceduresRequests;
  data: ProceduresData;
  pages: ProceduresPages;
  response: IRequestResponse;
}

function state(): ProceduresState {
  return {
    procedureCode: "",
    procedureDescription: "",

    // Procedures V.2
    requests: {
      procedure: false,
    },
    data: {
      procedures: [],
      procedure: null,
      groups: [],
    },
    pages: {
      procedures: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
