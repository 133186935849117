export default [
  {
    path: "/encuesta/:key",
    name: "SurveyView",
    component: () =>
      import(
        /* webpackChunkName: "survey" */ "@/views/survey-view/SurveyView.vue"
      ),
 
  },
  {
    path: "/encuesta/respuesta",
    name: "SurveyStatus",
    component: () =>
      import(
        /* webpackChunkName: "survey" */ "@/views/survey-view/sub-pages/SurveyStatus.vue"
      ),
 
  },
];
