import { isAuthenticatedGuard } from '@/router/auth-guard';

export default [
   
        {
            path: '/firma-electronica',
            name: 'DigitalSignatureList',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/digital-signature/DigitalSignatureList.vue'),
        },

        {
            path: '/crear-firma-electronica',
            name: 'DigitalSignatureCreated',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/digital-signature/subpages/created/DigitalSignatureCreated.vue'),
        },

        {
            path: '/editar-firma-electronica/:id',
            name: 'DigitalSignatureEdit',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/digital-signature/subpages/edit/DigitalSignatureEdit.vue'),
        },
]