import { GetterTree } from 'vuex';
import { HealthcareManagementUI } from './state';
import { StateInterface } from '../index';

const getters: GetterTree<HealthcareManagementUI, StateInterface> = {
    // get loader
    getTitle(state) {
        return state.title
    }

}
export default getters;