import { IRequestResponse } from "@/interfaces/global/interfaces";

interface ICenterTypes {
  id: number;
  created_at: null;
  updated_at: null;
  description: string;
}

export interface ICenters {
  id: number;
  created_at: null | string;
  updated_at: null | string;
  name: string;
  nit: string;
  ips_code: null | string;
  address: string;
  phone: string;
  email: string;
  logo: null | string;
  contact_name: string;
  contact_phone: string;
  status: string;
  centers_types_id: number;
  reference_center_id: number | null;
  digital_signature_url: null | string;
  digital_signature_password: null | string;
  digital_signature_status: string;
  careCenters?: ICenters[];
  referenceCenter: ICenters | null;
  center_types?: ICenterTypes;
  laravel_through_key?: number;
}

export type TCentersRequests = "referenceCenters" | "careCenters";
export type TCentersValue = "referenceCenter" | "careCenter";
export type TValue = null | number;

interface ISettings {
  centers: {
    referenceCenters: ICenters[];
    careCenters: ICenters[];
  };
}

interface Requests {
  settings: {
    centers: Record<TCentersRequests, boolean>;
  };
}

interface Values {
  settings: {
    centers: Record<TCentersValue, TValue>;
  };
}

interface Data {
  settings: ISettings;
}

export interface Response extends IRequestResponse {
  module: string;
}

export interface CustomComponentsState {
  requests: Requests;
  values: Values;
  data: Data;
  response: Response;
}

function state(): CustomComponentsState {
  return {
    requests: {
      settings: {
        centers: {
          referenceCenters: false,
          careCenters: false,
        },
      },
    },
    values: {
      settings: {
        centers: {
          referenceCenter: null,
          careCenter: null,
        },
      },
    },
    data: {
      settings: {
        centers: {
          referenceCenters: [],
          careCenters: [],
        },
      },
    },
    response: {
      success: false,
      message: "",
      module: "",
    },
  };
}

export default state;
