export interface NursingNotesState {
  nursingNotesList: unknown;
  documentTypesDownload: { value: string; name: string }[] | [];
  nursingNote: unknown;
  nursingNotesCurrentPage: number;
  nursingNotesLastPage: number;
  proceduresByNursingNote:
    | Array<{
        id: number;
        created_at_formatted: string;
        updated_at_formatted: string;
        ammount: string;
        created_at: string;
        updated_at: string;
        usage_frequency: string;
        treatment_duration: number;
        treatment_duration_period: string;
        observation: string;
        warning_signs: string;
        procedure: {
          id: number;
          description: string;
        };
      }>
    | [];
}

function state(): NursingNotesState {
  return {
    nursingNotesList: [],
    documentTypesDownload: [],
    nursingNote: null,
    nursingNotesCurrentPage: 1,
    nursingNotesLastPage: 1,
    proceduresByNursingNote: [],
  };
}

export default state;
