// import axios from "axios";

// export const careProgramsApi = axios.create({
//   baseURL: `${process.env.VUE_APP_URL}/api/patients/careProgram`,
// });

import apiBase from "@/apis/AxiosIntance";

export const careProgramsApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/patients/careProgram`;

  return apiBase;
};