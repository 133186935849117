import { GetterTree } from "vuex";
import { TelesupportsState } from "./state";
import { StateInterface } from "../index";
import moment from "moment";

const getters: GetterTree<TelesupportsState, StateInterface> = {
  getTelesupportSchedule(state) {
    return  state.telesupportSchedule
  },

  getTelesupportsList(state) {

    return state.telesupportsList.length >= 1
      ? state.telesupportsList.map(
          ({
            id,
            care_center,
            patient,
            specialty,
            created_at: date,
            type_telesupport: type,
            status,
            pdf_url: pdf,
            created_by: createdBy,
            current_schedule,
          }) => ({
            id,
            careCenter: care_center?.name,
            patient: `${patient?.name} ${patient?.last_name}`,
            document: patient?.document,
            specialty: specialty?.description,
            date: moment(date).format("YYYY-MM-DD HH:mm"),
            type,
            status,
            pdf,
            createdBy,
            scheduledTo: current_schedule?.scheduled_to
              ? typeof current_schedule.scheduled_to == "number"
                ? current_schedule.scheduled_to
                : current_schedule.scheduled_to?.id
              : null,
          })
        )
      : [];
  },

  getTelesupport(state) {
    return state.telesupport.length >= 1
      ? state.telesupport.map(
          ({
            patient,
            query_type: queryType,
            reason_for_consultation: motive,
            response,
            documents,
          }) => ({
            patient: [patient].map(
              ({
                document_type,
                document,
                name,
                last_name,
                gender,
                marital_status,
                type_of_population,
                membership,
                regime,
                vital_state,
                birthday,
                eps,
                department_name,
                city_name,
                address,
                phone,
                email,
                occupation,
                arl,
                prepaid,
                care_programs,
                rh_group,
                sex,
              }) => ({
                document_type: document_type?.abbreviation,
                document,
                name: `${name} ${last_name}`,
                gender,
                marital_status,
                type_of_population,
                membership: membership?.name,
                regime: regime?.name,
                vital_state,
                birthday,
                eps: eps?.name,
                department_name,
                city_name,
                address,
                phone,
                email,
                occupation,
                arl: arl?.name,
                prepaid: prepaid?.name,
                care_programs:
                  care_programs?.length >= 1 ? care_programs[0].name : "",
                rh_group,
                sex: sex?.name,
              })
            )[0],
            queryType,
            motive,
            response,
            documents,
          })
        )[0]
      : null;
  },
};

export default getters;
