import { Module } from "vuex";
import { StateInterface } from "../index";

import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

import { RateManualState } from "@/interfaces/global/interfaces";

const rateManualModule: Module<RateManualState, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default rateManualModule;
