import { MutationTree } from "vuex";
import { SchedulesState } from "./state";

const mutation: MutationTree<SchedulesState> = {

  setScheduleListBySpecialty(state, rowList) {
    state.scheduleListBySpecialty = rowList
  },

  setScheduleListCard(state, cardsInfo) {
    state.scheduleListCard = cardsInfo
  },

  setScheduleCardLoading(state, status){
   state.scheduleCardLoading = status
  }, 

  setSchedulesCurrentPage(state, currentPage) {
    state.schedulesCurrentPage = currentPage
  },
  setSchedulesLastPage(state, lastPage) {
    state.schedulesLastPage = lastPage
  },
  setScheduleListBySpecialtyLoading(state, status) {
    state.scheduleListBySpecialtyLoading = status
  }
};

export default mutation;
