import { Module } from 'vuex';
import { StateInterface } from '../index';

import state, { Resolution1604of2013State } from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';


const Resolution1604of2013Module: Module<Resolution1604of2013State, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default Resolution1604of2013Module;