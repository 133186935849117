import { MutationTree } from 'vuex';
import { Resolution2175of2015State } from './state';


const mutation: MutationTree<Resolution2175of2015State> = {
    setListResolution2175(state: Resolution2175of2015State, { data }) {
        state.dataTable.data.data = data.data;
    },

    setListListResolution2175CurrentPage(state: Resolution2175of2015State, { data }){
        state.dataTable.current_page = data;
    },

    setListListResolution2175LastPage(state: Resolution2175of2015State, { data }){
        state.dataTable.current_page = data;
    }
}

export default mutation;