import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
    {
      path: "/control-oxigenos",
      name: "OxygenControlList",
      beforeEnter: [isAuthenticatedGuard],
      component: () =>
        import(
          /* webpackChunkName: "Oxygen Control List" */ "@/views/oxygen-control/list/OxygenControlList.vue"
        ),
    },
    {
      path: "/control-oxigenos/control-oxigenos-crear",
      name: "OxygenControlCreate",
      beforeEnter: [isAuthenticatedGuard],
      component: () =>
        import(
          /* webpackChunkName: "Oxygen Control List" */ "@/views/oxygen-control/create/OxygenControlCreate.vue"
        ),
    },
    {
      path: "/control-oxigenos/control-oxigenos-ver/:id(\\d+)",
      name: "OxygenControlView",
      beforeEnter: [isAuthenticatedGuard],
      component: () =>
        import(
          /* webpackChunkName: "Oxygen Control List" */ "@/views/oxygen-control/view/OxygenControlView.vue"
        ),
    },
]