import { isAuthenticatedGuard } from '@/router/auth-guard';

export default [
   
        {
            path: '/listado-resolucion-1604-de-2013',
            name: 'Resolution1604of2013List',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/resolution-1604-2013/Resolution1604of2013List.vue'),
        },

        {
            path: '/crear-resolucion-1604-de-2013',
            name: 'Resolution1604of2013Request',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/resolution-1604-2013/subpages/resolution-request/Resolution1604of2013Request.vue'),
        },

        {
            path: '/editar-resolucion-1604-de-2013',
            name: 'Resolution1604of2013Edit',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/resolution-1604-2013/subpages/resolution-edit/Resolution1604of2013Edit.vue'),
        },
]