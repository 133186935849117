import { isAuthenticatedGuard } from '@/router/auth-guard';

export default [
   
        {
            path: '/tiempo-inactividad',
            name: 'TimeInactivity',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import('@/views/time-inactivity/TimeInactivity.vue'),
        },

        {
            path: '/gestionar-tiempo-inactividad',
            name: 'TimeInactivityManage',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import('@/views/time-inactivity/subpages/manage/TimeInactivityManage.vue'),
        },

        {
            path: '/editar-tiempo-inactividad/:id',
            name: 'TimeInactivityEdit',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import('@/views/time-inactivity/subpages/edit/TimeInactivityEdit.vue'),
        },
]