import { computed } from "vue";
import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { Telesupports, TelesupportsList } from "@/store/telesupport/state";

export const useTelesupportsStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    telesupportsCurrentPage: computed<number>(
      () => store.state.telesupports.telesupportsCurrentPage
    ),
    telesupportsLastPage: computed<number>(
      () => store.state.telesupports.telesupportsLastPage
    ),

    // Getters
    getTelesupportsList: computed<TelesupportsList[]>(
      () => store.getters["telesupports/getTelesupportsList"]
    ),
    getTelesupport: computed<Telesupports | null>(
      () => store.getters["telesupports/getTelesupport"]
    ),

    // Actions
    telesupportsList: (
      token: string,
      page: number,
      selects: { referenceCenter: number; careCenter: number; status: string }
    ) =>
      store.dispatch("telesupports/telesupportsList", {
        token,
        page,
        selects,
      }),
    telesupportCreate: (
      token: string,
      bodyRequest: {
        data: string;
        documents?: any;
      }
    ) =>
      store.dispatch("telesupports/telesupportCreate", {
        token,
        bodyRequest,
      }),
    telesupportByID: (token: string, id: number) =>
      store.dispatch("telesupports/telesupportByID", { token, id }),

    //Mutations
    setTelesupportsList: () => store.commit("telesupports/setTelesupportsList"),
  };
};
