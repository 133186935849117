import axios from "axios";
import apiBase from "@/apis/AxiosIntance";

//to list all available doctors by center ref
export const availableAllApi = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/settings/availabilities/getDoctorsAv`,
})

// Doctors By CenterSpecialty
export const doctorsByCenterSpecialty = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/users/getDoctorsByCenterSpecialty`,
})

//Create Unavailability
export const createUnavailability = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/settings/availabilities/unavailability`,
})

// updates availables
export const availableUpdateByDoctor = () => {
    apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/availabilities/updateByDoctor`;
    return apiBase;
  };

export const availableUpdateById = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/settings/availabilities/updateById`,
})

// Get Doctor Availabilities by Month
export const doctorAvailabilitiesByMonthApi = () => {
    apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/availabilities/getByMonth`;
    return apiBase;
}

// Delete Available By Id
export const deleteAvailableById = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/settings/availabilities`,
})


// Get Doctor Availabilities by Date
export const doctorAvailabilitiesByDateApi = () => {
    apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/availabilities/getByDate`
    return apiBase;
}

// Create Schedule
export const createScheduleApi = () => {
    apiBase.defaults.baseURL =`${process.env.VUE_APP_URL}/api/telemedicine/scheduling`;
    return apiBase;
}

export const createScheduleApiV3 = () =>{
    apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/availabilities/updateByDoctor`;
    return apiBase;
}

// delete availables
export const deleteAvailableApi = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/settings/availabilities`,
})

export const deleteAvailableByDoctorApi = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/settings/availabilities/delByDoctor`,
})

// create, update, delete
export const availableApi = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/settings/availabilities`,
})

// by doctor
export const availableByDoctorApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/availabilities/getbyDoctorId`;
  return apiBase;
};

// create list
export const availableDoctorsByCenter = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/users/getDoctorsByCenter`,
})

export const availableCenterRefOptions = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/settings/centers/getByTypeWP`,
})


