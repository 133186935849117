export interface RootObject extends RequestResponse {
  data: Data;
  service: string;
}

export interface RequestResponse {
  message: string;
  success: boolean;
}

export interface Data {
  current_page: number;
  data: Datum[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: Link[];
  next_page_url: null | string;
  path: string;
  per_page: number;
  prev_page_url: null | string;
  to: number;
  total: number;
}

export interface Datum {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: number;
  patient_id: number;
  response_by: number | null;
  response_at: null | string;
  key: null | string;
  pdf_url: null | string;
  teleexpertise_id: number;
  care_center_id: number;
  reference_center_id: number;
  status: string;
  response: null | string;
  patient: Patient;
  doctor: Doctor;
  care_center: CareCenter;
}

export interface CareCenter {
  id: number;
  created_at: null;
  updated_at: string;
  name: string;
  nit: string;
  address: string;
  phone: string;
  email: string;
  logo: string;
  contact_name: string;
  contact_phone: string;
  status: string;
  centers_types_id: number;
}

export interface Doctor {
    id:                   number;
    email:                string;
    email_verified_at:    string;
    status:               string;
    last_login:           string;
    last_change_password: string;
    logout:               string;
    expiry_date:          null;
    created_at:           null;
    updated_at:           string;
    user_types_id:        number;
    user_info_id:         number;
    centers_id:           number;
    contract_id:          null;
    name:                 string;
    specialty:            Specialty;
    details:              Details;
}

export interface Details {
  id: number;
  created_at: null;
  updated_at: null;
  names: string;
  surnames: string;
  document: string;
  phone: string;
  signature: null;
  photo: null;
  professional_reg: null;
  professional_type: null;
  gender: string;
}

export interface Specialty {
  id:                  number;
  created_at:          null;
  updated_at:          null;
  description:         string;
  status:              string;
  laravel_through_key: number;
}

export interface Patient {
  id: number;
  name: string;
  last_name: string;
  care_center_id: number;
  birthday: string;
  document_type_id: number;
  document: string;
  eps_id: number;
  arl_id: null;
  prepaid_id: null;
  sex_id: null;
  email: string;
  gender: string;
  marital_status: string;
  phone: string;
  department_code: string;
  city_code: string;
  address: string;
  regime_id: number;
  membership_id: number;
  scholarship: string;
  status: string;
  vital_state: string;
  companion_phone: string;
  companion_name: string;
  occupation: string;
  rh_group: string;
  pacientescol: null;
  background: null;
  allergies: null;
  reason: string;
  url_informed_consent: string;
  type_of_population: string;
  relationship_companion: null;
  is_companion: boolean;
  created_at: null;
  updated_at: string;
}

export interface Link {
  url: null | string;
  label: string;
  active: boolean;
}

export interface ExplanatoryNotes {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: number;
  patient_id: number;
  response_by: number;
  response_at: string;
  key: string;
  pdf_url: string;
  teleexpertise_id: number;
  care_center_id: number;
  reference_center_id: number;
  status: string;
  response: string;
  procedures: [] | Procedure[];
  diagnostics: [] | Diagnostics[];
  medicines: [] | Medicine[];
  documents: [] | Document[];
  responser: Responser;
}

export interface Diagnostics {
  id:                   number;
  reference_center_id:  number;
  diagnostic_id:        number;
  patient_id:           null;
  diagnosticable_type:  string;
  diagnosticable_id:    number;
  type:                 null;
  typification:         null;
  description:          null;
  created_at_formatted: string;
  diagnostic:           Diagnostic;
}

export interface Diagnostic {
  id:          number;
  created_at:  null;
  updated_at:  null;
  code:        string;
  description: string;
  status:      string;
}

export interface Document {
  id: number;
  url: string;
  name: string;
  documentable_id: number;
  documentable_type: string;
  mime_type: string;
  created_at: string;
  updated_at: string;
}

export interface Medicine {
  id: number;
  created_at: null;
  updated_at: null;
  code: string;
  description: string;
  drug_concentration: null;
  dosage_form: null;
  status: string;
  pbs: number;
  pivot: MedicinePivot;
}

export interface MedicinePivot {
  explanatory_note_id: number;
  medicine_id: number;
  amount: string;
  posology: string;
  created_at: string;
  updated_at: string;
}

export interface Procedure {
  id: number;
  created_at: null;
  updated_at: null;
  code: string;
  description: string;
  status: string;
  pivot: ProcedurePivot;
}

export interface ProcedurePivot {
  explanatory_note_id: number;
  procedure_id: number;
  ammount: string;
  observation: string;
  created_at: string;
  updated_at: string;
}

export interface Responser {
  id: number;
  email: string;
  email_verified_at: string;
  status: string;
  last_login: string;
  last_change_password: string;
  logout: string;
  expiry_date: null;
  created_at: null;
  updated_at: string;
  user_types_id: number;
  user_info_id: number;
  centers_id: number;
  contract_id: null;
  name: string;
  details: Details;
}

export interface DataList {
  id: Datum["id"];
  careCenter: Datum["care_center"]["name"];
  patient: Datum["patient"]["name"];
  document: Datum["patient"]["document"];
  doctor: Datum["doctor"]["name"];
  specialty: Datum["doctor"]["specialty"]['description'];
  date: Datum["created_at"];
  status: Datum["status"];
  pdf: Datum["pdf_url"];
  telexpertise_id: Datum["teleexpertise_id"];
}

export interface ExplanatoryNotesState {
  explanatoryNotesList: DataList[] | [];
  explanatoryNotesCurrentPage: number;
  explanatoryNotesLastPage: number;
  explanatoryNote: ExplanatoryNotes;
}

export interface ExplanatoryNote {
  response: ExplanatoryNotes["response"];
  procedures: ExplanatoryNotes["procedures"];
  diagnostics: Diagnostics['diagnostic'] | [];
  medicines: ExplanatoryNotes["medicines"];
  documents: ExplanatoryNotes["documents"];
  responser: ExplanatoryNotes["responser"]["user_types_id"];
}

function state(): ExplanatoryNotesState {
  return {
    explanatoryNotesList: [],
    explanatoryNotesCurrentPage: 0,
    explanatoryNotesLastPage: 0,
    explanatoryNote: {
      id: 0,
      created_at: "",
      updated_at: "",
      created_by: 0,
      patient_id: 0,
      response_by: 0,
      response_at: "",
      key: "",
      pdf_url: "",
      teleexpertise_id: 0,
      care_center_id: 0,
      reference_center_id: 0,
      status: "",
      response: "",
      procedures: [],
      diagnostics: [],
      medicines: [],
      documents: [],
      responser: {
        id: 0,
        email: "",
        email_verified_at: "",
        status: "",
        last_login: "",
        last_change_password: "",
        logout: "",
        expiry_date: null,
        created_at: null,
        updated_at: "",
        user_types_id: 0,
        user_info_id: 0,
        centers_id: 0,
        contract_id: null,
        name: "",
        details: {
          id: 0,
          created_at: null,
          updated_at: null,
          names: "",
          surnames: "",
          document: "",
          phone: "",
          signature: null,
          photo: null,
          professional_reg: null,
          professional_type: null,
          gender: "",
        },
      },
    },
  };
}

export default state;
