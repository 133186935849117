import { IDataTable, IOxygenControl } from "@/interfaces/customs/interface";
export interface OxygenControlState {
  oxygenControlList: object[];
  oxygenControlCurrentPage: number;
  oxygenControlLastPage: number;
  dataTable: IDataTable;
  ventilatory_modes: string[];
  dataOxygenControl: IOxygenControl | null;
}

function state(): OxygenControlState {
  return {
    oxygenControlList: [],
    oxygenControlCurrentPage: 1,
    oxygenControlLastPage: 1,
    dataTable: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    ventilatory_modes: [
      "Cateter canutal nasal",
      "Sistema de alto flujo",
      "Ventilación mecanica",
      "Ventury al 35%",
      "Ventury al 40%",
      "Ventury al 50%",
    ],
    dataOxygenControl: null,
  };
}

export default state;
