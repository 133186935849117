import { TelemedicineReportState } from "@/interfaces/global/interfaces";

function state(): TelemedicineReportState {
  return {
    list: [],
    currentPage: 1,
    lastPage: 1,
    years: [],
    months: [],
  };
}

export default state;
