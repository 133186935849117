import { IDataTable } from "@/interfaces/customs/interface";
import { MutationTree } from "vuex";
import { TriageState } from "./state";

const mutation: MutationTree<TriageState> = {
  setTriages(state: TriageState, list: IDataTable): void {
    state.triageList = list?.data;

    state.triageCurrentPage = list?.current_page;
    state.triageLastPage = list?.last_page;
  },
  setClassificationTriages(state: TriageState, list): void {
    state.classifications = list;
  },
  setTriage(state: TriageState, data): void {
    state.triageData = data;
  },
  setTriageById(state: TriageState, data): void {
    state.triage = data;
  },
  setPatientByTriageId(state: TriageState, data): void {
    state.patientTriage = data;

    if (state.patientTriage) {
      state.patientTriage.relationship_companion_patient =
        data?.attendant?.relationship;
      state.patientTriage.name_companion_patient = data?.attendant?.name;
      state.patientTriage.phone_companion_patient = data?.attendant?.phone;
    }
  },
};

export default mutation;
