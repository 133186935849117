import { IPages } from "@/interfaces/customs/interface";
import { IRequestResponse, ISpecialty } from "@/interfaces/global/interfaces";

// Specialties V.2
export type SpecialtiesPages = Record<"specialties", IPages>;

export interface SpecialtiesList {
  id: ISpecialty["id"];
  description: ISpecialty["description"];
  status: ISpecialty["status"];
}

export interface SpecialtiesData {
  specialties: ISpecialty[];
}

export interface SpecialtesState {
  isLoading: boolean;
  specialtesList: Array<object>;
  specialteDescription: string;
  specialtesStatus: object;
  specialtiesByCenter: object[];
  specialtiesCurrentPage: number;
  specialtiesLastPage: number;

  // Specialties V.2
  data: SpecialtiesData;
  pages: SpecialtiesPages;
  response: IRequestResponse;
}

function state(): SpecialtesState {
  return {
    isLoading: true,
    specialtesList: [],
    specialteDescription: "",
    specialtesStatus: {},
    specialtiesByCenter: [],
    specialtiesCurrentPage: 1,
    specialtiesLastPage: 1,

    // Specialties V.2
    data: {
      specialties: [],
    },
    pages: {
      specialties: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
