import { executeApi } from "@/apis";
import { TelemedicineReportState } from "@/interfaces/global/interfaces";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import errorException from "@/functions/ErrorException";
import { data } from '../absences/state';

const URL_PATH = "/api/documents/telemedicine-indicator";

const headers = {
  "Content-Type": "multipart/form-data",
};

const actions: ActionTree<TelemedicineReportState, StateInterface> = {
  async listAction({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().get(URL_PATH, { params });
      const { success, message } = data;

      commit("setTelemedicineReport", data);

      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async listYearsSelectorAction({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().get(`${URL_PATH}/years`, { params });
      const { success, message } = data;

      commit("setYears", data.data);

      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async listMonthsSelectorAction({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().get(`${URL_PATH}/months/`, {
        params,
      });
      const { success, message } = data;

      commit("setMonths", data.data);

      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async generateReport({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().post(`${URL_PATH}`, params);
      const { success, message } = data;

      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },
};

export default actions;
