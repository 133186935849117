import {
  IData,
  IIndicators256,
  IIndicators256Details,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { Indicators256State } from "./state";

const mutation: MutationTree<Indicators256State> = {
  setIndicators(state: Indicators256State, data?: IData): void {
    if (data) {
      state.data.indicators = data.data as IIndicators256[];

      state.pages.indicators.currentPage.value = data.current_page;
      state.pages.indicators.lastPage.value = data.last_page;

      state.request.indicators = true;
    } else {
      state.data.indicators = [];

      state.request.indicators = false;
    }
  },
  setIndicator(state: Indicators256State, data?: IIndicators256 | null) {
    if (data !== undefined) {
      state.data.indicator = data;
      state.request.indicator = true;
    } else {
      state.data.indicator = null;
      state.request.indicator = false;
    }
  },
  setYears(state: Indicators256State, data?: string[]) {
    if (data) {
      state.data.years = data;
      state.request.years = true;
    } else {
      state.data.years = [];
      state.request.years = false;
    }
  },
  setResponse(state: Indicators256State, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
  setDetails(state: Indicators256State, data?: IData): void {
    if (data) {
      state.data.details = data.data as IIndicators256Details[];

      state.pages.details.currentPage.value = data.current_page;
      state.pages.details.lastPage.value = data.last_page;

      state.request.details = true;
    } else {
      state.data.details = [];

      state.request.details = false;
    }
  },
};

export default mutation;
