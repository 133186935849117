import { useStore } from "vuex";
import { StateInterface } from '@/store/index';
import { computed } from "vue";

export const useResolution2175of2015 = () => {

    const store = useStore<StateInterface>();

    return {
        // State - Getter
        listResolution2175of2015: computed( () => store.state.resolution2175of2015.dataTable.data.data),
        currentPage: computed( () => store.state.resolution2175of2015.dataTable.current_page),
        lastPage: computed( () => store.state.resolution2175of2015.dataTable.last_page),
        // Actions
        isGetListResolution2175of2015: (data:any) => store.dispatch('resolution2175of2015/getListResolution2175', data),
        isRequestResolution2175of2015: (data:any) => store.dispatch('resolution2175of2015/requestReportListResolution2175', data),
        isCancelResolutionStatus: (data:any) => store.dispatch('resolution2175of2015/cancelResolutionStatus', data),
        isGetYearsReport: (data:any) => store.dispatch('resolution2175of2015/getYearsReport', data),
        isGetMonthsReport: (data:any) => store.dispatch('resolution2175of2015/getMonthsReport', data),
        //Mutations
        isSetCurrentPage: (data:number) => store.commit('resolution2175of2015/setListListResolution2175CurrentPage', data),
        isSetLastPage: (data:number) => store.commit('resolution2175of2015/setListListResolution2175LastPage', data)
    }
}