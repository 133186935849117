import { ActionTree } from "vuex";
import { OxygenControlState } from "./state";
import { StateInterface } from "../index";
import { oxygenControlApi } from "@/apis";
import { IError } from "@/interfaces/customs/interface";

const actions: ActionTree<OxygenControlState, StateInterface> = {
  async getOxygensControl(
    { commit },
    { referenceCenterId, careCenterId, page }
  ): Promise<object> {
    try {
      const { data } = await oxygenControlApi().get(
        `?page=${page}&reference_center=${referenceCenterId}&care_center=${careCenterId}`
      );
      const { success, message, data: dataRequest } = data;

      dataRequest.data.forEach((element: any, i: number) => {
        element[
          "patient_name"
        ] = `${element.patient.name} ${element.patient?.last_name}`;
        element["document"] = element.patient.document;
      });

      commit("setOxygenControls", data.data);
      return { success, message, dataRequest };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async createOxygenControl({ commit }, { dataRequest }) {
    try {
      const { data } = await oxygenControlApi().post("", dataRequest);
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getOxygenControlById({ commit }, { id }) {
    try {
      const { data } = await oxygenControlApi().get("/" + id);
      const { success, message, data: dataRequest } = data;

      return { success, message, dataRequest };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
};

function errorException(error: IError) {
  let success = false;
  if (error.message == "Network Error") {
    const { message } = error;
    return { success, message };
  }
  const data = error.response?.data;
  if (data) {
    const { success, message } = data;
    return { success, message };
  }
  const message = "Error";
  return { success, message };
}

export default actions;
