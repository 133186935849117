import { MutationTree } from "vuex";
import {
  AllResources,
  Data,
  DataList,
  Datum,
  ReasonForConsultation,
  UnificationMedicalRecordsState,
} from "./state";

const mutation: MutationTree<UnificationMedicalRecordsState> = {
  getUnificationMedicalRecords(
    state: UnificationMedicalRecordsState,
    data: Data
  ): void {
    if (data) {
      if (data.data.length >= 1) {
        state.unificationMedicalRecordsList = data.data.map(
          ({
            id,
            date_request: date,
            type_request_service,
            patient_one,
            patient_two,
            requested_by,
            care_center,
            status,
            pdf_url: document,
          }: Datum): DataList => ({
            id,
            date,
            type: type_request_service ? type_request_service.name : "",
            patientOne: patient_one ? patient_one.document : "",
            patientTwo: patient_two ? patient_two.document : "",
            requestedBy: requested_by ? requested_by.name : "",
            careCenter: care_center ? care_center.name : "",
            status,
            document,
          })
        );
      } else state.unificationMedicalRecordsList = [];

      state.unificationMedicalRecordsCurrentPage = data.current_page;
      state.unificationMedicalRecordsLastPage = data.last_page;
    }
  },
  getAllResources(state: UnificationMedicalRecordsState, data: AllResources) {
    if (data) {
      if (data.type_request.length >= 1) {
        state.typeRequest = data.type_request.map(
          ({ id, name }: ReasonForConsultation) => ({ id, name })
        );
      } else state.typeRequest = [];

      if (data.reason_for_consultation.length >= 1) {
        state.reasonForConsultation = data.reason_for_consultation.map(
          ({ id, name }: ReasonForConsultation) => ({ id, name })
        );
      } else state.reasonForConsultation = [];
    }
  },
  getUnificationMedicalRecordById(
    state: UnificationMedicalRecordsState,
    data: Datum
  ) {
    if (data) {
      state.unificationMedicalRecord = data;
    }
  },
  clearUnificationMedicalRecordsList(state: UnificationMedicalRecordsState) {
    state.unificationMedicalRecordsList = [];
  },
  clearUnificationMedicalRecordsData(state) {
    state.unificationMedicalRecord = {
      id: 0,
      reference_center: {
        id: 0,
        created_at: null,
        updated_at: null,
        name: "",
        nit: "",
        address: "",
        phone: "",
        email: "",
        logo: null,
        contact_name: "",
        contact_phone: "",
        status: "",
        centers_types_id: 0,
      },
      care_center: {
        id: 0,
        created_at: null,
        updated_at: null,
        name: "",
        nit: "",
        address: "",
        phone: "",
        email: "",
        logo: null,
        contact_name: "",
        contact_phone: "",
        status: "",
        centers_types_id: 0,
      },
      type_request_service: {
        id: 0,
        name: "",
        created_at: null,
        updated_at: null,
      },
      reason_for_consultation: {
        id: 0,
        name: "",
        created_at: null,
        updated_at: null,
      },
      patient_one: {
        id: 0,
        name: "",
        last_name: "",
        gender: "",
        age: "",
        phone: "",
        email: "",
        status_sms: "",
        status_email: "",
        care_program: [],
        eps: {
          id: 0,
          code: "",
          name: "",
          status: "",
          created_at: null,
          updated_at: null,
        },
        document_type: {
          id: 0,
          name: "",
          abbreviation: "",
          created_at: null,
          updated_at: null,
        },
        document: "",
      },
      patient_two: {
        id: 0,
        name: "",
        last_name: "",
        gender: "",
        age: "",
        phone: "",
        email: "",
        status_sms: "",
        status_email: "",
        care_program: [],
        eps: {
          id: 0,
          code: "",
          name: "",
          status: "",
          created_at: null,
          updated_at: null,
        },
        document_type: {
          id: 0,
          name: "",
          abbreviation: "",
          created_at: null,
          updated_at: null,
        },
        document: "",
      },
      observation: "",
      status: "",
      created_at: "",
      updated_at: "",
      date_request: "",
      requested_by: {
        id: 0,
        email: "",
        email_verified_at: "",
        status: "",
        last_login: "",
        last_change_password: "",
        logout: "",
        expiry_date: null,
        created_at: null,
        updated_at: "",
        user_types_id: 0,
        user_info_id: 0,
        centers_id: 0,
        contract_id: null,
        name: "",
        details: {
          id: 0,
          created_at: null,
          updated_at: null,
          names: "",
          surnames: "",
          document: "",
          phone: "",
          signature: null,
          photo: null,
          professional_reg: null,
          professional_type: null,
          gender: "",
        },
      },
      revision: null,
      audited_by: {
        id: 0,
        email: "",
        email_verified_at: "",
        status: "",
        last_login: "",
        last_change_password: "",
        logout: "",
        expiry_date: null,
        created_at: null,
        updated_at: "",
        user_types_id: 0,
        user_info_id: 0,
        centers_id: 0,
        contract_id: null,
        name: "",
        details: {
          id: 0,
          created_at: null,
          updated_at: null,
          names: "",
          surnames: "",
          document: "",
          phone: "",
          signature: null,
          photo: null,
          professional_reg: null,
          professional_type: null,
          gender: "",
        },
      },
      pdf_url: null,
      reason: null,
      documents: [
        {
          id: 0,
          url: "",
          name: "",
          documentable_id: 0,
          documentable_type: "",
          mime_type: "",
          created_at: "",
          updated_at: "",
          date: "",
          description: "",
        },
      ],
    };
  },
};

export default mutation;
