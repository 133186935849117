import {
  IData,
  IPackage,
  IPackageService,
  IRequestResponse,
  ITax,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { PackagesState } from "./state";

const mutation: MutationTree<PackagesState> = {
  setPackages(state: PackagesState, data?: IData): void {
    if (data) {
      state.data.packages = data.data as IPackage[];

      state.pages.packages.currentPage.value = data.current_page;
      state.pages.packages.lastPage.value = data.last_page;
    } else {
      state.data.packages = [];
    }
  },
  setPackage(state: PackagesState, data?: IPackage | null): void {
    if (data !== undefined) {
      state.data.package = data;

      state.requests.package = true;
    } else {
      state.data.package = null;

      state.requests.package = false;
    }
  },
  setPackageServices(state: PackagesState, data?: IData): void {
    if (data) {
      if (Array.isArray(data)) {
        state.data.packageServices = data;
      } else {
        state.data.packageServices = data.data as IPackageService[];

        state.pages.packageServices.currentPage.value = data.current_page;
        state.pages.packageServices.lastPage.value = data.last_page;
      }
    } else {
      state.data.packageServices = [];
    }
  },
  setTaxes(state: PackagesState, data?: ITax[]): void {
    if (data) {
      state.data.taxes = data;
    } else {
      state.data.taxes = [];
    }
  },
  setResponse(state: PackagesState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
