import { executeApi } from "@/apis";
import { AdministratorsState } from "@/interfaces/global/interfaces";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import errorException from "@/functions/ErrorException";

const URL_PATH = "/api/patients/eps";

const headers = {
  "Content-Type": "multipart/form-data",
};

const actions: ActionTree<AdministratorsState, StateInterface> = {
  async getListAdministrators({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().get(URL_PATH, { params });
      const { success, message } = data;

      commit("setAdministratorsList", data);

      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async createAdministrator({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().post(`${URL_PATH}`, params, {
        headers,
      });
      const { success, message } = data;

      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async getAdministratorsById({ commit }, id): Promise<object> {
    try {
      const { data } = await executeApi().get(`${URL_PATH}/${id}`);
      const { success, message } = data;

      commit("setAdministrator", data.data);

      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async editAdministrator({ commit }, { params, id }): Promise<object> {
    try {
      const { data } = await executeApi().post(`${URL_PATH}/${id}`, params, {
        headers,
      });
      const { success, message } = data;

      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async changeStatusAdministrator({ commit }, id): Promise<object> {
    try {
      const { data } = await executeApi().post(
        `${URL_PATH}/changeStatus/${id}`
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },

  async deleteAdministrator({ commit }, id): Promise<object> {
    try {
      const { data } = await executeApi().delete(`${URL_PATH}/${id}`);
      const { success, message } = data;

      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },
};

export default actions;
