import {
  IBackgrounds,
  IClinicalLaboratories,
  ICurrentSchedule,
  IData,
  IDiagnostics,
  IDisabilities,
  IFile,
  IGynecologist,
  IMedicalExams,
  IMedicines,
  IPatient,
  IProcedures,
  IRequestResponse,
  IService,
  IVaccines,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { MedicalRecordsState } from "./state";

const mutation: MutationTree<MedicalRecordsState> = {
  // Medical Records V.2
  setPatient(state: MedicalRecordsState, data?: IPatient): void {
    if (data !== undefined) {
      state.data.patient = data;
      state.request.patient = true;
    } else {
      state.data.patient = null;
      state.request.patient = false;
    }
  },
  setFolios(state: MedicalRecordsState, data?: IData): void {
    if (data) {
      state.data.folios = data.data as IService[];

      state.pages.folios.currentPage.value = data.current_page;
      state.pages.folios.lastPage.value = data.last_page;

      state.request.folios = true;
    } else {
      state.data.folios = [];

      state.request.folios = false;
    }
  },
  setDiagnostics(state: MedicalRecordsState, data?: IData): void {
    if (data) {
      state.data.diagnostics = data.data as IDiagnostics[];

      state.pages.diagnostics.currentPage.value = data.current_page;
      state.pages.diagnostics.lastPage.value = data.last_page;

      state.request.diagnostics = true;
    } else {
      state.data.diagnostics = [];

      state.request.diagnostics = false;
    }
  },
  setBackgrounds(state: MedicalRecordsState, data?: IData): void {
    if (data) {
      state.data.backgrounds = data.data as IBackgrounds[];

      state.pages.backgrounds.currentPage.value = data.current_page;
      state.pages.backgrounds.lastPage.value = data.last_page;

      state.request.backgrounds = true;
    } else {
      state.data.backgrounds = [];

      state.request.backgrounds = false;
    }
  },
  setProcedures(state: MedicalRecordsState, data?: IData): void {
    if (data) {
      state.data.procedures = data.data as IProcedures[];

      state.pages.procedures.currentPage.value = data.current_page;
      state.pages.procedures.lastPage.value = data.last_page;

      state.request.procedures = true;
    } else {
      state.data.procedures = [];

      state.request.procedures = false;
    }
  },
  setManagementPlans(state: MedicalRecordsState, data?: IData): void {
    if (data) {
      state.data.managementPlans = data.data as IMedicines[];

      state.pages.managementPlans.currentPage.value = data.current_page;
      state.pages.managementPlans.lastPage.value = data.last_page;

      state.request.managementPlans = true;
    } else {
      state.data.managementPlans = [];

      state.request.managementPlans = false;
    }
  },
  setDocuments(state: MedicalRecordsState, data?: IData): void {
    if (data) {
      state.data.documents = data.data as IFile[];

      state.pages.documents.currentPage.value = data.current_page;
      state.pages.documents.lastPage.value = data.last_page;

      state.request.documents = true;
    } else {
      state.data.documents = [];

      state.request.documents = false;
    }
  },
  setClinicalLaboratories(state: MedicalRecordsState, data?: IData): void {
    if (data) {
      state.data.clinicalLaboratories = data.data as IClinicalLaboratories[];

      state.pages.clinicalLaboratories.currentPage.value = data.current_page;
      state.pages.clinicalLaboratories.lastPage.value = data.last_page;

      state.request.clinicalLaboratories = true;
    } else {
      state.data.clinicalLaboratories = [];

      state.request.clinicalLaboratories = false;
    }
  },
  setMedicalExams(state: MedicalRecordsState, data?: IData): void {
    if (data) {
      state.data.medicalExams = data.data as IMedicalExams[];

      state.pages.medicalExams.currentPage.value = data.current_page;
      state.pages.medicalExams.lastPage.value = data.last_page;

      state.request.medicalExams = true;
    } else {
      state.data.medicalExams = [];

      state.request.medicalExams = false;
    }
  },
  setVaccines(state: MedicalRecordsState, data?: IData): void {
    if (data) {
      state.data.vaccines = data.data as IVaccines[];

      state.pages.vaccines.currentPage.value = data.current_page;
      state.pages.vaccines.lastPage.value = data.last_page;

      state.request.vaccines = true;
    } else {
      state.data.vaccines = [];

      state.request.vaccines = false;
    }
  },
  setDisabilities(state: MedicalRecordsState, data?: IData): void {
    if (data) {
      state.data.disabilities = data.data as IDisabilities[];

      state.pages.disabilities.currentPage.value = data.current_page;
      state.pages.disabilities.lastPage.value = data.last_page;

      state.request.disabilities = true;
    } else {
      state.data.disabilities = [];

      state.request.disabilities = false;
    }
  },
  setGynecologist(state: MedicalRecordsState, data: IGynecologist): void {
    if (data !== undefined) {
      state.data.gynecologist = data;
      state.request.gynecologist = true;
    } else {
      state.data.gynecologist = null;
      state.request.gynecologist = false;
    }
  },
  setResponse(state: MedicalRecordsState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
