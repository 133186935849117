import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/triage",
    name: "TriageList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Triage List" */ "@/views/triage/list/TriageList.vue"
      ),
  },
  {
    path: "/triage/crear-triage/:id(\\d+)",
    name: "TriageCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Triage Create" */ "@/views/triage/create/TriageCreate.vue"
      ),
  },
  {
    path: "/triage/ver-triage/:id(\\d+)",
    name: "TriageView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Triage View" */ "@/views/triage/view/TriageView.vue"
      ),
  },
];
