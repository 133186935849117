import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";

export const usePreparations = () => {
  const store = useStore<StateInterface>();
  return {
    // States
    preparationsList: computed(() => store.state.preparations.preparationsList),
    administratorRow: computed(() => store.state.preparations.preparationRow),
    preparationsCurrentPage: computed(
      () => store.state.preparations.preparationsCurrentPage
    ),
    preparationsLastPage: computed(
      () => store.state.preparations.preparationsLastPage
    ),
    states: computed(() => store.state.preparations.states),
    types: computed(() => store.state.preparations.types),
    typesWithoutAll: computed(() => store.state.preparations.typesWithoutAll),
    data_form: computed(() => store.state.preparations.data_form),
    data_table: computed(() => store.state.preparations.data_table),

    // Actions
    _getListPreparations: (params: object) =>
      store.dispatch("preparations/getListPreparations", params),
    _createPreparation: (params: object) =>
      store.dispatch("preparations/createPreparation", params),
    _getPreparationsById: (id: number | string) =>
      store.dispatch("preparations/getPreparationsById", id),
    _getRecomendationsByPreparationId: (params: object, id: number | string) =>
      store.dispatch("preparations/getRecomendationsByPreparationId", {
        params,
        id,
      }),
    _editPreparation: (params: object, id: number | string) =>
      store.dispatch("preparations/editPreparation", { params, id }),
    _changeStatusPreparation: (id: number | string) =>
      store.dispatch("preparations/changeStatusPreparation", id),
    _searchExistingPreparation: (params: object = {}) =>
      store.dispatch("preparations/searchExistingPreparation", params),
    _deletePreparation: (id: number | string) =>
      store.dispatch("preparations/deletePreparation", id),

    // Mutation
    _setDataForm: (
      details: {
        reference_center_id: number;
        care_center_id: number;
        type: string;
        preparationable_id: string | number;
        name: string;
      } | null
    ) => store.commit("preparations/setDataForm", details),
    _setDataTable: (details: object | null) =>
      store.commit("preparations/setDataTable", details),
    _setPreparationable: (value: number | string | null) =>
      store.commit("preparations/setPreparationable", value),
  };
};
