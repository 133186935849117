import { IPages } from "@/interfaces/customs/Pages";
import {
IGroup,
  IPackage,
  IPackageService,
  IRequestResponse,
  ITax,
} from "@/interfaces/global/interfaces";

export type PackagesRequests = Record<"package", boolean>;
export type PackagesPages = Record<"packages" | "packageServices", IPages>;

export interface PackagesServicesList {
  id: IPackageService["id"];
  soat_code: IPackageService["soat_code"];
  sell_price: string;
  observation: IPackageService["observation"];
  status: IPackageService["status"];
  procedure: IPackageService["procedure"];
  tax_type: IPackageService["tax_type"];
  group: IPackageService["procedure"]["group"];
}

export interface PackagesServicesView {
  soatCode: IPackageService["soat_code"];
  observation: IPackageService["observation"];
  sellPrice: IPackageService["sell_price"];
  status: IPackageService["status"];
  code: IPackageService["procedure"]["code"];
  description: IPackageService["procedure"]["description"];
  tax: IPackageService["tax_type"]["name"];
  group: IGroup["name"] | undefined;
}

export interface PackagesData {
  packages: IPackage[];
  package: IPackage | null;
  packageServices: IPackageService[];
  taxes: ITax[];
}

export interface PackagesState {
  requests: PackagesRequests;
  data: PackagesData;
  pages: PackagesPages;
  response: IRequestResponse;
}

function state(): PackagesState {
  return {
    requests: {
      package: false,
    },
    data: {
      packages: [],
      package: null,
      packageServices: [],
      taxes: [],
    },
    pages: {
      packages: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      packageServices: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
