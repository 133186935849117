import { ActionTree } from 'vuex';
import { Resolution1604of2013State } from './state';
import { StateInterface } from '../index';
import { Resolution1604of2013Api } from '@/apis';
import { allEPS } from '@/apis/general/generalApi';

const actions: ActionTree<Resolution1604of2013State, StateInterface> = {

    async getListResolution1604of2013( { commit }, data ) {
        const { page, token, reference_center, care_center } = data;
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const route = `?page=${page}&reference_center_id=${reference_center}${ care_center !== null ? '&care_center_id='+care_center : '' }`
            const { data } = await Resolution1604of2013Api.get(route, config);
            commit('setListResolution1604of2013', data);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }

    },

    async requestResolution1604of2013( { }, data:any ) {
        const { token, formData } = data;
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'    
            }
        };
        try{
            const { data } = await Resolution1604of2013Api.post('', formData, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async cancelResolutionStatus( { }, data ) {

        const { id, token } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const { data } = await Resolution1604of2013Api.post(`/cancel/${id}`, { }, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async getListEps( { }, data:any ) {
        const { token } = data;
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            },
        };

        try{
            const { data } = await allEPS.get("", config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },
}

function errorExeption(error: any) {
    
    //No hay conexion
    if(error.message == 'Network Error'){
        const success = false;
        const { message } = error;
        return { success, message }; 
    }            
    const { data } = error.response;
    const { success, message } = data; 
    return { success, message };
}

export default actions;