export interface RootObject extends RequestResponse {
  data: Data;
  service: string;
}

export interface RequestResponse {
  message: string;
  success: boolean;
}

export interface Data {
  current_page: number;
  data: Datum[] | Telexpertises[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: Link[];
  next_page_url: null;
  path: string;
  per_page: number;
  prev_page_url: null;
  to: number;
  total: number;
}

export interface Datum {
  id: number;
  user_id: number;
  reference_center_id: number;
  care_center_id: number | null;
  type_teleexpertise_id: number;
  patient_id: number;
  status: string;
  from_date: string;
  to_date: string;
  pdf_url: null | string;
  excel_url: null | string;
  created_at: string;
  from_date_formatted: string;
  to_date_formatted: string;
  created_at_formatted: string;
  user: User;
  type_teleexpertise: ReferenceData;
  reference_center: ReferenceData;
  care_center: ReferenceData | null;
  patient: Patient;
}

export interface Patient {
  id: number;
  name: string;
  last_name: string;
  document: string;
  eps_id?: number;
  eps?: ReferenceData;
  age?: null | string;
}

export interface ReferenceData {
  id: number;
  name: string;
}

export interface User {
  id: number;
  user_info_id: number;
  name: string;
  details: Details;
}

export interface Details {
  id: number;
  names: string;
  surnames: string;
}

export interface Link {
  url: null | string;
  label: string;
  active: boolean;
}

export interface Specialty {
  id: number;
  description: string;
}

export interface ScheduledTo {
  id:           number;
  user_info_id: number;
  name:         string;
  details:      Details;
}

export interface CurrentSchedule {
  scheduleable_type:      string;
  scheduleable_id:        number;
  scheduled_at:           string;
  scheduled_to:           ScheduledTo;
  duration:               null;
  scheduled_at_formatted: string;
}

export interface Telexpertises {
    id:                       number;
    user_id:                  number | null;
    patient_id:               number;
    type_teleexpertise_id:    number;
    specialty_id:             number;
    attention_date_formatted: null;
    scheduled_at_formatted:   null;
    type_teleexpertise:       ReferenceData;
    specialty:                Specialty;
    patient:                  Patient;
    current_schedule:         CurrentSchedule;
}

export interface DataList {
  id: Datum["id"];
  type: Datum["type_teleexpertise"]["name"];
  patient: Datum["patient"]["name"];
  document: Datum["patient"]["document"];
  fromDate: Datum["from_date_formatted"];
  toDate: Datum["to_date_formatted"];
  generatedDate: Datum["created_at_formatted"];
  requestedBy: Datum["user"]["name"];
  pdf: Datum["pdf_url"];
  excel: Datum["excel_url"];
}

export interface ScheduleReport {
  referenceCenter: Datum["reference_center"]["name"];
  careCenter?: ReferenceData["name"];
  type: Datum["type_teleexpertise"]["name"];
  requestedBy: Datum["user"]["name"];
  generatedDate: Datum["created_at_formatted"];
  fromDate: Datum["from_date_formatted"];
  status: Datum["status"];
  patient: Datum["patient"]["name"];
  toDate: Datum["to_date_formatted"];
  pdf: Datum["pdf_url"];
  excel: Datum["excel_url"];
}

export interface ReportTelexpertisesList {
  type: Telexpertises["type_teleexpertise"]["name"];
  doctor?: Telexpertises['current_schedule']["scheduled_to"]["name"];
  specialty: Telexpertises["specialty"]["description"];
  patient: Telexpertises["patient"]["name"];
  document: Telexpertises["patient"]["document"];
  eps?: ReferenceData["name"];
  scheduled: Telexpertises["current_schedule"]["scheduled_at_formatted"];
}

export interface ScheduleReportsState {
  scheduleReportsList: DataList[] | [];
  scheduleReportsCurrentPage: number;
  scheduleReportsLastPage: number;
  scheduleReport: Datum;
  reportTelexpertisesList: ReportTelexpertisesList[] | [];
  reportTelexpertisesCurrentPage: number;
  reportTelexpertisesLastPage: number;
  // reasonForConsultation: ReasonForConsultation[] | [];
}

function state(): ScheduleReportsState {
  return {
    scheduleReportsList: [],
    scheduleReportsCurrentPage: 0,
    scheduleReportsLastPage: 0,
    scheduleReport: {
      id: 0,
      user_id: 0,
      reference_center_id: 0,
      care_center_id: null,
      type_teleexpertise_id: 0,
      patient_id: 0,
      status: "",
      from_date: "",
      to_date: "",
      pdf_url: null,
      excel_url: null,
      created_at: "",
      from_date_formatted: "",
      to_date_formatted: "",
      created_at_formatted: "",
      user: {
        id: 0,
        user_info_id: 0,
        name: "",
        details: {
          id: 0,
          names: "",
          surnames: "",
        },
      },
      type_teleexpertise: {
        id: 0,
        name: "",
      },
      reference_center: {
        id: 0,
        name: "",
      },
      care_center: null,
      patient: {
        id: 0,
        name: "",
        last_name: "",
        document: "",
      },
    },
    reportTelexpertisesList: [],
    reportTelexpertisesCurrentPage: 0,
    reportTelexpertisesLastPage: 0,
    // reasonForConsultation: [],
  };
}

export default state;
