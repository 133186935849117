import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/vacunas",
    name: "VaccinesList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Vaccines List" */ "@/views/vaccines/VaccinesList.vue"
      ),
  },
  {
    path: "/vacunas/editar-vacunas/:id(\\d+)",
    name: "VaccinesEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Vaccines Edit" */ "@/views/vaccines/sub-pages/vaccines-edit/VaccinesEdit.vue"
      ),
  },
  {
    path: "/vacunas/crear-vacunas",
    name: "VaccinesCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Vaccines Create" */ "@/views/vaccines/sub-pages/vaccines-create/VaccinesCreate.vue"
      ),
  },
  {
    path: "/vacunas/ver-vacunas/:id(\\d+)",
    name: "VaccinesView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Vaccines View" */ "@/views/vaccines/sub-pages/vaccines-view/VaccinesView.vue"
      ),
  },
]