
export interface Resolution2175of2015State {
    dataTable: dataPaginate
}

export interface dataPaginate {
    data: dataList,
    current_page: number,
    last_page: number,
    per_page: number,
    total: number,
}

export interface dataList {
    data: datum[]
}

export interface datum {
    id:                   number;
    reference_center_id:  number;
    care_center_id:       number;
    user_id:              number;
    year:                 number;
    month:                number;
    status:               string;
    url:                  null;
    created_at_formatted: string;
    reference_center:     ECenter;
    care_center:          ECenter;
    user:                 User;
}

export interface ECenter {
    id:   number;
    name: string;
}

export interface User {
    id:                    number;
    email:                 string;
    email_verified_at:     Date;
    status:                string;
    last_login:            Date;
    last_change_password:  Date;
    logout:                Date;
    expiry_date:           null;
    created_at:            null;
    updated_at:            Date;
    user_types_id:         number;
    user_info_id:          number;
    centers_id:            number;
    contract_id:           null;
    failed_login_attempts: number;
    name:                  string;
    details:               Details;
}

export interface Details {
    id:                number;
    created_at:        null;
    updated_at:        Date;
    names:             string;
    surnames:          string;
    document:          string;
    phone:             string;
    signature:         null;
    photo:             null;
    professional_reg:  null;
    professional_type: null;
    gender:            string;
    rethus:            null;
    full_name:         string;
}

function state(): Resolution2175of2015State {
    return {
        dataTable: {
            data: {
                data:[]
            },
            current_page: 1,
            last_page: 1,
            per_page: 20,
            total: 0,
        }
        
    }
}

export default state;