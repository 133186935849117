import { GetterTree } from "vuex";
import { ReferenceCentersState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<ReferenceCentersState, StateInterface> = {
  getAllReferenceCenters(state) {
    return state.allReferenceCenters;
  },
  getCareCentersByReferenceCenter(state) {
    return state.careCentersList;
  },
  getReferenceCenterLogo(state) {
    return state.referenceCenterLogo;
  },

  // Reference Centers V.2
  getReferenceCentersList(state) {
    return state.data.referenceCenters.length >= 1
      ? state.data.referenceCenters.map(
          ({ id, name, address, phone, status }) => ({
            id,
            name,
            address,
            phone,
            status,
          })
        )
      : [];
  },
  getReferenceCenterView(state) {
    if (state.data.referenceCenter) {
      const {
        name,
        email,
        nit,
        address,
        phone,
        contact_name: contactName,
        contact_phone: contactPhone,
        logo,
        ips_code: ips
      } = state.data.referenceCenter;

      return {
        name,
        email,
        nit,
        address,
        phone,
        contactName,
        contactPhone,
        logo: logo ? `${logo}?t=${Math.floor(Math.random() * 10000)}` : logo,
        ips
      };
    }

    return null;
  },
  getReferenceCenterEdit(state) {
    if (state.data.referenceCenter) {
      const {
        name,
        email,
        nit,
        address,
        phone,
        contact_name,
        contact_phone,
        logo,
        ips_code
      } = state.data.referenceCenter;

      return {
        name,
        email,
        nit,
        address,
        phone,
        contact_name,
        contact_phone,
        url: logo ? `${logo}?t=${Math.floor(Math.random() * 10000)}` : logo,
        ips_code
      };
    }

    return null;
  }
};

export default getters;
