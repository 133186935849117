import { RipsUI } from '@/interfaces/customs/IRipsCustom';
import { MutationTree } from 'vuex';


const mutation: MutationTree<RipsUI> = {
    setRowRips(state, rowRips) {
        state.rowRips = rowRips
    },

    setRipsTableCurrentPage(state, page) {
        state.ripsTableCurrentPage = page
    },
    setRipsTableLastPage(state, page) {
        state.ripsTableLastPage = page
    },

    setViewRips(state, reportRIPS) {
        state.viewRips = reportRIPS
    },

    setSpecialtiesRips(state, specialties) {
        state.specialtiesRips = specialties
    },

    setCurrentRipSelected(state, currentRipSelected) {
        state.currentRipSelected = currentRipSelected
    },

    setDownloadReportRipURL(state, downloadURL) {
        state.downloadReportRipURL = downloadURL
    }
}

export default mutation;