import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { PackagesState } from "./state";

const URL_PATH_PACKAGE = "/api/settings/package";
const URL_PATH_TAXES = "/api/settings/taxes";

const actions: ActionTree<PackagesState, StateInterface> = {
  async loadPackages({ commit }, params) {
    commit("setPackages");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH_PACKAGE}`, {
        params,
      });
      const { success, message, data } = response;

      commit("setPackages", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadPackage({ commit }, id) {
    commit("setPackage");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH_PACKAGE}/${id}`
      );
      const { success, message, data } = response;

      commit("setPackage", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadPackageServices({ commit }, { id, params }) {
    commit("setPackageServices");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH_PACKAGE}/${id}/services`,
        {
          params,
        }
      );
      const { success, message, data } = response;

      commit("setPackageServices", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadTaxes({ commit }) {
    commit("setTaxes");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH_TAXES}`);
      const { success, message, data } = response;

      commit("setTaxes", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createPackage({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH_PACKAGE}`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async updatePackage({ commit }, { id, bodyRequest }) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH_PACKAGE}/${id}`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async changePackageStatus({ commit }, { id, bodyRequest }) {
    commit("setPackages");
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH_PACKAGE}/${id}/status`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async deletePackage({ commit }, id) {
    commit("setPackages");
    commit("setResponse");

    try {
      const { data } = await executeApi().delete(`${URL_PATH_PACKAGE}/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
};

export default actions;
