import { ProceduresReportState } from "@/interfaces/global/interfaces";

function state(): ProceduresReportState {
  return {
    proceduresReportList: [],
    procedureReport: null,
    proceduresReportCurrentPage: 1,
    proceduresReportLastPage: 1,
  };
}

export default state;
