import { indicators256Api } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { Indicators256State } from "./state";

const actions: ActionTree<Indicators256State, StateInterface> = {
  async loadIndicators(
    { commit },
    {
      centers,
      params,
    }: {
      centers: { referenceCenter: number; careCenter?: number };
      params: { status: string; page: number };
    }
  ) {
    commit("setResponse");

    try {
      const { data: response } = await indicators256Api().get(
        `/list/${centers.referenceCenter}/${centers.careCenter ?? ""}`,
        { params }
      );
      const { success, message, data } = response;

      commit("setIndicators", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadIndicator({ commit }, id) {
    commit("setResponse");

    try {
      const { data: response } = await indicators256Api().get(`/${id}`);
      const { success, message, data } = response;

      commit("setIndicator", data);
      commit("setDetails", data?.details);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createIndicator({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await indicators256Api().post("", bodyRequest);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async cancelIndicator({ commit }, id) {
    commit("setResponse");

    try {
      const { data } = await indicators256Api().post(`/changeStatus/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async loadYears({ commit }) {
    commit("setResponse");

    try {
      const { data: response } = await indicators256Api().get(
        "/yearsAvailabilities/"
      );
      const { success, message, data } = response;

      commit("setYears", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadDetails({ commit }, { id, page }) {
    commit("setResponse");

    try {
      const { data: response } = await indicators256Api().get(
        `/details/${id}`,
        { params: { page } }
      );
      const { success, message, data } = response;

      commit("setDetails", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
};

export default actions;
