import apiBase from "@/apis/AxiosIntance";

export const schedulesApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/scheduling`;
  return apiBase;
};

export const schedulesTelexperticesAvailablesApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/teleexpertise/getSentBySpecialty`
  return apiBase;
};


export const schedulesApiV3 = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/scheduling`
  return apiBase;
};


