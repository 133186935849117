import { ActionTree } from 'vuex';
import { Resolution2175of2015State } from './state';
import { StateInterface } from '../index';
import { Resolution2175of2015Api } from '@/apis/resolution2175of2015Api';

const actions: ActionTree<Resolution2175of2015State, StateInterface> = {

    async getListResolution2175( { commit }, data ) {
        const { page, token, reference_center, care_center } = data;
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const route = `?page=${page}&reference_center_id=${reference_center}${ care_center !== null ? '&care_center_id='+care_center : '' }`
            const { data } = await Resolution2175of2015Api.get(route, config);
            commit('setListResolution2175', data);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async requestReportListResolution2175( { }, data ) {
        const { token, formData } = data;
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        };
        try{
            const { data } = await Resolution2175of2015Api.post('', formData, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async cancelResolutionStatus( { }, data ) {

        const { id, token } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const { data } = await Resolution2175of2015Api.post(`/cancel/${id}`, { }, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async getYearsReport( {  }, data ) {
        const { token, reference_center, care_center } = data;
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const route = `years?reference_center_id=${reference_center}&care_center_id=${care_center}`
            const { data } = await Resolution2175of2015Api.get(route, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },
    
    async getMonthsReport( {  }, data ) {
        const { token, reference_center, care_center, year } = data;
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const route = `years/months?reference_center_id=${reference_center}&care_center_id=${care_center}&year=${year}`
            const { data } = await Resolution2175of2015Api.get(route, config);
            return data;
        }catch(error:any){
            return errorExeption(error);
        }
    },

}

function errorExeption(error: any) {
    
    //No hay conexion
    if(error.message == 'Network Error'){
        const success = false;
        const { message } = error;
        return { success, message }; 
    }            
    const { data } = error.response;
    const { success, message } = data; 
    return { success, message };
}

export default actions;