import {
  IData,
  IRequestResponse,
  ISpecialty,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { SpecialtiesState } from "./state";

const mutation: MutationTree<SpecialtiesState> = {
  setResponse(state: SpecialtiesState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
  setSpecialties(state: SpecialtiesState, data?: IData<ISpecialty[]>): void {
    if (data) {
      state.data.specialties = data.data;

      state.pages.specialties.currentPage.value = data.current_page;
      state.pages.specialties.lastPage.value = data.last_page;
    } else {
      state.data.specialties = [];
    }
  },
  setSpecialty(state: SpecialtiesState, data?: ISpecialty | null) {
    if (data !== undefined) {
      state.data.specialty = data;

      state.requests.specialty = true;
    } else {
      state.data.specialty = null;

      state.requests.specialty = false;
    }
  },
};

export default mutation;
