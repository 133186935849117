import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";

export const useCdas = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    teleexpertisesList: computed(
      () => store.state.teleexpertises.teleexpertisesList
    ),

    // Actions
    getTelexpertises: (
      token: string,
      centerReferenceId: number,
      centerAtentionId: number
    ) =>
      store.dispatch("cdas/getTelexpertises", {
        token,
        centerReferenceId,
        centerAtentionId,
      }),

    getXmlStringByTelexpertice: (token: string, idTelexpertise: number) =>
      store.dispatch("cdas/getXmlStringByTelexpertice", {
        token,
        idTelexpertise,
      }),
  };
};
