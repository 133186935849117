import apiBase from "@/apis/AxiosIntance";

export const proceduresApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/procedures`;

  return apiBase;
};

export const proceduresApiV2 = (
  filter: any | null = null,
  page = 1,
  urlRequest: string | null = null
) => {
  const urlBase = new URL(
    urlRequest
      ? `${process.env.VUE_APP_URL}/api/settings/procedures/${urlRequest}`
      : `${process.env.VUE_APP_URL}/api/settings/procedures`
  );
  if (filter) {
    for (let property in filter) {
      if (filter[property]) {
        urlBase.searchParams.append(property, filter[property]);
      }
    }
  }
  if (page > 0) {
    urlBase.searchParams.append("page", `${page}`);
  }

  apiBase.defaults.baseURL = urlBase.href;

  return apiBase;
};
