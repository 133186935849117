import { StateInterface } from "@/store/index";
import {
  BackgroundsList,
  ClinicalLaboratoriesList,
  DiagnosticsList,
  DisabilitiesList,
  DocumentsList,
  FoliosList,
  GynecologistView,
  ManagementPlansList,
  MedicalExamsList,
  MedicalRecordView,
  PatientView,
  ProceduresList,
  VaccinesList,
} from "@/store/medical-records/state";
import { computed } from "vue";
import { useStore } from "vuex";

export const useMedicalRecordsStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    // Medical Records V.2
    patient: computed(() => store.state.medicalRecords.data.patient),
    folios: computed(() => store.state.medicalRecords.data.folios),
    foliosPages: computed(() => store.state.medicalRecords.pages.folios),
    diagnostics: computed(() => store.state.medicalRecords.data.diagnostics),
    diagnosticsPages: computed(
      () => store.state.medicalRecords.pages.diagnostics
    ),
    backgrounds: computed(() => store.state.medicalRecords.data.backgrounds),
    backgroundsPages: computed(
      () => store.state.medicalRecords.pages.backgrounds
    ),
    procedures: computed(() => store.state.medicalRecords.data.procedures),
    proceduresPages: computed(
      () => store.state.medicalRecords.pages.procedures
    ),
    managementPlans: computed(
      () => store.state.medicalRecords.data.managementPlans
    ),
    managementPlansPages: computed(
      () => store.state.medicalRecords.pages.managementPlans
    ),
    documents: computed(() => store.state.medicalRecords.data.documents),
    documentsPages: computed(() => store.state.medicalRecords.pages.documents),
    clinicalLaboratories: computed(
      () => store.state.medicalRecords.data.clinicalLaboratories
    ),
    clinicalLaboratoriesPages: computed(
      () => store.state.medicalRecords.pages.clinicalLaboratories
    ),
    medicalExams: computed(() => store.state.medicalRecords.data.medicalExams),
    medicalExamsPages: computed(
      () => store.state.medicalRecords.pages.medicalExams
    ),
    vaccines: computed(() => store.state.medicalRecords.data.vaccines),
    vaccinesPages: computed(() => store.state.medicalRecords.pages.vaccines),
    disabilities: computed(() => store.state.medicalRecords.data.disabilities),
    disabilitiesPages: computed(
      () => store.state.medicalRecords.pages.disabilities
    ),
    gynecologist: computed(() => store.state.medicalRecords.data.gynecologist),
    request: computed(() => store.state.medicalRecords.request),
    pages: computed(() => store.state.medicalRecords.pages),
    response: computed(() => store.state.medicalRecords.response),

    // Getters
    // Medical Records V.2
    getMedicalRecord: computed<MedicalRecordView>(
      () => store.getters["medicalRecords/getMedicalRecord"]
    ),
    getPatient: computed<PatientView>(
      () => store.getters["medicalRecords/getPatient"]
    ),
    getFolios: computed<FoliosList[]>(
      () => store.getters["medicalRecords/getFolios"]
    ),
    getDiagnostics: computed<DiagnosticsList[]>(
      () => store.getters["medicalRecords/getDiagnostics"]
    ),
    getBackgrounds: computed<BackgroundsList[]>(
      () => store.getters["medicalRecords/getBackgrounds"]
    ),
    getProcedures: computed<ProceduresList[]>(
      () => store.getters["medicalRecords/getProcedures"]
    ),
    getManagementPlans: computed<ManagementPlansList[]>(
      () => store.getters["medicalRecords/getManagementPlans"]
    ),
    getDocuments: computed<DocumentsList[]>(
      () => store.getters["medicalRecords/getDocuments"]
    ),
    getClinicalLaboratories: computed<ClinicalLaboratoriesList[]>(
      () => store.getters["medicalRecords/getClinicalLaboratories"]
    ),
    getMedicalExams: computed<MedicalExamsList[]>(
      () => store.getters["medicalRecords/getMedicalExams"]
    ),
    getVaccines: computed<VaccinesList[]>(
      () => store.getters["medicalRecords/getVaccines"]
    ),
    getDisabilities: computed<DisabilitiesList[]>(
      () => store.getters["medicalRecords/getDisabilities"]
    ),
    getGynecologist: computed<GynecologistView>(
      () => store.getters["medicalRecords/getGynecologist"]
    ),

    // Medical Records V.2
    loadPatient: (id: number) =>
      store.dispatch("medicalRecords/loadPatient", id),
    loadFolios: (id: number, page = 1) =>
      store.dispatch("medicalRecords/loadFolios", { id, page }),
    loadDiagnostics: (id: number, page = 1) =>
      store.dispatch("medicalRecords/loadDiagnostics", { id, page }),
    loadBackgrounds: (id: number, page = 1) =>
      store.dispatch("medicalRecords/loadBackgrounds", { id, page }),
    loadProcedures: (id: number, page = 1) =>
      store.dispatch("medicalRecords/loadProcedures", { id, page }),
    loadManagementPlans: (id: number, page = 1) =>
      store.dispatch("medicalRecords/loadManagementPlans", { id, page }),
    loadDocuments: (id: number, page = 1) =>
      store.dispatch("medicalRecords/loadDocuments", { id, page }),
    loadClinicalLaboratories: (id: number, page = 1) =>
      store.dispatch("medicalRecords/loadClinicalLaboratories", { id, page }),
    loadMedicalExams: (id: number, page = 1) =>
      store.dispatch("medicalRecords/loadMedicalExams", { id, page }),
    loadVaccines: (id: number, page = 1) =>
      store.dispatch("medicalRecords/loadVaccines", { id, page }),
    loadDisabilities: (id: number, page = 1) =>
      store.dispatch("medicalRecords/loadDisabilities", { id, page }),
    loadGynecologist: (id: number) =>
      store.dispatch("medicalRecords/loadGynecologist", id),

    // Medical Records V.2
    setPatient: () => store.commit("medicalRecords/setPatient"),
    setFolios: () => store.commit("medicalRecords/setFolios"),
    setDiagnostics: () => store.commit("medicalRecords/setDiagnostics"),
    setBackgrounds: () => store.commit("medicalRecords/setBackgrounds"),
    setProcedures: () => store.commit("medicalRecords/setProcedures"),
    setManagementPlans: () => store.commit("medicalRecords/setManagementPlans"),
    setDocuments: () => store.commit("medicalRecords/setDocuments"),
    setClinicalLaboratories: () =>
      store.commit("medicalRecords/setClinicalLaboratories"),
    setMedicalExams: () => store.commit("medicalRecords/setMedicalExams"),
    setVaccines: () => store.commit("medicalRecords/setVaccines"),
    setDisabilities: () => store.commit("medicalRecords/setDisabilities"),
    setGynecologist: () => store.commit("medicalRecords/setGynecologist"),
  };
};
