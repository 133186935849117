import { MutationTree } from "vuex";
import { GeneralStoreUI } from "./state";

const mutation: MutationTree<GeneralStoreUI> = {
  setServiceTypeOptions(state, typeServiceOptions) {
    state.serviceTypeOptions = typeServiceOptions;
  },

  setServiceTypeOptionsLoader(state, serviceStatus) {
    state.serviceTypeOptionsLoader = serviceStatus;
  },

  //current Telexpertice Selected
  setCurrentTelexperticeSelected(state, currentTelexpertice) {
    state.currentTelexperticeSelected = currentTelexpertice;
  },

  //telexpertice badge
  setJobListBadge(state, badgeInfo) {
    state.jobListBadge = badgeInfo;
  },

  setJobListBadgeTotal(state, total) {
    state.jobListBadgeTotal = total;
  },

  //redirect
  setCurrentRenderURL(state, goURL) {
    state.currentRenderURL = goURL;
  },
  setGoToRenderURL(state, goURL) {
    state.goToRenderURL = goURL;
  },

  //current component
  setSectionComponent(state, renderComponent) {
    state.sectionComponent = renderComponent;
  },

  // loading page
  setLoadAvailablePage(state, status) {
    state.loadAvailablePage = status;
  },

  //update section
  setUpdateSection(state, status) {
    state.updateSection = status;
  },

  // alert
  setShowAlert(state, status) {
    state.showAlert = status;
  },
  setAlertTitle(state, title) {
    state.alertTitle = title;
  },
  setAlertMessage(state, message) {
    state.alertMessage = message;
  },
  setAlertType(state, alertType) {
    state.alertType = alertType;
  },

  // popup
  setPopupOpen(state, status) {
    state.popupOpen = status;
  },
  setPopupLoading(state, status) {
    state.popupLoading = status;
  },

  setPopupLoadingMessage(state, message) {
    state.popupLoadingMessage = message;
  },
  setPopupTitle(state, title) {
    state.popupTitle = title;
  },

  setPopupMessage(state, message) {
    state.popupMessage = message;
  },

  setPopupBasicList(state, basicList) {
    state.popupBasicList = basicList;
  },

  setPopupBasicListSelected(state, selected) {
    state.popupBasicListSelected = selected;
  },

  setPopupBtnAcceptLabel(state, label) {
    state.popupBtnAcceptLabel = label;
  },

  setPopupBtnCancelLabel(state, label) {
    state.popupBtnCancelLabel = label;
  },

  setShowContinue(state, status) {
    state.showContinue = status;
  },
  setShowClose(state, status) {
    state.showClose = status;
  },

  // Breadcomes routes
  setCurrentBreadcrumb(state, breadcrumb) {
    state.currentBreadcrumb = breadcrumb;
  },

  // Selectors center ref
  setCenterRefSelectedLoader(state, status) {
    state.centerRefSelectedLoader = status;
  },

  setFirstCenterRefOption(state, firstOption) {
    state.firstCenterRefOption = firstOption;
  },

  setCenterRefDisable(state, status) {
    state.centerRefDisable = status;
  },
  setCenterRefSelected(state, selected) {
    state.centerRefSelected = selected;
  },
  setCenterRefSelectorOptions(state, options) {
    state.centerRefSelectorOptions = options;
  },

  // Selectors care centers
  setCareCenterSelectedLoader(state, status) {
    state.careCenterSelectedLoader = status;
  },

  setCareCenterDisable(state, status) {
    state.careCenterDisable = status;
  },

  setCareCenterSelected(state, careCenterSelected) {
    state.careCenterSelected = careCenterSelected;
  },
  setFirstCareCenterOption(state, firstOptions) {
    state.firstCareCenterOption = firstOptions;
  },
  setCareCenterSelectorOptions(state, careCenterOptions) {
    state.careCenterSelectorOptions = careCenterOptions;
  },

  //eps selector
  setEpsSelected(state, epsSelected) {
    state.epsSelected = epsSelected;
  },

  setEpsSelectorOpions(state, epsOptions) {
    state.epsSelectorOptions = epsOptions;
  },
  setEpsSelectorLoader(state, epsLoader) {
    state.epsSelectorLoader = epsLoader;
  },

  //regime selector
  setRegimenSelected(state, regimenSelected) {
    state.regimenSelected = regimenSelected;
  },

  setRegimenSelectorOptions(state, regimenOptions) {
    state.regimenSelectorOptions = regimenOptions;
  },
  setRegimenSelectorLoader(state, epsRegimen) {
    state.regimenSelectorLoader = epsRegimen;
  },

  // Selectors specialities
  setSpecialitiesByCenterRefLoader(state, status) {
    state.specialitiesByCenterRefLoader = status;
  },
  setSpecialitiesByCenterRefSelected(state, selected) {
    state.specialitiesByCenterRefSelected = selected;
  },
  setSpecialitiesByCenterRefOptions(state, options) {
    state.specialitiesByCenterRefOptions = options;
  },

  // Selectors doctors by specialities
  setDoctorBySpecialityLoader(state, status) {
    state.doctorBySpecialityLoader = status;
  },
  setDoctorBySpecialitySelected(state, selected) {
    state.doctorBySpecialitySelected = selected;
  },
  setDoctorBySpecialityOptions(state, options) {
    state.doctorBySpecialityOptions = options;
  },

  // telexpertices availables
  setTelexperticesAvailablesLoader(state, status) {
    state.telexperticesAvailablesLoader = status;
  },
  setTelexperticesAvailablesSelected(state, selected) {
    state.telexperticesAvailablesSelected = selected;
  },
  setTelexperticesAvailablesOptions(state, options) {
    state.telexperticesAvailablesOptions = options;
  },

  // patient card info
  setPatientCardInfo(state, cardInfo) {
    state.patientCardInfo = cardInfo;
  },

  setPatientCardLoader(state, status) {
    state.patientCardLoader = status;
  },

  // telexpertices availables by month calendar
  setAvailableByCalendar(state, availables) {
    state.availableByCalendar = availables;
  },

  //current schedule component
  setCurrentScheduleComponent(state, component) {
    state.currentScheduleComponent = component;
  },

  setScheduleCalendarLoader(state, status) {
    state.scheduleCalendarLoader = status;
  },

  // info selected by day
  setSelectedAvailableByDay(state, selectedInfo) {
    state.selectedAvailableByDay = selectedInfo;
  },

  // availables hour by day
  setAvailablesHoursByDay(state, option) {
    state.availablesHoursByDay = option;
  },

  //schedule reducer type
  setScheduleReducer(state, type) {
    state.scheduleReducer = type;
  },

  //schedule info reducer type
  setScheduleCreatePayload(state, payload) {
    state.scheduleCreatePayload = payload;
  },

  //reload schedule
  setReloadScheduleStatus(state, status) {
    state.scheduleCreatePayload = status;
  },

  setPatientInfo(state, patientInfo){
    state.patientInfo = patientInfo
  },

  setSearchByPatient(state, status){
    state.searchByPatient = status
  },

    setMeetResponse(state, meetInfo) {
      state.meetResponse = meetInfo;
    },
  
    setMeetStatus(state, status) {
      state.meetStatus = status;
    },


};

export default mutation;
