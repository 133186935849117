import { IRequestResponse } from "@/interfaces/global/interfaces";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { useStore } from "vuex";

export const useAppointmentConfirmationStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    response: computed<IRequestResponse>(
      () => store.state.appointmentConfirmation.response
    ),

    // Getters

    // Actions
    sendAppointmentConfirmation: (
      type: string,
      bodyRequest: {
        session_id: number;
        type_id: number;
        token: string;
        "g-recaptcha-response": string;
        reason?: string;
      }
    ) =>
      store.dispatch("appointmentConfirmation/sendAppointmentConfirmation", {
        type,
        bodyRequest,
      }),

    //Mutations
  };
};
