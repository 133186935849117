import { StateInterface } from "@/store/index";
import {
  CareCenter,
  Datum,
  ReferenceCenterEdit,
  ReferenceCenterView,
  ReferenceCentersData,
  ReferenceCentersList,
  ReferenceCentersPages,
} from "@/store/reference-centers/state";
import { computed } from "vue";
import { useStore } from "vuex";

export const useReferenceCentersStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    // Reference Centers V.2
    referenceCenters: computed(
      () => store.state.referenceCenters.data.referenceCenters
    ),
    referenceCentersPages: computed(
      () => store.state.referenceCenters.pages.referenceCenters
    ),
    referenceCenter: computed(
      () => store.state.referenceCenters.data.referenceCenter
    ),
    data: computed(() => store.state.referenceCenters.data),
    pages: computed(() => store.state.referenceCenters.pages),
    response: computed(() => store.state.referenceCenters.response),

    // Getters
    referenceCenterLogo: computed<string>(
      () => store.getters["referenceCenters/getReferenceCenterLogo"]
    ),
    allReferenceCenters: computed<Datum[]>(
      () => store.getters["referenceCenters/getAllReferenceCenters"]
    ),
    careCentersByReferenceCenter: computed<CareCenter[]>(
      () => store.getters["referenceCenters/getCareCentersByReferenceCenter"]
    ),

    // Reference Centers V.2
    getReferenceCentersList: computed<ReferenceCentersList[]>(
      () => store.getters["referenceCenters/getReferenceCentersList"]
    ),
    getReferenceCenterView: computed<ReferenceCenterView | null>(
      () => store.getters["referenceCenters/getReferenceCenterView"]
    ),
    getReferenceCenterEdit: computed<ReferenceCenterEdit | null>(
      () => store.getters["referenceCenters/getReferenceCenterEdit"]
    ),

    // Actions
    getAllReferenceCenters: (token: string, type: number) =>
      store.dispatch("referenceCenters/getAllReferenceCenters", {
        token,
        type,
      }),
    getCareCentersByReferenceCenter: (token: string, id: number) =>
      store.dispatch("referenceCenters/getCareCentersByReferenceCenter", {
        token,
        id,
      }),
    changeReferenceCenterStatusById: (token: string, id: number) =>
      store.dispatch("referenceCenters/changeReferenceCenterStatusById", {
        token,
        id,
      }),
    getReferenceCenterById: (token: string, id: string | string[]) =>
      store.dispatch("referenceCenters/getReferenceCenterById", { token, id }),
    updateReferenceCenter: (
      token: string,
      id: string | string[],
      formData: object
    ) =>
      store.dispatch("referenceCenters/updateReferenceCenter", {
        token,
        id,
        formData,
      }),

    // Reference Centers V.2
    loadReferenceCenters: (params: {
      status: string;
      page: number;
      name?: string;
    }) => store.dispatch("referenceCenters/loadReferenceCenters", params),
    loadReferenceCenter: (id: number) =>
      store.dispatch("referenceCenters/loadReferenceCenter", id),
    createReferenceCenter: (bodyRequest: {
      id?: number,
      name: string;
      email: string;
      nit: string;
      address: string;
      phone: string;
      contact_name: string;
      contact_phone: string;
      centers_types_id: number;
      logo: null | File;
      ips_code: string;
    }) => store.dispatch("referenceCenters/createReferenceCenter", bodyRequest),

    // Mutations
    // Reference Centers V.2
    setReferenceCenters: () =>
      store.commit("referenceCenters/setReferenceCenters"),
    setReferenceCenter: () =>
      store.commit("referenceCenters/setReferenceCenter"),
  };
};
