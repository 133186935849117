import {
  careProgramsApi,
  centersApi,
  highCostAccounts2463Api,
  patientsApi,
  resolutionReportsApi,
} from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { SelectorsState } from "./state";

const actions: ActionTree<SelectorsState, StateInterface> = {
  async loadCenters({ commit }, type: number) {
    commit("setResponse");
    commit("setCenters", { type, data: undefined });

    try {
      const { data: response } = await centersApi().get(`/getByTypeWP/${type}`);
      const { success, message, data } = response;

      commit("setCenters", { type, data });
      commit("setResponse", {
        success,
        message,
        of: type == 1 ? "referenceCenter" : "careCenter",
      });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", {
        ...response,
        of: type == 1 ? "referenceCenter" : "careCenter",
      });
    }
  },
  async loadCareCenters({ commit }, id: number) {
    commit("setResponse");
    commit("setCenters", { type: 2, data: undefined });

    try {
      const { data: response } = await centersApi().get(
        `/getCareByReference/${id}`
      );
      const { success, message, data } = response;

      commit("setCenters", { type: 2, data });
      commit("setResponse", { success, message, of: "careCenter" });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", { ...response, of: "careCenter" });
    }
  },
  async loadEPS({ commit }) {
    commit("setResponse");
    commit("setEPS");

    try {
      const { data: response } = await patientsApi().get("/eps");
      const { success, message, data } = response;

      commit("setEPS", data);
      commit("setResponse", { success, message, of: "eps" });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", { ...response, of: "eps" });
    }
  },
  async loadRegimes({ commit }) {
    commit("setResponse");
    commit("setRegimes");

    try {
      const { data: response } = await patientsApi().get("/regimes");
      const { success, message, data } = response;

      commit("setRegimes", data);
      commit("setResponse", { success, message, of: "regime" });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", { ...response, of: "regime" });
    }
  },
  async loadEPSWithData(
    { commit },
    { referenceCenter, careCenter, fromDate, toDate }
  ) {
    commit("setResponse");
    commit("setEPS");

    try {
      const { data: response } = await resolutionReportsApi().get(
        `/epsData/${referenceCenter}/${careCenter}/${fromDate}/${toDate}`
      );
      const { success, message, data } = response;

      commit("setEPS", data);
      commit("setResponse", { success, message, of: "eps" });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", { ...response, of: "eps" });
    }
  },
  async loadEPSWithDataResolution2463(
    { commit },
    params
  ) {
    commit("setResponse");
    commit("setEPS");

    try {
      const { data: response } = await highCostAccounts2463Api().get(
      "/eps", { params });
      const { success, message, data } = response;

      commit("setEPS", data);
      commit("setResponse", { success, message, of: "eps" });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", { ...response, of: "eps" });
    }
  },
  async loadResolution2463EPS(
    { commit },
    params
  ) {
    commit("setResponse");
    commit("setEPS");

    try {
      const { data: response } = await highCostAccounts2463Api().get(
      "/reports/eps", { params });
      const { success, message, data } = response;

      commit("setEPS", data);
      commit("setResponse", { success, message, of: "eps" });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", { ...response, of: "eps" });
    }
  },
  async loadCarePrograms({ commit }, params) {
    commit("setResponse");
    commit("setCarePrograms");

    try {
      const { data: response } = await careProgramsApi().get(
        "/without-paginate?",
        {
          params,
        }
      );
      const { success, message, data } = response;

      commit("setCarePrograms", data);
      commit("setResponse", { success, message, of: "carePrograms" });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", { ...response, of: "carePrograms" });
    }
  },
  async loadPatientResources({ commit }, query) {
    commit("setResponse");
    commit("setPatientResources");

    try {
      const { data: response } = await patientsApi().get(
        '/utils/getAllResource',
        {
          params: {
            query
          },
        }
      );
      const { success, message, data } = response;

      commit("setPatientResources", data);
      commit("setResponse", { success, message, of: "patientResources" });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", { ...response, of: "patientResources" });
    }
  },
};

export default actions;
