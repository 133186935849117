import { RipsUI } from "@/interfaces/customs/IRipsCustom";

function state(): RipsUI {
    return {
        rowRips: [],
        ripsTableCurrentPage: 0,
        ripsTableLastPage: 0,
        viewRips: undefined,
        specialtiesRips: [],
        currentRipSelected: undefined,
        downloadReportRipURL: '',
    }
}

export default state;