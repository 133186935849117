import { ActionTree } from "vuex";
import { CampaignsState, RequestResponse } from "./state";
import { StateInterface } from "../index";
import { campaignsApi } from "@/apis";

const actions: ActionTree<CampaignsState, StateInterface> = {
  async getCampaigns(
    { commit },
    { token, page, id }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await campaignsApi.get(
        `/list/${id}?page=${page}`,
        config
      );
      const { success, message, data: campaignData } = data;

      commit("getCampaigns", campaignData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async cancelCampaignById(
    { commit },
    { token, id }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await campaignsApi.post(`/${id}`, {}, config);
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async createCampaign(
    { commit },
    { token, bodyRequest }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await campaignsApi.post("/", bodyRequest, config);
      const { success, message, data: campaignData } = data;

      commit("getCampaigns", campaignData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getCampaignById({ commit }, { token, id }): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await campaignsApi.get(`/${id}`, config);
      const { success, message, data: campaignData } = data;

      commit("getCampaignById", campaignData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async updateCampaign(
    { commit },
    { token, id, bodyRequest }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await campaignsApi.put(`/${id}`, bodyRequest, config);
      const { success, message, data: campaignData } = data;

      commit("getCampaigns", campaignData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getPatients({ commit }, { token, page, id }): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await campaignsApi.get(
        `/patients/${id}?page=${page}`,
        config
      );
      const { success, message, data: patientData } = data;

      commit("getPatients", patientData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
};

function errorException(error: any) {
  //No hay conexión
  if (error.message == "Network Error") {
    const success = false;
    const { message } = error;
    return { success, message };
  }
  const { data } = error.response;
  const { success, message } = data;
  return { success, message };
}

export default actions;
