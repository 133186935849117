import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/historial-inicio-sesion",
    name: "LoginsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Logins List" */ "@/views/logins/LoginsList.vue"
      ),
  },
]