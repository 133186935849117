import { IVaccine } from "@/interfaces/global/interfaces";
import { StateInterface } from "@/store/index";
import { Edit, List, View } from "@/store/vaccines/state";
import { computed, toRef } from "vue";
import { useStore } from "vuex";

export const useVaccinesStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    vaccines: toRef(store.state.vaccines.data, "vaccines"),
    vaccinesPages: toRef(store.state.vaccines.pages, "vaccines"),
    vaccine: toRef(store.state.vaccines.data, "vaccine"),
    typeDoses: toRef(store.state.vaccines.data, "typeDoses"),
    requests: toRef(store.state.vaccines, "requests"),
    data: toRef(store.state.vaccines, "data"),
    pages: toRef(store.state.vaccines, "pages"),
    response: toRef(store.state.vaccines, "response"),

    // Getters
    getVaccinesList: computed<List[]>(
      () => store.getters["vaccines/getVaccinesList"]
    ),
    getVaccinesEdit: computed<Edit | null>(
      () => store.getters["vaccines/getVaccinesEdit"]
    ),
    getVaccinesView: computed<View | null>(
      () => store.getters["vaccines/getVaccinesView"]
    ),

    // Actions
    loadVaccines: (params: {
      paginate?: number;
      page?: number;
      rows?: number;
      search?: string;
      status?: string;
    }) => store.dispatch("vaccines/loadVaccines", params),
    loadVaccine: (id: number) => store.dispatch("vaccines/loadVaccine", id),
    loadTypeDoses: () => store.dispatch("vaccines/loadTypeDoses"),
    createVaccine: (bodyRequest: {
      name: string;
      disease_it_prevents?: string | null;
      doses: number[];
    }) => store.dispatch("vaccines/createVaccine", bodyRequest),
    updateVaccine: (
      id: number,
      bodyRequest: {
        _method: "PUT";
        name: string;
        disease_it_prevents?: string | null;
        doses: number[];
      }
    ) => store.dispatch("vaccines/updateVaccine", { id, bodyRequest }),
    changeVaccineStatus: (id: number) =>
      store.dispatch("vaccines/changeVaccineStatus", id),
    deleteVaccine: (id: number) => store.dispatch("vaccines/deleteVaccine", id),
    _getVaccinesSelectors: () =>
      store.dispatch("vaccines/getVaccinesSelectors"),

    //Mutations
    setVaccines: () => store.commit("vaccines/setVaccines"),
    setVaccine: (data?: IVaccine) => store.commit("vaccines/setVaccine", data),
  };
};
