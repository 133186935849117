import { MutationTree } from "vuex";
import { Data, DataList, Datum, CampaignsState, Patients } from "./state";

const mutation: MutationTree<CampaignsState> = {
  getCampaigns(state: CampaignsState, data: Data): void {
    if (data) {
      if (data.data.length >= 1) {
        const campaignsList = data.data as Datum[];

        state.campaignsList = campaignsList.map(
          ({
            id,
            name: campaign,
            sms_message: smsMessage,
            email_subject: emailSubject,
            email_message: emailMessage,
            total_patients: patients,
            status,
            date,
          }: Datum): DataList => ({
            id,
            campaign,
            smsMessage,
            emailSubject,
            emailMessage,
            patients,
            status,
            date,
          })
        );
      } else state.campaignsList = data.data as [];

      state.campaignsCurrentPage = data.current_page;
      state.campaignsLastPage = data.last_page;
    }
  },
  getCampaignById(state: CampaignsState, data: Datum) {
    if (data) {
      state.campaign = data;
    }
  },
  clearCampaignsList(state: CampaignsState) {
    state.campaignsList = [];
  },
  clearCampaignsData(state) {
    state.campaign = {
      id: 0,
      name: "",
      date: "",
      is_sms: false,
      is_email: false,
      email_subject: "",
      email_delivered: null,
      care_program_id: 0,
      email_batch_id: "",
      sms_message: "",
      email_message: "",
      sms_failed: 0,
      sms_send: 0,
      email_failed: 0,
      email_send: 0,
      status: "",
      total_patients: 0,
      created_at: "",
      updated_at: "",
    };
  },
  getPatients(state: CampaignsState, data: Data): void {
    if (data) {
      if (data.data) {
        state.patientsList = data.data as Patients[];
      }

      state.patientsCurrentPage = data.current_page;
      state.patientsLastPage = data.last_page;
    }
  },
};

export default mutation;
