import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/pacientes/ver-detalles-paciente/:id(\\d+)/ver-folio/:folioId(\\d+)",
    name: "FoliosView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Medical Records View" */ "@/views/folios/FoliosView.vue"
      ),
  },
];
