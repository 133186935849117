import { GetterTree } from "vuex";
import { ExplanatoryNotesState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<ExplanatoryNotesState, StateInterface> = {
  getExplanatoryNotesList(state) {
    return state.explanatoryNotesList;
  },
  getExplanatoryNotesCurrentPage(state) {
    return state.explanatoryNotesCurrentPage;
  },
  getExplanatoryNotesLastPage(state) {
    return state.explanatoryNotesLastPage;
  },
  getExplanatoryNote(state) {
    return [{ ...state.explanatoryNote }].map(
      ({
        response,
        procedures,
        diagnostics,
        medicines,
        documents,
        responser,
      }) => ({
        response,
        procedures:
          procedures.length >= 1
            ? procedures.map(({ code, description, pivot }) => ({
                code,
                description,
                amount: pivot?.ammount,
                observation: pivot?.observation,
              }))
            : [],
        diagnostics:
          diagnostics.length >= 1
            ? diagnostics.map(({ diagnostic }) => ({
                code: diagnostic?.code,
                description: diagnostic?.description,
              }))
            : [],
        medicines:
          medicines.length >= 1
            ? medicines.map(({ code, description, pivot }) => ({
                code,
                description,
                amount: pivot?.amount,
                posology: pivot?.posology,
              }))
            : [],
        documents:
          documents.length >= 1
            ? documents.map(({ url, name, mime_type: type }) => ({
                url,
                name,
                type,
              }))
            : [],
        responser: responser?.user_types_id,
      })
    )[0];
  },
};

export default getters;
