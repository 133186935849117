import { AdvanceDirectiveReportState } from "@/interfaces/global/AdvanceDirectiveReport";

function state(): AdvanceDirectiveReportState {
  return {
    years: [],
    months: [],
    indicators: {
      success: true || false,
      message: "",
      data: {
        indications: {
          care: {},
          donation: {},
          eol: {},
          indication: {},
        },
        patients: {
          total: 0,
          with_advance_directive: 0,
          without_advance_directive: 0,
        },
      },
    },
    directives: {
      success: true || false,
      message: "",
      data: {
        care: {},
        donation: {},
        eol: {},
        indication: {},
      },
    },
  };
}

export default state;
