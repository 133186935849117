import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/notas",
    name: "NotesList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Notes List" */ "@/views/notes/NotesList.vue"
      ),
  },
  {
    path: "/notas/crear-notas",
    name: "NotesCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Notes Create" */ "@/views/notes/sub-pages/notes-create/NotesCreate.vue"
      ),
  },
  {
    path: "/notas/editar-notas/:id(\\d+)",
    name: "NotesEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Notes Edit" */ "@/views/notes/sub-pages/notes-edit/NotesEdit.vue"
      ),
  },
];
