import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/pacientes/ver-detalles-paciente/:id(\\d+)",
    name: "MedicalRecordsView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Medical Records View" */ "@/views/medical-records/MedicalRecordsView.vue"
      ),
  },
];
