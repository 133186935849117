import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";

export const usePhotograph = () => {
  const store = useStore<StateInterface>();
  return {
    // States
    file: computed(() => store.state.photograph.file),
    urlBlob: computed(() => store.state.photograph.urlBlob),

    // Mutation
    _setFile: (file: File | null) =>
      store.commit("photograph/setFile", file),
    _setURLBlob: (url: string | null) =>
      store.commit("photograph/setUrlBlob", url),
  };
};
