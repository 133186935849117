import { IPages } from "@/interfaces/customs/Pages";
import {
  IRequestResponse,
  IHighCostAccounts2463,
} from "@/interfaces/global/interfaces";

export type HighCostAccounts2463Pages = Record<"highCostAccounts", IPages>;

export interface HighCostAccounts2463List {
  id: IHighCostAccounts2463["id"];
  fromDate: IHighCostAccounts2463["from_date_formatted"];
  toDate: IHighCostAccounts2463["to_date_formatted"];
  date: IHighCostAccounts2463["created_at_formatted"];
  requestedBy: IHighCostAccounts2463["user"]["name"];
  careCenter: IHighCostAccounts2463["care_center"]["name"];
  referenceCenter: IHighCostAccounts2463["reference_center"]["name"];
  eps: IHighCostAccounts2463["eps"]["name"];
  status: IHighCostAccounts2463["status"];
  url: IHighCostAccounts2463["url"];
}

export interface HighCostAccounts2463View {
  fromDate: IHighCostAccounts2463["from_date_formatted"];
  toDate: IHighCostAccounts2463["to_date_formatted"];
  date: IHighCostAccounts2463["created_at_formatted"];
  requestedBy: IHighCostAccounts2463["user"]["name"];
  careCenter: IHighCostAccounts2463["care_center"]["name"];
  referenceCenter: IHighCostAccounts2463["reference_center"]["name"];
  eps: IHighCostAccounts2463["eps"]["name"];
  status: IHighCostAccounts2463["status"];
  regime: IHighCostAccounts2463["regime"]["name"];
  disease: IHighCostAccounts2463["disease_type"];
  url: IHighCostAccounts2463["url"];
}

export interface HighCostAccounts2463Data {
  highCostAccounts: IHighCostAccounts2463[];
  highCostAccount: IHighCostAccounts2463 | null;
  diseaseTypes: Record<string, string> | null;
}

export interface HighCostAccounts2463State {
  data: HighCostAccounts2463Data;
  pages: HighCostAccounts2463Pages;
  response: IRequestResponse;
}

function state(): HighCostAccounts2463State {
  return {
    data: {
      highCostAccounts: [],
      highCostAccount: null,
      diseaseTypes: {},
    },
    pages: {
      highCostAccounts: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
