import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { VaccinesState } from "./state";

const URL_PATH: { [key: number]: string } = {
  1: "/api/settings/vaccine",
};

const actions: ActionTree<VaccinesState, StateInterface> = {
  async loadVaccines({ commit }, params) {
    commit("setVaccines");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH[1]}`, {
        params,
      });
      const { success, message, data } = response;

      commit("setVaccines", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadVaccine({ commit }, id) {
    commit("setVaccine");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH[1]}/${id}`);
      const { success, message, data } = response;

      commit("setVaccine", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadTypeDoses({ commit }) {
    commit("setTypeDoses");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH[1]}/type-doses`
      );
      const { success, message, data } = response;

      commit("setTypeDoses", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createVaccine({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(`${URL_PATH[1]}`, bodyRequest);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async updateVaccine({ commit }, { id, bodyRequest }) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH[1]}/${id}`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async changeVaccineStatus({ commit }, id) {
    commit("setVaccines");
    commit("setResponse");

    try {
      const { data } = await executeApi().patch(`${URL_PATH[1]}/${id}/status`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async deleteVaccine({ commit }, id) {
    commit("setVaccines");
    commit("setResponse");

    try {
      const { data } = await executeApi().delete(`${URL_PATH[1]}/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async getVaccinesSelectors({ commit }) {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH[1]}/search?paginate=0`
      );
      const { success, message, data: req } = data;

      return { success, message, req };
    } catch (error) {
      return errorException(error);
    }
  },
};

export default actions;
