import { ActionTree } from "vuex";
import { EmergenciesState } from "./state";
import { StateInterface } from "../index";
import { IError } from "@/interfaces/customs/interface";
import { emergenciesPatientApi, emergenciesTriageApi } from "@/apis";
import { IEmergencie } from "@/interfaces/global/interfaces";
import moment from "moment";
import store from "@/store";
import errorException from '@/functions/ErrorException';

const actions: ActionTree<EmergenciesState, StateInterface> = {
  async getEmergencies({ commit }, { filters, page }): Promise<object> {
    try {
      const { data } = await emergenciesPatientApi(filters, page).get(``);
      const { success, message } = data;
      data.data.data.forEach((element: IEmergencie) => {
        element[
          "patient_name"
        ] = `${element?.patient?.name} ${element?.patient?.last_name}`;
        element["document"] = element?.patient?.document;
        element["gender"] = element?.patient?.gender;
        element["eps_name"] = element?.patient?.eps?.name;
        element["age"] = element?.patient?.age;
        element["classification"] = element?.triage.name;
      });
      commit("setEmergencies", data.data);

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async removeUrgency({ commit }, { idTriage, reason }) {
    try {
      const { data } = await emergenciesTriageApi().post(
        `/${idTriage}/urgency/remove`,
        { reason: reason }
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async getUrgencyById({ commit }, { lastPatientUrgencyId }) {
    try {
      const { data } = await emergenciesPatientApi(null, 0).get(
        `/${lastPatientUrgencyId}`
      );
      const { success, message } = data;

      commit("setEmergencieById", data.data);

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },

  async createAttentionUrgency({ commit }, { idTriage, bodyRequest }) {
    try {
      const { data } = await emergenciesPatientApi(null, 0).post(
        `/${idTriage}`,
        bodyRequest
      );
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },

  async getBackgrounds({ commit }, { idUrgency, page }) {
    try {
      const { data } = await emergenciesPatientApi(null, 0).get(
        `/${idUrgency}/backgrounds?page=${page}`
      );

      const { success, message, data: backgroundsHistory } = data;

      backgroundsHistory.data.forEach(
        (
          element: {
            reference_center: {
              name: string;
            };
            diagnostic: {
              code: string;
              description: string;
            };
            background: {
              id: string;
              name: string;
            };
            type_background: string;
            reference_center_name: string;
            description: string;
            created_at: string;
            cie: string;
            code: string;
            diagnostic_description: string;
          },
          i: number
        ) => {
          element["reference_center_name"] = element?.reference_center?.name;
          element["created_at"] = element.created_at
            ? moment(element.created_at).format("DD-MM-YYYY h:mm")
            : "";
          element["type_background"] = element.background?.name;
          element[
            "cie"
          ] = `${element.diagnostic?.code} - ${element.diagnostic?.description}`;
        }
      );

      store.commit("teleexpertises/setBackgroundHistory", backgroundsHistory);

      return { success, message, backgroundsHistory };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },

  async getDiagnostics({ commit }, { idUrgency, page }) {
    try {
      const { data } = await emergenciesPatientApi(null, 0).get(
        `/${idUrgency}/diagnostics?page=${page}`
      );

      const { success, message, data: diagnosticHistory } = data;

      diagnosticHistory.data.forEach(
        (
          element: {
            reference_center: {
              name: string;
            };
            diagnostic: {
              code: string;
              description: string;
            };
            created_at_formatted: string;
            reference_center_name: string;
            description: string;
            created_at: string;
            code: string;
            diagnostic_description: string;
          },
          i: number
        ) => {
          element["reference_center_name"] = element?.reference_center?.name;
          element[
            "description"
          ] = `${element?.diagnostic?.code} - ${element?.diagnostic?.description}`;
          element["created_at"] = element.created_at_formatted;
          element["code"] = element.diagnostic?.code;
          element["diagnostic_description"] = element.diagnostic?.description;
        }
      );

      store.commit("teleexpertises/setDiagnosticHistory", diagnosticHistory);

      return { success, message, diagnosticHistory };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
};

export default actions;
