import { MutationTree } from "vuex";
import { TelemedicineReportState } from "@/interfaces/global/interfaces";

const mutations: MutationTree<TelemedicineReportState> = {
  setTelemedicineReport(state: TelemedicineReportState, list: object[]): void {
    state.list = list;
  },
  setYears(state: TelemedicineReportState, list: number[] | []): void {
    state.years = list;
  },
  setMonths(
    state: TelemedicineReportState,
    list: { id: number; name: string }[] | []
  ): void {
    state.months = list;
  },
};

export default mutations;
