import { useStore } from "vuex";
import { StateInterface } from '@/store/index';
import { computed } from "vue";

export const useAbsences = () => {

    const store = useStore<StateInterface>();

    return {
        // State - Getter
        listAbsences: computed( () => store.state.absences.dataTable.data.data),
        currentPage: computed( () => store.state.absences.dataTable.current_page),
        lastPage: computed( () => store.state.absences.dataTable.last_page),
        // Actions
        isGetListAbsences: (data:any) => store.dispatch('absences/getListAbsences', data),
        isCreateReportAbsence: (data:any) => store.dispatch('absences/createReportAbsence', data),
        isViewAbsence: (data:any) => store.dispatch('absences/viewAbsence', data),
        isViewDetailAbsence: (data:any) => store.dispatch('absences/viewDetailAbsence', data),
        //Mutations
        isSetCurrentPage: (data:number) => store.commit('absences/setListAbsencesCurrentPage', data),
        isSetLastPage: (data:number) => store.commit('absences/setListAbsencesLastPage', data)
    }
}