import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { load } from "recaptcha-v3";
import { ref } from "vue";

export const useRecaptcha = () => {
  const { openMainLoader } = useMainLoader();
  const recaptcha = document.querySelector("div > .grecaptcha-badge");
  const recaptchaToken = ref<string>("");

  function removeRecaptcha() {
    if (!recaptcha) return;
    recaptcha.remove();
  }

  const loadRecaptcha = async () => {
    // openMainLoader(true);
    try {
      const recaptchaLoading = await load(
        "6LdIt1kkAAAAALdZaTBnrHA772zhSEsUTR0goBbE"
      );
      const token = await recaptchaLoading.execute("submit");
      recaptchaToken.value = token;
      // openMainLoader(false);
    } catch (error) {
      // openMainLoader(false);
    }
  };

  return {
    recaptchaToken,
    loadRecaptcha,
    removeRecaptcha,
  };
};
