import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { IGynecologist, ITelemedicine } from "@/interfaces/global/interfaces";

export const useTelemedicine = () => {
  const store = useStore<StateInterface>();
  return {
    // State
    telemedicine: computed<ITelemedicine | null>(
      () => store.state.telemedicine.telemedicine
    ),
    telemedicineList: computed(() => store.state.telemedicine.telemedicineList),
    telemedicineListCurrentPage: computed(
      () => store.state.telemedicine.telemedicineCurrentPage
    ),
    telemedicineListLastPage: computed(
      () => store.state.telemedicine.telemedicineLastPage
    ),
    states: computed(() => store.state.telemedicine.states),
    types: computed(() => store.state.telemedicine.types),
    queryType: computed(() => store.state.telemedicine.queryType),
    informed_consent: computed(() => store.state.telemedicine.informed_consent),
    notify_send: computed(() => store.state.telemedicine.notify_send),
    notify_type: computed(() => store.state.telemedicine.notify_type),
    consent_through_type: computed(
      () => store.state.telemedicine.consent_through_type
    ),
    data_usage: computed(() => store.state.telemedicine.data_usage),
    signature: computed(() => store.state.telemedicine.signature),
    signature_url: computed(() => store.state.telemedicine.signature_url),

    // Actions
    _getEntityResponse: (params: object, responseId: number | string) =>
      store.dispatch("telemedicine/getEntityResponse", {
        params,
        responseId,
      }),
    _getListTelemedicine: (filters: object | null = null, page: number = 1) =>
      store.dispatch("telemedicine/getTelemedicinesList", {
        filters,
        page,
      }),
    _getTelemedicineById: (idTelemedicine: number | string) =>
      store.dispatch("telemedicine/getTelemedicineById", {
        idTelemedicine,
      }),
    _getEntityByTelemdicine: (
      telemedicineId: number | string,
      entityName: string,
      page: number
    ) =>
      store.dispatch("telemedicine/getEntityByTelemdicine", {
        telemedicineId,
        entityName,
        page,
      }),
    _createTelemedicine: (
      bodyRequest: ITelemedicine | object | string,
      annexDocuments: object,
      medicalExamsDocuments: object
    ) =>
      store.dispatch("telemedicine/createTelemedicine", {
        bodyRequest,
        annexDocuments,
        medicalExamsDocuments,
      }),

    // Getter
    getGynecologistsBackground: computed<IGynecologist>(
      () => store.getters["telemedicine/getGynecologistsBackground"]
    ),
    getDisabilities: computed(
      () => store.getters["telemedicine/getDisabilities"]
    ),

    // Mutation
    setGynecologistsBackground: (details: object | null = null) =>
      store.commit("telemedicine/setGynecologistsBackground", details),
    setDisabilities: (details: object | null = null) =>
      store.commit("telemedicine/setDisabilities", details),
    setInformedConsent: (value: boolean) =>
      store.commit("telemedicine/setInformedConsent", value),
    setNotifySend: (value: boolean) =>
      store.commit("telemedicine/setNotifySend", value),
    setNotifyType: (value: string[] | null) =>
      store.commit("telemedicine/setNotifyType", value),
    setConsentThroughType: (value: string) =>
      store.commit("telemedicine/setConsentThroughType", value),
    setDataUsage: (value: boolean) =>
      store.commit("telemedicine/setDataUsage", value),
    setSignature: (
      sign: { value: File | null; url: string | null } | null = null
    ) => store.commit("telemedicine/setSignature", sign),
  };
};
