import { ActionTree } from 'vuex';
import { CdasState } from './state';
import { StateInterface } from '../index';
import { teleexpertisesApi, telexpertiseDocument } from "@/apis/teleexpertisesApi";


const actions: ActionTree<CdasState, StateInterface> = {
    
  async getTelexpertises({ commit }, { token, centerReferenceId, centerAtentionId }): Promise<object> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await teleexpertisesApi().get(
        `/withoutPaginate/All/${centerReferenceId}/${centerAtentionId}`,
        config
      );
      const { success, message, data: telexpertisesData } = data;
      
      commit("setTelexpertises", data);
      return { success, message, telexpertisesData };
    } catch (error) {
      return errorException(error);
    }
  },
    
  async getXmlStringByTelexpertice({ commit }, { token, idTelexpertise }): Promise<object> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await telexpertiseDocument().post(
        `/xml/teleexpertise/${idTelexpertise}`,
        config
      );
      
      return data;
    } catch (error) {
      return errorException(error);
    }
  },
}

function errorException(error: any) {
  //No hay conexion
  if (error.message == "Network Error") {
    const success = false;
    const { message } = error;
    return { success, message };
  }
  const { data } = error.response;
  const { success, message } = data;
  return { success, message };
}



export default actions;