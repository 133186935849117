export interface HealthcareManagementUI {
 title: string

}

function state(): HealthcareManagementUI {
    return {

        title: 'STATE TITLE'
      

    }
}

export default state;