export interface Resolution1604of2013State {
    dataTable: dataPaginate
}

export interface dataPaginate {
    data: dataList,
    current_page: number,
    last_page: number,
    per_page: number,
    total: number,
}

export interface dataList {
    data: datum[]
}

export interface datum {
    id:                   number;
    report_type:          string;
    report_date:          null;
    status:               string;
    url:                  null;
    consult_type:         null;
    reference_center_id:  number;
    care_center_id:       number;
    user_id:              number;
    eps_id:               number;
    regime_id:            null;
    disease_type:         null;
    format:               string;
    from_date_formatted:  string;
    to_date_formatted:    string;
    created_at_formatted: string;
    reference_center:     ECenter;
    care_center:          ECenter;
    eps:                  Eps;
    user:                 User;
}

export interface ECenter {
    id:   number;
    name: string;
}

export interface Eps {
    id:   number;
    code: string;
    name: string;
}

export interface User {
    id:                    number;
    email:                 string;
    email_verified_at:     Date;
    status:                string;
    last_login:            Date;
    last_change_password:  Date;
    logout:                Date;
    expiry_date:           null;
    created_at:            null;
    updated_at:            Date;
    user_types_id:         number;
    user_info_id:          number;
    centers_id:            number;
    contract_id:           null;
    failed_login_attempts: number;
    name:                  string;
    details:               Details;
}

export interface Details {
    id:                number;
    created_at:        Date;
    updated_at:        Date;
    names:             string;
    surnames:          string;
    document:          string;
    phone:             string;
    signature:         null;
    photo:             null;
    professional_reg:  null;
    professional_type: null;
    gender:            string;
    rethus:            null;
    full_name:         string;
}

function state(): Resolution1604of2013State {
    return {
        dataTable: {
            data: {
                data:[]
            },
            current_page: 1,
            last_page: 1,
            per_page: 20,
            total: 0,
        }
        
    }
}

export default state;