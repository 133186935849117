import {
  IData,
  ILogin,
  IReport,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { LoginsState } from "./state";

const mutation: MutationTree<LoginsState> = {
  setResponse(state: LoginsState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
  setLogins(state: LoginsState, data?: IData<ILogin[]>): void {
    if (data) {
      state.data.logins = data.data;

      state.pages.logins.currentPage.value = data.current_page;
      state.pages.logins.lastPage.value = data.last_page;
    } else {
      state.data.logins = [];
    }
  },
  setAgents(state: LoginsState, data?: string[]): void {
    if (data) {
      state.data.agents = data;
    } else {
      state.data.agents = [];
    }
  },
  setReport(state: LoginsState, data?: IReport | null) {
    if (data !== undefined) {
      state.data.report = data;
    } else {
      state.data.report = null;
    }
  },
};

export default mutation;
