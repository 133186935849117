import { ICancellationReason,  IResaonData } from "@/interfaces/global/ICancellationReason";
import { MutationTree } from "vuex";

const mutation: MutationTree<ICancellationReason> = {

  setCancellationReasons(state: ICancellationReason): void {
    (state.success = true || false),
      (state.message = ""),
      (state.data = {
        current_page: null,
        data: [],
        first_page_url: "",
        from: null,
        last_page: null,
        last_page_url: "",
        links: [],
        next_page_url: null,
        path: "",
        per_page: null,
        prev_page_url: null,
        to: null,
        total: null,
      });
  },

  setCancellationReason(state: ICancellationReason, data: IResaonData): void{
    state.data = data
  },

  setReason(state: ICancellationReason): void {
    (state.success = true || false),
      (state.message = ""),
      (state.data = {
        id: null,
        reason: "",
        observation: "",
        status: "",
        created_at: "",
        updated_at: "",
        reference_center: {
          id: null,
          name: "",
          referenceCenter: null,
        },
        created_by: {
          id: null,
          user_info_id: null,
          name: "",
          details: {
            id: null,
            names: "",
            surnames: "",
          },
        },
      });
  },

  removeCancellationReason(state: ICancellationReason):void{
    (state.success = true || false),
      (state.message = ""),
      (state.data = {
        id: null,
        reason: "",
        observation: "",
        status: "",
        created_at: "",
        updated_at: "",
        reference_center: {
          id: null,
          name: "",
          referenceCenter: null,
        },
        created_by: {
          id: null,
          user_info_id: null,
          name: "",
          details: {
            id: null,
            names: "",
            surnames: "",
          },
        },
      });
  }

};

export default mutation;
