import { IPages } from "@/interfaces/customs/Pages";
import {
  IResolution4725,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { StateInterface } from "@/store/index";
import {
  ResolutionReports4725View,
  ResolutionReports4725Data,
  ResolutionReports4725List,
  ResolutionReports4725Pages,
} from "@/store/resolution-4725/state";
import { computed } from "vue";
import { useStore } from "vuex";

export const useResolution4725Store = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    resolutionReports4725: computed<IResolution4725[]>(
      () => store.state.resolution4725.data.resolutionReports4725
    ),
    resolutionReports4725Pages: computed<IPages>(
      () => store.state.resolution4725.pages.resolutionReports4725
    ),
    resolutionReport4725: computed<IResolution4725 | null>(
      () => store.state.resolution4725.data.resolutionReport4725
    ),
    data: computed<ResolutionReports4725Data>(() => store.state.resolution4725.data),
    pages: computed<ResolutionReports4725Pages>(() => store.state.resolution4725.pages),
    response: computed<IRequestResponse>(
      () => store.state.resolution4725.response
    ),

    // Getters
    getResolutionReports4725List: computed<ResolutionReports4725List[]>(
      () => store.getters["resolution4725/getResolutionReports4725List"]
    ),
    getResolutionReport4725View: computed<ResolutionReports4725View | null>(
      () => store.getters["resolution4725/getResolutionReport4725View"]
    ),

    // Actions
    loadResolutionReports4725: (
      params: {
        reference_center?: number;
        care_center?: number;
        eps?: number;
        from_date: string;
        to_date: string;
        status: string;
        page: number
      }
    ) =>
      store.dispatch("resolution4725/loadResolutionReports4725",
        params,
      ),
    loadResolutionReport4725: (id: number) =>
      store.dispatch("resolution4725/loadResolutionReport4725", id),
    createResolutionReport4725: (bodyRequest: {
      reference_center_id: number | null;
      care_center_id: number | null;
      from_date: string;
      to_date: string;
      eps_id: number | null;
      regime_id: number | null;
    }) => store.dispatch("resolution4725/createResolutionReport4725", bodyRequest),
    cancelResolutionReport4725: (id: number) =>
      store.dispatch("resolution4725/cancelResolutionReport4725", id),
    loadEPSWithData: (params: {
      reference_center_id?: number;
      care_center_id?: number;
      form_date?: string;
      to_date?: string;
    }) => store.dispatch("resolution4725/loadEPSWithData", params),
    loadEPSWithReports: (params: {
      reference_center_id?: number;
      care_center_id?: number;
    }) => store.dispatch("resolution4725/loadEPSWithReports", params),

    //Mutations
    setResolutionReports4725: () => store.commit("resolution4725/setResolutionReports4725"),
    setResolutionReport4725: (data?: IResolution4725) =>
      store.commit("resolution4725/setResolutionReport4725", data),
  };
};
