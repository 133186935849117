import { MutationTree } from "vuex";
import { UsersState } from "./state";

const mutation: MutationTree<UsersState> = {
  setDataInfoTrend(state, infoTrend) {
    state.dataInfoTrend = infoTrend;
  },
  setInfoTrends(state, trendInfo) {
    state.trendInfo = trendInfo;
  },

  setMedicals(state: UsersState, { data }) {
    state.medicals = data;
  },
  setCenterAdmins(state: UsersState, { data }) {
    state.adminCenters = data;
  },
  setEditUser(state: UsersState, data) {
    state.adminCareEdit = data;
  },
  setColorAvatarUser(state: UsersState, color) {
    state.colorAvatar = color;
  },
  setImgUser(state: UsersState, img) {
    state.imgUser = img;
  },
  setDashboard(state: UsersState, val) {
    state.isDashboard = val;
  },
  setDataTypeUser(state: UsersState, data) {
    state.dataTableTypeUser = data;
  },
  setListOTPOptions(state: UsersState, data) {
    state.loginOTPOption = data;
  },
  setListOTPMethods(state: UsersState, data) {
    state.loginOTPMethods = data;
  },
  setLoginOTPQuestions(state: UsersState, data) {
    state.loginOTPQuestions = data;
  },
};

export default mutation;
