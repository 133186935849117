import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import {
  CentersSelectorsState,
  ICentersSelectorsV2,
} from "@/interfaces/global/interfaces";

const CONFIG = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const URL_PATH = "/api/settings/centers";

const actions: ActionTree<CentersSelectorsState, StateInterface> = {


  async getCenters({ commit }) {
    commit("setLoaderCenterV2", true)
    try {
      const { data } = await executeApi().get(`${URL_PATH}/referenceCenters`);

      const infoRef: ICentersSelectorsV2[] = data.data;
      const centersList =
        infoRef.length > 0
          ? infoRef.map((option) => ({
              label: option.name,
              value: option.id,
              careCenters:
                option.careCenters.length > 0
                  ? option.careCenters.map((careCenter) => ({
                      label: careCenter.name,
                      value: careCenter.id,
                      centerRefId: careCenter.referenceCenter.id,
                      centerRefName: careCenter.referenceCenter.name,
                    }))
                  : [],
            }))
          : [];

      const { success, message } = data;

      // selector @Jose
      commit("setCenters", data.data);

      // selector @Leo
      commit("setCentersSelectorsV2", centersList)

      commit("setLoaderCenterV2", false)

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
};

export default actions;
