import { MutationTree } from "vuex";
import { ISurveyAbsenceCustom } from "@/interfaces/customs/ISurveysCustom";

const mutation: MutationTree<ISurveyAbsenceCustom> = {
  setSurveyInfo(state, info) {
    state.surveyInfo = info;
  },

  setSurveyOptions(state, list) {
    state.surveyOptions = list;
  },
};

export default mutation;
