import { IRequestResponse } from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { AppointmentConfirmationState } from "./state";

const mutation: MutationTree<AppointmentConfirmationState> = {
  setResponse(
    state: AppointmentConfirmationState,
    response?: IRequestResponse
  ) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
