import { IPages } from "@/interfaces/customs/interface";
import { ICareCenter, IRequestResponse } from "@/interfaces/global/interfaces";

// Care Centers V.2
export type CareCentersPages = Record<"careCenters", IPages>;

export interface CareCentersList {
  id: ICareCenter["id"];
  name: ICareCenter["name"];
  address: ICareCenter["address"];
  phone: ICareCenter["phone"];
  status: ICareCenter["status"];
}

export interface CareCentersData {
  careCenters: ICareCenter[];
}

export interface AtentionCenterState {
  isLoading: boolean;
  atentionCenterList: Array<object>;
  atentionCenterName: string;
  atentionCenterStatus: object;
  atentionCenterCurrentPage: number;
  atentionCenterLastPage: number;

  // Care Centers V.2
  data: CareCentersData;
  pages: CareCentersPages;
  response: IRequestResponse;
}

function state(): AtentionCenterState {
  return {
    isLoading: true,
    atentionCenterList: [],
    atentionCenterName: "",
    atentionCenterStatus: {},
    atentionCenterCurrentPage: 1,
    atentionCenterLastPage: 1,

    // Care Centers V.2
    data: {
      careCenters: [],
    },
    pages: {
      careCenters: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
