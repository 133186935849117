import { ActionTree } from "vuex";
import { ScheduleReportsState, RequestResponse } from "./state";
import { StateInterface } from "../index";
import { scheduleReportsApi } from "@/apis";
import errorException from "@/functions/ErrorException";

const actions: ActionTree<ScheduleReportsState, StateInterface> = {
  async getScheduleReports(
    { commit },
    {
      token,
      page,
      selects,
    }: {
      token: string;
      page: number;
      selects: { referenceCenter: number; careCenter: number };
    }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await scheduleReportsApi.get(
        `?page=${page}&rows=20&reference_center=${selects.referenceCenter}&care_center=${selects.careCenter}`,
        config
      );
      const { success, message, data: scheduleReportData } = data;

      commit("getScheduleReports", scheduleReportData);
      commit("clearScheduleReportsData");
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async createScheduleReport(
    { commit },
    { token, bodyRequest }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      : {};

    try {
      const { data } = await scheduleReportsApi.post("/", bodyRequest, config);
      const { success, message } = data;

      commit("clearScheduleReportsList");
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getScheduleReportById(
    { commit },
    { token, id }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await scheduleReportsApi.get(`/${id}`, config);
      const { success, message, data: scheduleReportData } = data;

      commit("getScheduleReportById", scheduleReportData);
      commit("clearScheduleReportsList");
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getTelexpertisesByReport(
    { commit },
    {
      token,
      page,
      id,
    }: {
      token: string;
      page: number;
      id: number;
    }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await scheduleReportsApi.get(
        `/${id}/results/?page=${page}&rows=20`,
        config
      );
      const { success, message, data: scheduleReportData } = data;

      commit("getTelexpertisesByReport", scheduleReportData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
};

export default actions;
