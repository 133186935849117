export interface RootObject extends RequestResponse {
  data: Data;
  service: string;
}

export interface RequestResponse {
  message: string;
  success: boolean;
}

export interface Data {
  current_page: number;
  data: Datum[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: Link[];
  next_page_url: null | string;
  path: string;
  per_page: number;
  prev_page_url: null | string;
  to: number;
  total: number;
}

export interface Datum {
  id: number;
  reference_center: Center;
  care_center: Center;
  type_request_service: ReasonForConsultation;
  reason_for_consultation: ReasonForConsultation;
  patient_one: Patient;
  patient_two: Patient;
  observation: string;
  status: string;
  created_at: string;
  updated_at: string;
  date_request: string;
  requested_by: RequestedBy;
  revision: null | string;
  audited_by: RequestedBy;
  pdf_url: null | string;
  reason: null | string;
  documents: Document[];
}

export interface Center {
  id: number;
  created_at: null;
  updated_at: null;
  name: string;
  nit: string;
  address: string;
  phone: string;
  email: string;
  logo: null;
  contact_name: string;
  contact_phone: string;
  status: string;
  centers_types_id: number;
}

export interface Document {
  id: number;
  url: string;
  name: string;
  documentable_id: number;
  documentable_type: string;
  mime_type: string;
  created_at: string;
  updated_at: string;
  date: string;
  description: string;
}

export interface Patient {
  id: number;
  name: string;
  last_name: string;
  gender: string;
  age: string;
  phone: string;
  email: string;
  status_sms: string;
  status_email: string;
  care_program: [];
  eps: EPS;
  document_type: ReasonForConsultation;
  document: string;
}

export interface ReasonForConsultation {
  id: number;
  name: string;
  abbreviation?: string;
  created_at?: null | string;
  updated_at?: null | string;
}

export interface EPS {
  id: number;
  code: string;
  name: string;
  status: string;
  created_at: null | string;
  updated_at: null | string;
}

export interface RequestedBy {
  id: number;
  email: string;
  email_verified_at: string;
  status: string;
  last_login: string;
  last_change_password: string;
  logout: string;
  expiry_date: null;
  created_at: null;
  updated_at: string;
  user_types_id: number;
  user_info_id: number;
  centers_id: number;
  contract_id: null;
  name: string;
  details: Details;
}

export interface Details {
  id: number;
  created_at: null;
  updated_at: null;
  names: string;
  surnames: string;
  document: string;
  phone: string;
  signature: null;
  photo: null;
  professional_reg: null;
  professional_type: null;
  gender: string;
}

export interface Link {
  url: null | string;
  label: string;
  active: boolean;
}

export interface DataList {
  id: Datum["id"];
  date: Datum["date_request"];
  type: Datum["type_request_service"]["name"];
  patientOne: Datum["patient_one"]["document"];
  patientTwo: Datum["patient_two"]["document"];
  requestedBy: Datum["requested_by"]["name"];
  careCenter: Datum["care_center"]["name"];
  status: Datum["status"];
  document: Datum["pdf_url"];
}

export interface UnificationMedicalRecordsState {
  unificationMedicalRecordsList: DataList[] | [];
  unificationMedicalRecordsCurrentPage: number;
  unificationMedicalRecordsLastPage: number;
  unificationMedicalRecord: Datum;
  typeRequest: ReasonForConsultation[] | [];
  reasonForConsultation: ReasonForConsultation[] | [];
}

export interface AllResources {
  type_request: ReasonForConsultation[];
  reason_for_consultation: ReasonForConsultation[];
}

export interface UnificationMedicalRecord {
  date: Datum["date_request"];
  careCenter: Datum["care_center"]["name"];
  revision: Datum["revision"];
  type: Datum["type_request_service"]["name"];
  requestedBy: Datum["requested_by"]["name"];
  auditedBy: Datum["audited_by"]["name"];
  status: Datum["status"];
  motive: Datum["reason_for_consultation"]["name"];
  patientOne: Datum["patient_one"];
  patientTwo: Datum["patient_two"];
  observation: Datum["observation"];
  document: Datum["pdf_url"];
  reason: Datum["reason"];
  documents: Datum["documents"];
}

function state(): UnificationMedicalRecordsState {
  return {
    unificationMedicalRecordsList: [],
    unificationMedicalRecordsCurrentPage: 0,
    unificationMedicalRecordsLastPage: 0,
    unificationMedicalRecord: {
      id: 0,
      reference_center: {
        id: 0,
        created_at: null,
        updated_at: null,
        name: "",
        nit: "",
        address: "",
        phone: "",
        email: "",
        logo: null,
        contact_name: "",
        contact_phone: "",
        status: "",
        centers_types_id: 0,
      },
      care_center: {
        id: 0,
        created_at: null,
        updated_at: null,
        name: "",
        nit: "",
        address: "",
        phone: "",
        email: "",
        logo: null,
        contact_name: "",
        contact_phone: "",
        status: "",
        centers_types_id: 0,
      },
      type_request_service: {
        id: 0,
        name: "",
        created_at: null,
        updated_at: null,
      },
      reason_for_consultation: {
        id: 0,
        name: "",
        created_at: null,
        updated_at: null,
      },
      patient_one: {
        id: 0,
        name: "",
        last_name: "",
        gender: "",
        age: "",
        phone: "",
        email: "",
        status_sms: "",
        status_email: "",
        care_program: [],
        eps: {
          id: 0,
          code: "",
          name: "",
          status: "",
          created_at: null,
          updated_at: null,
        },
        document_type: {
          id: 0,
          name: "",
          abbreviation: "",
          created_at: null,
          updated_at: null,
        },
        document: "",
      },
      patient_two: {
        id: 0,
        name: "",
        last_name: "",
        gender: "",
        age: "",
        phone: "",
        email: "",
        status_sms: "",
        status_email: "",
        care_program: [],
        eps: {
          id: 0,
          code: "",
          name: "",
          status: "",
          created_at: null,
          updated_at: null,
        },
        document_type: {
          id: 0,
          name: "",
          abbreviation: "",
          created_at: null,
          updated_at: null,
        },
        document: "",
      },
      observation: "",
      status: "",
      created_at: "",
      updated_at: "",
      date_request: "",
      requested_by: {
        id: 0,
        email: "",
        email_verified_at: "",
        status: "",
        last_login: "",
        last_change_password: "",
        logout: "",
        expiry_date: null,
        created_at: null,
        updated_at: "",
        user_types_id: 0,
        user_info_id: 0,
        centers_id: 0,
        contract_id: null,
        name: "",
        details: {
          id: 0,
          created_at: null,
          updated_at: null,
          names: "",
          surnames: "",
          document: "",
          phone: "",
          signature: null,
          photo: null,
          professional_reg: null,
          professional_type: null,
          gender: "",
        },
      },
      revision: null,
      audited_by: {
        id: 0,
        email: "",
        email_verified_at: "",
        status: "",
        last_login: "",
        last_change_password: "",
        logout: "",
        expiry_date: null,
        created_at: null,
        updated_at: "",
        user_types_id: 0,
        user_info_id: 0,
        centers_id: 0,
        contract_id: null,
        name: "",
        details: {
          id: 0,
          created_at: null,
          updated_at: null,
          names: "",
          surnames: "",
          document: "",
          phone: "",
          signature: null,
          photo: null,
          professional_reg: null,
          professional_type: null,
          gender: "",
        },
      },
      pdf_url: null,
      reason: null,
      documents: [
        {
          id: 0,
          url: "",
          name: "",
          documentable_id: 0,
          documentable_type: "",
          mime_type: "",
          created_at: "",
          updated_at: "",
          date: "",
          description: "",
        },
      ],
    },
    typeRequest: [],
    reasonForConsultation: [],
  };
}

export default state;
