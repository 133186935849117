import { ActionTree } from "vuex";
import { TelesupportsState } from "./state";
import { StateInterface } from "../index";
import { telesupportsApi } from "@/apis";
import { IRequestResponse } from "@/interfaces/global/interfaces";
import errorException from "@/functions/ErrorException";

const actions: ActionTree<TelesupportsState, StateInterface> = {
  async telesupportsList(
    { commit },
    {
      token,
      page,
      selects,
    }: {
      token: string;
      page: number;
      selects: { referenceCenter: number; careCenter: number; status: string };
    }
  ): Promise<IRequestResponse> {
    const config = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await telesupportsApi.get(
        `/${selects.referenceCenter}/${selects.careCenter}/${selects.status}?page=${page}`,
        config
      );
      const { success, message, data: telesupportResponse } = data;

      commit("setTelesupportsList", telesupportResponse);
      commit("setTelesupportByID");
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async telesupportCreate(
    _,
    { token, bodyRequest }
  ): Promise<IRequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      : {};

    try {
      const { data } = await telesupportsApi.post("", bodyRequest, config);
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async telesupportByID({ commit }, { token, id }): Promise<IRequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await telesupportsApi.get(`/${id}`, config);
      const { success, message, data: telesupportResponse } = data;

     const telesupportRef = telesupportResponse
     
     const telesupportSchedule = telesupportResponse.current_schedule

     if(telesupportSchedule !== null){
       const teleExperticeInfo =  {
         id: telesupportSchedule.scheduleable_id,
         initialHour: telesupportSchedule.scheduled_at,
         finalHour: telesupportSchedule.schedule_final_hour,
         meetURL: telesupportSchedule.schedule_url,
         status: telesupportSchedule.status,
         token: telesupportSchedule.token,
         patient: `${telesupportRef.patient.name} ${telesupportRef.patient.last_name}`,
         formatedDay: telesupportSchedule.scheduled_at_formatted,
         type: telesupportSchedule.scheduleable_type_formatted,
       }

       commit("setTelesupportSchedule", teleExperticeInfo);
     } else {
       const teleExperticeInfo =  {
         id: -1,
         initialHour: '',
         finalHour: '',
         meetURL: '',
         status: 'NotAvailable',
         token:'',
         patient: `${telesupportRef.patient.name} ${telesupportRef.patient.last_name}`,
         formatedDay:'',
         type: ''
       }
  
       commit("setTelesupportSchedule", teleExperticeInfo);
     }

      commit("setTelesupportByID", telesupportResponse);
      commit("patients/setPatientDetails", telesupportResponse?.patient, {
        root: true,
      });
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
};

export default actions;
