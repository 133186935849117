import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/centros-referencia",
    name: "ReferenceCentersList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Reference Centers List" */ "@/views/reference-centers/ReferenceCentersList.vue"
      ),
  },
  {
    path: "/centros-referencia/ver-centros-referencia/:id(\\d+)",
    name: "ReferenceCentersView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Reference Centers View" */ "@/views/reference-centers/sub-pages/reference-centers-view/ReferenceCentersView.vue"
      ),
  },
  {
    path: "/centros-referencia/crear-centros-referencia",
    name: "ReferenceCentersCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Reference Centers Create" */ "@/views/reference-centers/sub-pages/reference-centers-create/ReferenceCentersCreate.vue"
      ),
  },
  {
    path: "/centros-referencia/editar-centros-referencia/:id(\\d+)",
    name: "ReferenceCentersEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Reference Centers Edit" */ "@/views/reference-centers/sub-pages/reference-centers-edit/ReferenceCentersEdit.vue"
      ),
  },
];
