import { MutationTree } from "vuex";
import { PatientsState } from "./state";
import {
  ISocialSecurityInformation,
  ICompanionInformation,
} from "../../interfaces/global/Patient";
import {
  IData,
  IPatient,
  IImportedPatients,
  IRequestResponse,
  IImportedPatientsCards,
  IPersonalInformation,
  IContactInformation,
  ICompanionPatientInformation,
} from "@/interfaces/global/interfaces";

const mutation: MutationTree<PatientsState> = {
  getPatientList(state: PatientsState, list: any): void {
    if (list) {
      state.patientsList = list.data.data;

      state.patientsCurrentPage = list.data.current_page;
      state.patientsLastPage = list.data.last_page || 1;
    }
  },

  setPatientDetails(state: PatientsState, data: IPatient): void {
    state.patientData = data;
  },

  setPatientDetailsAdditionalInfo(
    state: PatientsState,
    data: null | { name: string; phone: string; relationship: string }
  ): void {
    if (data) {
      if (state.patientData) {
        state.patientData.relationship_companion_patient = data?.relationship;
        state.patientData.name_companion_patient = data?.name;
        state.patientData.phone_companion_patient = data?.phone;
      }
    }
  },

  setDepartmentWithCities(state: PatientsState, deptos: Array<any>): void {
    if (deptos) {
      state.deptoCitiesData = deptos;
    }
  },

  // Patients V.3
  setPatients(state: PatientsState, data?: IData): void {
    if (data) {
      state.data.patients = data.data as IPatient[];

      state.pages.patients.currentPage.value = data.current_page;
      state.pages.patients.lastPage.value = data.last_page;
    } else {
      state.data.patients = [];
    }
  },
  setPatient(state: PatientsState, data?: IPatient | null) {
    if (data !== undefined) {
      state.data.patient = data;
      state.patientData = data;
    } else {
      state.data.patient = null;
    }
  },
  setImportedPatients(state: PatientsState, data?: IData) {
    if (data) {
      state.data.importedPatients = data.data as IImportedPatients[];

      state.pages.importedPatients.currentPage.value = data.current_page;
      state.pages.importedPatients.lastPage.value = data.last_page;
    } else {
      state.data.importedPatients = [];
    }
  },
  setImportedPatientsCards(
    state: PatientsState,
    data?: IImportedPatientsCards
  ) {
    if (data) {
      state.data.importedPatientsCards = data;
    } else {
      state.data.importedPatientsCards = {
        success: 0,
        errors: 0,
      };
    }
  },
  setResponse(state: PatientsState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
  setPatientDetailsForm(state: PatientsState, data: object | null) {
    state.patientDetailForm = data;
  },
  setPatientPersonalInformation(
    state: PatientsState,
    data: IPersonalInformation | null
  ) {
    state.personalInformationForm = data;
  },
  setPatientContactInformation(
    state: PatientsState,
    data: IContactInformation | null
  ) {
    state.contactInformationForm = data;
  },
  setPatientSocialSecurityInformation(
    state: PatientsState,
    data: ISocialSecurityInformation | null
  ) {
    state.socialSecurityInformation = data;
  },
  setCompanionInformation(
    state: PatientsState,
    data: ICompanionInformation | null
  ) {
    state.companionInformation = data;
  },
  setPatientCompanionInformation(
    state: PatientsState,
    data: ICompanionPatientInformation | null
  ) {
    state.companionPatientInformation = data;
  },
  setDocumentNumberPrefix(state: PatientsState, document_prefix: string) {
    if (state.personalInformationForm) {
      state.personalInformationForm.document_number_prefix = document_prefix;
    }
  },
  setDocumentNumber(state: PatientsState, document_number: string) {
    if (state.personalInformationForm) {
      state.personalInformationForm.document_number = document_number;
    }
  },
  setNationality(state: PatientsState, nationality: string) {
    if (state.personalInformationForm) {
      state.personalInformationForm.nationality = nationality;
    }
  },
};

export default mutation;
