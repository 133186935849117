import {
  IData,
  IDiagnostic,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { Datum, DiagnosticsState } from "./state";

const mutation: MutationTree<DiagnosticsState> = {
  getDiagnosticById(state: DiagnosticsState, diagnostic: Datum): void {
    if (diagnostic) {
      const { code, description }: Datum = diagnostic;

      state.diagnosticCode = code;
      state.diagnosticDescription = description;
    }
  },
  clearDiagnosticsData(state: DiagnosticsState): void {
    state.diagnosticCode = "";
    state.diagnosticDescription = "";
  },

  // Diagnostics V.2
  setDiagnostics(state: DiagnosticsState, data?: IData<IDiagnostic[]>): void {
    if (data) {
      state.data.diagnostics = data.data;

      state.pages.diagnostics.currentPage.value = data.current_page;
      state.pages.diagnostics.lastPage.value = data.last_page;
    } else {
      state.data.diagnostics = [];
    }
  },
  setDiagnostic(state: DiagnosticsState, data?: IDiagnostic | null) {
    if (data !== undefined) {
      state.data.diagnostic = data;

      state.requests.diagnostic = true;
    } else {
      state.data.diagnostic = null;

      state.requests.diagnostic = false;
    }
  },
  setResponse(state: DiagnosticsState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
