import { MutationTree } from "vuex";
import { IDataTable } from "@/interfaces/customs/interface";
import {
  IProcedureReport,
  ProceduresReportState,
} from "@/interfaces/global/interfaces";

const mutation: MutationTree<ProceduresReportState> = {
  setProceduresReportList(
    state: ProceduresReportState,
    list: IDataTable
  ): void {
    state.proceduresReportList = list?.data;

    state.proceduresReportCurrentPage = list?.current_page || 1;
    state.proceduresReportLastPage = list?.last_page || 1;
  },
  setProcedureReport(
    state: ProceduresReportState,
    data: IProcedureReport
  ): void {
    state.procedureReport = data || null;
  },
};

export default mutation;
