import { MutationTree } from 'vuex';
import { AbsenceState } from './state';


const mutation: MutationTree<AbsenceState> = {
    setListAbsences(state: AbsenceState, { data }) {
        state.dataTable.data.data = data.data;
    },

    setListAbsencesCurrentPage(state: AbsenceState, { data }){
        state.dataTable.current_page = data;
    },

    setListAbsencesLastPage(state: AbsenceState, { data }){
        state.dataTable.current_page = data;
    }
}


export default mutation;