import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { BanksState } from "./state";

const URL_PATH_BANK = "/api/settings/banks";

const actions: ActionTree<BanksState, StateInterface> = {
  async loadBanks({ commit }, params) {
    commit("setBanks");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH_BANK}`, {
        params,
      });
      const { success, message, data } = response;

      commit("setBanks", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadBank({ commit }, id) {
    commit("setBank");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH_BANK}/${id}`
      );
      const { success, message, data } = response;

      commit("setBank", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createBank({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(`${URL_PATH_BANK}`, bodyRequest);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async updateBank({ commit }, { id, bodyRequest }) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH_BANK}/${id}`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async changeBankStatus({ commit }, id) {
    commit("setBanks");
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH_BANK}/changeStatus/${id}`,
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async deleteBank({ commit }, id) {
    commit("setBanks");
    commit("setResponse");

    try {
      const { data } = await executeApi().delete(`${URL_PATH_BANK}/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
};

export default actions;
