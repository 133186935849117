import {
  IRequestResponse,
  IInitialAttention,
} from "@/interfaces/global/interfaces";

export interface InitialAttentionsRequest {
  initialAttentions: boolean;
  initialAttention: boolean;
}

export interface InitialAttentionsPages {
  currentPage: { value: number };
  lastPage: { value: number };
}

export interface InitialAttentionsList {
  id: IInitialAttention["id"];
  patient: IInitialAttention["patient"]["name"];
  document: IInitialAttention["patient"]["document"];
  gender: IInitialAttention["patient"]["gender"];
  eps: IInitialAttention["patient"]["eps"]["name"];
  age: IInitialAttention["patient"]["age"];
  date: IInitialAttention["registered_date_formatted"];
  status: IInitialAttention["status"];
  statusFormatted: IInitialAttention["status_formatted"];
}

export interface InitialAttentionView {
  condition: IInitialAttention["condition"];
  observation: IInitialAttention["observation"];
  date: IInitialAttention["registered_date_formatted"];
  time: IInitialAttention["registered_time_formatted"];
  patient: IInitialAttention["patient"];
  attendant: IInitialAttention["attendant"];
  isAttendant: boolean;
}

export interface InitialAttentionsState {
  request: InitialAttentionsRequest;
  initialAttentions: IInitialAttention[] | [];
  initialAttentionsPages: InitialAttentionsPages;
  initialAttention: IInitialAttention | null;
  response: IRequestResponse;
}

function state(): InitialAttentionsState {
  return {
    request: {
      initialAttentions: false,
      initialAttention: false,
    },
    initialAttentions: [],
    initialAttentionsPages: {
      currentPage: { value: 1 },
      lastPage: { value: 1 },
    },
    initialAttention: null,
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
