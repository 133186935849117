import { ActionTree } from 'vuex';
import { AttendanceUI } from './state';
import { StateInterface } from '../../index';
import { indexApi, yearsWithDataApi, scheduledPatientsApi } from '../../../apis/patientsApi';
import { useMainLoader } from '@/components/loader/hooks/useMainLoader';
import { ErrorsUI } from "@/interfaces/customs/ErrorException";
import { showErrorInTS } from '@/composables/showErrorInTS/showErrorInTS';

const { openMainLoader } = useMainLoader()
const { showCatchError } = showErrorInTS()
const actions: ActionTree<AttendanceUI, StateInterface> = {
    async DispatchIndex({ commit }, { token, indexURL }: { token: string, indexURL: string }) {
        openMainLoader(true)

        // loader
        try {
            const cardList = await indexApi().get(`/${indexURL}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            const counterCardMessage = cardList.data.message

            const counterCardList = cardList.data.data.map((cardInfo: { patients: number, schedules: number, attendances: number, unattendances: number }) => {
                return [
                    { quantity: cardInfo.patients, name: 'Pacientes', icon: 'mdi-account-heart-outline' },
                    { quantity: cardInfo.schedules, name: 'Agendamiento', icon: 'mdi-calendar-clock-outline' },
                    { quantity: cardInfo.attendances, name: 'Ejecutadas', icon: 'mdi-message-processing-outline ' },
                    { quantity: cardInfo.unattendances, name: 'No ejecutados', icon: 'mdi-message-minus-outline' }
                ]
            })
            const attendanceTable = cardList.data.data[0].dataList.map((tableRowInfo: {
                service: string,
                type: string,
                attendances: number,
                unattendances: number,
                unattendancesPerc: number
            }) => {
                return {
                    servicesName: tableRowInfo.service,
                    typeService: tableRowInfo.type,
                    attendances: tableRowInfo.attendances,
                    unattendances: tableRowInfo.unattendances,
                    percentageUnattendances: tableRowInfo.unattendancesPerc
                }
            })

            // row attendance
            commit('setAttendanceCounterCards', counterCardList[0])
            commit('setAttendanceTable', attendanceTable)

            // loader
            openMainLoader(false)

            // alert
            commit('general/setShowAlert', true, { root: true })
            commit('general/setAlertTitle', '', { root: true })
            commit('general/setAlertMessage', `¡${counterCardMessage}!`, { root: true })
            commit('general/setAlertType', 'success', { root: true })

        } catch (e) {
            const error = e as ErrorsUI;
            const message = error.message
            const response = error.response
            const errorParse = JSON.parse(JSON.stringify({ message, response }))
            // loader
            openMainLoader(false)
            // alert
            commit('general/setShowAlert', true, { root: true })
            commit('general/setAlertTitle', '', { root: true })
            commit('general/setAlertMessage', showCatchError(errorParse), { root: true })
            commit('general/setAlertType', 'error', { root: true })
        }
    },

    async DispatchYearsWithData({ commit }, { token, yearsURL }: { token: string, yearsURL: string }) {
        openMainLoader(true)
        commit('setAttendanceYearLoaders', true)
        // loader
        try {
            const yearList = await yearsWithDataApi().get(`/${yearsURL}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            const yearSelectorMessage = yearList.data.message
            const yearSelectorList = yearList.data.data

            // selectors - year
            commit('setAttendanceYearOptions', yearSelectorList)

            // loader
            openMainLoader(false)
            commit('setAttendanceYearLoaders', false)

            // alert
            commit('general/setShowAlert', true, { root: true })
            commit('general/setAlertTitle', '', { root: true })
            commit('general/setAlertMessage', `${yearSelectorMessage}`, { root: true })
            commit('general/setAlertType', `${yearSelectorList.length > 0 ? 'success' : 'warning'}`, { root: true })

        } catch (e) {
            const error = e as ErrorsUI;
            const message = error.message
            const response = error.response
            const errorParse = JSON.parse(JSON.stringify({ message, response }))
            // loader
            openMainLoader(false)
            commit('setAttendanceYearLoaders', false)
            // alert
            commit('general/setShowAlert', true, { root: true })
            commit('general/setAlertTitle', '', { root: true })
            commit('general/setAlertMessage', showCatchError(errorParse), { root: true })
            commit('general/setAlertType', 'error', { root: true })
        }
    },

    async DispatchScheduledPatients({ commit }, { token, patientsURL }: { token: string, patientsURL: string }) {
        commit('setPatientListLoaders', true)
        // loader
        try {
            const patientsInfo = await scheduledPatientsApi().get(`/${patientsURL}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            const patinetMessage = patientsInfo.data.message
            const patientsRef = patientsInfo.data.data[0]

            const agePatients = patientsRef.agePatients
            const agePatientsMessage = patientsRef.agePatientsMessage

            const attendancePatients = patientsRef.attendancePatients
            const attendancePatientsMessage = patientsRef.attendancePatientsMessage

            const attendancesDistribution = patientsRef.attendancesDistribution
            const attendancesDistributionMessage = patientsRef.attendancesDistributionMessage

            const unattendancesGender = patientsRef.unattendancesSex
            const unattendancesGenderMessage = patientsRef.unattendancesSexMessage

            // atendance tabs
            commit('setPatientListOptions', patientsRef)

            commit('setAgePatients', agePatients)
            commit('setAgePatientsMessage', agePatientsMessage)

            commit('setAttendancePatients', attendancePatients)
            commit('setAttendancePatientsMessage', attendancePatientsMessage)

            commit('setAttendancesDistribution', attendancesDistribution)
            commit('setAttendancesDistributionMessage', attendancesDistributionMessage)

            commit('setUnattendancesGender', unattendancesGender)
            commit('setUnattendancesGenderMessage', unattendancesGenderMessage)

            // loader
            commit('setPatientListLoaders', false)
            openMainLoader(false)

            // alert
            commit('general/setShowAlert', true, { root: true })
            commit('general/setAlertTitle', '', { root: true })
            commit('general/setAlertMessage', `¡${patinetMessage}!`, { root: true })
            commit('general/setAlertType', 'success', { root: true })

        } catch (e) {
            const error = e as ErrorsUI;
            const message = error.message
            const response = error.response
            const errorParse = JSON.parse(JSON.stringify({ message, response }))
            // loader
            openMainLoader(false)
            commit('setPatientListLoaders', false)
            // alert
            commit('general/setShowAlert', true, { root: true })
            commit('general/setAlertTitle', '', { root: true })
            commit('general/setAlertMessage', showCatchError(errorParse), { root: true })
            commit('general/setAlertType', 'error', { root: true })
        }
    }
}

export default actions;



