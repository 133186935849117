import { Module } from 'vuex';
import { StateInterface } from '../index';

import state, { ControlBoardsState } from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';


const controlBoardsModule: Module<ControlBoardsState, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default controlBoardsModule;