import { GetterTree } from "vuex";
import { PackagesState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<PackagesState, StateInterface> = {
  getPackageServicesList(state) {
    return state.data.packageServices.length >= 1
      ? state.data.packageServices.map((packageService) => ({
          ...packageService,
          group: packageService.procedure.group,
          sell_price: new Intl.NumberFormat("es-CO", {
            currency: "COP",
            maximumFractionDigits: 2,
          }).format(packageService.sell_price),
        }))
      : [];
  },
  getPackageServicesView(state) {
    return state.data.packageServices.length >= 1
      ? state.data.packageServices.map(
          ({
            soat_code: soatCode,
            observation,
            procedure,
            tax_type,
            sell_price,
            status,
          }) => ({
            code: procedure.code,
            description: procedure.description,
            group: procedure.group?.name,
            sellPrice: new Intl.NumberFormat("es-CO", {
              currency: "COP",
              maximumFractionDigits: 2,
            }).format(sell_price),
            tax: tax_type.name,
            soatCode,
            observation,
            status,
          })
        )
      : [];
  },
};

export default getters;
