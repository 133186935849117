import {
  IRequestResponse,
  IResolutionReport,
} from "@/interfaces/global/interfaces";
import { StateInterface } from "@/store/index";
import {
  ResolutionReportsList,
  ResolutionReportsPages,
  ResolutionReportsRequest,
  ResolutionReportView,
} from "@/store/resolution-reports/state";
import { computed } from "vue";
import { useStore } from "vuex";

export const useResolutionReportsStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    resolutionReports: computed<IResolutionReport[]>(
      () => store.state.resolutionReports.resolutionReports
    ),
    resolutionReportsPages: computed<ResolutionReportsPages>(
      () => store.state.resolutionReports.resolutionReportsPages
    ),
    resolutionReport: computed<IResolutionReport | null>(
      () => store.state.resolutionReports.resolutionReport
    ),
    request: computed<ResolutionReportsRequest>(
      () => store.state.resolutionReports.request
    ),
    response: computed<IRequestResponse>(
      () => store.state.resolutionReports.response
    ),

    // Getters
    getResolutionReportsList: computed<ResolutionReportsList[]>(
      () => store.getters["resolutionReports/getResolutionReportsList"]
    ),
    getResolutionReportView: computed<ResolutionReportView>(
      () => store.getters["resolutionReports/getResolutionReportView"]
    ),

    // Actions
    loadResolutionReports: (careCenter: number, page = 1) =>
      store.dispatch("resolutionReports/loadResolutionReports", {
        careCenter,
        page,
      }),
    loadResolutionReport: (id: number) =>
      store.dispatch("resolutionReports/loadResolutionReport", id),
    cancelResolutionReport: (id: number) =>
      store.dispatch("resolutionReports/cancelResolutionReport", id),
    createResolutionReport: (bodyRequest: {
      reference_center_id: number | null;
      care_center_id: number | null;
      from_date: string;
      to_date: string;
      eps_id: number | null;
      regime_id: number | null;
    }) =>
      store.dispatch("resolutionReports/createResolutionReport", bodyRequest),

    //Mutations
    setResolutionReports: () =>
      store.commit("resolutionReports/setResolutionReports"),
    setResolutionReport: (data?: IResolutionReport) =>
      store.commit("resolutionReports/setResolutionReport", data),
  };
};
