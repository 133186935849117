import { GetterTree } from "vuex";
import { ISurveyAbsenceCustom } from "@/interfaces/customs/ISurveysCustom";
import { StateInterface } from "../index";

const getters: GetterTree<ISurveyAbsenceCustom, StateInterface> = {
  getSurveyInfo(state) {
    return state.surveyInfo;
  },

  getSurveyOptions(state) {
    return state.surveyOptions;
  },
};

export default getters;
