import { IPackage } from "@/interfaces/global/Package";
import { StateInterface } from "@/store/index";
import {
  PackagesServicesList,
  PackagesServicesView,
} from "@/store/packages/state";
import { computed } from "vue";
import { useStore } from "vuex";

export const usePackagesStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    packages: computed(() => store.state.packages.data.packages),
    packagesPages: computed(() => store.state.packages.pages.packages),
    package: computed(() => store.state.packages.data.package),
    packageServices: computed(() => store.state.packages.data.packageServices),
    packageServicesPages: computed(
      () => store.state.packages.pages.packageServices
    ),
    taxes: computed(() => store.state.packages.data.taxes),
    requests: computed(() => store.state.packages.requests),
    data: computed(() => store.state.packages.data),
    pages: computed(() => store.state.packages.pages),
    response: computed(() => store.state.packages.response),

    // Getters
    getPackageServicesList: computed<PackagesServicesList[]>(
      () => store.getters["packages/getPackageServicesList"]
    ),
    getPackageServicesView: computed<PackagesServicesView[]>(
      () => store.getters["packages/getPackageServicesView"]
    ),

    // Actions
    loadPackages: (params: {
      page: number;
      reference_center_id: number | null;
      status: string;
      search?: string;
    }) => store.dispatch("packages/loadPackages", params),
    loadPackage: (id: number) => store.dispatch("packages/loadPackage", id),
    loadPackageServices: (
      id: number,
      params?: {
        paginate?: 0 | 1,
        page?: number;
        status?: string;
        tax_type_id?: number;
        group_id?: number;
        search?: string;
      }
    ) => store.dispatch("packages/loadPackageServices", { id, params }),
    loadTaxes: () => store.dispatch("packages/loadTaxes"),
    createPackage: (bodyRequest: object) =>
      store.dispatch("packages/createPackage", bodyRequest),
    updatePackage: (id: number, bodyRequest: object) =>
      store.dispatch("packages/updatePackage", {
        id,
        bodyRequest,
      }),
    changePackageStatus: (
      id: number,
      bodyRequest = {
        _method: "PATCH",
      }
    ) =>
      store.dispatch("packages/changePackageStatus", {
        id,
        bodyRequest,
      }),
    deletePackage: (id: number) => store.dispatch("packages/deletePackage", id),

    //Mutations
    setPackages: () => store.commit("packages/setPackages"),
    setPackage: (data?: IPackage) => store.commit("packages/setPackage", data),
    setPackageServices: () => store.commit("packages/setPackageServices"),
    setTaxes: () => store.commit("packages/setTaxes"),
  };
};
