import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import {
  IPatient,
  IPersonalInformation,
  IImportedPatients,
  IRequestResponse,
  IImportedPatientsCards,
  IContactInformation,
  ICompanionPatientInformation,
  IPatientResourceSelectors,
} from "@/interfaces/global/interfaces";
import { IPages, IPatientForm } from "@/interfaces/customs/interface";
import {
  ISocialSecurityInformation,
  ICompanionInformation,
} from "../interfaces/global/Patient";
import {
  ImportedPatientsList,
  PatientsData,
  PatientsList,
  PatientsPages,
} from "@/store/patients/state";

export const usePatients = () => {
  const store = useStore<StateInterface>();
  return {
    // State
    patientsList: computed<Array<object>>(
      () => store.state.patients.patientsList
    ),

    patientData: computed<IPatient | null>(
      () => store.state.patients.patientData
    ),

    selectorList: computed<IPatientResourceSelectors>(
      () => store.state.patients.selectorsList
    ),

    _getPatientDetailForm: computed<object | null>(
      () => store.state.patients.patientDetailForm
    ),

    _getPatientPersonalInformation: computed<IPersonalInformation | null>(
      () => store.state.patients.personalInformationForm
    ),

    _getPatientContactInformation: computed<IContactInformation | null>(
      () => store.state.patients.contactInformationForm
    ),

    _getPatientSocialSecurityInformation:
      computed<ISocialSecurityInformation | null>(
        () => store.state.patients.socialSecurityInformation
      ),

    _getCompanionInformation: computed<ICompanionInformation | null>(
      () => store.state.patients.companionInformation
    ),

    _getPatientCompanionInformation:
      computed<ICompanionPatientInformation | null>(
        () => store.state.patients.companionPatientInformation
      ),

    deptoCitiesData: computed<Array<object>>(
      () => store.state.patients.deptoCitiesData
    ),

    patientCurrentPage: computed(
      () => store.state.patients.patientsCurrentPage
    ),

    patientLastPage: computed(() => store.state.patients.patientsLastPage),

    // Patients V.3
    patients: computed<IPatient[]>(() => store.state.patients.data.patients),
    patient: computed<IPatient | null>(() => store.state.patients.data.patient),
    patientsPages: computed<IPages>(() => store.state.patients.pages.patients),
    data: computed<PatientsData>(() => store.state.patients.data),
    pages: computed<PatientsPages>(() => store.state.patients.pages),
    response: computed<IRequestResponse>(() => store.state.patients.response),
    importedPatients: computed<IImportedPatients[]>(
      () => store.state.patients.data.importedPatients
    ),
    importedPatientsPages: computed<IPages>(
      () => store.state.patients.pages.importedPatients
    ),
    importedPatientsCards: computed<IImportedPatientsCards>(
      () => store.state.patients.data.importedPatientsCards
    ),

    // Getters
    // Patients V.3
    getPatientsList: computed<PatientsList[]>(
      () => store.getters["patients/getPatientsList"]
    ),
    getImportedPatientsList: computed<ImportedPatientsList[]>(
      () => store.getters["patients/getImportedPatientsList"]
    ),
    getPatientsEdit: computed<IPatientForm | null>(
      () => store.getters["patients/getPatientsEdit"]
    ),

    // Actions
    getPatients: (token: string, id: number, page: number) =>
      store.dispatch("patients/getPatients", { token, id, page }),

    _getEpsPatients: (token: string) =>
      store.dispatch("patients/getEpsAll", { token }),

    _getDocumentTypesPatients: (token: string) =>
      store.dispatch("patients/getDocumentTypes", { token }),

    _getDepartmentAndCities: (token: string) =>
      store.dispatch("patients/getDepartmentAndCities", { token }),

    _getRegimes: (token: string) =>
      store.dispatch("patients/getRegimes", { token }),

    _getMemberships: (token: string) =>
      store.dispatch("patients/getMemberships", { token }),

    _createPatient: (patientRequest: any) =>
      store.dispatch("patients/createPatient", { patientRequest }),

    _createPatientV2: (patientRequest: any, id: number | string = "") =>
      store.dispatch("patients/createPatientV2", { patientRequest, id }),

    _changeStatusPatient: (token: string, objectRequest: any) =>
      store.dispatch("patients/changePatientStatus", { token, objectRequest }),

    _searchPatientByDocument: (document: string, queryParams: unknown = null) =>
      store.dispatch("patients/searchPatientByDocument", {
        document,
        queryParams,
      }),

    _emptyPatientDataByDocument: () =>
      store.dispatch("patients/emptyDataPatientByDocument"),

    _getArls: (token: string) => store.dispatch("patients/getArls", { token }),

    _getSexes: (token: string) =>
      store.dispatch("patients/getSexes", { token }),

    _getBloodTypes: (token: string) =>
      store.dispatch("patients/getBloodTypes", { token }),

    _getPrepaidWithoutPaginate: () =>
      store.dispatch("patients/getPrepaidWithoutPaginate"),

    // Patients V.3
    loadPatients: (
      careCenter: number,
      params: object
    ) => store.dispatch("patients/loadPatients", { careCenter, params }),
    loadPatient: (id: number) => store.dispatch("patients/loadPatient", id),
    loadPatientByDocument: (
      document: string,
      params?: { care_center_id?: number }
    ) => store.dispatch("patients/loadPatientByDocument", { document, params }),
    createPatients: (formData: {
      file: File;
      reference_center: number;
      care_center: number;
    }) => store.dispatch("patients/createPatients", formData),
    loadImportedPatients: (
      selectors: {
        referenceCenter: number | null;
        careCenter: number | null;
      },
      params: {
        status: string;
        page: number;
      }
    ) =>
      store.dispatch("patients/loadImportedPatients", { ...selectors, params }),
    loadImportedPatientsCards: (selectors: {
      referenceCenter: number | null;
      careCenter: number | null;
    }) =>
      store.dispatch("patients/loadImportedPatientsCards", { ...selectors }),

    _searchConsecutiveByCountry: (code: string | null = null) =>
      store.dispatch("patients/searchConsecutiveByCountry", code),

    // Mutations
    /**Patient Detail */
    setPatientDetails: (patient: any) => {
      store.commit("patients/setPatientDetails", patient);
    },

    // Patients V.3
    setPatients: () => store.commit("patients/setPatients"),
    setImportedPatients: () => store.commit("patients/setImportedPatients"),
    setImportedPatientsCards: () =>
      store.commit("patients/setImportedPatientsCards"),
    setPatient: (data?: IPatient) => store.commit("patients/setPatient", data),

    /**Patient Detail Form */
    _setPatientDetailsForm: (details: object | null) =>
      store.commit("patients/setPatientDetailsForm", details),
    _setPatientPersonalInformation: (details: object | null) =>
      store.commit("patients/setPatientPersonalInformation", details),
    _setPatientContactInformation: (details: object | null) =>
      store.commit("patients/setPatientContactInformation", details),
    _setPatientSocialSecurityInformation: (details: object | null) =>
      store.commit("patients/setPatientSocialSecurityInformation", details),
    _setCompanionInformation: (details: object | null) =>
      store.commit("patients/setCompanionInformation", details),
    _setPatientCompanionInformation: (details: object | null) =>
      store.commit("patients/setPatientCompanionInformation", details),
    _setDocumentNumberPrefix: (document_prefix: number | string) =>
      store.commit("patients/setDocumentNumberPrefix", document_prefix),
    _setDocumentNumber: (document_number: number | string) =>
      store.commit("patients/setDocumentNumber", document_number),
    _setNationality: (nationality: number | string) =>
      store.commit("patients/setNationality", nationality),
  };
};
