import { GetterTree } from "vuex";
import { SpecialtesState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<SpecialtesState, StateInterface> = {
  getSpecialtesName(state) {
    return state.specialteDescription;
  },
  getSpecialtiesByCenter(state) {
    return state.specialtiesByCenter;
  },

  // Specialties V.2
  getSpecialtiesList(state) {
    return state.data.specialties.length >= 1
      ? state.data.specialties.map(({ id, description, status }) => ({
          id,
          description,
          status,
        }))
      : [];
  },
};

export default getters;
