export interface RootObject extends RequestResponse {
  data: Data;
  service: string;
}

export interface RequestResponse {
  message: string;
  success: boolean;
}

export interface Data {
  current_page: number;
  data: Datum[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: Link[];
  next_page_url?: any;
  path: string;
  per_page: number;
  prev_page_url?: any;
  to: number;
  total: number;
}

export interface Link {
  url?: string;
  label: string;
  active: boolean;
}

export interface Datum {
  id: number;
  reference_center_id: number;
  care_center_id: number;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  status: string;
  patients: any[];
  care_center: Center;
  reference_center: Center;
  total_patients: number;
  total_campaigns: number;
  total_campaigns_programed: number;
  total_campaigns_done: number;
  total_campaigns_cancel: number;
}

export interface Center {
  id: number;
  created_at?: any;
  updated_at?: any;
  name: string;
  nit: string;
  address: string;
  phone: string;
  email: string;
  logo?: any;
  contact_name: string;
  contact_phone: string;
  status: string;
  centers_types_id: number;
}

export interface DataList {
  id: Datum["id"];
  referenceCenter: Center["name"];
  careCenter: Center["name"];
  program: Datum["name"];
  description: Datum["description"];
  patients: Datum["total_patients"];
  campaigns: Datum["total_campaigns"];
  status: Datum["status"];
}

export interface CareProgramsState {
  careProgramsList: DataList[] | [];
  careProgramsCurrentPage: number;
  careProgramsLastPage: number;
  careProgram: Datum;
}

export interface CareProgram {
  reference_center: Center["name"];
  reference_center_id: Center["id"];
  care_center: Center["name"];
  care_center_id: Center["id"];
  name: Datum["name"];
  description: Datum["description"];
}

export interface CareProgramView {
  reference_center: Center["name"];
  care_center: Center["name"];
  name: Datum["name"];
  total_patients: Datum["total_patients"];
  total_campaigns: Datum["total_campaigns"];
  total_campaigns_programed: Datum["total_campaigns_programed"];
  total_campaigns_done: Datum["total_campaigns_done"];
}

function state(): CareProgramsState {
  return {
    careProgramsList: [],
    careProgramsCurrentPage: 0,
    careProgramsLastPage: 0,
    careProgram: {
      id: 0,
      reference_center_id: 0,
      care_center_id: 0,
      name: "",
      description: "",
      created_at: "",
      updated_at: "",
      status: "",
      patients: [],
      care_center: {
        id: 0,
        created_at: null,
        updated_at: null,
        name: "",
        nit: "",
        address: "",
        phone: "",
        email: "",
        logo: null,
        contact_name: "",
        contact_phone: "",
        status: "",
        centers_types_id: 0,
      },
      reference_center: {
        id: 0,
        created_at: null,
        updated_at: null,
        name: "",
        nit: "",
        address: "",
        phone: "",
        email: "",
        logo: null,
        contact_name: "",
        contact_phone: "",
        status: "",
        centers_types_id: 0,
      },
      total_patients: 0,
      total_campaigns: 0,
      total_campaigns_programed: 0,
      total_campaigns_done: 0,
      total_campaigns_cancel: 0,
    },
  };
}

export default state;
