import { isAuthenticatedGuard } from '@/router/auth-guard';

export default [
   
        {
            path: '/plantillas-examenes-fisicos',
            name: 'PhysicalExamTemplates',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import('@/views/physical-exam-templates/PhysicalExamTemplates.vue'),
        },

        {
            path: '/plantillas-examenes-fisicos-crear',
            name: 'PhysicalExamTemplatesCreate',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import('@/views/physical-exam-templates/subpages/create/PhysicalExamTemplatesCreate.vue'),
        },

        {
            path: '/plantillas-examenes-fisicos-editar/:id',
            name: 'PhysicalExamTemplatesEdit',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import('@/views/physical-exam-templates/subpages/edit/PhysicalExamTemplatesEdit.vue'),
        },

        {
            path: '/plantillas-examenes-fisicos-ver/:id',
            name: 'PhysicalExamTemplatesView',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import('@/views/physical-exam-templates/subpages/view/PhysicalExamTemplatesView.vue'),
        },
]