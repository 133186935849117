import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";

export const useResources = () => {
  const store = useStore<StateInterface>();
  return {
    // State
    documentTypes: computed(() => store.state.resources.documentTypes),
    classes: computed(() => store.state.resources.classes),
    types: computed(() => store.state.resources.types),
    measurement_units: computed(() => store.state.resources.measurement_units),
    dispensing_units: computed(() => store.state.resources.dispensing_units),
    provision_types: computed(() => store.state.resources.provision_types),
    pharmaceutical_forms: computed(
      () => store.state.resources.pharmaceutical_forms
    ),
    user_registered: computed(() => store.state.resources.user_registered),
    pbs: computed(() => [
      { label: "Si", value: 1 },
      { label: "No", value: 2 },
    ]),
    administration_routes: computed(
      () => store.state.resources.administration_routes
    ),
    tax_types: computed(() => store.state.resources.tax_types),
    manufacturers: computed(() => store.state.resources.manufacturers),
    status: computed(() => [
      { label: "Todos", value: "" },
      { label: "Activo", value: "active" },
      { label: "Inactivo", value: "inactive" },
    ]),
    services: computed(() => [
      { label: "Todos", value: "Todos" },
      { label: "Consultas", value: "Consultas" },
      { label: "Procedimientos", value: "Procedimientos" },
      { label: "Medicamentos", value: "Medicamentos" },
      { label: "Vacunación ", value: "Vacunación " },
    ]),
    medicine_status: computed(() => [
      { label: "Todos", value: "all" },
      { label: "Activo", value: "active" },
      { label: "Inactivo", value: "inactive" },
    ]),
    proceduresV2: computed(() => store.state.resources.proceduresV2),
    subprograms: computed(() => store.state.resources.subprograms),

    // Actions
    _getResources: (
      query: string, // query = vaccines|procedures|diagnostics|medicines|specialties only
      centers: { reference_center: number; care_center: number },
      userType: number
    ) => store.dispatch("resources/getResources", { query, centers, userType }),
    _getDocumentTypesAdministrators: (documentTypes: string) =>
      store.dispatch("resources/getDocumentTypesAdministrators", documentTypes),
    _getResourcesPhysicalExam: () =>
      store.dispatch("resources/getResourcesPhysicalExam"),
    _getResource: (resource: string) =>
      store.dispatch("resources/getResource", resource),
    _getDataGraphicHistoricalHeightWeight: (
      entityId: string | number,
      date_initial: string,
      date_end: string
    ) =>
      store.dispatch("resources/getDataGraphicHistoricalHeightWeight", {
        entityId,
        date_initial,
        date_end,
      }),
    _getMedicineResources: () =>
      store.dispatch("resources/getMedicineResources"),
    _getProceduresV2: (params: object) =>
      store.dispatch("resources/getProceduresV2", params),
    _getSubprograms: (params: object) =>
      store.dispatch("resources/getSubprograms", params),
    _getUserRegisters: () => store.dispatch("resources/getUsersSystem"),
  };
};
