// vue | vuex
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { NotificationsState } from "./state";

// apis
import { notificationsApi } from "@/apis";

// composables
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";
import { useAlertV2 } from "@/composables/useAlertV2";
import errorException from "@/functions/ErrorException";
// interfaces
import { INotification } from "@/interfaces/global/Notification";
import { IErrors } from "@/interfaces/customs/IShowErrors";

const { showCatchError } = showErrorInTS();
const { showAlert } = useAlertV2();

const actions: ActionTree<NotificationsState, StateInterface> = {
  async loadNotifications({ commit }, params) {
    commit("setNotifications");
    commit("setResponse");

    try {
      const { data: response } = await notificationsApi().get("/", { params });
      const { success, message, data } = response;

      if (!params.notification) commit("setNotifications", data);
      else commit("auth/setNotifications", data, { root: true });
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadTypeNotifications({ commit }) {
    commit("setTypeNotifications");
    commit("setResponse");

    try {
      const { data: response } = await notificationsApi().get(
        "/type-notifications"
      );
      const { success, message, data } = response;
      commit("setTypeNotifications", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },

  async LoadBySearchNotifications({ commit }, params) {
    commit("setNotificationLoader", true);
    commit("setSearchNotifications", []);

    try {
      const infoNotifications = await notificationsApi().get("/", {
        params,
      });
      const infoList = infoNotifications.data.data.data as INotification[];
      const currentPage = infoNotifications.data.data.current_page;
      const lastPage = infoNotifications.data.data.last_page;

      const notificationList =
        infoList && infoList.length > 1
          ? infoList.map((item: INotification) => ({
              id: item.id,
              description: item.description,
              type: item.type_notification.name,
              date: item.created_at,
              status: item.is_read,
              url: item.type_notification.url_segment,
              possibleId: item.possible_id,
              typeId: item.type_notification.id,
            }))
          : [];

      commit("setSearchNotifications", notificationList);
      commit("setNotificationPagination", {
        currentPage,
        lastPage,
      });
      commit("setNotificationLoader", false);
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setSearchNotifications", []);

      // alert
      showAlert(String(showCatchError(errorParse)), "error");
      commit("setNotificationLoader", false);
    }
  },

  async readNotification({ commit }, id) {
    commit("setResponse");

    try {
      const { data } = await notificationsApi().post(`/mark-as-read/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
};

export default actions;
