export interface AttendanceUI {

    attendanceCounterCards: {
        quantity: number,
        name: string,
        icon: string
    }[]

    attendanceTable: {
        servicesName: string
        typeService: string
        attendances: number
        unattendances: number
        percentageUnattendances: number

    }[]

    //year options
    attendanceYearSelected: string
    attendanceYearLoaders: boolean
    attendanceYearOptions: string[]

    //attendance options
    serviceTypeSelected: string,

    //patient lists info
    patientListLoaders: boolean
    patientListOptions: []
    agePatients: {
        group: string
        patientsCount: number
        patientsPerc: number
    }[]
    agePatientsMessage: string
    attendancePatients: {
        month: string
        scheduled: number
        attendances: number
        femaleAttendances: number
        maleAttendances: number
        femaleUnattendances: number
        maleUnattendances: number
    }[]
    attendancePatientsMessage: string

    attendancesDistribution: []
    attendancesDistributionMessage: string

    unattendancesGender: []
    unattendancesGenderMessage: string


    // cards and patiends URLS
    attendanceCardURL: string
    attendanceListURL: string
    attendanceYearURL: string
}

function state(): AttendanceUI {
    return {
        attendanceCounterCards: [],
        attendanceTable: [],

        //year options
        attendanceYearSelected: '',
        attendanceYearLoaders: false,
        attendanceYearOptions: [],

        //attendance options
        serviceTypeSelected: '',

        //patient lists info
        patientListLoaders: false,
        patientListOptions: [],
        agePatients: [],
        agePatientsMessage: '',
        attendancePatients: [],
        attendancePatientsMessage: '',
        attendancesDistribution: [],
        attendancesDistributionMessage: '',
        unattendancesGender: [],
        unattendancesGenderMessage: '',

        // cards and patiends URLS
        attendanceCardURL: '',
        attendanceListURL: '',
        attendanceYearURL: ''
    }
}

export default state;