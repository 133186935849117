export default function errorException(error: any) {
  // Error for request cancelled and Error for connection
  if (
    ["REQUEST_ALREADY_IN_PROGRESS", "Network Error"].includes(error.message)
  ) {
    const success = false;
    const { message } = error;
    return { success, message };
  }

  const { data } = error.response;
  const { success, message } = data;

  return { success, message };
}
