import { computed } from "vue";
import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import {
  UnificationMedicalRecord,
  DataList,
  ReasonForConsultation,
} from "@/store/unification-medical-records/state";

export const useUnificationMedicalRecordsStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State

    // Getters
    unificationMedicalRecordsList: computed<DataList[]>(
      () =>
        store.getters[
          "unificationMedicalRecords/getUnificationMedicalRecordsList"
        ]
    ),
    unificationMedicalRecordsCurrentPage: computed<number>(
      () =>
        store.getters[
          "unificationMedicalRecords/getUnificationMedicalRecordsCurrentPage"
        ]
    ),
    unificationMedicalRecordsLastPage: computed<number>(
      () =>
        store.getters[
          "unificationMedicalRecords/getUnificationMedicalRecordsLastPage"
        ]
    ),
    typeRequest: computed<ReasonForConsultation[]>(
      () => store.getters["unificationMedicalRecords/getTypeRequest"]
    ),
    reasonForConsultation: computed<ReasonForConsultation[]>(
      () => store.getters["unificationMedicalRecords/getReasonForConsultation"]
    ),
    unificationMedicalRecord: computed<UnificationMedicalRecord>(
      () =>
        store.getters["unificationMedicalRecords/getUnificationMedicalRecord"]
    ),

    // Actions
    getUnificationMedicalRecords: (
      token: string,
      page: number,
      selects: { referenceCenter: number; careCenter: number; status: string }
    ) =>
      store.dispatch("unificationMedicalRecords/getUnificationMedicalRecords", {
        token,
        page,
        selects,
      }),
    getAllResources: (token: string) =>
      store.dispatch("unificationMedicalRecords/getAllResources", { token }),
    rejectedUnificationMedicalRecord: (
      token: string,
      id: number,
      bodyRequest: { reason: string }
    ) =>
      store.dispatch(
        "unificationMedicalRecords/rejectedUnificationMedicalRecord",
        { token, id, bodyRequest }
      ),
    authorizedUnificationMedicalRecord: (token: string, id: number) =>
      store.dispatch(
        "unificationMedicalRecords/authorizedUnificationMedicalRecord",
        { token, id }
      ),
    createUnificationMedicalRecord: (
      token: string,
      bodyRequest: {
        reference_center_id: string | number;
        care_center_id: string | number;
        type_request_service_id: string | number;
        reason_for_consultation_id: string | number;
        patient_one_id: string | number;
        patient_two_id: string | number;
        observation: string;
        files: [] | FileList;
      }
    ) =>
      store.dispatch(
        "unificationMedicalRecords/createUnificationMedicalRecord",
        { token, bodyRequest }
      ),
    getUnificationMedicalRecordById: (token: string, id: number) =>
      store.dispatch(
        "unificationMedicalRecords/getUnificationMedicalRecordById",
        { token, id }
      ),

    //Mutations
    clearUnificationMedicalRecordsList: () =>
      store.commit(
        "unificationMedicalRecords/clearUnificationMedicalRecordsList"
      ),
  };
};
