import { IPatient, IUrgencyResponse } from "@/interfaces/global/interfaces";
export interface EmergenciesState {
  emergenciesList: object[];
  emergenciesCurrentPage: number;
  emergenciesLastPage: number;
  states: object[];
  destinations: string[];
  urgencyResponse: IUrgencyResponse | null;
  conductValues: {
    external_cause: string;
    consultation_purpose: string;
    treatment_plan: string;
    complications: string;
  } | null;
  backgrounds: object | null;
  diagnostics: object | null;
}
function state(): EmergenciesState {
  return {
    emergenciesList: [],
    emergenciesCurrentPage: 1,
    emergenciesLastPage: 1,
    states: [
      { label: "Todos", value: "" },
      { label: "Activo", value: "Activo" },
      { label: "En atención", value: "En atención" },
    ],
    destinations: [
      "Urgencias",
      "Hospitalización",
      "Consulta externa",
      "Morgue",
    ],
    urgencyResponse: null,
    conductValues: null,
    backgrounds: null,
    diagnostics: null,
  };
}

export default state;
