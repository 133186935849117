import { StateInterface } from "@/store/index";
import { List } from "@/store/logins/state";
import { computed, toRef } from "vue";
import { useStore } from "vuex";

export const useLoginsStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    logins: toRef(store.state.logins.data, "logins"),
    loginsPages: toRef(store.state.logins.pages, "logins"),
    agents: toRef(store.state.logins.data, "agents"),
    report: toRef(store.state.logins.data, "report"),
    data: toRef(store.state.logins, "data"),
    pages: toRef(store.state.logins, "pages"),
    response: toRef(store.state.logins, "response"),

    // Getters
    getLoginsList: computed<List[]>(
      () => store.getters["logins/getLoginsList"]
    ),

    // Actions
    loadLogins: (params: {
      paginate?: number;
      page?: number;
      rows?: number;
      user_id?: number;
      ip?: string;
      agent?: string;
      was_successful?: 1 | 0;
      from_date?: string;
      to_date?: string;
      user_type_id?: number;
      reference_center_id?: number;
      care_center_id?: number;
    }) => store.dispatch("logins/loadLogins", params),
    loadAgents: () => store.dispatch("logins/loadAgents"),
    loadReport: (formData: {
      user_id?: number;
      ip?: string;
      agent?: string;
      was_successful?: 1 | 0;
      from_date?: string;
      to_date?: string;
      user_type_id?: number;
      reference_center_id?: number;
      care_center_id?: number;
    }) => store.dispatch("logins/loadReport", formData),

    //Mutations
    setLogins: () => store.commit("logins/setLogins"),
    setAgents: () => store.commit("logins/setAgents"),
    setReport: () => store.commit("logins/setReport"),
  };
};
