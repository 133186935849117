import {
  IData,
  IHighCostAccounts2463,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { HighCostAccounts2463State } from "./state";

const mutation: MutationTree<HighCostAccounts2463State> = {
  setHighCostAccounts(state: HighCostAccounts2463State, data?: IData): void {
    if (data) {
      state.data.highCostAccounts = data.data as IHighCostAccounts2463[];

      state.pages.highCostAccounts.currentPage.value = data.current_page;
      state.pages.highCostAccounts.lastPage.value = data.last_page;
    } else {
      state.data.highCostAccounts = [];
    }
  },
  setHighCostAccount(state: HighCostAccounts2463State, data?: IHighCostAccounts2463 | null) {
    if (data !== undefined) {
      state.data.highCostAccount = data;
    } else {
      state.data.highCostAccount = null;
    }
  },
  setDiseaseTypes(state: HighCostAccounts2463State, data?: Record<string, string>) {
    if (data !== undefined) {
      state.data.diseaseTypes = data;
    } else {
      state.data.diseaseTypes = null;
    }
  },
  setResponse(state: HighCostAccounts2463State, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
