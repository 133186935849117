export default [
  {
    path: "confirmacion-cita",
    name: "AppointmentConfirmationView",
    component: () =>
      import(
        /* webpackChunkName: "Appointment Confirmation View" */ "@/views/appointment-confirmation/AppointmentConfirmationView.vue"
      ),
    children: [
      {
        path: "asistencia/:id(\\d+)/:type(\\d+)/:token",
        name: "AppointmentConfirmationAttendanceView",
        component: () =>
          import(
            /* webpackChunkName: "Appointment Confirmation Attendance View" */ "@/views/appointment-confirmation/sub-pages/appointment-confirmation-attendance/AppointmentConfirmationAttendanceView.vue"
          ),
      },
      {
        path: "inasistencia/:id(\\d+)/:type(\\d+)/:token",
        name: "AppointmentConfirmationAbsenceView",
        component: () =>
          import(
            /* webpackChunkName: "Appointment Confirmation Absence View" */ "@/views/appointment-confirmation/sub-pages/appointment-confirmation-absence/AppointmentConfirmationAbsenceView.vue"
          ),
      },
      {
        path: "respuesta",
        name: "AppointmentConfirmationResponseView",
        component: () =>
          import(
            /* webpackChunkName: "Appointment Confirmation Response View" */ "@/views/appointment-confirmation/sub-pages/appointment-confirmation-response/AppointmentConfirmationResponseView.vue"
          ),
      },
      {
        path: ":id(\\d+)/:type(\\d+)/:token",
        name: "AppointmentConfirmationRequestView",
        component: () =>
          import(
            /* webpackChunkName: "Appointment Confirmation Response View" */ "@/views/appointment-confirmation/sub-pages/appointment-confirmation-request/AppointmentConfirmationRequestView.vue"
          ),
      },
    ],
  },
];
