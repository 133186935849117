import { isAuthenticatedGuard } from '@/router/auth-guard';

export default [
   
        {
            path: '/prescripcion-de-medicamentos',
            name: 'PrescriptionMedicines',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/prescription-medicines-templates/PrescriptionMedicines.vue'),
        },

        {
            path: '/crear-plantillas-prescripcion-de-medicamentos',
            name: 'PrescriptionMedicinesCreated',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/prescription-medicines-templates/subpages/created/PrescriptionMedicinesCreated.vue'),
        },

        {
            path: '/editar-plantillas-prescripcion-de-medicamentos/:id',
            name: 'PrescriptionMedicinesEdit',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/prescription-medicines-templates/subpages/edit/PrescriptionMedicinesEdit.vue'),
        },
        {
            path: '/ver-plantillas-prescripcion-de-medicamentos/:id',
            name: 'PrescriptionMedicinesView',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/prescription-medicines-templates/subpages/view/PrescriptionMedicinesView.vue'),
        },
]