import { MutationTree } from 'vuex';
import { CdasState } from './state';


const mutation: MutationTree<CdasState> = {
    setTelexpertises(state: CdasState, list: any) {        
        state.teleexpertisesList = list
    }
}


export default mutation;