import apiBase from "@/apis/AxiosIntance";

// Get Work Count
export const getWorkCount = () => {
  apiBase.defaults.baseURL =  `${process.env.VUE_APP_URL}/api/telemedicine/teleexpertise/getWorkCount`;
  return apiBase;
};

// Get Work List
export const getWorkList = () => {
  apiBase.defaults.baseURL =  `${process.env.VUE_APP_URL}/api/telemedicine/teleexpertise/getWorkList`;
  return apiBase;
};


// Get Work List
export const getWorkListV3 = () => {
  apiBase.defaults.baseURL =  `${process.env.VUE_APP_URL}/api/telemedicine/scheduling/v3`;
  return apiBase;
};


// Get Work List
export const cancel = () => {
  apiBase.defaults.baseURL =  `${process.env.VUE_APP_URL}/api/telemedicine/scheduling`;
  return apiBase;
}



