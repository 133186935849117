import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import {
  ProceduresEdit,
  ProceduresList,
} from "@/store/procedures/state";
import { IProcedure } from "@/interfaces/global/interfaces";

export const useProceduresStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    // Procedures V.2
    procedures: computed(() => store.state.procedures.data.procedures),
    proceduresPages: computed(() => store.state.procedures.pages.procedures),
    groups: computed(() => store.state.procedures.data.groups),
    requests: computed(() => store.state.procedures.requests),
    data: computed(() => store.state.procedures.data),
    pages: computed(() => store.state.procedures.pages),
    response: computed(() => store.state.procedures.response),

    // Getters
    procedureCode: computed<string>(
      () => store.getters["procedures/getProcedureCode"]
    ),
    procedureDescription: computed<string>(
      () => store.getters["procedures/getProcedureDescription"]
    ),

    // Procedures V.2
    getProceduresList: computed<ProceduresList[]>(
      () => store.getters["procedures/getProceduresList"]
    ),
    getProceduresEdit: computed<ProceduresEdit | null>(
      () => store.getters["procedures/getProceduresEdit"]
    ),

    // Actions
    changeProcedureStatusById: (token: string, id: number) =>
      store.dispatch("procedures/changeProcedureStatusById", { token, id }),
    // createProcedure: (token: string, bodyRequest: object) =>
    //   store.dispatch("procedures/createProcedure", { token, bodyRequest }),
    getProcedureById: (token: string, id: string | string[]) =>
      store.dispatch("procedures/getProcedureById", { token, id }),
    // updateProcedure: (token: string, bodyRequest: object) =>
    //   store.dispatch("procedures/updateProcedure", {
    //     token,
    //     bodyRequest,
    //   }),
    _loadProceduresWithoutPaginate: (
      filters: object | null = null,
      page = 1,
      urlRequest: string
    ) =>
      store.dispatch("procedures/loadProceduresWithoutPaginate", {
        filters,
        page,
        urlRequest,
      }),
    _loadProceduresWithoutPaginateV2: (params: object = {}) =>
      store.dispatch("procedures/loadProceduresWithoutPaginateV2", params),

    // Procedures V.2
    loadProcedures: (
      params:
        | {
            status: string;
            page: number;
            full_reference?: string;
            care_center_id?: number;
          }
        | {
            care_center_id?: null | number;
            group_id?: number;
            paginate?: number;
            page?: number;
            reference_center_id: null | number;
            rows?: number;
            search?: string;
            status?: string;
          },
      version = 1
    ) => store.dispatch("procedures/loadProcedures", { params, version }),
    loadProcedure: (id: number) => store.dispatch("procedures/loadProcedure", id),
    createProcedure: (bodyRequest: {
      care_center_id?: number | null;
      code: string;
      description: string;
      reference_center_id: number | null;
    }) => store.dispatch("procedures/createProcedure", bodyRequest),
    updateProcedure: (
      id: number,
      bodyRequest: {
        _method: "PATCH";
        code: string;
        description: string;
      }
    ) => store.dispatch("procedures/updateProcedure", { id, bodyRequest }),
    changeProcedureStatus: (id: number) =>
      store.dispatch("procedures/changeProcedureStatus", id),
    deleteProcedure: (id: number) =>
      store.dispatch("procedures/deleteProcedure", id),
    loadGroups: () => store.dispatch("procedures/loadGroups"),

    // Mutations
    // Procedures V.2
    setProcedures: () => store.commit("procedures/setProcedures"),
    setProcedure: (data?: IProcedure) =>
      store.commit("procedures/setProcedure", data),
  };
};
