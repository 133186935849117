import { MutationTree } from "vuex";
import {
  Data,
  DataList,
  Datum,
  ExplanatoryNotes,
  ExplanatoryNotesState,
} from "./state";

const mutation: MutationTree<ExplanatoryNotesState> = {
  getExplanatoryNotes(state: ExplanatoryNotesState, data: Data): void {
    if (data) {
      if (data.data.length >= 1) {
        state.explanatoryNotesList = data.data.map(
          ({
            id,
            care_center,
            patient,
            doctor,
            created_at: date,
            status,
            pdf_url: pdf,
            teleexpertise_id: telexpertise_id,
          }: Datum): DataList => ({
            id,
            careCenter: care_center?.name,
            patient: `${patient?.name} ${patient?.last_name}`,
            document: patient?.document,
            doctor: doctor?.name,
            specialty: doctor?.specialty?.description ?? "Sin especialidad",
            date,
            status,
            pdf,
            telexpertise_id,
          })
        );
      } else state.explanatoryNotesList = [];

      state.explanatoryNotesCurrentPage = data.current_page;
      state.explanatoryNotesLastPage = data.last_page;
    }
  },
  getExplanatoryNoteById(state: ExplanatoryNotesState, data: ExplanatoryNotes) {
    if (data) {
      state.explanatoryNote = data;
    }
  },
  clearExplanatoryNotesList(state: ExplanatoryNotesState) {
    state.explanatoryNotesList = [];
  },
  clearExplanatoryNotesData(state) {
    state.explanatoryNote = {
      id: 0,
      created_at: "",
      updated_at: "",
      created_by: 0,
      patient_id: 0,
      response_by: 0,
      response_at: "",
      key: "",
      pdf_url: "",
      teleexpertise_id: 0,
      care_center_id: 0,
      reference_center_id: 0,
      status: "",
      response: "",
      procedures: [],
      diagnostics: [],
      medicines: [],
      documents: [],
      responser: {
        id: 0,
        email: "",
        email_verified_at: "",
        status: "",
        last_login: "",
        last_change_password: "",
        logout: "",
        expiry_date: null,
        created_at: null,
        updated_at: "",
        user_types_id: 0,
        user_info_id: 0,
        centers_id: 0,
        contract_id: null,
        name: "",
        details: {
          id: 0,
          created_at: null,
          updated_at: null,
          names: "",
          surnames: "",
          document: "",
          phone: "",
          signature: null,
          photo: null,
          professional_reg: null,
          professional_type: null,
          gender: "",
        },
      },
    };
  },
};

export default mutation;
