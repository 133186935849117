import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { IError } from "@/interfaces/customs/interface";
import { ResourcesState } from "@/interfaces/global/interfaces";

const URL_PATH_UTILS = "/api/settings/utils";

const URL_PATH_DOCUMENTTYPE_ADMINISTRATORS = "/api/patients/documentTypes";

const URL_PATH_TELEMEDICINE_UTILS = "/api/telemedicine/teleexpertise/utils";

const URL_PATH_SETTINGS = "/api/settings";

const actions: ActionTree<ResourcesState, StateInterface> = {
  async getResources(
    { commit },
    { query, centers, userType }
  ): Promise<object> {
    try {
      let center = "";

      switch (userType) {
        case 2: //Admin Ref Centers
        case 5: //Medical
        case 99:
          center = `&care_center_id=${centers.care_center}`;
          break;
      }
      const { data } = await executeApi().get(
        query
          ? `${URL_PATH_UTILS}/resources${query}${center}`
          : `${URL_PATH_UTILS}/resources`
      );

      const { success, message } = data;

      //   commit("setResources", data.data);

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getResourcesPhysicalExam(): Promise<object> {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH_UTILS}/physical-exams`
      );
      return data;
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getDocumentTypesAdministrators(
    { commit },
    documentTypes
  ): Promise<object> {
    try {
      let documentTypesSplit = documentTypes.split(",");
      let query = documentTypesSplit
        .map(
          (elemento: string, index: number) =>
            `abbreviations[${index}]=${elemento}`
        )
        .join("&");

      const { data } = await executeApi().get(
        `${URL_PATH_DOCUMENTTYPE_ADMINISTRATORS}?${query}`
      );

      const { success, message } = data;

      commit("setDocumentTypes", data.data);
      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getResource({ commit }, resource): Promise<object> {
    try {
      const { data } = await executeApi().get(`${URL_PATH_UTILS}/${resource}`);

      const { success, message, data: dataList } = data;

      return { success, message, dataList };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getDataGraphicHistoricalHeightWeight(
    { commit },
    { entityId, date_initial, date_end }
  ): Promise<object> {
    try {
      let response: any = null;
      if (!date_initial.trim() && !date_end.trim()) {
        response = await executeApi().get(
          `${URL_PATH_TELEMEDICINE_UTILS}/getHeightWeight/${entityId}`
        );
      } else {
        response = await executeApi().get(
          `${URL_PATH_TELEMEDICINE_UTILS}/getHeightWeight/${entityId}?initial_date=${date_initial}&end_date=${date_end}`
        );
      }

      const { success, message, data: dataRequest } = response.data;

      return { success, message, dataRequest };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getMedicineResources({ commit }): Promise<Object> {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH_SETTINGS}/medicines/resources`
      );
      commit("setMedicineResources", data.data);
      return true;
    } catch (error: any) {
      return errorException(error);
    }
  },
  async getProceduresV2({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH_SETTINGS}/procedures/v2`,
        {
          params,
        }
      );

      commit("setProceduresV2", data.data);

      const { success, message } = data;

      return { success, message };
    } catch (error: IError | any) {
      commit("setProceduresV2", []);
      return errorException(error);
    }
  },
  async getSubprograms({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH_SETTINGS}/subprograms/get/search`,
        {
          params,
        }
      );

      commit("setSubprograms", data.data);

      const { success, message } = data;

      return { success, message };
    } catch (error: IError | any) {
      commit("setProceduresV2", []);
      return errorException(error);
    }
  },
  async getUsersSystem({ commit }): Promise<object> {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH_TELEMEDICINE_UTILS}/getPossibleCreatedTxServices`
      );
      const { success, message } = data;

      commit("setUsersSystem", data.data);

      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },
};

export default actions;
