import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/emergencies",
    name: "EmergenciesList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Emergencies List" */ "@/views/emergencies/list/EmergenciesList.vue"
      ),
  },
  {
    path: "/emergencies/ver-urgencia/:id(\\d+)",
    name: "EmergenciesView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Emergencies View" */ "@/views/emergencies/view/EmergenciesView.vue"
      ),
  },
  {
    path: "/emergencies/crear-urgencia/:id(\\d+)",
    name: "EmergenciesCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Emergencies Create" */ "@/views/emergencies/create/EmergenciesCreate.vue"
      ),
  },
];
