import { IDataTable, ILiquidControlHydricBalance } from "@/interfaces/customs/interface";
export interface LiquidControlState {
  liquidControlList: object[];
  liquidControlCurrentPage: number;
  liquidControlLastPage: number;
  types: Array<object>;
  ways: Array<object>;
  dataTable: IDataTable;
  dataHydricBalance: ILiquidControlHydricBalance | null;
}

function state(): LiquidControlState {
  return {
    liquidControlList: [],
    liquidControlCurrentPage: 1,
    liquidControlLastPage: 1,
    types: [],
    ways: [],
    dataTable: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    dataHydricBalance: null,
  };
}

export default state;
