import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/procedures-report",
    name: "ProceduresReportList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "ProceduresReport List" */ "@/views/procedures-report/list/ProceduresReportList.vue"
      ),
  },
  {
    path: "/procedures-report/ver/:id(\\d+)",
    name: "ProceduresReportView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "ProceduresReport View" */ "@/views/procedures-report/view/ProceduresReportView.vue"
      ),
  },
  {
    path: "/procedures-report/crear",
    name: "ProceduresReportCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "ProceduresReport View" */ "@/views/procedures-report/create/ProceduresReportCreate.vue"
      ),
  },
];