import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { executeApi, medicinesApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { medicinesApiV2 } from "@/apis/medicinesApi";
import { MedicinesState, Response } from "@/interfaces/global/Medicine";
import { IError } from "@/interfaces/customs/ErrorException";

const URL_PATH: { [key: number]: string } = {
  1: "/api/settings/medicines",
  2: "/api/settings/medicines/v2",
  3: "/api/documents/medicines",
};

const actions: ActionTree<MedicinesState, StateInterface> = {
  async changeMedicineStatusById(_, { token, id }): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await medicinesApi().post(
        `/changeStatus/${id}`,
        {},
        config
      );
      const { success, message } = data;
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async LoadMedicineById({ commit }, id): Promise<Response> {
    try {
      const { data } = await medicinesApi().get(`/${id}`);
      const { success, message, data: medicineData } = data;

      commit("setMedicineById", medicineData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  // Medicines V2
  async getMedicinesV2({ commit }, data) {
    const { token, params } = data;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    };

    try {
      const { data } = await medicinesApiV2().get("", config);
      commit("setMedicines", data);
      return data;
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  // Medicines V.3
  async loadMedicines({ commit }, { params, version }) {
    commit("setMedicines");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH[version]}`,
        {
          params,
        }
      );
      const { success, message, data } = response;

      commit("setMedicines", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadMedicine({ commit }, id) {
    commit("setMedicine");
    commit("setResponse");
    try {
      const { data: response } = await executeApi().get(`${URL_PATH[1]}/${id}`);
      const { success, message, data } = response;
      commit("setMedicine", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  // Create medicine v4:
  async createMedicine({ commit }, bodyRequest) {
    commit("setResponse");
    try {
      const { data } = await executeApi().post(`${URL_PATH[2]}`, bodyRequest);
      const { success, message } = data;
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async changeMedicineStatus({ commit }, id) {
    commit("setMedicines");
    commit("setResponse");
    try {
      const { data } = await executeApi().post(
        `${URL_PATH[1]}/changeStatus/${id}`
      );
      const { success, message } = data;
      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async deleteMedicine({ commit }, id) {
    commit("setMedicines");
    commit("setResponse");
    try {
      const { data } = await executeApi().delete(`${URL_PATH[1]}/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async medicinesListWithoutPaginate({ commit }, params) {
    try {
      const { data: response } = await medicinesApi().get("/withoutPaginate");
      const { success, message, data } = response;
      commit("setListMedicinesSelector", data);
      return { success, message, data };
    } catch (error) {
      return errorException(error);
    }
  },
  // Medicine V.4:
  async getMedicineFilterByParams({ commit }, params) {
    commit("setResponse");
    try {
      const { data } = await executeApi().get(`${URL_PATH[1]}/codes-filter`, {
        params: params,
      });
      commit("setFilteredMedicineData", data.data);
      return true;
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
      return false;
    }
  },
  async getMedicineById({ commit }, id) {
    try {
      const { data } = await executeApi().get(`${URL_PATH[1]}/${id}`);
      commit("setMedicineDataById", data.data);
      return true;
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
      return false;
    }
  },
  async updateMedicineV4(_, formData): Promise<object> {
    const bodyRequest = {
      _method: formData?.method,
      ...formData.bodyRequest,
    };
    try {
      const { data } = await executeApi().post(
        `${URL_PATH[2]}/${formData.id}`,
        bodyRequest
      );
      const { success, message, data: dataRequest } = data;
      return { success, message, dataRequest };
    } catch (error: IError | unknown) {
      return errorException(error);
    }
  },
  // Medicine V.5:
  async listMedicinesV5({ commit }, params): Promise<object> {
    commit("setMedicines");
    try {
      const { data } = await executeApi().get(`${URL_PATH[2]}`, {
        params: params,
      });
      commit("setMedicines", data.data);
      const { success, message } = data;
      return { success, message };
    } catch (error) {
      commit("setMedicines");
      return errorException(error);
    }
  },
  // Export medicine V1:
  async exportMedicine(_, params): Promise<object> {
    try {
      const { data } = await executeApi().post(`${URL_PATH[3]}/export`, params);
      const { success, message, data: dataRequest } = data;
      return { success, message, dataRequest };
    } catch (error) {
      return errorException(error);
    }
  },
  // Delete medicine V2:
  async deleteMedicineV2(_, id): Promise<object> {
    try {
      const { data } = await executeApi().delete(`${URL_PATH[1]}/${id}`);
      const { success, message } = data;
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  // Change medicine status V2:
  async changeMedicineStatusV2(_, id): Promise<object> {
    const formData = {
      _method: "PATCH",
    };
    try {
      const { data } = await executeApi().post(
        `${URL_PATH[1]}/${id}/status`,
        formData
      );
      const { success, message } = data;
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
};

export default actions;
