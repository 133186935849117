import { GetterTree } from "vuex";
import { LoginsState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<LoginsState, StateInterface> = {
  getLoginsList(state) {
    return state.data.logins.length >= 1
      ? state.data.logins.map(
          ({
            id,
            reference_center,
            care_center,
            user,
            created_at_formatted: date,
            status,
            failure_reason: reason,
            user_agent: detail,
            ip_address: ip,
          }) => ({
            id,
            referenceCenter: reference_center?.name,
            careCenter: care_center?.name,
            userType: user.user_type,
            userName: user.name,
            date,
            status,
            reason,
            detail,
            ip,
          })
        )
      : [];
  },
};

export default getters;
