import { computed } from "vue";
import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { Campaign, DataList, Patients } from "@/store/campaigns/state";

export const useCampaignsStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State

    // Getters
    campaignsList: computed<DataList[]>(
      () => store.getters["campaigns/getCampaignsList"]
    ),
    campaignsCurrentPage: computed<number>(
      () => store.getters["campaigns/getCampaignsCurrentPage"]
    ),
    campaignsLastPage: computed<number>(
      () => store.getters["campaigns/getCampaignsLastPage"]
    ),
    campaign: computed<Campaign>(
      () => store.getters["campaigns/getCampaignById"]
    ),
    patientsList: computed<Patients[]>(
      () => store.getters["campaigns/getPatientsList"]
    ),
    patientsCurrentPage: computed<number>(
      () => store.getters["campaigns/getPatientsCurrentPage"]
    ),
    patientsLastPage: computed<number>(
      () => store.getters["campaigns/getPatientsLastPage"]
    ),

    // Actions
    getCampaigns: (token: string, page: number, id: number) =>
      store.dispatch("campaigns/getCampaigns", {
        token,
        page,
        id,
      }),
    cancelCampaignById: (token: string, id: number) =>
      store.dispatch("campaigns/cancelCampaignById", { token, id }),
    createCampaign: (
      token: string,
      bodyRequest: {
        name: string;
        date: string;
        is_sms: boolean;
        is_email: boolean;
        sms_message: string;
        email_subject: string;
        email_message: string;
      }
    ) => store.dispatch("campaigns/createCampaign", { token, bodyRequest }),
    getCampaignById: (token: string, id: number) =>
      store.dispatch("campaigns/getCampaignById", { token, id }),
    updateCampaign: (
      token: string,
      id: number,
      bodyRequest: {
        name: string;
        date: string;
        is_sms: boolean;
        is_email: boolean;
        sms_message: string;
        email_subject: string;
        email_message: string;
      }
    ) =>
      store.dispatch("campaigns/updateCampaign", {
        token,
        id,
        bodyRequest,
      }),
    getPatients: (token: string, page: number, id: number) =>
      store.dispatch("campaigns/getPatients", {
        token,
        page,
        id,
      }),

    //Mutations
  };
};
