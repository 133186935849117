import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/procedimientos",
    name: "ProceduresList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Procedures List" */ "@/views/procedures/ProceduresList.vue"
      ),
  },
  {
    path: "/procedimientos/crear-procedimientos",
    name: "ProceduresCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Procedures Create" */ "@/views/procedures/sub-pages/procedures-create/ProceduresCreate.vue"
      ),
  },
  {
    path: "/procedimientos/editar-procedimientos/:id(\\d+)",
    name: "ProceduresEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Procedures Edit" */ "@/views/procedures/sub-pages/procedures-edit/ProceduresEdit.vue"
      ),
  },
];
