import { IPages } from "@/interfaces/customs/Pages";
import {
  IReferenceCenter,
  IRequestResponse,
  ISpecialty,
} from "@/interfaces/global/interfaces";

type SpecialtiesRequests = Record<"specialty", boolean>;
type SpecialtiesPages = Record<"specialties", IPages>;

export interface SpecialtiesList {
  id: ISpecialty["id"];
  referenceCenter: IReferenceCenter["name"] | null;
  description: ISpecialty["description"];
  status: ISpecialty["status"];
  isDefault: ISpecialty["default"];
}

export interface SpecialtiesEdit {
  referenceCenter: ISpecialty["reference_center_id"];
  description: ISpecialty["description"];
}

interface SpecialtiesData {
  specialties: ISpecialty[];
  specialty: ISpecialty | null;
}

export interface SpecialtiesState {
  requests: SpecialtiesRequests;
  data: SpecialtiesData;
  pages: SpecialtiesPages;
  response: IRequestResponse;
}

function state(): SpecialtiesState {
  return {
    requests: {
      specialty: false,
    },
    data: {
      specialties: [],
      specialty: null,
    },
    pages: {
      specialties: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
