import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";

export const useProceduresReport = () => {
  const store = useStore<StateInterface>();
  return {
    // States
    proceduresReportList: computed(
      () => store.state.proceduresReport.proceduresReportList
    ),
    procedureReport: computed(
      () => store.state.proceduresReport.procedureReport
    ),
    proceduresReportListCurrentPage: computed(
      () => store.state.proceduresReport.proceduresReportCurrentPage
    ),
    proceduresReportListLastPage: computed(
      () => store.state.proceduresReport.proceduresReportLastPage
    ),

    // Actions
    _getListProceduresReport: (params: object) =>
      store.dispatch("proceduresReport/getListProceduresReport", params),
    _createProceduresReport: (bodyRequest: object = {}) =>
      store.dispatch("proceduresReport/createProceduresReport", {
        bodyRequest,
      }),
    _cancelProceduresReport: (idRow: number) =>
      store.dispatch("proceduresReport/cancelProceduresReport", {
        idRow,
      }),
    _getByIdProceduresReport: (idRow: number) =>
      store.dispatch("proceduresReport/getByIdProceduresReport", {
        idRow,
      }),
    _getListSelector: (params: object | null, urlRequest: string) =>
      store.dispatch("proceduresReport/getListSelector", {
        params,
        urlRequest,
      }),

    // Mutation
    _setProcedureReport: (details: object | null = null) =>
      store.commit("proceduresReport/setProcedureReport", details),
  };
};
