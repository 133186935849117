import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/centros-atencion",
    name: "AtentionCentersList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Atention Centers List" */ "@/views/atention-centers-list/AtentionCentersListView.vue"
      ),
  },
  {
    path: "/centros-atencion/editar-centros-atencion/:id(\\d+)",
    name: "AtentionCentersEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Atention Centers Edit" */ "@/views/atention-centers-edit/AtentionCentersEditView.vue"
      ),
  },
  {
    path: "/centros-atencion/crear-centros-atencion",
    name: "AtentionCentersCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Atention Centers Create" */ "@/views/atention-centers-create/AtentionCentersCreateView.vue"
      ),
  },
  {
    path: "/centros-atencion/ver-centros-atencion/:id(\\d+)",
    name: "AtentionCentersView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Atention Centers Edit" */ "@/views/atention-centers-view/AtentionCentersView.vue"
      ),
  },
];
