import { computed } from "vue";
import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { ExplanatoryNote, DataList } from "@/store/explanatory-notes/state";

export const useExplanatoryNotesStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State

    // Getters
    explanatoryNotesList: computed<DataList[]>(
      () => store.getters["explanatoryNotes/getExplanatoryNotesList"]
    ),
    explanatoryNotesCurrentPage: computed<number>(
      () => store.getters["explanatoryNotes/getExplanatoryNotesCurrentPage"]
    ),
    explanatoryNotesLastPage: computed<number>(
      () => store.getters["explanatoryNotes/getExplanatoryNotesLastPage"]
    ),
    explanatoryNote: computed<ExplanatoryNote>(
      () => store.getters["explanatoryNotes/getExplanatoryNote"]
    ),

    // Actions
    getExplanatoryNotes: (
      token: string,
      page: number,
      selects: { referenceCenter: number; careCenter: number; status: string }
    ) =>
      store.dispatch("explanatoryNotes/getExplanatoryNotes", {
        token,
        page,
        selects,
      }),
    cancelExplanatoryNote: (token: string, id: number) =>
      store.dispatch("explanatoryNotes/cancelExplanatoryNote", {
        token,
        id,
      }),
    createExplanatoryNote: (
      token: string,
      bodyRequest: {
        data?:
          | {
              explanatory_note: {
                teleexpertise_id?: null | number;
                id?: number;
                response?: string;
              };
              diagnostics?: {
                diagnostic_id: number;
              }[];
              medicines?: {
                medicine_id: number;
                amount: number;
                posology: number;
              }[];
              procedures?: {
                procedure_id: number;
                ammount: number;
                observation: string;
              }[];
            }
          | string;
        documents?: any;
      }
    ) =>
      store.dispatch("explanatoryNotes/createExplanatoryNote", {
        token,
        bodyRequest,
      }),
    getExplanatoryNoteById: (token: string, id: number) =>
      store.dispatch("explanatoryNotes/getExplanatoryNoteById", { token, id }),

    //Mutations
    clearExplanatoryNotesList: () =>
      store.commit("explanatoryNotes/clearExplanatoryNotesList"),
  };
};
