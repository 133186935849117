import {
  IData,
  IGroup,
  IProcedure,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { Datum, ProceduresState } from "./state";

const mutation: MutationTree<ProceduresState> = {
  getProcedureById(state: ProceduresState, procedure: Datum): void {
    if (procedure) {
      const { code, description }: Datum = procedure;

      state.procedureCode = code;
      state.procedureDescription = description;
    }
  },
  clearProceduresData(state: ProceduresState): void {
    state.procedureCode = "";
    state.procedureDescription = "";
  },

  // Procedures V.2
  setProcedures(state: ProceduresState, data?: IData<IProcedure[]> | IProcedure[]): void {
    if (data) {
      if (Array.isArray(data)) {
        state.data.procedures = data;
      } else {
        state.data.procedures = data.data;

        state.pages.procedures.currentPage.value = data.current_page;
        state.pages.procedures.lastPage.value = data.last_page;
      }
    } else {
      state.data.procedures = [];
    }
  },
  setProcedure(state: ProceduresState, data?: IProcedure | null) {
    if (data !== undefined) {
      state.data.procedure = data;

      state.requests.procedure = true;
    } else {
      state.data.procedure = null;

      state.requests.procedure = false;
    }
  },
  setGroups(state: ProceduresState, data?: IGroup[]): void {
    if (data) {
      state.data.groups = data;
    } else {
      state.data.groups = [];
    }
  },
  setResponse(state: ProceduresState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
