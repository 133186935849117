import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/numeracion",
    name: "NumerationsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Numerations List" */ "@/views/numerations/NumerationsList.vue"
      ),
  },
  {
    path: "/numeracion/crear-numeracion",
    name: "NumerationsCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Numerations Create" */ "@/views/numerations/sub-pages/numerations-create/NumerationsCreate.vue"
      ),
  },
  {
    path: "/numeracion/editar-numeracion/:id(\\d+)",
    name: "NumerationsEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Numerations Edit" */ "@/views/numerations/sub-pages/numerations-edit/NumerationsEdit.vue"
      ),
  },
  {
    path: "/numeracion/ver-numeracion/:id(\\d+)",
    name: "NumerationsView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Numerations View" */ "@/views/numerations/sub-pages/numerations-view/NumerationsView.vue"
      ),
  },
];
