import { GetterTree } from 'vuex';
import { AttendanceUI } from './state';
import { StateInterface } from '../../index';

const getters: GetterTree<AttendanceUI, StateInterface> = {
    //info cards 
    getAttendanceCounterCards(state) {
        return state.attendanceCounterCards
    },

    getAttendanceTable(state) {
        return state.attendanceTable
    },

    // cards and patiends URLS
    getAttendanceCardURL(state) {
        return state.attendanceCardURL
    },
    getAttendanceListURL(state) {
        return state.attendanceListURL
    },

    getAttendanceYearURL(state) {
        return state.attendanceYearURL
    },

    //patient lists info
    getPatientListLoaders(state) {
        return state.patientListLoaders
    },
    getPatientListOptions(state) {
        return state.patientListOptions
    },

    getAgePatients(state) {
        return state.agePatients
    },

    getAgePatientsMessage(state) {
        return state.agePatientsMessage
    },

    getAttendancePatients(state) {
        return state.attendancePatients
    },

    getAttendancePatientsMessage(state) {
        return state.attendancePatientsMessage
    },

    getAttendancesDistribution(state) {
        return state.attendancesDistribution
    },

    getAttendancesDistributionMessage(state,) {
        return state.attendancesDistributionMessage
    },


    getUnattendancesGender(state) {
        return state.unattendancesGender
    },

    getUnattendancesGenderMessage(state) {
        return state.unattendancesGenderMessage
    },

    // year options
    getAttendanceYearSelected(state) {
        return state.attendanceYearSelected
    },


    getAttendanceYearLoaders(state) {
        return state.attendanceYearLoaders
    },

    getAttendanceYearOptions(state) {
        return state.attendanceYearOptions
    },
}
export default getters;