const dayFullName = (dayNumber: number) => {
  switch (dayNumber) {
    case 0:
      return "Domingo";
    case 1:
      return "Lunes";
    case 2:
      return "Martes";
    case 3:
      return "Miércoles";
    case 4:
      return "Jueves";
    case 5:
      return "Viernes";
    case 6:
      return "Sábado";
    default:
      return "Lunes";
  }
};

const monthFullName = (monthNumber: number) => {
  switch (monthNumber) {
    case 0:
      return "Enero";
    case 1:
      return "Febrero";
    case 2:
      return "Marzo";
    case 3:
      return "Abril";
    case 4:
      return "Mayo";
    case 5:
      return "Junio";
    case 6:
      return "Julio";
    case 7:
      return "Agosto";
    case 8:
      return "Septiembre";
    case 9:
      return "Octubre";
    case 10:
      return "Noviembre";
    case 11:
      return "Diciembre";
    default:
      return "Enero";
  }
};

export const useFormatDate = () => {
  const dayNameOfWeek = (dateString: string) => {
    const now = new Date(dateString);
    const dayNumber = now.getDay();
    return dayFullName(dayNumber);
  };

  const fullDateComplete = (dateString: string) => {
    const now = new Date(dateString);
    const dayNumber = now.getDate();
    const monthNumber = now.getMonth();
    const yearNumber = now.getFullYear();
    const dayName = now.getDay();
    return `el día ${dayFullName(dayName)} ${dayNumber} de ${monthFullName(
      monthNumber
    )} de ${yearNumber}`;
  };

  const currentDate = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1;
    const currentDay = now.getDate();
    return { currentYear, currentMonth, currentDay };
  };

  const monthNameOfYear = (dateString: number) => {
    return monthFullName(dateString);
  };

  const formatIndividualYYYYMMDD = (newDate: string) => {
    const selectDate = new Date(newDate);
    const selectYear = selectDate.getFullYear();
    const selectMonth = selectDate.getMonth() + 1;
    const selectDay = selectDate.getDate();
    return { selectYear, selectMonth, selectDay };
  };

  const todayIs = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = String(now.getMonth() + 1).padStart(2, "0");
    const currentDay = String(now.getDate()).padStart(2, "0");
    const fullDate = `${currentYear}-${currentMonth}-${currentDay}`;
    return fullDate;
  };

  const dateIsMinor = (dateYYYYMMDD: string) => {
    const [year, month, day] = dateYYYYMMDD.split("-");
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    const now = new Date();
    date.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);
    return date >= now;
  };

  const formatHyphenDDMMYYYY = (dateTo: string) => {
    if (!dateTo) return;
    const [year, month, day] = dateTo.split("/");
    const formattedDate = new Date(`${month}/${day}/${year}`);
    const dayFormatted = formattedDate.getDate().toString().padStart(2, "0");
    const monthFormatted = (formattedDate.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const yearFormatted = formattedDate.getFullYear();
    const formatDate = `${dayFormatted}-${monthFormatted}-${yearFormatted}`;
    return formatDate;
  };

  const formatSlashYYYYMMDD = (date: string): string | undefined => {
    if (!date) return;
    const [day, month, year] = date.split("-");
    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  };

  const formaISOtoYYYYMMDDhhmm = (date: string) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const hours = ("0" + dateObj.getHours()).slice(-2);
    const minutes = ("0" + dateObj.getMinutes()).slice(-2);
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

    return formattedDate;
  };

  const formatToSecondsYYYYMMDDHHMM = (date: string) => {
    const [yearMonthDay, hoursMinutes] = date.split(" ");
    const [year, month, day] = yearMonthDay.split("-");
    const [hours, minutes] = hoursMinutes.split(":");

    const formattedDate = new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(hours),
      Number(minutes)
    );

    const seconds = formattedDate.getTime() / 1000;

    return seconds;
  };

  const currentDateYYYYMMDDHHMM = (timeMinDelay: number = 0) => {
    const padZero = (num: number) => (num < 10 ? `0${num}` : num);

    const now = new Date();
    now.setMinutes(now.getMinutes() + timeMinDelay);

    const year = now.getFullYear();
    const month = padZero(now.getMonth() + 1);
    const day = padZero(now.getDate());
    const hours = padZero(now.getHours());
    const minutes = padZero(now.getMinutes());

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  return {
    dayNameOfWeek,
    monthNameOfYear,
    currentDate,
    fullDateComplete,
    todayIs,
    dateIsMinor,
    formatHyphenDDMMYYYY,
    formatSlashYYYYMMDD,
    formatToSecondsYYYYMMDDHHMM,
    currentDateYYYYMMDDHHMM,
    formatIndividualYYYYMMDD,
    formaISOtoYYYYMMDDhhmm
  };
};
