import { IDataTable } from "@/interfaces/customs/interface";
import { IUrgencyResponse } from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { EmergenciesState } from "./state";

const mutation: MutationTree<EmergenciesState> = {
  setEmergencies(state: EmergenciesState, list: IDataTable) {
    state.emergenciesList = list?.data;

    state.emergenciesCurrentPage = list?.current_page;
    state.emergenciesLastPage = list?.last_page;
  },
  setEmergencieById(state: EmergenciesState, data: IUrgencyResponse) {
    state.urgencyResponse = data;
  },
  setDiagnostics(state: EmergenciesState, diagnostics: object) {
    state.diagnostics = diagnostics;
  },
  setBackgrounds(state: EmergenciesState, backgrounds: object) {
    state.backgrounds = backgrounds;
  },
  setDataConduct(
    state: EmergenciesState,
    data: {
      external_cause: string;
      consultation_purpose: string;
      treatment_plan: string;
      complications: string;
    } | null
  ) {
    state.conductValues = data;
  },
};

export default mutation;
