import { GetterTree } from "vuex";
import { SpecialtiesState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<SpecialtiesState, StateInterface> = {
  getSpecialtiesList(state) {
    return state.data.specialties.length >= 1
      ? state.data.specialties.map(
          ({
            id,
            reference_center,
            description,
            status,
            default: isDefault,
          }) => ({
            id,
            referenceCenter: reference_center?.name,
            description,
            status,
            isDefault,
          })
        )
      : [];
  },
  getSpecialtiesEdit(state) {
    if (state.data.specialty) {
      const {
        reference_center_id: referenceCenter,
        description,
      } = state.data.specialty;

      return {
        referenceCenter,
        description,
      };
    }

    return null;
  },
};

export default getters;
