import { Module } from 'vuex';
import { StateInterface } from '../index';

import actions from './actions';


const digitalSignatureModule: Module<any, StateInterface> = {
    namespaced: true,
    actions
}


export default digitalSignatureModule;