import { GetterTree } from "vuex";
import { ResolutionReportsState } from "./state";
import { StateInterface } from "../index";
import moment from "moment";

const getters: GetterTree<ResolutionReportsState, StateInterface> = {
  getResolutionReportsList(state) {
    return state.resolutionReports.length >= 1
      ? state.resolutionReports.map(
          ({
            id,
            from_date,
            to_date,
            created_at,
            user,
            reference_center,
            care_center,
            status,
            url: document,
          }) => ({
            id,
            fromDate: moment(from_date).format("YYYY-MM-DD"),
            toDate: moment(to_date).format("YYYY-MM-DD"),
            createdDate: moment(created_at).format("YYYY-MM-DD HH:mm"),
            requestedBy: user?.name,
            referenceCenter: reference_center?.name,
            careCenter: care_center?.name,
            status,
            document,
          })
        )
      : [];
  },
  getResolutionReportView(state) {
    if (state.resolutionReport) {
      const {
        from_date,
        to_date,
        created_at,
        eps,
        reference_center,
        care_center,
        regime,
        user,
        status,
        url: document,
      } = state.resolutionReport;

      return {
        fromDate: moment(from_date).format("YYYY-MM-DD"),
        toDate: moment(to_date).format("YYYY-MM-DD"),
        createdDate: moment(created_at).format("YYYY-MM-DD HH:mm"),
        eps: eps?.name,
        referenceCenter: reference_center?.name,
        careCenter: care_center?.name,
        regime: regime?.name,
        requestedBy: user?.name,
        status,
        document,
      };
    }

    return null;
  },
};

export default getters;
