import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/niveles",
    name: "LevelsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Levels List" */ "@/views/levels/LevelsList.vue"
      ),
  },
  {
    path: "/niveles/crear-niveles",
    name: "LevelsCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Levels Create" */ "@/views/levels/sub-pages/level-create/LevelCreate.vue"
      ),
  },
  {
    path: "/niveles/editar-niveles/:id(\\d+)",
    name: "LevelsEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Levels Edit" */ "@/views/levels/sub-pages/level-edit/LevelEdit.vue"
      ),
  },
];
