import { foliosApi, foliosApiV1 } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { FoliosState } from "./state";

const actions: ActionTree<FoliosState, StateInterface> = {
  // Folios V.2
  // Change endpoint
  async loadFolio({ commit }, params): Promise<void> {
    commit("setResponse");
    commit("setFolio");

    try {
      const { data: response } = await foliosApiV1().get(`/getData`, {
        params,
      });

      const { success, message, data } = response;

      commit("setFolio", data);
      commit("setVitalSigns", data.vital_signs);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadVitalSigns({ commit }, params): Promise<void> {
    commit("setResponse");
    commit("setVitalSigns");

    try {
      const { data: response } = await foliosApiV1().get(`/getVitalSigns`, {
        params,
      });

      const { success, message, data } = response;

      commit("setVitalSigns", data[0]);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadPhysicalExam({ commit }, params): Promise<void> {
    commit("setResponse");
    commit("setPhysicalExam");

    try {
      const { data: response } = await foliosApiV1().get(`/getExamination`, {
        params,
      });
      const { success, message, data } = response;

      commit("setPhysicalExam", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadDiagnostics({ commit }, params): Promise<void> {
    commit("setResponse");
    commit("setDiagnostics");

    try {
      const { data: response } = await foliosApiV1().get(`/getDiagnostics`, {
        params,
      });
      const { success, message, data } = response;

      commit("setDiagnostics", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadBackgrounds({ commit }, params): Promise<void> {
    commit("setResponse");
    commit("setBackgrounds");

    try {
      const { data: response } = await foliosApiV1().get(`/getBackgrounds`, {
        params,
      });
      const { success, message, data } = response;

      commit("setBackgrounds", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadProcedures({ commit }, params): Promise<void> {
    commit("setResponse");
    commit("setProcedures");

    try {
      const { data: response } = await foliosApiV1().get(`/getProcedures`, {
        params,
      });
      const { success, message, data } = response;

      commit("setProcedures", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadManagementPlans({ commit }, params): Promise<void> {
    commit("setResponse");
    commit("setManagementPlans");

    try {
      const { data: response } = await foliosApiV1().get(`/getWorkingPlan`, {
        params,
      });
      const { success, message, data } = response;

      commit("setManagementPlans", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadDocuments({ commit }, params): Promise<void> {
    commit("setResponse");
    commit("setDocuments");

    try {
      const { data: response } = await foliosApiV1().get(`/getDocuments`, {
        params,
      });
      const { success, message, data } = response;

      commit("setDocuments", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
};

export default actions;
