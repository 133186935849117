import { IPages } from "@/interfaces/customs/Pages";
import {
  IAdvanceDirective,
  IDirective,
  IPatient,
  IRequestResponse,
} from "@/interfaces/global/interfaces";

export type AdvanceDirectivesPages = Record<"advanceDirectives", IPages>;

export interface AdvanceDirectivesList {
  id: IAdvanceDirective["id"];
  date: IAdvanceDirective["created_at_formatted"];
  name: IPatient["name"];
  lastName: IPatient["last_name"];
  document: IPatient["document"];
  status: IAdvanceDirective["status"];
  url: IAdvanceDirective["pdf_url"];
  fullName: IPatient["full_name"];
}

export interface AdvanceDirectivesData {
  advanceDirectives: IAdvanceDirective[];
  advanceDirective: IAdvanceDirective | null;
  directives: IDirective[];
}

export interface AdvanceDirectivesState {
  data: AdvanceDirectivesData;
  pages: AdvanceDirectivesPages;
  response: IRequestResponse;
}

function state(): AdvanceDirectivesState {
  return {
    data: {
      advanceDirectives: [],
      advanceDirective: null,
      directives: [],
    },
    pages: {
      advanceDirectives: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
