import { Module } from 'vuex';
import { StateInterface } from '../../index';

import state, { AttendanceUI } from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const attendanceModule: Module<AttendanceUI, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state, 
}

export default attendanceModule;