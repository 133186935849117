import { MutationTree } from 'vuex';
import { ControlBoardsState } from './state';


const mutation: MutationTree<any> = {
    setDataControlBoards(state: any, { data, path }) {
        if(path == null){
            state.patients = null;
            state.telexpertices = null;
            state.diagnostics = null;
            state.specialities = null;
            state.telemedicines = null;
            state.schedules = null;
        }else{
            state[path] = data;
        }
    }
}


export default mutation;