import { useStore } from "vuex";
import { StateInterface } from '@/store/index';
import { computed } from "vue";

export const useCenters = () => {

    const store = useStore<StateInterface>();
    return {
        // State
        careCenters: computed( () => store.state.centers.care_centers ),
        referedCenters: computed( () => store.state.centers.referenced_centers ),
        // Getters
        isLoginUser: computed<boolean>( () => store.getters['auth/isLoginUser'] ),
        // Actions
        isGetCenters: (info:any) => store.dispatch('centers/getCenters', info),
        isGetCentersById: (info:any) => store.dispatch('centers/getCentersById', info),
        isGetCareCentersByReferenced: (info:any) => store.dispatch('centers/getCareCentersByReferenced', info),
        //Mutations
    }

}