import { ActionTree } from "vuex";
import { LevelsState } from "./state";
import { StateInterface } from "../index";
import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";

const URL_PATH_LEVEL = "/api/settings/regime-level";
const URL_PATH_LEVEL_EXPORT = "/api/documents/regime-level";

const actions: ActionTree<LevelsState, StateInterface> = {
  async loadLevels({ commit }, params) {
    commit("setLevels");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH_LEVEL}`, {
        params,
      });
      const { success, message, data } = response;

      commit("setLevels", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadLevel({ commit }, id) {
    commit("setLevel");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH_LEVEL}/${id}`
      );

      const { success, message, data } = response;

      commit("setLevel", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createLevel({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH_LEVEL}`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error: any) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async updateLevel({ commit }, { id, bodyRequest }) {
    commit("setResponse");

    try {
      const { data } = await executeApi().put(
        `${URL_PATH_LEVEL}/${id}`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async deleteLevel({ commit }, id) {
    commit("setLevels");
    commit("setResponse");

    try {
      const { data } = await executeApi().delete(`${URL_PATH_LEVEL}/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async changeLevelStatus({ commit }, id) {
    commit("setLevels");
    commit("setResponse");

    try {
      const { data } = await executeApi().patch(
        `${URL_PATH_LEVEL}/${id}/toggle-status`
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async exportListLevels({ commit }, params) {
    commit("setURLExcel");
    commit("setResponse");
    try {
      const { data: response } = await executeApi().post(
        `${URL_PATH_LEVEL_EXPORT}/export?reference_center_id=${params.reference_center_id}&status=${params.status}`
      );
      const { success, message, data } = response;

      commit("setURLExcel", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
};

export default actions;
