export const showErrorInTS = () => {
    function showCatchError({ message, response }: {
        
        message: string | undefined, response: {
            data: {
                message: string | undefined

            } | undefined
        } | undefined
    }) {
        if (response) { return response.data?.message ? response.data?.message : '¡Error sin definir!' }
        else { return message }
    }

    return {
        showCatchError
    }
}