import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed, ComputedRef } from "vue";
import { IRequestResponse, ISpecialty } from "@/interfaces/global/interfaces";
import { IPages } from "@/interfaces/customs/interface";
import {
  SpecialtiesData,
  SpecialtiesList,
  SpecialtiesPages,
} from "@/store/specialtes/state";

export const useSpecialtes = () => {
  const store = useStore<StateInterface>();

  return {
    // STATE ############################################
    specialtesList: computed<Array<object>>(
      () => store.state.specialtes.specialtesList
    ),

    specialtesStatus: computed<object>(
      () => store.state.specialtes.specialtesStatus
    ),
    specialtiesCurrentPage: computed(
      () => store.state.specialtes.specialtiesCurrentPage
    ),

    specialtiesLastPage: computed(
      () => store.state.specialtes.specialtiesLastPage
    ),

    // Specialties V.2
    specialties: computed<ISpecialty[]>(
      () => store.state.specialtes.data.specialties
    ),
    specialtiesPages: computed<IPages>(
      () => store.state.specialtes.pages.specialties
    ),
    data: computed<SpecialtiesData>(() => store.state.specialtes.data),
    pages: computed<SpecialtiesPages>(() => store.state.specialtes.pages),
    response: computed<IRequestResponse>(() => store.state.specialtes.response),

    // GETTERS ##########################################
    specialtesName: computed<string>(
      () => store.getters["specialtes/getSpecialtesName"]
    ),
    specialtiesByCenter: computed<object[]>(
      () => store.getters["specialtes/getSpecialtiesByCenter"]
    ),

    // Specialties V.2
    getSpecialtiesList: computed<SpecialtiesList[]>(
      () => store.getters["specialtes/getSpecialtiesList"]
    ),

    // ACTIONS ###########################################
    getSpecialtes: (params: object = {}, page = 1) =>
      store.dispatch("specialtes/getSpecialtes", { params, page }),

    getAllSpecialties: (params: object = {}) =>
      store.dispatch("specialtes/getAllSpecialties", { params }),

    changeSpecialteStatus: (token: string, id: number) =>
      store.dispatch("specialtes/changeSpecialtesStatus", { token, id }),

    createSpecialte: (token: string, bodyRequest: object) =>
      store.dispatch("specialtes/createSpecialte", { token, bodyRequest }),

    getSpecialteById: (token: string, id: string | string[]) =>
      store.dispatch("specialtes/getSpecialteById", { token, id }),

    getSpecialteByIdContract: (token: string, id: string | string[]) =>
      store.dispatch("specialtes/getSpecialteByIdContract", { token, id }),

    getSpecialtiesByCenter: (token: string, id: number) =>
      store.dispatch("specialtes/getSpecialtiesByCenter", { token, id }),

    updateSpecialte: (
      token: string,
      id: string | string[],
      description: string
    ) =>
      store.dispatch("specialtes/updateSpecialte", {
        token,
        id,
        description,
      }),

    // Specialties V.2
    loadSpecialties: (params: {
      status: string;
      page: number;
      description?: string;
    }) => store.dispatch("specialtes/loadSpecialties", params),

    // MUTATIONS #########################################

    updateSpecialteName: (description: ComputedRef<string> | unknown) =>
      store.commit("specialtes/updateSpecialteName", description),

    // Specialties V.2
    setSpecialties: () => store.commit("specialtes/setSpecialties"),
  };
};
