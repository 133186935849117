import { GetterTree } from "vuex";
import { MedicalRecordsState } from "./state";
import { StateInterface } from "../index";
import moment from "moment";
import { joinValues } from "@/functions/ArraysFunction";

const MESSAGE = "Sin datos disponibles";

const getters: GetterTree<MedicalRecordsState, StateInterface> = {
  // Medical Records V.2
  getMedicalRecord(state) {
    if (state.data.patient) {
      const {
        full_name,
        name,
        last_name,
        document_type,
        document,
        gender,
        birthday,
        age,
        photo,
        patient_advance_directive,
      } = state.data.patient;

      return {
        fullName: full_name ?? `${name ?? ""} ${last_name ?? ""}`,
        documentType: document_type?.abbreviation ?? undefined,
        document: document ?? undefined,
        gender: gender ?? undefined,
        birthday: birthday
          ? moment(birthday, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY")
          : undefined,
        age: age ?? undefined,
        photo,
        advanceDirective: patient_advance_directive?.pdf_url,
      };
    }

    return {
      fullName: "",
      documentType: undefined,
      document: undefined,
      gender: undefined,
      birthday: undefined,
      age: undefined,
      photo: undefined,
      advanceDirective: undefined,
    };
  },
  getPatient(state) {
    if (state.data.patient) {
      const {
        name,
        last_name,
        document_type,
        document,
        phone,
        email,
        department_name,
        city_name,
        address,
        eps,
        marital_status,
        gender,
        birthday,
        regime,
        membership,
        scholarship,
        occupation,
        companion_name,
        companion_phone,
        companion_document,
        type_of_population,
        rh_group,
        vital_state,
        prepaid,
        arl,
        care_center,
        sex,
        care_programs,
        document_expedition_location,
        nationality,
        secondary_phone,
      } = state.data.patient;

      return {
        name: name ?? MESSAGE,
        lastName: last_name ?? MESSAGE,
        documentType: document_type?.name ?? MESSAGE,
        document: document ?? MESSAGE,
        phone: phone ?? MESSAGE,
        email: email ?? MESSAGE,
        department: department_name ?? MESSAGE,
        city: city_name ?? MESSAGE,
        address: address ?? MESSAGE,
        eps: eps?.name ?? MESSAGE,
        maritalStatus: marital_status ?? MESSAGE,
        gender: gender ?? MESSAGE,
        birthday: birthday ?? MESSAGE,
        regime: regime?.name ?? MESSAGE,
        membership: membership?.name ?? MESSAGE,
        scholarship: scholarship ?? MESSAGE,
        occupation: occupation ?? MESSAGE,
        companionName: companion_name ?? MESSAGE,
        companionPhone: companion_phone ?? MESSAGE,
        companionDocument: companion_document ?? MESSAGE,
        populationType: type_of_population ?? MESSAGE,
        rh: rh_group ?? MESSAGE,
        vitalState: vital_state ?? MESSAGE,
        prepaid: prepaid?.name ?? MESSAGE,
        arl: arl?.name ?? MESSAGE,
        referenceCenter: care_center?.referenceCenter?.name ?? MESSAGE,
        careCenter: care_center?.name ?? MESSAGE,
        sex: sex?.name ?? MESSAGE,
        careProgram:
          care_programs?.length >= 1
            ? joinValues(care_programs, "name", ", ")
            : MESSAGE,
        expeditionLocation: document_expedition_location ?? MESSAGE,
        nationality: nationality ?? MESSAGE,
        secondaryPhone: secondary_phone ?? MESSAGE,
      };
    }

    return {
      name: MESSAGE,
      lastName: MESSAGE,
      documentType: MESSAGE,
      document: MESSAGE,
      phone: MESSAGE,
      email: MESSAGE,
      department: MESSAGE,
      city: MESSAGE,
      address: MESSAGE,
      eps: MESSAGE,
      maritalStatus: MESSAGE,
      gender: MESSAGE,
      birthday: MESSAGE,
      regime: MESSAGE,
      membership: MESSAGE,
      scholarship: MESSAGE,
      occupation: MESSAGE,
      companionName: MESSAGE,
      companionPhone: MESSAGE,
      companionDocument: MESSAGE,
      populationType: MESSAGE,
      rh: MESSAGE,
      vitalState: MESSAGE,
      prepaid: MESSAGE,
      arl: MESSAGE,
      referenceCenter: MESSAGE,
      careCenter: MESSAGE,
      sex: MESSAGE,
      careProgram: MESSAGE,
      expeditionLocation: MESSAGE,
      nationality: MESSAGE,
      secondaryPhone: MESSAGE,
    };
  },
  getFolios(state) {
    return state.data.folios.length >= 1
      ? state.data.folios.map(
          ({
            identifier: id,
            reference_center,
            serviceable,
            session_type,
            serviceable_type: serviceableType,
            serviceable_id: serviceableId,
          }) => ({
            id,
            center: reference_center?.name,
            specialty: serviceable?.specialty?.description,
            date: serviceable?.date ?? "Por definir",
            ambit: serviceable?.query_type,
            type: session_type?.name,
            status: serviceable?.status,
            serviceableType,
            serviceableId,
          })
        )
      : [];
  },
  getDiagnostics(state) {
    return state.data.diagnostics.length >= 1
      ? state.data.diagnostics.map(
          ({
            diagnosticable_id: id,
            reference_center,
            created_at_formatted: date,
            diagnostic,
            type,
            diagnosticable_type: diagnosticableType,
          }) => ({
            id,
            center: reference_center?.name,
            date,
            description: diagnostic?.description,
            type,
            diagnosticableType,
          })
        )
      : [];
  },
  getBackgrounds(state) {
    return state.data.backgrounds.length >= 1
      ? state.data.backgrounds.map(
          ({
            backgroundable_id: id,
            reference_center,
            created_at_formatted: date,
            background,
            description,
            backgroundable_type: backgroundableType,
          }) => ({
            id,
            center: reference_center?.name,
            date,
            type: background?.name,
            description,
            backgroundableType,
          })
        )
      : [];
  },
  getProcedures(state) {
    return state.data.procedures.length >= 1
      ? state.data.procedures.map(
          ({
            procedurable_id: id,
            reference_center,
            created_at_formatted: date,
            presentation_type: presentationType,
            usage_frequency: usageFrequency,
            ammount: amount,
            treatment_duration,
            treatment_duration_period,
            observation,
            warning_signs: warningSigns,
            procedure,
            procedurable_type: procedurableType,
          }) => ({
            id,
            center: reference_center?.name,
            date,
            procedure: procedure?.description,
            presentationType,
            usageFrequency,
            amount,
            treatmentDuration: `${treatment_duration ?? ""} ${
              treatment_duration_period ?? ""
            }`,
            observation,
            warningSigns,
            procedurableType,
          })
        )
      : [];
  },
  getManagementPlans(state) {
    return state.data.managementPlans.length >= 1
      ? state.data.managementPlans.map(
          ({
            medicinable_id: id,
            reference_center,
            created_at_formatted: date,
            medicine,
            presentation_type: presentationType,
            dose_number,
            dosage_form,
            route_administration: administrationRoute,
            administration_frequency: administrationFrequency,
            treatment_duration: treatmentDuration,
            amount,
            amount_unit,
            posology,
            warning_signs: warningSigns,
            medicinable_type: medicinableType,
          }) => ({
            center: reference_center?.name,
            date,
            medicine: medicine?.description,
            presentationType,
            dosage: `${dose_number ?? ""} ${dosage_form ?? ""}`,
            administrationRoute,
            administrationFrequency,
            treatmentDuration,
            amount: `${amount ?? ""} ${amount_unit ?? ""}`,
            posology,
            warningSigns,
            id,
            medicinableType,
          })
        )
      : [];
  },
  getDocuments(state) {
    return state.data.documents.length >= 1
      ? state.data.documents.map(
          ({
            reference_center,
            date,
            description,
            url,
            mime_type: type,
            name,
          }) => ({
            center: reference_center?.name,
            date,
            description,
            url,
            type,
            name,
          })
        )
      : [];
  },
  getClinicalLaboratories(state) {
    return state.data.clinicalLaboratories.length >= 1
      ? state.data.clinicalLaboratories.map(
          ({
            reference_center,
            created_at_formatted: date,
            unit,
            classification,
            assessment,
            interpretation,
            status,
          }) => ({
            center: reference_center?.name,
            date,
            unit,
            classification: classification?.name,
            assessment,
            interpretation,
            status,
          })
        )
      : [];
  },
  getMedicalExams(state) {
    return state.data.medicalExams.length >= 1
      ? state.data.medicalExams.map(
          ({
            reference_center,
            created_at_formatted: date,
            procedure,
            classification,
            date: examDate,
            interpretation,
            file,
          }) => ({
            center: reference_center?.name,
            date,
            exam: procedure?.description,
            classification: classification?.name,
            examDate,
            interpretation,
            file,
          })
        )
      : [];
  },
  getVaccines(state) {
    return state.data.vaccines.length >= 1
      ? state.data.vaccines.map(
          ({
            reference_center,
            created_at_formatted: date,
            years: age,
            vaccine,
            type_doses_vaccine,
            application_date: applicationDate,
            laboratory,
            batch_number: batchNumber,
            ips_name: ipsVaccinator,
            next_appointment_date: nextAppointmentDate,
            vaccinator_name: vaccinator,
          }) => ({
            center: reference_center?.name,
            date,
            age,
            protectsMeFrom: vaccine?.name,
            dose: type_doses_vaccine?.type_dose?.name,
            applicationDate,
            laboratory,
            batchNumber,
            ipsVaccinator,
            nextAppointmentDate,
            vaccinator,
          })
        )
      : [];
  },
  getDisabilities(state) {
    return state.data.disabilities.length >= 1
      ? state.data.disabilities.map(
          ({
            user,
            created_at_formatted: date,
            type,
            cause,
            days,
            patient,
            start_date_formatted: startDate,
            end_date_formatted: endDate,
            grade,
            diagnostic,
            description,
          }) => ({
            center: user?.center?.name,
            professional: user?.name,
            specialty: user?.specialtyType?.description,
            date,
            type,
            cause,
            days,
            patient,
            startDate,
            endDate,
            grade,
            diagnostic,
            description,
          })
        )
      : [];
  },
  getGynecologist(state) {
    if (state.data.gynecologist) {
      const {
        last_pregnancy_date_formatted,
        last_pregnancy_weeks,
        last_pregnancy_details,
        last_menstruation_date_formatted,
        last_menstruation_is_reliable_date,
        last_menstruation_assessment,
        last_menstruation_is_planning_method,
        last_menstruation_detail_planning_method,
        obstetric_profile_births,
        obstetric_profile_childbirth,
        obstetric_profile_pregnancies,
        obstetric_profile_caesarean,
        obstetric_profile_cool,
        obstetric_profile_ectopic,
        obstetric_profile_abortions,
        last_cytology_date_formatted,
        pregnant_details,
        pregnant_currently_pregnant,
      } = state.data.gynecologist;

      return {
        lastPregnancyDate: last_pregnancy_date_formatted ?? MESSAGE,
        lastPregnancyWeeks: last_pregnancy_weeks ?? MESSAGE,
        lastPregnancyDetails: last_pregnancy_details ?? MESSAGE,
        lastMenstruationDate: last_menstruation_date_formatted ?? MESSAGE,
        lastMenstruationIsReliableDate: last_menstruation_is_reliable_date
          ? "Sí"
          : "No",
        lastMenstruationAssessment: last_menstruation_assessment ? "Sí" : "No",
        lastMenstruationIsPlanningMethod: last_menstruation_is_planning_method
          ? "Sí"
          : "No",
        lastMenstruationDetailPlanningMethod:
          last_menstruation_detail_planning_method ?? MESSAGE,
        obstetricProfileBirths: obstetric_profile_births ?? MESSAGE,
        obstetricProfileChildbirth: obstetric_profile_childbirth ?? MESSAGE,
        obstetricProfilePregnancies: obstetric_profile_pregnancies ?? MESSAGE,
        obstetricProfileCaesarean: obstetric_profile_caesarean ?? MESSAGE,
        obstetricProfileCool: obstetric_profile_cool ?? MESSAGE,
        obstetricProfileEctopic: obstetric_profile_ectopic ?? MESSAGE,
        obstetricProfileAbortions: obstetric_profile_abortions ?? MESSAGE,
        lastCytologyDate: last_cytology_date_formatted ?? MESSAGE,
        pregnantDetails: pregnant_details ?? MESSAGE,
        pregnantCurrentlyPregnant: pregnant_currently_pregnant ? "Sí" : "No",
      };
    }

    return {
      lastPregnancyDate: MESSAGE,
      lastPregnancyWeeks: MESSAGE,
      lastPregnancyDetails: MESSAGE,
      lastMenstruationDate: MESSAGE,
      lastMenstruationIsReliableDate: MESSAGE,
      lastMenstruationAssessment: MESSAGE,
      lastMenstruationIsPlanningMethod: MESSAGE,
      lastMenstruationDetailPlanningMethod: MESSAGE,
      obstetricProfileBirths: MESSAGE,
      obstetricProfileChildbirth: MESSAGE,
      obstetricProfilePregnancies: MESSAGE,
      obstetricProfileCaesarean: MESSAGE,
      obstetricProfileCool: MESSAGE,
      obstetricProfileEctopic: MESSAGE,
      obstetricProfileAbortions: MESSAGE,
      lastCytologyDate: MESSAGE,
      pregnantDetails: MESSAGE,
      pregnantCurrentlyPregnant: MESSAGE,
    };
  },
};

export default getters;
