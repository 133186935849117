import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/administradoras",
    name: "AdministratorsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import("@/views/administrators/list/AdministratorsList.vue"),
  },
  {
    path: "/administradoras/crear",
    name: "AdministratorsCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import("@/views/administrators/create/AdministratorsCreate.vue"),
  },
  {
    path: "/administradoras/editar/:id",
    name: "AdministratorsEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import("@/views/administrators/edit/AdministratorsEdit.vue"),
  },
];
