import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/notas-aclaratorias",
    name: "ExplanatoryNotesList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Explanatory Notes List" */ "@/views/explanatory-notes/ExplanatoryNotesList.vue"
      ),
  },
  {
    path: "/notas-aclaratorias/ver-nota/:id(\\d+)",
    name: "ExplanatoryNotesView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Explanatory Notes View" */ "@/views/explanatory-notes/sub-pages/explanatory-notes-view/ExplanatoryNotesView.vue"
      ),
  },
  {
    path: "/notas-aclaratorias/autorizar-notas",
    name: "ExplanatoryNotesCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Explanatory Notes Create" */ "@/views/explanatory-notes/sub-pages/explanatory-notes-create/ExplanatoryNotesCreate.vue"
      ),
  },
  {
    path: "/notas-aclaratorias/responder-nota/:id(\\d+)",
    name: "ExplanatoryNotesResponse",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Explanatory Notes Response" */ "@/views/explanatory-notes/sub-pages/explanatory-notes-response/ExplanatoryNotesResponse.vue"
      ),
  },
];
