import { IDiagnostic } from "@/interfaces/global/interfaces";
import { DiagnosticsEdit, DiagnosticsList } from "@/store/diagnostics/state";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { useStore } from "vuex";

export const useDiagnosticsStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    // Diagnostics V.2
    diagnostics: computed(() => store.state.diagnostics.data.diagnostics),
    diagnosticsPages: computed(() => store.state.diagnostics.pages.diagnostics),
    requests: computed(() => store.state.diagnostics.requests),
    data: computed(() => store.state.diagnostics.data),
    pages: computed(() => store.state.diagnostics.pages),
    response: computed(() => store.state.diagnostics.response),

    // Getters
    diagnosticCode: computed<string>(
      () => store.getters["diagnostics/getDiagnosticCode"]
    ),
    diagnosticDescription: computed<string>(
      () => store.getters["diagnostics/getDiagnosticDescription"]
    ),

    // Diagnostics V.2
    getDiagnosticsList: computed<DiagnosticsList[]>(
      () => store.getters["diagnostics/getDiagnosticsList"]
    ),
    getDiagnosticsEdit: computed<DiagnosticsEdit | null>(
      () => store.getters["diagnostics/getDiagnosticsEdit"]
    ),

    // Actions
    changeDiagnosticStatusById: (token: string, id: number) =>
      store.dispatch("diagnostics/changeDiagnosticStatusById", { token, id }),
    // createDiagnostic: (token: string, bodyRequest: object) =>
    //   store.dispatch("diagnostics/createDiagnostic", { token, bodyRequest }),
    getDiagnosticById: (token: string, id: string | string[]) =>
      store.dispatch("diagnostics/getDiagnosticById", { token, id }),
    // updateDiagnostic: (token: string, bodyRequest: object) =>
    //   store.dispatch("diagnostics/updateDiagnostic", {
    //     token,
    //     bodyRequest,
    //   }),

    // Diagnostics V.2
    loadDiagnostics: (
      params:
        | {
            status: string;
            page: number;
            full_reference?: string;
          }
        | {
            care_center_id: null | number;
            paginate?: number;
            page?: number;
            reference_center_id: null | number;
            rows?: number;
            search?: string;
            status?: string;
          },
      version = 1
    ) => store.dispatch("diagnostics/loadDiagnostics", { params, version }),
    loadDiagnostic: (id: number) =>
      store.dispatch("diagnostics/loadDiagnostic", id),
    createDiagnostic: (bodyRequest: {
      care_center_id: number | null;
      code: string;
      description: string;
      reference_center_id: number | null;
    }) => store.dispatch("diagnostics/createDiagnostic", bodyRequest),
    updateDiagnostic: (
      id: number,
      bodyRequest: {
        _method: "PATCH";
        code: string;
        description: string;
      }
    ) => store.dispatch("diagnostics/updateDiagnostic", { id, bodyRequest }),
    changeDiagnosticStatus: (id: number) =>
      store.dispatch("diagnostics/changeDiagnosticStatus", id),
    deleteDiagnostic: (id: number) =>
      store.dispatch("diagnostics/deleteDiagnostic", id),

    // Mutations
    // Diagnostics V.2
    setDiagnostics: () => store.commit("diagnostics/setDiagnostics"),
    setDiagnostic: (data?: IDiagnostic) =>
      store.commit("diagnostics/setDiagnostic", data),
  };
};
