import { GetterTree } from "vuex";
import { OxygenControlState} from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<OxygenControlState, StateInterface> = {
    getOxygenControls(state) {
      return state.oxygenControlList;
    },
    getOxygenControlsCurrentPage(state) {
      return state.oxygenControlCurrentPage;
    },
    getOxygenControlsLastPage(state) {
      return state.oxygenControlLastPage;
    },
};

export default getters;