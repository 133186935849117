import {
  IData,
  IRequestResponse,
  IAdvanceDirective,
  IDirective,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { AdvanceDirectivesState } from "./state";

const mutation: MutationTree<AdvanceDirectivesState> = {
  setAdvanceDirectives(state: AdvanceDirectivesState, data?: IData): void {
    if (data) {
      state.data.advanceDirectives = data.data as IAdvanceDirective[];

      state.pages.advanceDirectives.currentPage.value = data.current_page;
      state.pages.advanceDirectives.lastPage.value = data.last_page;
    } else {
      state.data.advanceDirectives = [];
    }
  },
  setAdvanceDirective(
    state: AdvanceDirectivesState,
    data?: IAdvanceDirective | null
  ): void {
    if (data !== undefined) {
      state.data.advanceDirective = data;
    } else {
      state.data.advanceDirective = null;
    }
  },
  setDirectives(state: AdvanceDirectivesState, data?: IDirective[]): void {
    if (data) {
      state.data.directives = data;
    } else {
      state.data.directives = [];
    }
  },
  setResponse(state: AdvanceDirectivesState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
