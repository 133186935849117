import { IDirective } from "@/interfaces/global/AdvanceDirective";
import { AdvanceDirectivesList } from "@/store/advance-directives/state";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { useStore } from "vuex";

export const useAdvanceDirectivesStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    advanceDirectives: computed(
      () => store.state.advanceDirectives.data.advanceDirectives
    ),
    advanceDirectivesPages: computed(
      () => store.state.advanceDirectives.pages.advanceDirectives
    ),
    advanceDirective: computed(
      () => store.state.advanceDirectives.data.advanceDirective
    ),
    directives: computed(() => store.state.advanceDirectives.data.directives),
    data: computed(() => store.state.advanceDirectives.data),
    pages: computed(() => store.state.advanceDirectives.pages),
    response: computed(() => store.state.advanceDirectives.response),

    // Getters
    getAdvanceDirectivesList: computed<AdvanceDirectivesList[]>(
      () => store.getters["advanceDirectives/getAdvanceDirectivesList"]
    ),
    getCareDirectives: computed<IDirective[]>(
      () => store.getters["advanceDirectives/getCareDirectives"]
    ),
    getEOLDirectives: computed<IDirective[]>(
      () => store.getters["advanceDirectives/getEOLDirectives"]
    ),
    getDonationDirectives: computed<IDirective[]>(
      () => store.getters["advanceDirectives/getDonationDirectives"]
    ),
    getIndicationDirectives: computed<IDirective[]>(
      () => store.getters["advanceDirectives/getIndicationDirectives"]
    ),

    // Actions
    loadAdvanceDirectives: (params: {
      reference_center_id?: number;
      care_center_id?: number;
      search?: string;
      status: string;
      page: number;
    }) => store.dispatch("advanceDirectives/loadAdvanceDirectives", params),
    loadAdvanceDirective: (id: number) =>
      store.dispatch("advanceDirectives/loadAdvanceDirective", id),
    updateAdvanceDirective: (
      id: number,
      bodyRequest: {
        _method: string;
        signature?: File;
        directives:
          | {
              id?: number;
              directive?: number;
              variables?: {
                [key: string]: string | FormDataEntryValue;
              };
            }[]
          | string;
      }
    ) =>
      store.dispatch("advanceDirectives/updateAdvanceDirective", {
        id,
        bodyRequest,
      }),
    createAdvanceDirective: (bodyRequest: {
      patient_id?: number;
      signature?: File;
      directives:
        | {
            directive?: number;
            variables?: {
              [key: string]: string | FormDataEntryValue;
            };
          }[]
        | string;
    }) =>
      store.dispatch("advanceDirectives/createAdvanceDirective", bodyRequest),
    deleteAdvanceDirective: (id: number) =>
      store.dispatch("advanceDirectives/deleteAdvanceDirective", id),
    changeAdvanceDirective: (
      id: number,
      bodyRequest = {
        _method: "PATCH",
      }
    ) =>
      store.dispatch("advanceDirectives/changeAdvanceDirective", {
        id,
        bodyRequest,
      }),
    loadDirectives: (params?: { type?: string }) =>
      store.dispatch("advanceDirectives/loadDirectives", params),

    //Mutations
    setAdvanceDirectives: () =>
      store.commit("advanceDirectives/setAdvanceDirectives"),
    setDirectives: () => store.commit("advanceDirectives/setDirectives"),
  };
};
