import {
  IBackgrounds,
  IData,
  IDiagnostics,
  IFolio,
  IFile,
  IVitalSigns,
  IManagementPlans,
  IPhysicalExam,
  IProcedures,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { FoliosState } from "./state";

const mutation: MutationTree<FoliosState> = {
  // Folios V.2
  setProcedures(state: FoliosState, data?: IData): void {
    if (data) {
      state.data.procedures = data.data as IProcedures[];

      state.pages.procedures.currentPage.value = data.current_page;
      state.pages.procedures.lastPage.value = data.last_page;

      state.request.procedures = true;
    } else {
      state.data.procedures = [];

      state.request.procedures = false;
    }
  },
  setManagementPlans(state: FoliosState, data?: IData): void {
    if (data) {
      state.data.managementPlans = data.data as IManagementPlans[];

      state.pages.managementPlans.currentPage.value = data.current_page;
      state.pages.managementPlans.lastPage.value = data.last_page;

      state.request.managementPlans = true;
    } else {
      state.data.managementPlans = [];

      state.request.managementPlans = false;
    }
  },
  setResponse(state: FoliosState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },

  // Update endpoint
  setFolio(state: FoliosState, data: IFolio): void {
    if (data !== undefined) {
      state.data.folio = data;
      state.request.folio = true;
    } else {
      state.data.folio = null;
      state.request.folio = false;
    }
  },
  setVitalSigns(state: FoliosState, data: IVitalSigns): void {
    if (data !== undefined) {
      state.data.vitalSigns = data;
      state.request.vitalSigns = true;
    } else {
      state.data.vitalSigns = null;
      state.request.vitalSigns = false;
    }
  },
  setPhysicalExam(state: FoliosState, data: IPhysicalExam[]): void {
    if (data) {
      state.data.physicalExam = data;

      state.request.physicalExam = true;
    } else {
      state.data.physicalExam = [];

      state.request.physicalExam = false;
    }
  },
  setDiagnostics(state: FoliosState, data: IData): void {
    if (data) {
      state.data.diagnostics = data.data as IDiagnostics[];

      state.pages.diagnostics.currentPage.value = data.current_page;
      state.pages.diagnostics.lastPage.value = data.last_page;

      state.request.diagnostics = true;
    } else {
      state.data.diagnostics = [];

      state.request.diagnostics = false;
    }
  },
  setBackgrounds(state: FoliosState, data: IData): void {
    if (data) {
      state.data.backgrounds = data.data as IBackgrounds[];

      state.pages.backgrounds.currentPage.value = data.current_page;
      state.pages.backgrounds.lastPage.value = data.last_page;

      state.request.backgrounds = true;
    } else {
      state.data.backgrounds = [];

      state.request.backgrounds = false;
    }
  },
  setDocuments(state: FoliosState, data: IData): void {
    if (data) {
      state.data.documents = data.data as IFile[];

      state.pages.documents.currentPage.value = data.current_page;
      state.pages.documents.lastPage.value = data.last_page;

      state.request.documents = true;
    } else {
      state.data.documents = [];

      state.request.documents = false;
    }
  },
};

export default mutation;
