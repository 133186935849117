import { isAuthenticatedGuard } from '@/router/auth-guard';

export default [
   
        {
            path: '/prescripcion-de-procedimientos',
            name: 'PrescriptionProcedures',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/prescription-procedures-templates/PrescriptionProcedures.vue'),
        },

        {
            path: '/crear-plantillas-prescripcion-de-procedimientos',
            name: 'PrescriptionProceduresCreated',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/prescription-procedures-templates/subpages/created/PrescriptionProceduresCreated.vue'),
        },

        {
            path: '/editar-plantillas-prescripcion-de-procedimientos/:id',
            name: 'PrescriptionProceduresEdit',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/prescription-procedures-templates/subpages/edit/PrescriptionProceduresEdit.vue'),
        },
        {
            path: '/ver-plantillas-prescripcion-de-procedimientos/:id',
            name: 'PrescriptionProceduresView',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/prescription-procedures-templates/subpages/view/PrescriptionProceduresView.vue'),
        },
]