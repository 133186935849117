import { GetterTree } from "vuex";
import { JobListUI } from "./state";
import { StateInterface } from "../../index";

const getters: GetterTree<JobListUI, StateInterface> = {
  getJobList(state: JobListUI) {
    return state.jobList;
  },

  getJobListCurrentPage(state) {
    return state.jobListCurrentPage;
  },

  getJobListLastPage(state) {
    return state.jobListLastPage;
  },
  getJobCounterList(state: JobListUI) {
   return state.jobCounterList
  },

  getTelexperticeTypeSelector(state) {
    return state.telexperticeTypeSelector;
  },
};
export default getters;
