import { isAuthenticatedGuard } from '@/router/auth-guard';

export default [
    {
        path: '/gestion-de-atencion-medica',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(/* webpackChunkName: "HealthcareManagement" */
            '@/views/healthcareManagement/healthcareManagement.vue'),
        children: [
            {
                path: 'lista-de-trabajo',
                name: 'JobList',
                beforeEnter: [isAuthenticatedGuard],
                component: () => import(
                    /* webpackChunkName: "JobList" */
                    '@/views/healthcareManagement/job-list/JobList.vue'),
            }
        ]
    },

    {
        path: '/gestion-de-atencion-medica/plantillas',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(/* webpackChunkName: "HealthcareManagement" */
            '@/views/healthcareManagement/healthcareManagement.vue'),
        children: [
            {
                path: '',
                name: 'TemplatesView',
                beforeEnter: [isAuthenticatedGuard],
                component: () => import(
                    /* webpackChunkName: "JobList" */
                    '@/views/healthcareManagement/template/TemplatesView.vue'),
            }
        ]
    },
]