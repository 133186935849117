import { GetterTree } from "vuex";
import { CampaignsState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<CampaignsState, StateInterface> = {
  getCampaignsList(state) {
    return state.campaignsList;
  },
  getCampaignsCurrentPage(state) {
    return state.campaignsCurrentPage;
  },
  getCampaignsLastPage(state) {
    return state.campaignsLastPage;
  },
  getCampaignById(state) {
    return [{ ...state.campaign }].map(
      ({
        id,
        name,
        date,
        is_sms,
        is_email,
        email_subject,
        sms_message,
        email_message,
        sms_failed,
        sms_send,
        email_failed,
        email_send,
        total_patients,
      }) => ({
        id,
        name,
        date,
        is_sms,
        is_email,
        email_subject,
        sms_message,
        email_message,
        sms_failed,
        sms_send,
        email_failed,
        email_send,
        total_patients,
      })
    )[0];
  },
  getPatientsList(state) {
    return state.patientsList;
  },
  getPatientsCurrentPage(state) {
    return state.patientsCurrentPage;
  },
  getPatientsLastPage(state) {
    return state.patientsLastPage;
  },
};

export default getters;
