import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import {
  IDataTable,
  ILiquidControlHydricBalance,
} from "@/interfaces/customs/interface";

export const useLiquidControl = () => {
  const store = useStore<StateInterface>();
  return {
    // State
    liquidControlList: computed(
      () => store.state.liquidControl.liquidControlList
    ),
    liquidControlCurrentPage: computed(
      () => store.state.liquidControl.liquidControlCurrentPage
    ),
    liquidControlLastPage: computed(
      () => store.state.liquidControl.liquidControlLastPage
    ),
    types: computed(() => store.state.liquidControl.types),
    ways: computed(() => store.state.liquidControl.ways),
    _dataHydricBalance: computed<ILiquidControlHydricBalance | null>(
      () => store.state.liquidControl.dataHydricBalance
    ),
    _dataTableHydricBalance: computed<IDataTable>(
      () => store.state.liquidControl.dataTable
    ),

    // Actions
    _getListLiquidsControl: (
      token: string,
      referenceCenterId: number | undefined,
      careCenterId: number | undefined,
      page: number
    ) =>
      store.dispatch("liquidControl/getLiquidsControl", {
        token,
        referenceCenterId,
        careCenterId,
        page,
      }),
    _getLiquidControlById: (id: number | string) =>
      store.dispatch("liquidControl/getLiquidControlById", {
        id,
      }),
    _createLiquidControl: (dataRequest: ILiquidControlHydricBalance) =>
      store.dispatch("liquidControl/createLiquidControl", { dataRequest }),
    _getListTypes: () => store.dispatch("liquidControl/getTypesList"),
    _getListWays: (idType: number) =>
      store.dispatch("liquidControl/getWaysList", { idType }),

    // Mutation
    setDataHydricBalance: (details: ILiquidControlHydricBalance | null) =>
      store.commit("liquidControl/setDataHydricBalance", details),
    setDataTableHydricBalance: (details: IDataTable | null) =>
      store.commit("liquidControl/setDataTableHydricBalance", details),
  };
};
