// outside of a Vue file
import Notify from 'quasar/src/plugins/Notify.js';;
import { useAlertV2 } from "@/composables/useAlertV2";

const { showAlert } = useAlertV2();

// Trigger Notification Function
export default function triggerNotification(
  message: string,
  timeout = 1000,
  type = "negative"
) {
  let typeFormat = "";
  switch (type) {
    case "positive":
      typeFormat = "success";
      break;
    case "negative":
      typeFormat = "error";
      break;
    default:
      typeFormat = type;
      break;
  }
  if (!["REQUEST_ALREADY_IN_PROGRESS"].includes(message)) {
    showAlert(message, typeFormat, undefined, timeout);
  }
}
