import { IPages } from "@/interfaces/customs/Pages";
import { ILevel, IRequestResponse } from "@/interfaces/global/interfaces";
import { IExcel } from "@/interfaces/global/Level";

type LevelsRequests = Record<"level", boolean>;
type LevelsPages = Record<"levels", IPages>;

export interface LevelsList {
  level: ILevel["level"];
  regime: ILevel["regime"];
  moderation_quota: ILevel["moderation_quota"];
  copay: ILevel["copay"];
  status: ILevel["status"];
}

export interface LevelsEdit {
  reference_center_id: ILevel["reference_center"]["id"] | null;
  level: ILevel["level"] | null;
  regime_id: ILevel["regime_id"] | null;
  moderation_quota: ILevel["moderation_quota"] | null;
  copay: ILevel["copay"] | null;
  status: ILevel["status"] | null;
  annual_limit: ILevel["annual_limit"] | null;
  service_limit: ILevel["service_limit"] | null;
  year: ILevel["year"] | null;
  regime: ILevel["regime"] | null;
}

interface LevelsData {
  levels: ILevel[];
  level: ILevel | null;
  excel: IExcel | null;
}

export interface LevelsState {
  requests: LevelsRequests;
  data: LevelsData;
  pages: LevelsPages;
  response: IRequestResponse;
}

function state(): LevelsState {
  return {
    requests: {
      level: false,
    },
    data: {
      levels: [],
      level: null,
      excel: null,
    },
    pages: {
      levels: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
