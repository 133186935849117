import apiBase from "@/apis/AxiosIntance";

export const medicinesApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/medicines`;
  return apiBase;
};

export const medicinesApiV2 = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/medicines/v2`;

  return apiBase;
};