import { GetterTree } from "vuex";
import { StateInterface } from "../index";
import { MedicinesState } from "@/interfaces/global/Medicine";

const getters: GetterTree<MedicinesState, StateInterface> = {
  getMedicineCode(state) {
    return state.medicineCode;
  },
  getMedicineDescription(state) {
    return state.medicineDescription;
  },

  getPbsValue(state) {
    return state.pbs;
  },

  // Medicines V.3
  getMedicinesList(state) {
    return state.data.medicines.length >= 1
      ? state.data.medicines.map(
          ({
            id,
            ium_code,
            code,
            atc_code,
            description,
            pbs,
            class: isClass,
            status,
            default: isDefault,
          }) => ({
            id,
            ium_code,
            code,
            atc_code,
            description,
            pbs: pbs ? "Si" : "No",
            medicine_class: isClass?.name,
            status,
            isDefault,
          })
        )
      : [];
  },
};

export default getters;
