import { defineComponent, watch } from 'vue';
import { useAuth, useUsers } from '@/composables';
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
    name: 'App',
 
    setup() {
        const colors = ['orange', 'blue', 'green', 'red', 'grey', 'black', 'brown', 'accent', 'negative', 'indigo-10', 'cyan-10', 'teal-9', 'deep-orange', 'blue-grey', 'pink', 'purple', 'deep-purple', 'red', 'green-10'];
        const { isMaintainSesionUser } = useAuth();
        const { isSetColorAvatarUser, isSetIsDashboard } = useUsers();
        const router = useRouter();
        const route = useRoute();
    
            if(window.sessionStorage.getItem('user') && sessionStorage.getItem('color_avatar')){
                isMaintainSesionUser();
                isSetColorAvatarUser(sessionStorage.getItem('color_avatar'));
                router.push({ name: 'dashboard'});
            }else{
                const color = colors[Math.floor(Math.random()*colors.length)];
                isSetColorAvatarUser(color);
                sessionStorage.setItem('color_avatar', color);
            }

            //Watchers
            watch(
                () => route.path,
                (val) => {
                    if(val == '/dashboard'){
                        isSetIsDashboard(true);
                    }else{
                        isSetIsDashboard(false);
                    }
                }
            )

        return {} 
    }
});
