import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/especialidades",
    name: "SpecialtiesList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Specialties List" */ "@/views/specialties/SpecialtiesList.vue"
      ),
  },
  {
    path: "/especialidades/editar-especialidades/:id(\\d+)",
    name: "SpecialtiesEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Specialties Edit" */ "@/views/specialties/sub-pages/specialties-edit/SpecialtiesEdit.vue"
      ),
  },
  {
    path: "/especialidades/crear-especialidades",
    name: "SpecialtiesCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Specialties Create" */ "@/views/specialties/sub-pages/specialties-create/SpecialtiesCreate.vue"
      ),
  },
]