import { MutationTree } from "vuex";
import {
  CustomComponentsState,
  ICenters,
  TCentersRequests,
  TCentersValue,
  TValue,
} from "./state";

const mutation: MutationTree<CustomComponentsState> = {
  setResponse(state: CustomComponentsState, response) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "", module: "" };
    }
  },
  setCenters(
    state: CustomComponentsState,
    { center, data }: { center: TCentersRequests; data?: ICenters[] }
  ) {
    if (data) {
      state.data.settings.centers[center] = data;
      state.requests.settings.centers[center] = true;
    } else {
      state.data.settings.centers[center] = [];
      state.requests.settings.centers[center] = false;
    }
  },
  setCentersValue(
    state: CustomComponentsState,
    { center, value }: { center: TCentersValue; value?: TValue }
  ) {
    if (value !== undefined) {
      state.values.settings.centers[center] = value;
    } else {
      state.values.settings.centers[center] = null;
    }
  },
};

export default mutation;
