import { AdministratorsState } from "@/interfaces/global/interfaces";

function state(): AdministratorsState {
  return {
    administratorsList: [],
    administratorRow: null,
    administratorsCurrentPage: 1,
    administratorsLastPage: 1,
    states: [
      { label: "Todos", value: "" },
      { label: "Activo", value: "active" },
      { label: "Inactivo", value: "inactive" },
    ],
  };
}

export default state;
