import { IPages } from "@/interfaces/customs/Pages";
import {
  IRequestResponse,
  IResolution4725,
} from "@/interfaces/global/interfaces";

export type ResolutionReports4725Pages = Record<
  "resolutionReports4725",
  IPages
>;

export interface ResolutionReports4725List {
  id: IResolution4725["id"];
  fromDate: IResolution4725["from_date_formatted"];
  toDate: IResolution4725["to_date_formatted"];
  date: IResolution4725["created_at_formatted"];
  requestedBy: IResolution4725["user"]["name"];
  careCenter: IResolution4725["care_center"]["name"];
  referenceCenter: IResolution4725["reference_center"]["name"];
  eps: IResolution4725["eps"]["name"];
  status: IResolution4725["status"];
  url: IResolution4725["url"];
}

export interface ResolutionReports4725View {
  fromDate: IResolution4725["from_date_formatted"];
  toDate: IResolution4725["to_date_formatted"];
  date: IResolution4725["created_at_formatted"];
  requestedBy: IResolution4725["user"]["name"];
  careCenter: IResolution4725["care_center"]["name"];
  referenceCenter: IResolution4725["reference_center"]["name"];
  eps: IResolution4725["eps"]["name"];
  status: IResolution4725["status"];
  regime: IResolution4725["regime"]["name"];
  url: IResolution4725["url"];
}

export interface ResolutionReports4725Data {
  resolutionReports4725: IResolution4725[];
  resolutionReport4725: IResolution4725 | null;
}

export interface ResolutionReports4725State {
  data: ResolutionReports4725Data;
  pages: ResolutionReports4725Pages;
  response: IRequestResponse;
}

function state(): ResolutionReports4725State {
  return {
    data: {
      resolutionReports4725: [],
      resolutionReport4725: null,
    },
    pages: {
      resolutionReports4725: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
