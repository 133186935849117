import { highCostAccounts2463Api } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { HighCostAccounts2463State } from "./state";

const actions: ActionTree<HighCostAccounts2463State, StateInterface> = {
  async loadHighCostAccounts({ commit }, params) {
    commit("setHighCostAccounts");
    commit("setResponse");

    try {
      const { data: response } = await highCostAccounts2463Api().get(
        "/",
        { params }
      );
      const { success, message, data } = response;

      commit("setHighCostAccounts", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadHighCostAccount({ commit }, id) {
    commit("setHighCostAccount");
    commit("setResponse");

    try {
      const { data: response } = await highCostAccounts2463Api().get(`/${id}`);
      const { success, message, data } = response;

      commit("setHighCostAccount", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createHighCostAccount({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await highCostAccounts2463Api().post("", bodyRequest);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async cancelHighCostAccount({ commit }, id) {
    commit("setResponse");

    try {
      const { data } = await highCostAccounts2463Api().post(`/cancel/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async loadDiseaseTypes({ commit }) {
    commit("setDiseaseTypes");
    commit("setResponse");

    try {
      const { data: response } = await highCostAccounts2463Api().get(
        "/disease-types"
      );
      const { success, message, data } = response;

      commit("setDiseaseTypes", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
};

export default actions;
