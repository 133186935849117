import {
  ContactListPaginationUI,
  ContractListCustomUI,
} from "@/interfaces/customs/ContractsCustomUI";
import { IContractInfoByIdCustom, IContractServicesCustom } from "@/interfaces/customs/ContractsUI";



export interface ContractsUI {
  contractsBreadcrumbs: {
    title: string;
    path: {
      label: string;
      path: string;
    }[];
    btn: string;
    navigate: string;
  };

  contractCurrentPage: number;
  contractLastPage: number;
  contractsPagination: ContactListPaginationUI;
  contractListLoader: boolean;
  contractReset: boolean;

  // alert general ini
  showAlert: boolean;
  alertMessage: string;
  alertTitle: string;
  alertType: string;
  // alert general end
  popupOpen: boolean;
  popupLoading: boolean;
  popupLoadingMessage: string;
  popupTitle: string;
  popupMessage: string;
  contractsList: ContractListCustomUI[];
  loader: boolean;
  loadingSpecialities: boolean;
  loadingCenterList: boolean;
  currentUser: {
    user: string;
    id: number;
    email: string;
    token: string;
  };
  currentPage: number;
  totalPage: number;
  to: number;
  specialitySelectedId: {
    name: string;
    id: number;
    token: string;
  };
  centerSelectedId: {
    name: string;
    id: number;
    token: string;
  };

  contractSelected: ContractListCustomUI | null | undefined;

  rowSpeciality: {
    id: number | null;
    created_at: string | null;
    updated_at: string | null;
    description: string;
    status: string;
    pivot: {
      contracts_id: number;
      specialties_id: number;
      id: number;
      status: string;
    };
  }[];

  rowCenters: {
    id: number;
    created_at: string | null;
    updated_at: string | null;
    status: string | null;
    name: string | null;
    initial_date: string | null;
    final_date: string | null;
    associated_contract: string | null;
    interactive_telexpertise: string | null;
    non_interactive_telexpertise: string | null;
    telesupport: string | null;
    centers_id: number;
    contract_code: 1;
    get_center_contracts: [
      {
        id: number;
        created_at: string | null;
        updated_at: string | null;
        status: string;
        name: string;
        initial_date: string;
        final_date: string;
        associated_contract: string | null;
        interactive_telexpertise: number | null;
        non_interactive_telexpertise: number | null;
        telesupport: number | null;
        centers_id: number;
        contract_code: number;
        contract_remain: string;
      }
    ];
  }[];
  totalActives: number;
  totalExpired: number;
  totalInactives: number;
  allReferenceCenters: {
    label: string;
    value: string;
  }[];
  allAtentionCenter: {
    label: string;
    value: string;
  }[];
  contractSpecialities: string;

  availablesCentersOptions: object[];
  availablesSpecialtiesOptions: object[];
  telemedicineServices: IContractServicesCustom[];
  healthServices: IContractServicesCustom[];

  contractInfoById: IContractInfoByIdCustom | undefined
  contractViewInfoById: IContractInfoByIdCustom | undefined
}

function state(): ContractsUI {
  return {
    contractsBreadcrumbs: {
      title: "Sin definir",
      path: [
        {
          label: "",
          path: "",
        },
      ],
      btn: "",
      navigate: "",
    },

    contractCurrentPage: 0,
    contractLastPage: 0,

    contractsPagination: {
      currentPage: { value: 1 },
      lastPage: { value: 1 },
    },
    contractListLoader: false,
    contractReset: false,
    // alert general ini
    showAlert: false,
    alertTitle: "Your title HERE",
    alertMessage: "Your message HERE",
    alertType: "success",
    // alert general end

    popupOpen: false,
    popupLoading: false,
    popupLoadingMessage: "",
    popupTitle: "",
    popupMessage: "",
    contractsList: [],
    loader: false,
    loadingSpecialities: false,
    loadingCenterList: false,
    currentUser: {
      user: "",
      id: 0,
      email: "",
      token: "",
    },
    currentPage: 0,
    totalPage: 0,
    to: 0,
    specialitySelectedId: {
      name: "",
      id: 0,
      token: "",
    },
    centerSelectedId: {
      name: "",
      id: 0,
      token: "",
    },
    contractSelected: null,
    rowSpeciality: [],
    rowCenters: [],

    totalActives: 0,
    totalExpired: 0,
    totalInactives: 0,

    // form options
    allReferenceCenters: [],
    allAtentionCenter: [],
    contractSpecialities: "",

    availablesCentersOptions: [],
    availablesSpecialtiesOptions: [],

    telemedicineServices: [],
    healthServices: [],

    contractInfoById: undefined,
    contractViewInfoById: undefined
  };
}

export default state;
