import apiBase from "@/apis/AxiosIntance";

export const teleexpertisesApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/teleexpertise`;

  return apiBase;
};
export const telexpertiseApiV2 = (filter: any | null = null, page = 1) => {
  const urlBase = new URL(
    `${process.env.VUE_APP_URL}/api/telemedicine/teleexpertise/v2`
  );
  if (filter) {
    for (const property in filter) {
      if (filter[property]) {
        urlBase.searchParams.append(property, filter[property]);
      }
    }
  }
  if (page > 0) {
    urlBase.searchParams.append("page", `${page}`);
  }

  apiBase.defaults.baseURL = urlBase.href;

  return apiBase;
};
export const teleexpertisesResourcesApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine`;

  return apiBase;
};
export const teleexpertisesApiCreate = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/responseTeleexpertise`;

  return apiBase;
};
export const teleexpertisesApiRespond = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/`;

  return apiBase;
};
export const teleexpertisesApiEntitys = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/telemedicine/teleexpertise/dataTeleexpertise`;

  return apiBase;
};
export const telexpertiseDocument = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/documents`;

  return apiBase;
};
