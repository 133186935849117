import { MutationTree } from 'vuex';
import { StateCenters } from './state';


const mutation: MutationTree<StateCenters> = {
    setCareCenters(state: StateCenters, { data }) {
        state.care_centers = data;
    },
    setReferencedCenters(state: StateCenters, { data }) {
        state.referenced_centers = data;
    },
}

export default mutation;