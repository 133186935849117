import { IPages } from "@/interfaces/customs/interface";
import {
  IManagementPlans,
  IProcedures,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import {
  BackgroundsList,
  DiagnosticsList,
  DocumentsList,
  FolioView,
  FoliosData,
  FoliosPages,
  FoliosRequest,
  ManagementPlansList,
  ProceduresList,
  VitalSignsView,
} from "@/store/folios/state";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { useStore } from "vuex";

export const useFoliosStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    // Folios V.2
    procedures: computed<IProcedures[]>(
      () => store.state.folios.data.procedures
    ),
    proceduresPages: computed<IPages>(
      () => store.state.folios.pages.procedures
    ),
    managementPlans: computed<IManagementPlans[]>(
      () => store.state.folios.data.managementPlans
    ),
    managementPlansPages: computed<IPages>(
      () => store.state.folios.pages.managementPlans
    ),
    request: computed<FoliosRequest>(() => store.state.folios.request),
    data: computed<FoliosData>(() => store.state.folios.data),
    pages: computed<FoliosPages>(() => store.state.folios.pages),
    response: computed<IRequestResponse>(() => store.state.folios.response),

    // Update endpoint
    folio: computed(() => store.state.folios.data.folio),
    vitalSigns: computed(() => store.state.folios.data.vitalSigns),
    physicalExam: computed(() => store.state.folios.data.physicalExam),
    diagnostics: computed(() => store.state.folios.data.diagnostics),
    diagnosticsPages: computed(() => store.state.folios.pages.diagnostics),
    backgrounds: computed(() => store.state.folios.data.backgrounds),
    backgroundsPages: computed(() => store.state.folios.pages.backgrounds),
    documents: computed(() => store.state.folios.data.documents),
    documentsPages: computed(() => store.state.folios.pages.documents),

    // Getters
    // Folios V.2
    getFolio: computed<FolioView | null>(
      () => store.getters["folios/getFolio"]
    ),
    getVitalSigns: computed<VitalSignsView | null>(
      () => store.getters["folios/getVitalSigns"]
    ),
    getDiagnostics: computed<DiagnosticsList[]>(
      () => store.getters["folios/getDiagnostics"]
    ),
    getBackgrounds: computed<BackgroundsList[]>(
      () => store.getters["folios/getBackgrounds"]
    ),
    getProcedures: computed<ProceduresList[]>(
      () => store.getters["folios/getProcedures"]
    ),
    getManagementPlans: computed<ManagementPlansList[]>(
      () => store.getters["folios/getManagementPlans"]
    ),
    getDocuments: computed<DocumentsList[]>(
      () => store.getters["folios/getDocuments"]
    ),

    // Actions
    // Folios V.2
    loadFolio: (params: { type: string; session: number }) =>
      store.dispatch("folios/loadFolio", params),
    loadVitalSigns: (params: { type: string; session: number }) =>
      store.dispatch("folios/loadVitalSigns", params),
    loadPhysicalExam: (params: { type: string; session: number }) =>
      store.dispatch("folios/loadPhysicalExam", params),
    loadDiagnostics: (params: {
      type: string;
      session: number;
      page: number;
    }) => store.dispatch("folios/loadDiagnostics", params),
    loadBackgrounds: (params: {
      type: string;
      session: number;
      page: number;
    }) => store.dispatch("folios/loadBackgrounds", params),
    loadProcedures: (params: { type: string; session: number; page: number }) =>
      store.dispatch("folios/loadProcedures", params),
    loadManagementPlans: (params: { type: string; session: number; page: number }) =>
      store.dispatch("folios/loadManagementPlans", params),
    loadDocuments: (params: { type: string; session: number; page: number }) =>
      store.dispatch("folios/loadDocuments", params),

    //Mutations
    // Folios V.2
    setFolio: () => store.commit("folios/setFolio"),
    setVitalSigns: () => store.commit("folios/setVitalSigns"),
    setPhysicalExam: () => store.commit("folios/setPhysicalExam"),
    setDiagnostics: () => store.commit("folios/setDiagnostics"),
    setBackgrounds: () => store.commit("folios/setBackgrounds"),
    setProcedures: () => store.commit("folios/setProcedures"),
    setManagementPlans: () => store.commit("folios/setManagementPlans"),
    setDocuments: () => store.commit("folios/setDocuments"),
  };
};
