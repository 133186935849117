import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";

export const useNursingNotes = () => {
  const store = useStore<StateInterface>();
  return {
    // State
    nursingNotesList: computed(() => store.state.nursingNotes.nursingNotesList),
    nursingNote: computed(() => store.state.nursingNotes.nursingNote),
    nursingNotesCurrentPage: computed(
      () => store.state.nursingNotes.nursingNotesCurrentPage
    ),
    nursingNotesLastPage: computed(
      () => store.state.nursingNotes.nursingNotesLastPage
    ),
    proceduresByNursingNote: computed(
      () => store.state.nursingNotes.nursingNotesLastPage
    ),
    documentTypesForDownload: computed<{ value: string; name: string }[] | []>(
      () => store.state.nursingNotes.documentTypesDownload
    ),

    // Actions
    _getListNursingNotes: (params: object) =>
      store.dispatch("nursingNotes/getNursingNotes", params),
    _createNursingNotes: (token: string, bodyRequest: any, documents: any) =>
      store.dispatch("nursingNotes/createNursingNotes", {
        token,
        bodyRequest,
        documents,
      }),
    _createNursingNotesV2: (bodyRequest: any) =>
      store.dispatch("nursingNotes/createNursingNotesV2", bodyRequest),
    _getDocumentTypesForDownloadV2: () =>
      store.dispatch("nursingNotes/getDocumentTypesForDownloadV2"),
    _generateFile: (id: number, url: string = "pdf") =>
      store.dispatch("nursingNotes/generateFile", { id, url }),
    _downloadOrSendEmailFiles: (
      id: number,
      formData: FormData,
      action: string
    ) =>
      store.dispatch("nursingNotes/downloadOrSendEmailFiles", {
        id,
        formData,
        action,
      }),
    _getListProceduresByNursingNoteID: (idNursingNote: number | string) =>
      store.dispatch(
        "nursingNotes/getListProceduresByNursingNoteID",
        idNursingNote
      ),
    _getNursingNoteById: (token: string, id: unknown) =>
      store.dispatch("nursingNotes/getNursingNoteByID", {
        token,
        id,
      }),
  };
};
