import { GetterTree } from "vuex";
import { UnificationMedicalRecordsState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<UnificationMedicalRecordsState, StateInterface> = {
  getUnificationMedicalRecordsList(state) {
    return state.unificationMedicalRecordsList;
  },
  getUnificationMedicalRecordsCurrentPage(state) {
    return state.unificationMedicalRecordsCurrentPage;
  },
  getUnificationMedicalRecordsLastPage(state) {
    return state.unificationMedicalRecordsLastPage;
  },
  getTypeRequest(state) {
    return state.typeRequest;
  },
  getReasonForConsultation(state) {
    return state.reasonForConsultation;
  },
  getUnificationMedicalRecord(state) {
    return [{ ...state.unificationMedicalRecord }].map(
      ({
        date_request: date,
        care_center,
        revision,
        type_request_service,
        requested_by,
        audited_by,
        status,
        reason_for_consultation,
        patient_one: patientOne,
        patient_two: patientTwo,
        observation,
        pdf_url: document,
        reason,
        documents,
      }) => ({
        date,
        careCenter: care_center?.name,
        revision,
        type: type_request_service?.name,
        requestedBy: requested_by?.name,
        auditedBy: audited_by?.name,
        status,
        motive: reason_for_consultation?.name,
        patientOne,
        patientTwo,
        observation,
        document,
        reason,
        documents,
      })
    )[0];
  },
};

export default getters;
