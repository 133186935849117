import { GetterTree } from "vuex";
import { StateInterface } from "..";
import { WarehousesState } from "./state";

const getters: GetterTree<WarehousesState, StateInterface> = {
  getWarehousesList(state) {
    return state.data.bodegas.length >= 1
      ? state.data.bodegas.map(({ id, description }) => ({
          id,
          description,
        }))
      : [];
  },
  getWarehousesEdit(state) {
    if (state.data.bodega) {
      const { id, description, reference_center_id } = state.data.bodega;

      return {
        id,
        description,
        reference_center_id,
      };
    }
    return null;
  },
};

export default getters;
