import { computed } from "vue";
import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import {
  CareProgram,
  CareProgramView,
  DataList,
} from "@/store/care-programs/state";

export const useCareProgramsStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State

    // Getters
    careProgramsList: computed<DataList[]>(
      () => store.getters["carePrograms/getCareProgramsList"]
    ),
    careProgramsCurrentPage: computed<number>(
      () => store.getters["carePrograms/getCareProgramsCurrentPage"]
    ),
    careProgramsLastPage: computed<number>(
      () => store.getters["carePrograms/getCareProgramsLastPage"]
    ),
    careProgram: computed<CareProgram>(
      () => store.getters["carePrograms/getCareProgramById"]
    ),
    careProgramView: computed<CareProgramView>(
      () => store.getters["carePrograms/getCareProgram"]
    ),

    // Actions
    getCarePrograms: (
      token: string,
      page: number,
      selects: { referenceCenter: number; careCenter: number }
    ) =>
      store.dispatch("carePrograms/getCarePrograms", {
        token,
        page,
        selects,
      }),
    _getCareProgramsListSelect: (token: string, urlQueryParams: unknown) =>
      store.dispatch("carePrograms/getCareProgramsListSelect", {
        token,
        urlQueryParams,
      }),
    changeCareProgramStatusById: (token: string, id: number) =>
      store.dispatch("carePrograms/changeCareProgramStatusById", { token, id }),
    createCareProgram: (
      token: string,
      bodyRequest: {
        reference_center_id: string | number;
        care_center_id: string | number;
        name: string;
        description: string;
      }
    ) =>
      store.dispatch("carePrograms/createCareProgram", { token, bodyRequest }),
    getCareProgramById: (token: string, id: number) =>
      store.dispatch("carePrograms/getCareProgramById", { token, id }),
    updateCareProgram: (
      token: string,
      id: number,
      bodyRequest: {
        reference_center_id: string | number;
        care_center_id: string | number;
        name: string;
        description: string;
      }
    ) =>
      store.dispatch("carePrograms/updateCareProgram", {
        token,
        id,
        bodyRequest,
      }),
    addPatient: (
      token: string,
      id: number,
      bodyRequest: [{ patient_id: number }]
    ) =>
      store.dispatch("carePrograms/addPatient", {
        token,
        id,
        bodyRequest,
      }),

    //Mutations
    clearCareProgramsList: () =>
      store.commit("carePrograms/clearCareProgramsList"),
  };
};
