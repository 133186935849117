import { IData, ITelesupport } from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { TelesupportsState } from "./state";

const mutation: MutationTree<TelesupportsState> = {
  setTelesupportSchedule(state, telesupportInfo) {
    state.telesupportSchedule = telesupportInfo;
  },

  setTelesupportsList(state: TelesupportsState, data: IData): void {
    if (data) {
      state.telesupportsList = data.data as unknown as ITelesupport[] | [];

      state.telesupportsCurrentPage = data.current_page;
      state.telesupportsLastPage = data.last_page;

      state.listRequest = true;
    } else {
      state.telesupportsList = [];
      state.listRequest = false;
    }
  },
  setTelesupportByID(state: TelesupportsState, data: ITelesupport) {
    if (data) {
      state.telesupport[0] = data;
    } else {
      state.telesupport = [];
    }
  },
};

export default mutation;
