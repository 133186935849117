
import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/manual-tarifas",
    name: "RateManualList",
    beforeEnter: [isAuthenticatedGuard],
    component: () => import("@/views/rate-manual/list/RateManualList.vue"),
  },
  {
    path: "/manual-tarifas/crear",
    name: "RateManualCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () => import("@/views/rate-manual/create/RateManualCreate.vue"),
  },
  {
    path: "/manual-tarifas/editar/:id(\\d+)",
    name: "RateManualEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () => import("@/views/rate-manual/edit/RateManualEdit.vue"),
  },
  {
    path: "/manual-tarifas/ver/:id(\\d+)",
    name: "RateManualView",
    beforeEnter: [isAuthenticatedGuard],
    component: () => import("@/views/rate-manual/view/RateManualView.vue"),
  },
];
