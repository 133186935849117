import { GetterTree } from "vuex";
import { NumerationsState } from "./state";
import { StateInterface } from "../index";

const MESSAGE = "Sin datos disponibles";

const STATUS_OPTIONS: { [key: string]: string } = {
  active: "Activo",
  inactive: "Inactivo",
  expired: "Vencido",
  test: "Prueba",
};

const getters: GetterTree<NumerationsState, StateInterface> = {
  getNumerationsList(state) {
    return state.data.numerations.length >= 1
      ? state.data.numerations.map(
          ({
            id,
            type,
            description,
            resolution_number: resolutionNumber,
            prefix,
            range_from: rangeFrom,
            range_to: rangeTo,
            start_number: startNumber,
            actual_number: actualNumber,
            start_date: startDate,
            end_date: endDate,
            status,
          }) => ({
            id,
            type,
            description,
            resolutionNumber,
            prefix,
            rangeFrom,
            rangeTo,
            startNumber,
            actualNumber,
            startDate,
            endDate,
            status,
          })
        )
      : [];
  },
  getNumerationsEdit(state) {
    if (state.data.numeration) {
      const {
        description,
        end_date,
        prefix,
        range_from,
        range_to,
        reference_center_id,
        resolution_number,
        start_date,
        start_number,
        status,
        type,
      } = state.data.numeration;

      return {
        description,
        end_date,
        prefix,
        range_from: range_from.toString(),
        range_to: range_to.toString(),
        reference_center_id,
        resolution_number,
        start_date,
        start_number: start_number.toString(),
        status,
        type,
      };
    }

    return null;
  },
  getNumerationsView(state) {
    if (state.data.numeration) {
      const {
        description,
        end_date: endDate,
        prefix,
        range_from: rangeFrom,
        range_to: rangeTo,
        reference_center,
        resolution_number,
        start_date: startDate,
        start_number: startNumber,
        actual_number: actualNumber,
        status,
        type,
      } = state.data.numeration;

      return {
        description,
        endDate,
        prefix: prefix ?? MESSAGE,
        rangeFrom,
        rangeTo,
        referenceCenter: reference_center.name,
        resolutionNumber: resolution_number ?? MESSAGE,
        startDate,
        startNumber,
        actualNumber,
        status: STATUS_OPTIONS[status],
        type,
      };
    }

    return null;
  },
};

export default getters;
