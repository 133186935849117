import { RateManualState } from "@/interfaces/global/interfaces";

function state(): RateManualState {
  return {
    rateManualContractsCurrentPage: 1,
    rateManualContractsLastPage: 1,
    rateManualContractsList: [],
    rateManualList: [],
    rateManualFeesList: [],
    rateManual: null,
    rateManualCurrentPage: 1,
    rateManualLastPage: 1,
  };
}

export default state;
