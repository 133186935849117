import { GetterTree } from "vuex";
import { NotificationsState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<NotificationsState, StateInterface> = {
  getSearchNotifications(state) {
    return state.notificationList;
  },

  getNotificationPagination(state){
   return state.notificationPagination 
  },

  getNotificationLoader(state) {
    return state.notificationLoader;
  },

  getNotificationsList(state) {
    return state.data.notifications.length >= 1
      ? state.data.notifications.map(
          ({
            id,
            description,
            type_notification,
            type_notification_id,
            created_at: date,
            is_read: status,
            possible_id: possibleId,
          }) => ({
            id,
            description,
            type: type_notification?.name,
            date,
            status,
            url: type_notification?.url_segment,
            possibleId,
            typeId: type_notification_id,
          })
        )
      : [];
  },
};

export default getters;
