import { isAuthenticatedGuard } from '@/router/auth-guard';

export default [
   
        {
            path: '/listado-reporte-de-inasistencias',
            name: 'AbsencesList',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/absences/AbsencesList.vue'),
        },

        {
            path: '/crear-reporte-de-inasistencias',
            name: 'AbsencesCreate',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/absences/subpages/absences-create/AbsencesCreate.vue'),
        },

        {
            path: '/ver-reporte-de-inasistencias/:id',
            name: 'AbsencesView',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/absences/subpages/absences-view/AbsencesView.vue'),
        }
]