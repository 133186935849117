import { GetterTree } from "vuex";
import { VaccinesState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<VaccinesState, StateInterface> = {
  getVaccinesList(state) {
    return state.data.vaccines.length >= 1
      ? state.data.vaccines.map(
          ({
            id,
            name,
            disease_it_prevents: prevents,
            status,
            reference_center_id: referenceCenter,
            care_center_id: careCenter,
          }) => ({
            id,
            name,
            prevents,
            status,
            referenceCenter,
            careCenter,
          })
        )
      : [];
  },
  getVaccinesEdit(state) {
    if (state.data.vaccine) {
      const { name, disease_it_prevents, type_doses } = state.data.vaccine;

      return {
        name,
        disease_it_prevents,
        type_doses: type_doses
          ? type_doses.length >= 1
            ? type_doses.map(({ id }) => id)
            : []
          : [],
      };
    }

    return null;
  },
  getVaccinesView(state) {
    if (state.data.vaccine) {
      const { name, disease_it_prevents, type_doses } = state.data.vaccine;

      return {
        name,
        prevents: disease_it_prevents ?? "Sin datos disponibles",
        doses: type_doses
          ? type_doses.length >= 1
            ? type_doses.map(({ id }) => id)
            : []
          : [],
      };
    }

    return null;
  },
};

export default getters;
