import { MutationTree } from "vuex";
import { DisabilityUI } from "./state";

const mutation: MutationTree<DisabilityUI> = {
  setDisabilitiesTableInfo(state, tableInfo) {
    state.disabilitiesTableInfo = tableInfo;
  },

  setDesabilitiesLastPage(state, lastPage) {
    state.desabilitiesLastPage = lastPage;
  },
  setDesabilitiesCurrentPage(state, currentPage) {
    state.desabilitiesCurrentPage = currentPage;
  },

  setCurrentPatientSearch(state, patientInfo) {
    state.currentPatientSearch = patientInfo;
  },

  setDiagnosticOptions(state, diagnosticOption) {
    state.diagnosticOptions = diagnosticOption;
  },
  setDisabilityInfoById(state, infoData) {
    state.disabilityInfoById = infoData;
  },
};

export default mutation;
