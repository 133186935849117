// interfaces
import { IGenderByTypes } from "@/interfaces/customs/Type";

export const useTypes = () => {
  const serviceType = (serviceType: number) => {
    switch (serviceType) {
      case -1:
        return "Todas las telexperticias";
      case 1:
        return "Telexperticia interactiva";
      case 2:
        return "Telexperticia no Interactivas";
      case 3:
        return "Teleapoyo Interactiva";
      case 9:
        return "Teleapoyo no interactiva";
      case 8:
        return "Telemedicina";
      case 10:
        return "Telemedicina no interactiva";
      default:
        return "Sin definir...";
    }
  };

  const responseMeetStatus = (statusMessage: string) => {
    switch (statusMessage) {
      case "Esta sesion esta cancelada":
        return {
          type: "counter/cancel",
          message: statusMessage,
        };
      case "Esta sesion esta enviada":
        return {
          type: "counter/reschedule",
          message: statusMessage,
        };

      case "Esta sesion esta realizada":
        return {
          type: "counter/success",
          message: statusMessage,
        };

      default:
        return {
          type: "counter/error",
          message: statusMessage,
        };
    }
  };

  const fullFileTypes = [
    "image/jpeg",
    "image/png",
    "image/pjpeg",
    "image/bmp",
    "image/x-windows-bmp",
    "video/avi",
    "video/msvideo",
    "video/x-msvideo",
    "audio/mpeg",
    "audio/x-mpeg",
    "audio/mp3",
    "audio/x-mp3",
    "audio/mpeg3",
    "audio/x-mpeg3",
    "audio/mpg",
    "audio/x-mpg",
    "audio/x-mpegaudio",
    "application/x-troff-msvideo",
    "application/msword",
    "application/pdf",
    "application/zsa",
    "application/dicom",
    "application/scp",
    "application/octet-stream",
    "text/plain",
    "text/xml",
    "application/x-zip-compressed",
  ];

  const genderTypes: IGenderByTypes = {
    "sin definir": {
      min: "sin definir",
      caps: "Sin definir",
      disMin: "",
      disCaps: "",
      contextA: "selección",
    },
    Masculino: {
      min: "el hombre",
      caps: "El hombre",
      disMin: "hombre",
      disCaps: "Hombre",
      contextA: "seleccionado",
    },
    Femenino: {
      min: "la mujer",
      caps: "La mujer",
      disMin: "mujer",
      disCaps: "Mujer",
      contextA: "seleccionada",
    },

    Teleapoyo: {
      min: "el Teleapoyo",
      caps: "El Teleapoyo",
      disMin: "el Teleapoyo",
      disCaps: "El Teleapoyo",
      contextA: "seleccionada",
    },
    "Telexperticia Interactiva": {
      min: "la Telexperticia Interactiva",
      caps: "La Telexperticia Interactiva",
      disMin: "la Telexperticia",
      disCaps: "La Telexperticia",
      contextA: "seleccionada",
    },
    "Telemedicina Interactiva": {
      min: "la Telemedicina Interactiva",
      caps: "La Telemedicina Interactiva",
      disMin: "la Telemedicina",
      disCaps: "La Telemedicina",
      contextA: "seleccionada",
    },
    "Teleapoyo Interactivo": {
      min: "el Teleapoyo Interactivo",
      caps: "El Teleapoyo Interactivo",
      disMin: "el Teleapoyo",
      disCaps: "El Teleapoyo",
      contextA: "seleccionado",
    },

    "Telemedicina no Interactiva": {
      min: "la Telemedicina no Interactiva",
      caps: "La Telemedicina no Interactiva",
      disMin: "la Telemedicina",
      disCaps: "La Telemedicina",
      contextA: "seleccionada",
    },
    Cirugía: {
      min: "la Cirugía",
      caps: "La Cirugía",
      disMin: "la Cirugía",
      disCaps: "La Cirugía",
      contextA: "seleccionada",
    },
    Consulta: {
      min: "la Consulta",
      caps: "La Consulta",
      disMin: "la Consulta",
      disCaps: "La Consulta",
      contextA: "seleccionada",
    },
    Cita: {
      min: "la Cita",
      caps: "La Cita",
      disMin: "la Cita",
      disCaps: "La Cita",
      contextA: "seleccionada",
    },
    Sesión: {
      min: "la Sesión",
      caps: "La Sesión",
      disMin: "la Sesión",
      disCaps: "La Sesión",
      contextA: "seleccionada",
    },
  };

  const genderRoleTypes: IGenderByTypes = {
    "Masculino Médico": {
      min: "el médico",
      caps: "El médico",
      disMin: "",
      disCaps: "",
      contextA: "seleccionado",
    },
    "Femenino Médico": {
      min: "la médica",
      caps: "La médica",
      disMin: "",
      disCaps: "",
      contextA: "seleccionada",
    },
    "Masculino Auxiliar": {
      min: "el auxiliar",
      caps: "El auxiliar",
      disMin: "",
      disCaps: "",
      contextA: "seleccionado",
    },
    "Femenino Auxiliar": {
      min: "la auxiliar",
      caps: "La auxiliar",
      disMin: "",
      disCaps: "",
      contextA: "seleccionada",
    },
    "Masculino Paciente": {
      min: "el paciente",
      caps: "El paciente",
      disMin: "",
      disCaps: "",
      contextA: "seleccionado",
    },
    "Femenino Paciente": {
      min: "la paciente",
      caps: "La paciente",
      disMin: "",
      disCaps: "",
      contextA: "seleccionada",
    },
    "Masculino Doctor": {
      min: "el médico",
      caps: "El médico",
      disMin: "",
      disCaps: "",
      contextA: "seleccionado",
    },
    "Femenino Doctor": {
      min: "la médica",
      caps: "La médica",
      disMin: "",
      disCaps: "",
      contextA: "seleccionada",
    },
    "Masculino Acompañante": {
      min: "el acompañante",
      caps: "El acompañante",
      disMin: "",
      disCaps: "",
      contextA: "seleccionado",
    },
    "Femenino Acompañante": {
      min: "la acompañante",
      caps: "La acompañante",
      disMin: "",
      disCaps: "",
      contextA: "seleccionada",
    },
  };

  const fileTypes = (fileType: string) => {
    switch (fileType) {
      case "image/jpeg":
        return "JPG";
      case "image/png":
        return "png";
      case "application/x-zip-compressed":
        return "ZIP";
      case "application/pdf":
        return "PDF";
      case "text/plain":
        return "TXT";
      default:
        return "?";
    }
  };

  return {
    serviceType,
    responseMeetStatus,
    fileTypes,
    fullFileTypes,
    genderTypes,
    genderRoleTypes,
  };
};
