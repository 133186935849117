import { useStore } from "vuex";
import { StateInterface } from '@/store/index';
import { computed } from "vue";

export const useResolution1604of2013 = () => {

    const store = useStore<StateInterface>();

    return {
        // State - Getter
        listResolution1604of2013: computed( () => store.state.resolution1604of2013.dataTable.data.data),
        currentPage: computed( () => store.state.resolution1604of2013.dataTable.current_page),
        lastPage: computed( () => store.state.resolution1604of2013.dataTable.last_page),
        // Actions
        isGetListResolution1604of2013: (data:any) => store.dispatch('resolution1604of2013/getListResolution1604of2013', data),
        isRequestResolution1604of2013: (data:any) => store.dispatch('resolution1604of2013/requestResolution1604of2013', data),
        isCancelResolutionStatus: (data:any) => store.dispatch('resolution1604of2013/cancelResolutionStatus', data),
        isGetEpsList: (data:any) => store.dispatch('resolution1604of2013/getListEps', data),
        //Mutations
        isSetCurrentPage: (data:number) => store.commit('resolution1604of2013/setListResolution1604of2013CurrentPage', data),
        isSetLastPage: (data:number) => store.commit('resolution1604of2013/setListResolution1604of2013LastPage', data)
    }
}