import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/administrador-medicamentos",
    name: "MedicationAdministratorList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Medication Administrator List" */ "@/views/medication-administrator/list/MedicationAdministratorList.vue"
      ),
  },
  {
    path: "/administrador-medicamentos/ver/:id(\\d+)",
    name: "MedicationAdministratorView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Medication Administrator View" */ "@/views/medication-administrator/view/MedicationAdministratorView.vue"
      ),
  },
  {
    path: "/administrador-medicamentos/crear",
    name: "MedicationAdministratorCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Medication Administrator Create" */ "@/views/medication-administrator/create/MedicationAdministratorCreate.vue"
      ),
  },
];