import { IPages } from "@/interfaces/customs/Pages";
import {
  ICareCenter,
  ILogin,
  IReferenceCenter,
  IReport,
  IRequestResponse,
  IUser,
} from "@/interfaces/global/interfaces";

type Pages = Record<"logins", IPages>;

export interface List {
  id: ILogin["id"];
  referenceCenter: IReferenceCenter["name"];
  careCenter: ICareCenter["name"];
  userType: IUser["user_type"];
  userName: IUser["name"];
  date: ILogin["created_at_formatted"];
  status: ILogin["status"];
  reason: ILogin["failure_reason"];
  detail: ILogin["user_agent"];
  ip: ILogin["ip_address"];
}

interface Data {
  logins: ILogin[];
  agents: string[];
  report: IReport | null;
}

export interface LoginsState {
  data: Data;
  pages: Pages;
  response: IRequestResponse;
}

function state(): LoginsState {
  return {
    data: {
      logins: [],
      agents: [],
      report: null,
    },
    pages: {
      logins: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
