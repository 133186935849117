import { executeApi } from "@/apis";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import errorException from "@/functions/ErrorException";
import { AdvanceDirectiveReportState } from "@/interfaces/global/AdvanceDirectiveReport";

const URL_PATH_ADVANCE_DIRECTIVES_REPORT =
  "/api/documents/report-advance-directive";

const actions: ActionTree<AdvanceDirectiveReportState, StateInterface> = {
  async listYearsSelectorAction({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH_ADVANCE_DIRECTIVES_REPORT}/years`,
        { params }
      );
      const { success, message } = data;
      commit("setYears", data.data);
      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async listMonthsSelectorAction({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH_ADVANCE_DIRECTIVES_REPORT}/months/`,
        { params }
      );
      const { success, message } = data;
      commit("setMonths", data.data);
      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async listIndicatorsAction({ commit }, params): Promise<object> {
    const transformedParams = {
      reference_center_id: params.center_ref_id,
      care_center_id: params?.care_center_id,
      year: params.year,
      month: params?.month,
    };
    try {
      const { data } = await executeApi().get(
        URL_PATH_ADVANCE_DIRECTIVES_REPORT,
        { params: transformedParams }
      );
      const { success, message } = data;
      commit("setIndicators", data);
      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async listDirectivesByTypeAction({ commit }, params): Promise<object> {
    const transformedParams = {
      reference_center_id: params.center_ref_id,
      care_center_id: params?.care_center_id,
      year: params.year,
      month: params?.month,
    };
    try {
      const { data } = await executeApi().get(
        `${URL_PATH_ADVANCE_DIRECTIVES_REPORT}/directives/`,
        {
          params: transformedParams,
        }
      );
      const { success, message } = data;
      commit("setDirectivesByType", data.data);
      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async generateReport(_, params): Promise<object> {
    try {
      const { data } = await executeApi().post(
        `${URL_PATH_ADVANCE_DIRECTIVES_REPORT}/report`,
        params 
      );
      const { success, message } = data;
      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },
};

export default actions;
