import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/programas-atencion",
    name: "CareProgramsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Care Programs List" */ "@/views/care-programs/CareProgramsList.vue"
      ),
  },
  {
    path: "/programas-atencion/crear-programas",
    name: "CareProgramsCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Care Programs Create" */ "@/views/care-programs/sub-pages/care-programs-create/CareProgramsCreate.vue"
      ),
  },
  {
    path: "/programas-atencion/editar-programas/:id(\\d+)",
    name: "CareProgramsEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Care Programs Edit" */ "@/views/care-programs/sub-pages/care-programs-edit/CareProgramsEdit.vue"
      ),
  },
];
