import axios from "axios";

// export const specialtesApi = axios.create({
//   baseURL: `${process.env.VUE_APP_URL}/api/settings/specialties`,
// });

import apiBase from "@/apis/AxiosIntance";

export const specialtesApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/specialties`;

  return apiBase;
};

// Get Specialty by Center
export const specialtyByCenter = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/specialties/getByCenter`,
});