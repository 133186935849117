import {
  ICreateMedicineFormV4,
  IMedicineDataById,
  MedicinesState,
} from "@/interfaces/global/Medicine";

function state(): MedicinesState {
  return {
    medicineCode: "",
    medicineDescription: "",
    pbs: undefined,

    // Medicines V.3
    requests: {
      medicine: false,
    },
    data: {
      medicines: [],
      medicine: null,
    },
    pages: {
      medicines: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
    medicinesListSelector: [],

    // Medicine V.4:
    filteredMedicineData: [],
    medicineFormData: null as ICreateMedicineFormV4 | null,
    medicineDataById: null as IMedicineDataById | null,
  };
}

export default state;
