import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/teleapoyo",
    name: "TelesupportsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Telesupports List" */ "@/views/telesupports/TelesupportsList.vue"
      ),
  },
  {
    path: "/teleapoyo/ver-teleapoyo/:id(\\d+)",
    name: "TelesupportsView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Telesupports View" */ "@/views/telesupports/sub-pages/telesupports-view/TelesupportsView.vue"
      ),
  },
  {
    path: "/teleapoyo/crear-teleapoyo",
    name: "TelesupportsCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Telesupports Create" */ "@/views/telesupports/sub-pages/telesupports-create/TelesupportsCreate.vue"
      ),
  },
  {
    path: "/teleapoyo/responder-teleapoyo/:id(\\d+)",
    name: "TelesupportsResponse",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Telesupports Response" */ "@/views/telesupports/sub-pages/telesupports-response/TelesupportsResponse.vue"
      ),
  },
];
