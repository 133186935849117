import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/cdas",
    name: "CdasView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Cdas View" */ "@/views/cdas/CdasView.vue"
      ),
  },
];
