// vuex
import { ActionTree } from "vuex";
import { SchedulesState } from "./state";
import { StateInterface } from "../index";

// apis
import { schedulesApiV3 } from "@/apis/schedulesApi";
import { schedulesApi } from "@/apis";

// composable
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";
import { useAlertV2 } from "@/composables/useAlertV2";

// interfaces
import { IErrors } from "@/interfaces/customs/IShowErrors";
import {
  IScheduleListCard,
  IScheduleListV2,
} from "@/interfaces/global/Schedule";

const { openMainLoader } = useMainLoader();
const { showCatchError } = showErrorInTS();
const { showAlert } = useAlertV2();

const actions: ActionTree<SchedulesState, StateInterface> = {
  async LoadScheduleList({ commit }, urlFilter: string) {
    // loader
    openMainLoader(true);
    //in table loader
    commit("setScheduleListBySpecialtyLoading", true);
    commit("setScheduleListBySpecialty", []);

    try {
      const doctorData = await schedulesApi().get(`/v3${urlFilter}`);
      const dataListRef = doctorData.data.data;

      const LastPage = dataListRef.schedules.last_page;
      const currentPage = dataListRef.schedules.current_page;

      const doctorsWithSchedule: IScheduleListV2[] = dataListRef.schedules.data;
      const scheduleDashboard: IScheduleListCard = dataListRef.dashboard;

      const listSheduleCard = [
        {
          title: "Habilitadas",
          icon: "mdi-calendar",
          quote: scheduleDashboard.enabled,
        },
        {
          title: "Asignadas",
          icon: "mdi-calendar-clock",
          quote: scheduleDashboard.scheduled,
        },
        {
          title: "No asistidas",
          icon: "mdi-calendar-question",
          quote: scheduleDashboard.absenced,
        },
        {
          title: "Canceladas",
          icon: "mdi-calendar-remove",
          quote: scheduleDashboard.cancelled,
        },
        {
          title: "Reasignadas",
          icon: "mdi-calendar-start",
          quote: scheduleDashboard.rescheduled,
        },
      ];

      const listSheduleRow =  doctorsWithSchedule.filter(schedule => schedule.status !== "Reagendada").map((schedule) => ({
        id: schedule.scheduleable.id,
        specialty: schedule.scheduleable.specialty.description,
        specialtyId: schedule.scheduleable.care_center.id,
        careCenter: schedule.scheduleable.care_center.name,
        careCenterId: schedule.scheduleable.care_center.id,
        doctor: schedule.scheduled_to
          ? `${schedule.scheduled_to.name}`
          : "Sin definir",
        doctorId: schedule.scheduled_to ? schedule.scheduled_to.id : null,
        doctorGender: !schedule.doctor
          ? "Sin definir"
          : schedule.doctor.details.gender,
        doctorSpecialty: schedule.scheduleable.specialty.description,
        patient: `${schedule.scheduleable.patient.name} ${schedule.scheduleable.patient.last_name}`,
        patientId: schedule.scheduleable.patient.id,
        patientGender: schedule.scheduleable.patient.gender,
        document: schedule.scheduleable.patient.document,
        date: schedule.scheduleable.date,
        sessionName: schedule.session_type.name,
        sessionId: schedule.session_type.id,
        status: schedule.status,
      }))

      commit("setScheduleListCard", listSheduleCard);
      commit("setScheduleListBySpecialty", listSheduleRow);
      commit("setSchedulesCurrentPage", currentPage);
      commit("setSchedulesLastPage", LastPage);
      //loader
      openMainLoader(false);
      //table loader
      commit("setScheduleListBySpecialtyLoading", false);

      //alert
      if (doctorsWithSchedule.length === 0) {
        showAlert("¡No hay agendamientos disponibles!", "warning");
      }
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setScheduleListBySpecialty", []);

      openMainLoader(false);
      //table loader
      commit("setScheduleListBySpecialtyLoading", false);
      //alert
      commit("general/setAlertType", "error", { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", showCatchError(errorParse), {
        root: true,
      });
      commit("general/setShowAlert", true, { root: true });
    }
  },

  // available list update

  async RescheduleAppointment(
    _,
    {
      sessionId,
      reason,
      type,
    }: {
      sessionId: number;
      reason: string;
      type: number;
    }
  ) {
    try {
      const doctorData = await schedulesApiV3().post(`/v3/reschedule`, {
        session_id: sessionId,
        reason,
        type,
      });

      const alertMessage = doctorData.data.message;

      return {
        status: "success",
        message: alertMessage,
      };
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      return {
        status: "error",
        message: errorParse,
      };
    }
  },

  async CancelSchedule(
    _,
    {
      sessionId,
      reason,
      type,
    }: {
      sessionId: number;
      reason: string;
      type: number;
    }
  ) {
    try {
      const doctorData = await schedulesApiV3().post(`/v3/cancel`, {
        session_id: sessionId,
        reason,
        type,
      });

      const alertMessage = doctorData.data.message;

      return {
        status: "success",
        message: alertMessage,
      };
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      return {
        status: "error",
        message: errorParse,
      };
    }
  },
};

export default actions;
