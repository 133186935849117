import sampleData from "@/sampledata/contracts/contractList";
import { MutationTree } from "vuex";
import { AtentionCenterState } from "./state";
import {
  ICareCenter,
  IData,
  IRequestResponse,
} from "@/interfaces/global/interfaces";

const mutation: MutationTree<AtentionCenterState> = {
  getAtentionCenters(state: AtentionCenterState, list: any): void {
    if (list) {
      state.atentionCenterList = list.data.data;

      state.atentionCenterCurrentPage = list.data.current_page;
      state.atentionCenterLastPage = list.data.last_page;
    }
  },
  changeAtentionCenterStatus(state: AtentionCenterState, status: object): void {
    if (status) {
      state.atentionCenterStatus = status;
    }
  },

  // Care Centers V.2
  setCareCenters(state: AtentionCenterState, data?: IData) {
    if (data) {
      state.data.careCenters = data.data as ICareCenter[];

      state.pages.careCenters.currentPage.value = data.current_page;
      state.pages.careCenters.lastPage.value = data.last_page;
    } else {
      state.data.careCenters = [];
    }
  },
  setResponse(state: AtentionCenterState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
