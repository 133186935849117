import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { NotesState } from "./state";

const URL_PATH_NOTE = "/api/telemedicine/note";

const actions: ActionTree<NotesState, StateInterface> = {
  async loadNotes({ commit }, params) {
    commit("setNotes");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH_NOTE}`, {
        params,
      });
      const { success, message, data } = response;

      commit("setNotes", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadNote({ commit }, id) {
    commit("setNote");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH_NOTE}/${id}`
      );
      const { success, message, data } = response;

      commit("setNote", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadPendingNotes({ commit }) {
    commit("setPendingNotes");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH_NOTE}/pending`
      );
      const { success, message, data } = response;

      commit("setPendingNotes", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createNote({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(`${URL_PATH_NOTE}`, bodyRequest);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async updateNote({ commit }, { id, bodyRequest }) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH_NOTE}/${id}`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async cancelNote({ commit }, { id, bodyRequest }) {
    commit("setNotes");
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH_NOTE}/${id}/cancel`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async approveNote({ commit }, { id, bodyRequest }) {
    commit("setNotes");
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH_NOTE}/${id}/approve`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async deleteNote({ commit }, id) {
    commit("setNotes");
    commit("setResponse");

    try {
      const { data } = await executeApi().delete(`${URL_PATH_NOTE}/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
};

export default actions;
