import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { IDisability } from "@/interfaces/global/interfaces";

export const useDisabilities = () => {
  const store = useStore<StateInterface>();
  return {
    // State

    types: computed(() => ["Única", "Prórroga"]),
    grades: computed(() => [
      "Temporal",
      "Permanente parcial",
      "Permanente total",
      "Permanente absoluta",
    ]),
    causes: computed(() => [
      "Enfermedad general",
      "Enfermedad laboral",
      "Accidente de trabajo",
      "Accidente de tráfico",
      "Otro tipo de accidente",
      "Lesión por agresión",
      "Lesión auto infringida",
      "Sospecha de maltrato físico",
      "Sospecha de abuso sexual",
      "Sospecha de violencia sexual",
      "Sospecha de maltrato emocional",
      "Otra",
    ]),
    disabilitiesValues: computed<null | IDisability>(
      () => store.state.telemedicine.disabilitiesData
    ),

    // Mutation
    setDisabilities: (details: object) =>
      store.commit("telemedicine/setDisabilities", details),
  };
};
