import { MutationTree } from "vuex";
import {
  // AllResources,
  Data,
  DataList,
  Datum,
  ReportTelexpertisesList,
  ScheduleReportsState,
  Telexpertises,
} from "./state";

const mutation: MutationTree<ScheduleReportsState> = {
  getScheduleReports(state: ScheduleReportsState, data: Data): void {
    if (data) {
      if (data.data.length >= 1) {
        const scheduleReportsData = data.data as Datum[];

        state.scheduleReportsList = scheduleReportsData.map(
          ({
            id,
            type_teleexpertise,
            patient,
            from_date_formatted: fromDate,
            to_date_formatted: toDate,
            created_at_formatted: generatedDate,
            user,
            pdf_url: pdf,
            excel_url: excel,
          }: Datum): DataList => ({
            id,
            type: type_teleexpertise?.name,
            patient: patient ? `${patient.name} ${patient.last_name}` : "Todos",
            document: patient?.document ?? "Todos",
            fromDate,
            toDate,
            generatedDate,
            requestedBy: user?.name,
            pdf,
            excel,
          })
        );
      } else state.scheduleReportsList = [];

      state.scheduleReportsCurrentPage = data.current_page;
      state.scheduleReportsLastPage = data.last_page;
    }
  },
  getTelexpertisesByReport(state: ScheduleReportsState, data: Data) {
    if (data) {
      if (data.data.length >= 1) {
        const reportsData = data.data as Telexpertises[];

        state.reportTelexpertisesList = reportsData.map(
          ({
            type_teleexpertise,
            specialty,
            patient,
            current_schedule
          }: Telexpertises): ReportTelexpertisesList => ({
            type: type_teleexpertise?.name,
            doctor: current_schedule?.scheduled_to?.name,
            specialty: specialty?.description,
            patient: `${patient?.name} ${patient?.last_name}`,
            document: patient?.document,
            eps: patient?.eps?.name,
            scheduled: current_schedule?.scheduled_at_formatted,
          })
        );
      } else state.reportTelexpertisesList = [];

      state.reportTelexpertisesCurrentPage = data.current_page;
      state.reportTelexpertisesLastPage = data.last_page;
    }
  },
  getScheduleReportById(state: ScheduleReportsState, data: Datum) {
    if (data) {
      state.scheduleReport = data;
    }
  },
  clearScheduleReportsList(state: ScheduleReportsState) {
    state.scheduleReportsList = [];
  },
  clearScheduleReportsData(state) {
    state.scheduleReport = {
      id: 0,
      user_id: 0,
      reference_center_id: 0,
      care_center_id: null,
      type_teleexpertise_id: 0,
      patient_id: 0,
      status: "",
      from_date: "",
      to_date: "",
      pdf_url: null,
      excel_url: null,
      created_at: "",
      from_date_formatted: "",
      to_date_formatted: "",
      created_at_formatted: "",
      user: {
        id: 0,
        user_info_id: 0,
        name: "",
        details: {
          id: 0,
          names: "",
          surnames: "",
        },
      },
      type_teleexpertise: {
        id: 0,
        name: "",
      },
      reference_center: {
        id: 0,
        name: "",
      },
      care_center: null,
      patient: {
        id: 0,
        name: "",
        last_name: "",
        document: "",
      },
    };

    state.reportTelexpertisesList = [];
    state.reportTelexpertisesCurrentPage = 0;
    state.reportTelexpertisesLastPage = 0;
  },
};

export default mutation;
