import axios from "axios";


export const medicinesReportApi = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/documents/report-medicine`
});

export const specialitiesListApi = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/documents/report-procedure/specialities`
});

export const professionalsListApi = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/documents/report-procedure/professionals`
})