import { DirectiveBinding } from "vue";

interface CurrencyBinding {
  amount: number;
  currency?: string;
}

export const currencyDirective = {
  mounted(el: HTMLElement, binding: DirectiveBinding<CurrencyBinding>) {
    const { amount, currency = "COP" } = binding.value;
    el.innerText = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
    }).format(amount);
  },
  updated(el: HTMLElement, binding: DirectiveBinding<CurrencyBinding>) {
    const { amount, currency = "COP" } = binding.value;
    el.innerText = new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
    }).format(amount);
  },
};
