import { IDataTable } from "@/interfaces/customs/DataTable";
import { MutationTree } from "vuex";
import { OxygenControlState } from "./state";

const mutation: MutationTree<OxygenControlState> = {
  setOxygenControls(state: OxygenControlState, list: IDataTable): void {
    state.oxygenControlList = list?.data;

    state.oxygenControlCurrentPage = list?.current_page;
    state.oxygenControlLastPage = list?.last_page;
  },
  setDataOxygenControl(state: OxygenControlState, data): void {
    state.dataOxygenControl = data
  },
  setDataTableOxygenControl(state: OxygenControlState, dataTable): void {
    state.dataTable = dataTable
  },
};

export default mutation;
