import { MutationTree } from "vuex";
import { WarehousesState } from "./state";
import {
  IRequestResponse,
  IData,
  IWarehouse,
} from "@/interfaces/global/interfaces";

const mutation: MutationTree<WarehousesState> = {
  setResponse(state: WarehousesState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
  setWarehouses(state: WarehousesState, data?: IData): void {
    if (data) {
      state.data.bodegas = data.data as IWarehouse[];

      state.pages.bodegas.currentPage.value = data.current_page;
      state.pages.bodegas.lastPage.value = data.last_page;
    } else {
      state.data.bodegas = [];
    }
  },
  setWarehouse(state: WarehousesState, data?: IWarehouse | null): void {
    if (data !== undefined) {
      state.data.bodega = data;
      state.requests.bodega = true;
    } else {
      state.data.bodega = null;
      state.requests.bodega = false;
    }
  },
};

export default mutation;
