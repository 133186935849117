import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/bancos",
    name: "BanksList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Banks List" */ "@/views/banks/BanksList.vue"
      ),
  },
  {
    path: "/bancos/crear-bancos",
    name: "BanksCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Banks Create" */ "@/views/banks/sub-pages/banks-create/BanksCreate.vue"
      ),
  },
  {
    path: "/bancos/editar-bancos/:id(\\d+)",
    name: "BanksEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Banks Edit" */ "@/views/banks/sub-pages/banks-edit/BanksEdit.vue"
      ),
  },
];
