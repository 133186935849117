import { MutationTree } from "vuex";
import { AdvanceDirectiveReportState } from "@/interfaces/global/AdvanceDirectiveReport";

const mutations: MutationTree<AdvanceDirectiveReportState> = {
  setYears(state: AdvanceDirectiveReportState, list: number[] | []): void {
    state.years = list;
  },
  setMonths(
    state: AdvanceDirectiveReportState,
    list: { id: number; name: string }[] | []
  ): void {
    state.months = list;
  },
  setIndicators(state: AdvanceDirectiveReportState): void {
    state.indicators = {
      success: true || false,
      message: "",
      data: {
        indications: {
          care: {},
          donation: {},
          eol: {},
          indication: {},
        },
        patients: {
          total: 0,
          with_advance_directive: 0,
          without_advance_directive: 0,
        },
      },
    };
  },
  setDirectivesByType(state: AdvanceDirectiveReportState): void {
    state.directives = {
      success: true || false,
      message: "",
      data: {
        care: {},
        donation: {},
        eol: {},
        indication: {},
      },
    };
  },
};

export default mutations;
