import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";

export const useAdministrators = () => {
  const store = useStore<StateInterface>();
  return {
    // States
    administratorsList: computed(
      () => store.state.administrators.administratorsList
    ),
    administratorRow: computed(
      () => store.state.administrators.administratorRow
    ),
    administratorsCurrentPage: computed(
      () => store.state.administrators.administratorsCurrentPage
    ),
    administratorsLastPage: computed(
      () => store.state.administrators.administratorsLastPage
    ),
    states: computed(() => store.state.administrators.states),

    // Actions
    _getListAdministrators: (params: object) =>
      store.dispatch("administrators/getListAdministrators", params),
    _createAdministrator: (params: object) =>
      store.dispatch("administrators/createAdministrator", params),
    _getAdministratorsById: (id: number | string) =>
      store.dispatch("administrators/getAdministratorsById", id),
    _editAdministrator: (params: object, id: number | string) =>
      store.dispatch("administrators/editAdministrator", { params, id }),
    _changeStatusAdministrator: (id: number | string) =>
      store.dispatch("administrators/changeStatusAdministrator", id),
    _deleteAdministrator: (id: number | string) =>
      store.dispatch("administrators/deleteAdministrator", id),
  };
};
