import { isAuthenticatedGuard } from '@/router/auth-guard';

export default [
   
        {
            path: '/plantillas-de-antecedentes',
            name: 'BackgroundTemplates',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/background-templates/BackgroundTemplates.vue'),
        },

        {
            path: '/crear-plantillas-de-antecedentes',
            name: 'BackgroundTemplatesCreated',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/background-templates/subpages/created/BackgroundTemplatesCreated.vue'),
        },

        {
            path: '/editar-plantillas-de-antecedentes/:id',
            name: 'BackgroundTemplatesEdit',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/background-templates/subpages/edit/BackgroundTemplatesEdit.vue'),
        },
        {
            path: '/ver-plantillas-de-antecedentes/:id',
            name: 'BackgroundTemplatesView',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/background-templates/subpages/view/BackgroundTemplatesView.vue'),
        },
]