import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/facturacion",
    name: "InvoicingList",
    beforeEnter: [isAuthenticatedGuard],
    component: () => import("@/views/invoicing/list/InvoicingList.vue"),
  },
  {
    path: "/facturacion/crear",
    name: "InvoicingCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () => import("@/views/invoicing/create/InvoicingCreate.vue"),
  },
];
