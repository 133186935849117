import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/diagnosticos",
    name: "DiagnosticsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Diagnostics List" */ "@/views/diagnostics/DiagnosticsList.vue"
      ),
  },
  {
    path: "/diagnosticos/crear-diagnosticos",
    name: "DiagnosticsCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Diagnostics Create" */ "@/views/diagnostics/sub-pages/diagnostics-create/DiagnosticsCreate.vue"
      ),
  },
  {
    path: "/diagnosticos/editar-diagnosticos/:id(\\d+)",
    name: "DiagnosticsEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Diagnostics Edit" */ "@/views/diagnostics/sub-pages/diagnostics-edit/DiagnosticsEdit.vue"
      ),
  },
];
