import { ActionTree } from "vuex";
import { DiagnosticsState, Response } from "./state";
import { StateInterface } from "../index";
import { diagnosticsApi, executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";

const URL_PATH: { [key: number]: string } = {
  1: "/api/settings/diagnostics",
  2: "/api/settings/diagnostics/v2",
  3: "/api/settings/diagnostics/v3"
}

const actions: ActionTree<DiagnosticsState, StateInterface> = {
  async changeDiagnosticStatusById(
    { commit },
    { token, id }
  ): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await diagnosticsApi().post(
        `/changeStatus/${id}`,
        {},
        config
      );
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  // async createDiagnostic(
  //   { commit },
  //   { token, bodyRequest }
  // ): Promise<Response> {
  //   const config: object = token
  //     ? {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     : {};

  //   try {
  //     const { data } = await diagnosticsApi().post("/", bodyRequest, config);
  //     const { success, message } = data;

  //     return { success, message };
  //   } catch (error) {
  //     return errorException(error);
  //   }
  // },
  async getDiagnosticById({ commit }, { token, id }): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await diagnosticsApi().get(`/${id}`, config);
      const { success, message, data: diagnosticData } = data;

      commit("getDiagnosticById", diagnosticData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  // async updateDiagnostic(
  //   { commit },
  //   { token, bodyRequest }
  // ): Promise<Response> {
  //   const config: object = token
  //     ? {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     : {};

  //   try {
  //     const { data } = await diagnosticsApi().post("/", bodyRequest, config);
  //     const { success, message } = data;

  //     return { success, message };
  //   } catch (error) {
  //     return errorException(error);
  //   }
  // },


  // Diagnostics V.2
  // async loadDiagnostics({ commit }, params) {
  //   commit("setDiagnostics");
  //   commit("setResponse");

  //   try {
  //     const { data: response } = await diagnosticsApi().get("/", { params });
  //     const { success, message, data } = response;

  //     commit("setDiagnostics", data);
  //     commit("setResponse", { success, message });
  //   } catch (error) {
  //     const response = errorException(error);
  //     commit("setResponse", response);
  //   }
  // },
  async loadDiagnostics({ commit }, { params, version }) {
    commit("setDiagnostics");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(
        `${URL_PATH[version]}`,
        {
          params,
        }
      );
      const { success, message, data } = response;

      commit("setDiagnostics", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadDiagnostic({ commit }, id) {
    commit("setDiagnostic");
    commit("setResponse");

    try {
      const { data: response } = await executeApi().get(`${URL_PATH[1]}/${id}`);
      const { success, message, data } = response;

      commit("setDiagnostic", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createDiagnostic({ commit }, bodyRequest) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(`${URL_PATH[2]}`, bodyRequest);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async updateDiagnostic({ commit }, { id, bodyRequest }) {
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH[2]}/${id}`,
        bodyRequest
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async changeDiagnosticStatus({ commit }, id) {
    commit("setDiagnostics");
    commit("setResponse");

    try {
      const { data } = await executeApi().post(
        `${URL_PATH[1]}/changeStatus/${id}`
      );
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async deleteDiagnostic({ commit }, id) {
    commit("setDiagnostics");
    commit("setResponse");

    try {
      const { data } = await executeApi().delete(`${URL_PATH[1]}/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
};

export default actions;
