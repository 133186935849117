import { ActionTree } from "vuex";
import { TelemedicineState } from "./state";
import { StateInterface } from "../index";
import { telemedicineApi, executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { IError } from "@/interfaces/customs/interface";
import { IPatientBySchedule } from "@/interfaces/global/Patient";

const headers = {
  "Content-Type": "multipart/form-data",
};

const URL_PATH = "/api/telemedicine";

const actions: ActionTree<TelemedicineState, StateInterface> = {
  async getTelemedicinesList({ commit }, { filters, page }): Promise<object> {
    try {
      const { data } = await telemedicineApi(filters, page).get(``);
      const { success, message } = data;
      data.data.data.forEach(
        (element: {
          patient: { name: string; last_name: string; document: string };
          care_center: { name: string };
          current_schedule: { status: string; session_date: string };
          center_attention: string;
          patient_name: string;
          document: string;
          status_telemedicine: string;
          session_date: string;
        }) => {
          element[
            "patient_name"
          ] = `${element?.patient?.name} ${element?.patient?.last_name}`;
          element["document"] = element?.patient?.document;
          element["status_telemedicine"] = element?.current_schedule?.status
            ? element?.current_schedule.status
            : "";
          element["session_date"] = element?.current_schedule?.session_date
            ? element?.current_schedule.session_date
            : "";
          element["center_attention"] = element?.care_center.name;
        }
      );
      commit("setTelemedicineList", data.data);

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getTelemedicineById({ commit }, { idTelemedicine }): Promise<object> {
    try {
      const { data } = await telemedicineApi(null, 0).get(
        `/get/${idTelemedicine}`
      );

      const responseInfoRef: IPatientBySchedule = data.data;
      const scheduleInfo = {
        initialHour: responseInfoRef.currentSchedule.scheduled_at,
        finalHour: responseInfoRef.currentSchedule.schedule_final_hour,
        meetURL: responseInfoRef.currentSchedule.schedule_url,
        status: responseInfoRef.currentSchedule.status,
        patient: `${responseInfoRef.patient.name} ${responseInfoRef.patient.last_name}`,
        formatedDay: responseInfoRef.currentSchedule.scheduled_at_formatted,
        type: responseInfoRef.currentSchedule.scheduleable_type_formatted
      }

      const { success, message } = data;

      commit("setTelemedicine", data.data);
      commit('setTelemedicineMeetInfo', scheduleInfo)

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async createTelemedicine(
    { commit },
    { bodyRequest, annexDocuments, medicalExamsDocuments }
  ): Promise<object> {
    try {
      const { data } = await telemedicineApi(null, 0).post(
        "",
        {
          data: bodyRequest,
          documents: annexDocuments,
          medical_exams: medicalExamsDocuments,
        },

        { headers }
      );

      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getEntityByTelemdicine(
    { commit },
    { telemedicineId, entityName, page = 1 }
  ) {
    try {
      const { data } = await telemedicineApi(null, 0).get(
        `/${entityName}/${telemedicineId}?page=${page}`
      );

      const { success, message, data: dataEntity } = data;

      return { success, message, dataEntity };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getEntityResponse({ commit }, { params, responseId }): Promise<object> {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH}/responseTeleexpertise/${params.entityName}/${responseId}`,
        { params }
      );
      const { success, message } = data;

      return { success, message, data: data.data };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
};

export default actions;
