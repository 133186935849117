import { INote } from "@/interfaces/global/Note";
import { StateInterface } from "@/store/index";
import { NotesEdit, NotesList } from "@/store/notes/state";
import { computed, toRef } from "vue";
import { useStore } from "vuex";

export const useNotesStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    notes: toRef(store.state.notes.data, "notes"),
    notesPages: toRef(store.state.notes.pages, "notes"),
    note: toRef(store.state.notes.data, "note"),
    pendingNotes: toRef(store.state.notes.data, "pendingNotes"),
    requests: toRef(store.state.notes, "requests"),
    data: toRef(store.state.notes, "data"),
    pages: toRef(store.state.notes, "pages"),
    response: toRef(store.state.notes, "response"),

    // Getters
    getNotesList: computed<NotesList[]>(
      () => store.getters["notes/getNotesList"]
    ),
    getNotesEdit: computed<NotesEdit>(
      () => store.getters["notes/getNotesEdit"]
    ),

    // Actions
    loadNotes: (params: {
      page?: number;
      rows?: number;
      status?: string;
      search?: string;
    }) => store.dispatch("notes/loadNotes", params),
    loadNote: (id: number) => store.dispatch("notes/loadNote", id),
    loadPendingNotes: () => store.dispatch("notes/loadPendingNotes"),
    createNote: (bodyRequest: {
      status: string;
      date: string;
      mail_notification: number;
      description: string;
    }) => store.dispatch("notes/createNote", bodyRequest),
    updateNote: (
      id: number,
      bodyRequest: {
        _method: "PATCH";
        status: string;
        date: string;
        mail_notification: number;
        description: string;
      }
    ) => store.dispatch("notes/updateNote", { id, bodyRequest }),
    cancelNote: (
      id: number,
      bodyRequest: {
        _method: "PATCH";
      }
    ) =>
      store.dispatch("notes/cancelNote", {
        id,
        bodyRequest,
      }),
    approveNote: (
      id: number,
      bodyRequest = {
        _method: "PATCH",
      }
    ) =>
      store.dispatch("notes/approveNote", {
        id,
        bodyRequest,
      }),
    deleteNote: (id: number) => store.dispatch("notes/deleteNote", id),

    //Mutations
    setNotes: () => store.commit("notes/setNotes"),
    setNote: (data?: INote) => store.commit("notes/setNote", data),
    setPendingNotes: () => store.commit("notes/setPendingNotes"),
  };
};
