import { GetterTree } from "vuex";
import { NotesState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<NotesState, StateInterface> = {
  getNotesList(state) {
    return state.data.notes.length >= 1
      ? state.data.notes.map(
          ({
            id,
            description,
            created_at_formatted: createdAt,
            date_formatted: date,
            due_days: dueDays,
            status,
          }) => ({
            id,
            description,
            createdAt,
            date,
            dueDays,
            status,
          })
        )
      : [];
  },
  getNotesEdit(state) {
    if (state.data.note) {
      const {
        status,
        date_formatted: date,
        mail_notification,
        description,
      } = state.data.note;

      return {
        _method: "PATCH",
        status,
        date,
        mail_notification: mail_notification ? 1 : 0,
        description,
      };
    }

    return null;
  },
};

export default getters;
