import moment, { Moment } from "moment";

export const useRules = () => {
  return {
    only_number_greater_than_zero_with_decimal: (val: string) => {
      const numberValue = parseFloat(val);
      return (
        (!isNaN(numberValue) && numberValue >= 0) ||
        "Ingresa un número mayor o igual a cero (0) o un decimal positivo sin comas."
      );
    },
    only_number_greater_than_zero: (val: string) => {
      const numberValue = parseFloat(val);
      return (
        (!isNaN(numberValue) && numberValue >= 0) ||
        "Ingresa un número mayor o igual a cero (0)"
      );
    },
    date_before_or_equal_to_the_current_date: (val: string) => {
      // Date now with format moment
      const today: Moment = moment();

      const validDate = moment(val, "YYYY/MM/DD", true).isValid();

      if (!validDate) {
        return "Ingresa una fecha válida en el formato YYYY/MM/DD.";
      }

      const formattedDate = moment(val, "YYYY/MM/DD", true).format(
        "YYYY-MM-DD"
      );

      return (
        moment(formattedDate).isSameOrBefore(today, "day") ||
        "Ingresa una fecha anterior o igual a la fecha actual."
      );
    },
    date_before_or_equal_to_the_specific_date: (
      val: string,
      specificDate: string
    ) => {
      const format = "YYYY/MM/DD";

      const isValidDate = (date: string): boolean =>
        moment(date, format, true).isValid();

      if (!isValidDate(val) || !isValidDate(specificDate)) {
        return `Ingresa una fecha válida en el formato ${format}.`;
      }

      const inputDate: Moment = moment(val, format);
      const comparisonDate: Moment = moment(specificDate, format);
      const today: Moment = moment();

      if (!inputDate.isSameOrAfter(comparisonDate, "day")) {
        return "Ingresa una fecha superior o igual a la fecha inicial.";
      }

      if (!inputDate.isSameOrBefore(today, "day")) {
        return "Ingresa una fecha anterior o igual a la fecha actual.";
      }

      return true;
    },
    date_greater_than_or_equal_to_the_patient_date_of_birth: (
      val: string,
      birthday: string | null | undefined
    ) => {
      if (!birthday) {
        return "La fecha de nacimiento del paciente es necesaria";
      }
      const validDate = moment(val, "YYYY/MM/DD", true).isValid();
      const validDateBirthday = moment(birthday).isValid();

      if (!validDate) {
        return "Ingresa una fecha válida en el formato YYYY/MM/DD.";
      }

      if (!validDateBirthday) {
        return "La fecha de nacimiento del paciente no es valida";
      }

      const formattedDate = moment(val, "YYYY/MM/DD", true).format(
        "YYYY-MM-DD"
      );
      const formattedBirthDay = moment(birthday).utc().format("YYYY-MM-DD");

      return (
        moment(formattedDate).isSameOrAfter(formattedBirthDay, "day") ||
        "Ingresa una fecha mayor o igual a la fecha de nacimiento del paciente"
      );
    },
  };
};
