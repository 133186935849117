import { Module } from "vuex";
import { StateInterface } from "../index";

import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

import { ResourcesState } from "@/interfaces/global/interfaces";

const resourcesModule: Module<ResourcesState, StateInterface> = {
  namespaced: true,
  actions,
  //   getters,
  mutations,
  state,
};

export default resourcesModule;
