import { IDataTable, IDataTableV2 } from "@/interfaces/customs/interface";
import { RateManualState } from "@/interfaces/global/interfaces";
import { IFeesByRateManual, IRateManual } from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";

const mutations: MutationTree<RateManualState> = {
  setList(state: RateManualState, data: IDataTable) {
    state.rateManualList = data.data;
    state.rateManualCurrentPage = data.current_page;
    state.rateManualLastPage = data.last_page;
  },
  setListContract(state: RateManualState, data: IDataTable) {
    state.rateManualContractsList = data.data;
    state.rateManualContractsCurrentPage = data.current_page;
    state.rateManualContractsLastPage = data.last_page;
  },
  setListFees(state: RateManualState, data: IDataTableV2<IFeesByRateManual>) {
    const dataRequest = data.data ?? ([] as IFeesByRateManual[]);
    state.rateManualFeesList = dataRequest.map((feed) => ({
      id: feed.id,
      cups: feed.cups ?? "",
      procedure_id: feed.procedure?.id ?? null,
      name: feed.name ?? "",
      rate: feed.rate,
      subprogram: feed.subprogram ?? "",
      value: feed.value,
      status: feed.status,
    }));
  },
  setById(state: RateManualState, data: IRateManual) {
    state.rateManual = null;
    if (data) {
      state.rateManual = data;
    }
  },
};

export default mutations;
