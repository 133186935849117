import { Module } from 'vuex';
import { StateInterface } from '../index';

import state, { IndicatorsUI } from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const indicatorsModule: Module<IndicatorsUI, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state, 
}

export default indicatorsModule;