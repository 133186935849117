import { IPages } from "@/interfaces/customs/Pages";
import {
  IBackgrounds,
  IClinicalLaboratories,
  ICurrentSchedule,
  IDiagnostics,
  IDisabilities,
  IFile,
  IMedicines,
  IMedicalExams,
  IPatient,
  IProcedures,
  IReferenceCenter,
  IRequestResponse,
  IScheduleable,
  ISessionType,
  ISpecialty,
  ISpecialtyType,
  IVaccines,
  ICareCenter,
  ICarePrograms,
  IGynecologist,
  IAdvanceDirective,
  IService,
  IServiceable,
} from "@/interfaces/global/interfaces";

// Medical Records V.2
export type MedicalRecords =
  | "folios"
  | "diagnostics"
  | "backgrounds"
  | "procedures"
  | "managementPlans"
  | "documents"
  | "clinicalLaboratories"
  | "medicalExams"
  | "vaccines"
  | "disabilities";

export type MedicalRecordsRequest = Record<
  "patient" | "gynecologist" | MedicalRecords,
  boolean
>;
export type MedicalRecordsPages = Record<MedicalRecords, IPages>;

export interface MedicalRecordView {
  fullName: IPatient["full_name"];
  documentType: IPatient["document_type"]["abbreviation"] | undefined;
  document: IPatient["document"] | undefined;
  gender: IPatient["gender"] | undefined;
  birthday: IPatient["birthday"] | undefined;
  age: IPatient["age"] | undefined;
  photo: IPatient["photo"];
  advanceDirective: IAdvanceDirective["pdf_url"] | undefined;
}

export interface PatientView {
  name: IPatient["name"];
  lastName: IPatient["last_name"];
  documentType: IPatient["document_type"]["name"];
  document: IPatient["document"];
  phone: IPatient["phone"];
  email: IPatient["email"];
  department: IPatient["department_name"];
  city: IPatient["city_name"];
  address: IPatient["address"];
  eps: IPatient["eps"]["name"];
  maritalStatus: IPatient["marital_status"];
  gender: IPatient["gender"];
  birthday: IPatient["birthday"];
  regime: IPatient["regime"]["name"];
  membership: IPatient["membership"]["name"];
  scholarship: IPatient["scholarship"];
  occupation: IPatient["occupation"];
  companionName: IPatient["companion_name"];
  companionPhone: IPatient["companion_phone"];
  companionDocument: IPatient["companion_document"];
  populationType: IPatient["type_of_population"];
  rh: IPatient["rh_group"];
  vitalState: IPatient["vital_state"];
  prepaid: IPatient["prepaid"]["name"];
  arl: IPatient["arl"]["name"];
  referenceCenter: IReferenceCenter["name"];
  careCenter: ICareCenter["name"];
  sex: IPatient["sex"]["name"];
  careProgram: ICarePrograms["name"];
  expeditionLocation: IPatient["document_expedition_location"];
  nationality: IPatient["nationality"];
  secondaryPhone: IPatient["secondary_phone"];
}

export interface FoliosList {
  id: IService["identifier"];
  center: IReferenceCenter["name"];
  specialty: ISpecialty["description"];
  date: IServiceable["date"];
  ambit: IServiceable["query_type"];
  type: ISessionType["name"];
  status: IServiceable["status"];
  scheduleableType: IService["serviceable_type"];
  scheduleableId: IServiceable["id"];
}

export interface DiagnosticsList {
  id: IDiagnostics["diagnosticable_id"];
  center: IDiagnostics["reference_center"]["name"];
  date: IDiagnostics["created_at_formatted"];
  description: IDiagnostics["diagnostic"]["description"];
  type: IDiagnostics["type"];
  diagnosticableType: IDiagnostics["diagnosticable_type"];
}

export interface BackgroundsList {
  id: IBackgrounds["backgroundable_id"];
  center: IReferenceCenter["name"];
  date: IBackgrounds["created_at_formatted"];
  type: IBackgrounds["background"]["name"];
  description: IBackgrounds["description"];
  backgroundableType: IBackgrounds["backgroundable_type"];
}

export interface ProceduresList {
  id: IProcedures["procedurable_id"];
  center: IProcedures["procedurable"]["reference_center"]["name"];
  date: IProcedures["created_at_formatted"];
  procedure: IProcedures["procedure"]["description"];
  presentationType: IProcedures["presentation_type"];
  usageFrequency: IProcedures["usage_frequency"];
  amount: IProcedures["ammount"];
  treatmentDuration: IProcedures["treatment_duration"];
  observation: IProcedures["observation"];
  warningSigns: IProcedures["warning_signs"];
  procedurableType: IProcedures["procedurable_type"];
}

export interface ManagementPlansList {
  id: IMedicines["medicinable_id"];
  center: IMedicines["reference_center"]["name"];
  date: IMedicines["created_at_formatted"];
  medicine: IMedicines["medicine"]["description"];
  presentationType: IMedicines["presentation_type"];
  dosage: IMedicines["dosage_form"];
  administrationRoute: IMedicines["route_administration"];
  administrationFrequency: IMedicines["administration_frequency"];
  treatmentDuration: IMedicines["treatment_duration"];
  amount: IMedicines["amount"];
  posology: IMedicines["posology"];
  warningSigns: IMedicines["warning_signs"];
  medicinableType: IMedicines["medicinable_type"];
}

export interface DocumentsList {
  center: IReferenceCenter["name"];
  date: IFile["date"];
  description: IFile["description"];
  url: IFile["url"];
  type: IFile["mime_type"];
  name: IFile["name"];
}

export interface ClinicalLaboratoriesList {
  center: IClinicalLaboratories["reference_center"]["name"];
  date: IClinicalLaboratories["created_at_formatted"];
  unit: IClinicalLaboratories["unit"];
  classification: IClinicalLaboratories["classification"]["name"];
  assessment: IClinicalLaboratories["assessment"];
  interpretation: IClinicalLaboratories["interpretation"];
  status: IClinicalLaboratories["status"];
}

export interface MedicalExamsList {
  center: IMedicalExams["reference_center"]["name"];
  date: IMedicalExams["created_at_formatted"];
  exam: IMedicalExams["procedure"]["description"];
  classification: IMedicalExams["classification"]["name"];
  examDate: IMedicalExams["date"];
  interpretation: IMedicalExams["interpretation"];
  file: IMedicalExams["file"];
}

export interface VaccinesList {
  center: IVaccines["reference_center"]["name"];
  date: IVaccines["created_at_formatted"];
  age: IVaccines["years"];
  protectsMeFrom: IVaccines["vaccine"]["name"];
  dose: IVaccines["type_doses_vaccine"]["type_dose"]["name"];
  applicationDate: IVaccines["application_date"];
  laboratory: IVaccines["laboratory"];
  batchNumber: IVaccines["batch_number"];
  ipsVaccinator: IVaccines["ips_name"];
  nextAppointmentDate: IVaccines["next_appointment_date"];
  vaccinator: IVaccines["vaccinator_name"];
}

export interface DisabilitiesList {
  center: IDisabilities["user"]["center"]["name"];
  professional: IDisabilities["user"]["name"];
  specialty: ISpecialtyType["description"];
  date: IDisabilities["created_at_formatted"];
  type: IDisabilities["type"];
  cause: IDisabilities["cause"];
  days: IDisabilities["days"];
  patient: IDisabilities["patient"];
  startDate: IDisabilities["start_date_formatted"];
  endDate: IDisabilities["end_date_formatted"];
  grade: IDisabilities["grade"];
  diagnostic: IDisabilities["diagnostic"];
  description: IDisabilities["description"];
}

export interface GynecologistView {
  lastPregnancyDate: IGynecologist["last_pregnancy_date_formatted"];
  lastPregnancyWeeks: IGynecologist["last_pregnancy_weeks"];
  lastPregnancyDetails: IGynecologist["last_pregnancy_details"];
  lastMenstruationDate: IGynecologist["last_menstruation_date_formatted"];
  lastMenstruationIsReliableDate: string;
  lastMenstruationAssessment: string;
  lastMenstruationIsPlanningMethod: string;
  lastMenstruationDetailPlanningMethod: IGynecologist["last_menstruation_detail_planning_method"];
  obstetricProfileBirths: IGynecologist["obstetric_profile_births"] | string;
  obstetricProfileChildbirth:
    | IGynecologist["obstetric_profile_childbirth"]
    | string;
  obstetricProfilePregnancies:
    | IGynecologist["obstetric_profile_pregnancies"]
    | string;
  obstetricProfileCaesarean:
    | IGynecologist["obstetric_profile_caesarean"]
    | string;
  obstetricProfileCool: IGynecologist["obstetric_profile_cool"] | string;
  obstetricProfileEctopic: IGynecologist["obstetric_profile_ectopic"] | string;
  obstetricProfileAbortions:
    | IGynecologist["obstetric_profile_abortions"]
    | string;
  lastCytologyDate: IGynecologist["last_cytology_date_formatted"];
  lastCytologyObservation: IGynecologist["last_cytology_observation"];
  pregnantDetails: IGynecologist["pregnant_details"];
  pregnantCurrentlyPregnant: string;
}

export interface MedicalRecordsData {
  patient: IPatient | null;
  folios: IService[];
  diagnostics: IDiagnostics[];
  backgrounds: IBackgrounds[];
  procedures: IProcedures[];
  managementPlans: IMedicines[];
  documents: IFile[];
  clinicalLaboratories: IClinicalLaboratories[];
  medicalExams: IMedicalExams[];
  vaccines: IVaccines[];
  disabilities: IDisabilities[];
  gynecologist: IGynecologist | null;
}

export interface MedicalRecordsState {
  // Medical Records V.2
  request: MedicalRecordsRequest;
  data: MedicalRecordsData;
  pages: MedicalRecordsPages;
  response: IRequestResponse;
}

function state(): MedicalRecordsState {
  return {
    // Medical Records V.2
    request: {
      patient: false,
      folios: false,
      diagnostics: false,
      backgrounds: false,
      procedures: false,
      managementPlans: false,
      documents: false,
      clinicalLaboratories: false,
      medicalExams: false,
      vaccines: false,
      disabilities: false,
      gynecologist: false,
    },
    pages: {
      folios: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      diagnostics: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      backgrounds: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      procedures: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      managementPlans: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      documents: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      clinicalLaboratories: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      medicalExams: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      vaccines: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      disabilities: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
    data: {
      patient: null,
      folios: [],
      diagnostics: [],
      backgrounds: [],
      procedures: [],
      managementPlans: [],
      documents: [],
      clinicalLaboratories: [],
      medicalExams: [],
      vaccines: [],
      disabilities: [],
      gynecologist: null,
    },
  };
}

export default state;
