import { GetterTree } from "vuex";
import { ContractsUI } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<ContractsUI, StateInterface> = {
  getContractsBreadcrumbs(state) {
    return state.contractsBreadcrumbs;
  },

  getContractReset(state) {
    return state.contractReset;
  },

  getContractListLoader(state) {
    return state.contractListLoader;
  },

  getContractsPagination(state) {
    return state.contractsPagination;
  },

  //form options

  getAvailablesCentersOptions(state) {
    return state.availablesCentersOptions;
  },

  getAvailablesSpecialtiesOptions(state) {
    return state.availablesSpecialtiesOptions;
  },

  getTelemedicineServices(state) {
    return state.telemedicineServices;
  },
  getHealthServices(state) {
    return state.healthServices;
  },

  // alert general ini
  getShowAlert(state) {
    return state.showAlert;
  },
  getAlertMessage(state) {
    return state.alertMessage;
  },
  getAlertTitle(state) {
    return state.alertTitle;
  },
  getAlertType(state) {
    const type = state.alertType;
    switch (type) {
      case "success":
        return {
          color: "bg-positive",
          text: "text-white",
          icon: "mdi-check-circle",
        };

      case "warning":
        return {
          color: "bg-warning",
          text: "text-white",
          icon: "mdi-message-alert",
        };
      case "error":
        return {
          color: "bg-negative",
          text: "text-white",
          icon: "mdi-alert-circle",
        };

      case "info":
        return {
          color: "bg-info",
          text: "text-blue-10",
          icon: "mdi-information",
        };

      default:
        return {
          color: "bg-primary",
          text: "text-white",
          icon: "mdi-hand-pointing-right",
        };
    }
  },

  getContractList(state) {
    return state.contractsList;
  },

  getContractSelected(state) {
    return state.contractSelected;
  },

  getSpecialitySelectedId(state) {
    return state.specialitySelectedId;
  },

  getCenterSelectedId(state) {
    return state.centerSelectedId;
  },

  getContractCurrentPage(state) {
    return state.contractCurrentPage;
  },


  getContractLastPage(state) {
    return state.contractLastPage;
  },

  getTotalPages(state) {
    return state.totalPage;
  },

  getActiveContracts(state) {
    const contracts = state.contractsList.filter(
      (contract) => contract.status === "active"
    );
    return contracts.length;
  },

  getDisableContracts(state) {
    const contracts = state.contractsList.filter(
      (contract) => contract.status === "disable"
    );
    return contracts.length;
  },

  getRemainingContracts(state) {
    const contracts = state.contractsList.filter(
      (contract) => contract.status === "inactive"
    );
    return contracts.length;
  },

  getloadingSpecialities(state) {
    return state.loadingSpecialities;
  },

  getLoadingCenterList(state) {
    return state.loadingCenterList;
  },

  //tables loads
  getRowSpeciality(state) {
    const configRow = state.rowSpeciality.map((speciality) => ({
      name: speciality.description,
      status: speciality.pivot.status,
      id: speciality.pivot.id,
    }));
    return configRow;
  },

  getRowCenters(state) {
    const setupRow = state.rowCenters;

    const configRow = [...setupRow].map((centers) => ({
      name: centers.name,
      status: centers.get_center_contracts[0].status,
      id: centers.get_center_contracts[0].id,
    }));
    return configRow;
  },

  //update contract view
  getAllCareCenters(state) {
    const setupAllCareCenters = state.allAtentionCenter.map(
      (atentionCenter) => ({
        label: atentionCenter.label,
        value: atentionCenter.value,
      })
    );
    return setupAllCareCenters;
  },

  getContractSpecialities(state) {
    return state.contractSpecialities;
  },

  getLoadCareCenterByContract(state) {
    const careCenterList = state.rowCenters.map((centers) => ({
      name: centers.name,
      id: centers.id,
    }));
    return careCenterList;
  },

  /* popup */
  getPopupOpen(state) {
    return state.popupOpen;
  },
  getPopupLoading(state) {
    return state.popupLoading;
  },
  getPopupLoadingMessage(state) {
    return state.popupLoadingMessage;
  },
  getPopupTitle(state) {
    return state.popupTitle;
  },
  getPopupMessage(state) {
    return state.popupMessage;
  },

  //Total
  getTotalActives(state) {
    return state.totalActives;
  },
  getTotalExpired(state) {
    return state.totalExpired;
  },
  getTotalInactives(state) {
    return state.totalInactives;
  },
  //contract info and update
  getContractInfoById(state) {
    return state.contractInfoById
  },

  getContractViewInfoById(state) {
    return state.contractViewInfoById
  }


};
export default getters;
