import { GetterTree } from "vuex";
import { LevelsState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<LevelsState, StateInterface> = {
  getLevelsList(state) {
    return state.data.levels.length >= 1
      ? state.data.levels.map(
          ({ level, regime, moderation_quota, copay, status }) => ({
            level,
            regime,
            moderation_quota,
            copay,
            status,
          })
        )
      : [];
  },
  getLevelsEdit(state) {
    if (state.data.level) {
      const {
        level,
        regime,
        year,
        moderation_quota,
        copay,
        annual_limit,
        service_limit,
        reference_center,
      } = state.data.level;

      return {
        level,
        regime,
        moderation_quota,
        copay,
        year,
        annual_limit,
        service_limit,
        reference_center_id: reference_center.id,
      };
    }
    return null;
  },
  getDataExcelLevels(state) {
    if (state.data.excel) {
      const { url, filename } = state.data.excel;
      return { url, filename };
    }
    return null;
  },
};

export default getters;
