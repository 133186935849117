import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/reporte-agenda",
    name: "ScheduleReportsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Schedule Reports List" */ "@/views/schedule-reports/ScheduleReportsList.vue"
      ),
  },
  {
    path: "/reporte-agenda/ver-reporte/:id(\\d+)",
    name: "ScheduleReportsView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Schedule Reports View" */ "@/views/schedule-reports/sub-pages/schedule-reports-view/ScheduleReportsView.vue"
      ),
  },
  {
    path: "/reporte-agenda/crear-reporte",
    name: "ScheduleReportsCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Schedule Reports Create" */ "@/views/schedule-reports/sub-pages/schedule-reports-create/ScheduleReportsCreate.vue"
      ),
  },
];
