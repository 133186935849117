import { GetterTree } from "vuex";
import { SchedulesState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<SchedulesState, StateInterface> = {
  getScheduleListBySpecialty(state) {
    return state.scheduleListBySpecialty
  },

  getScheduleListCard(state) {
    return state.scheduleListCard
  },

  getScheduleCardLoading(state) {
    return state.scheduleCardLoading
  },

  getSchedulesCurrentPage(state) {
    return state.schedulesCurrentPage
  },
  getSchedulesLastPage(state) {
    return state.schedulesLastPage
  },
  getScheduleListBySpecialtyLoading(state) {
    return state.scheduleListBySpecialtyLoading
  }
};

export default getters;
