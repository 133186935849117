import { ActionTree } from 'vuex';
import { AbsenceState } from './state';
import { StateInterface } from '../index';
import { absencesApi } from '@/apis';

const actions: ActionTree<AbsenceState, StateInterface> = {

    async getListAbsences( { commit }, data ) {
        const { page, token, reference_center, care_center } = data;
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const route = `?page=${page}&reference_center=${reference_center}${ care_center !== null ? '&care_center='+care_center : '' }`
            const { data } = await absencesApi.get(route, config);
            commit('setListAbsences', data);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }

    },

    async createReportAbsence( { }, data:any ) {
        const { token, formData } = data;
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'    
            }
        };
        try{
            const { data } = await absencesApi.post('', formData, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async viewAbsence( { }, data:any ) {
        const { token, id } = data;
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const route = `/${id}`
            const { data } = await absencesApi.get(route, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async viewDetailAbsence( { }, data:any ) {
        const { token, id } = data;
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const route = `/${id}/results?rows&page`
            const { data } = await absencesApi.get(route, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },
    

}

function errorExeption(error: any) {
    
    //No hay conexion
    if(error.message == 'Network Error'){
        const success = false;
        const { message } = error;
        return { success, message }; 
    }            
    const { data } = error.response;
    const { success, message } = data; 
    return { success, message };
}

export default actions;