
export interface AbsenceState {
    dataTable: dataPaginate
}

export interface dataPaginate {
    data: dataList,
    current_page: number,
    last_page: number,
    per_page: number,
    total: number,
}

export interface dataList {
    data: []
}

export interface data {
    id:                   number;
    reference_center_id:  number;
    care_center_id:       null;
    user_id:              number;
    patient_id:           null;
    excel_url:            string;
    created_at:           Date;
    from_date_formatted:  string;
    to_date_formatted:    string;
    created_at_formatted: string;
    user:                 User;
    reference_center:     ReferenceCenter;
    care_center:          null;
    patient:              null;
}

export interface ReferenceCenter {
    id:   number;
    name: string;
}

export interface User {
    id:           number;
    user_info_id: number;
    name:         string;
    details:      Details;
}

export interface Details {
    id:       number;
    names:    string;
    surnames: string;
}

function state(): AbsenceState {
    return {
        dataTable: {
            data: {
                data:[]
            },
            current_page: 1,
            last_page: 1,
            per_page: 20,
            total: 0,
        }
        
    }
}

export default state;