import { Module } from "vuex";
import { StateInterface } from "../index";

import state, { ResolutionReports4725State } from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const resolution4725Module: Module<ResolutionReports4725State, StateInterface> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default resolution4725Module;
