import { MutationTree } from "vuex";
import { LevelsState } from "./state";
import {
  IData,
  ILevel,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { IExcel } from "@/interfaces/global/Level";

const mutation: MutationTree<LevelsState> = {
  setResponse(state: LevelsState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
  setLevels(state: LevelsState, data?: IData): void {
    if (data) {
      state.data.levels = data.data as ILevel[];

      state.pages.levels.currentPage.value = data.current_page;
      state.pages.levels.lastPage.value = data.last_page;
    } else {
      state.data.levels = [];
    }
  },
  setLevel(state: LevelsState, data?: ILevel | null): void {
    if (data !== undefined) {
      state.data.level = data;
      state.requests.level = true;
    } else {
      state.data.level = null;
      state.requests.level = false;
    }
  },
  setURLExcel(state: LevelsState, data?: IExcel | null): void {
    if (data !== undefined) {
      state.data.excel = data;
    } else {
      state.data.excel = null;
    }
  },
};

export default mutation;
