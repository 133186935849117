import { GetterTree } from "vuex";
import { BanksState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<BanksState, StateInterface> = {
  getBanksList(state) {
    return state.data.banks.length >= 1
      ? state.data.banks.map(({ id, type, description, account, status }) => ({
          id,
          type: type.charAt(0).toUpperCase() + type.slice(1),
          description,
          account,
          status,
        }))
      : [];
  },
  getBanksEdit(state) {
    if (state.data.bank) {
      const { account, description, reference_center_id, type } =
        state.data.bank;

      return {
        account,
        description,
        reference_center_id,
        type,
      };
    }

    return null;
  },
};

export default getters;
