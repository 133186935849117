import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { IDataTable, IOxygenControl } from "@/interfaces/customs/interface";

export const useOxygenControls = () => {
  const store = useStore<StateInterface>();
  return {
    // State
    oxygenControlList: computed(
      () => store.state.oxygenControl.oxygenControlList
    ),
    oxygenControlCurrentPage: computed(
      () => store.state.oxygenControl.oxygenControlCurrentPage
    ),
    oxygenControlLastPage: computed(
      () => store.state.oxygenControl.oxygenControlLastPage
    ),
    ventilatoryList: computed(
      () => store.state.oxygenControl.ventilatory_modes
    ),
    _dataOxygenControl: computed<IOxygenControl | null>(
      () => store.state.oxygenControl.dataOxygenControl
    ),
    _dataTableOxygenControl: computed<IDataTable>(
      () => store.state.oxygenControl.dataTable
    ),

    // Actions
    _getListOxygenControl: (
      referenceCenterId: number | undefined,
      careCenterId: number | undefined,
      page: number
    ) =>
      store.dispatch("oxygenControl/getOxygensControl", {
        referenceCenterId,
        careCenterId,
        page,
      }),
    _createOxygenControl: (dataRequest: IOxygenControl | null) =>
      store.dispatch("oxygenControl/createOxygenControl", { dataRequest }),

    _getOxygenControlById: (id: number | string) =>
      store.dispatch("oxygenControl/getOxygenControlById", {
        id,
      }),
    // Mutation
    setDataOxygenControl: (details: IOxygenControl | null) =>
      store.commit("oxygenControl/setDataOxygenControl", details),
    setDataTableOxygenControl: (details: IDataTable | null) =>
      store.commit("oxygenControl/setDataTableOxygenControl", details),
  };
};
