import { IPages } from "@/interfaces/customs/Pages";
import { IRequestResponse } from "@/interfaces/global/ResponseRequest";
import { IWarehouse } from "@/interfaces/global/Warehouse";

type WareHouseRequests = Record<"bodega", boolean>;
type WareHousePages = Record<"bodegas", IPages>;

export interface WarehousesList {
  id: IWarehouse["id"];
  description: IWarehouse["description"];
  status: IWarehouse["status"];
}

export type WarehousesEdit = {
  id: IWarehouse["id"];
  description: IWarehouse["description"];
  status: IWarehouse["status"];
  reference_center_id: IWarehouse["reference_center_id"];
};

interface WarehouseData {
  bodegas: IWarehouse[];
  bodega: IWarehouse | null;
}

export interface WarehousesState {
  requests: WareHouseRequests;
  data: WarehouseData;
  pages: WareHousePages;
  response: IRequestResponse;
}

function state(): WarehousesState {
  return {
    requests: {
      bodega: false,
    },
    data: {
      bodegas: [],
      bodega: null,
    },
    pages: {
      bodegas: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
