import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/gestion-de-pacientes/certificados",
    beforeEnter: [isAuthenticatedGuard],
    name: "CertificatesListView",
    component: () =>
      import(
        /* webpackChunkName: "avalible View" */
        "@/views/certificates-view/certificate-list-view/CertificatesListView.vue"
      ),
  },

  {
    path: "/gestion-de-pacientes/certificados/crear-certificado",
    beforeEnter: [isAuthenticatedGuard],
    name: "CertificateCreateView",
    component: () =>
      import(
        /* webpackChunkName: "avalible View" */
        "@/views/certificates-view/certificate-create-view/CertificateCreateView.vue"
      ),
  },

  {
    path: "/gestion-de-pacientes/certificados/editar-certificado/:id",
    beforeEnter: [isAuthenticatedGuard],
    name: "CertificateEditView",
    component: () =>
      import(
        /* webpackChunkName: "avalible View" */
        "@/views/certificates-view/certificate-edit-view/CertificateEditView.vue"
      ),
  },


  {
    path: "/gestion-de-pacientes/certificados/ver-certificado/:id",
    beforeEnter: [isAuthenticatedGuard],
    name: "CertificateShowView",
    component: () =>
      import(
        /* webpackChunkName: "avalible View" */
        "@/views/certificates-view/certificate-show-view/CertificateShowView.vue"
      ),
  },
];
