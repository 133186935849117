import { GetterTree } from 'vuex';
import { AvailableUI } from './state';
import { StateInterface } from '../index';

const getters: GetterTree<AvailableUI, StateInterface> = {

    //availableReditect
    getGoRedirectTo(state) {
        return state.goRedirectTo
    },

    // get alert
    getShowAlert(state) {
        return state.showAlert
    },
    getAlertTitle(state) {
        return state.alertTitle
    },

    getAlertMessage(state) {
        return state.alertMessage
    },

    getAlertType(state) {
        return state.alertType
    },

    // get popup
    getPopupOpen(state) {
        return state.popupOpen
    },
    getPopupLoading(state) {
        return state.popupLoading
    },
    getPopupLoadingMessage(state) {
        return state.popupLoadingMessage
    },
    getPopupTitle(state) {
        return state.popupTitle
    },
    getPopupMessage(state) {
        return state.popupMessage
    },

    getShowContinue(state) {
        return state.showContinue
    },
    getShowClose(state) {
        return state.showClose
    },

    // breadcrumbs
    getCurrentBreadcrumb(state) {
        return state.currentBreadcrumb
    },

    // availablecreate list
    getCenterRefOptions(state) {
        const setupAllReferenceCenters = [...state.centerRefOptions].map(centerRef => ({
            label: centerRef.name,
            value: {
                name: centerRef.name,
                id: centerRef.id,
            }
        }))
        return setupAllReferenceCenters
    },

    getCenterRefSelected(state) {
        if (state.centerRefOptions.length > 0) {
            const allCentersRef = [...state.centerRefOptions]
            const centerRef = allCentersRef[0]
            return {
                label: centerRef.name,
                value: {
                    name: centerRef.name,
                    id: centerRef.id,
                }
            }
        } else {
            return {
                label: '',
                value: {
                    name: '',
                    id: null,
                }
            }
        }
    },

    //tables loads
    getLoadingAvailableCreateList(state) {
        return state.loadingAvailableCreateList
    },

    getAvailableCreateRow(state) {
        const configRow = [...state.availableCreateRow].map(createAvailable => ({
            centerRef: { id: createAvailable.get_user_centers.id, name: createAvailable.get_user_centers.name },
            name: createAvailable.get_user_details.names + ' ' + createAvailable.get_user_details.surnames,
            id: createAvailable.id,
            speciality: createAvailable.get_user_specialties.length > 0 ? createAvailable.get_user_specialties[0].description : 'Sin especificar'
        }))

        return configRow.length > 0 ? configRow : []
    },


    getAvailableSelectedDoctor(state) {
        return state.availableSelectedDoctor
    },

    //render available table

    // available list 
    getCurrentAvailableCreatePage(state) {
        return state.currentAvailableCreatePage
    },

    getTotalAvailableCreatePage(state) {
        return state.totalAvailableCreatePage
    },



    getCurrentAvailablePage(state) {
        return state.currentAvailablePage
    },
    getTotalAvailablePage(state) {
        return state.totalAvailablePage
    },
    getAvailableTo(state) {
        return state.availableTo
    },

    getAvailableSelectedEdit(state) {
        return state.availableSelectedEdit
    },

    getAvailableRow(state) {
        const configRow = [...state.availableRow].map(createAvailable => ({
            centerRef: { id: createAvailable.centers_id },
            name: createAvailable.name,
            id: createAvailable.id,
            availables: createAvailable.availabilities.date,
            serviceType: createAvailable.availabilities.type,
            serviceTypeId: createAvailable.availabilities.type_id,
            times: createAvailable.availabilities.hour,
            speciality: createAvailable.specialties[0].description,
            interval: createAvailable.availabilities.interval,
            availableType: createAvailable.availabilities.type,

        }))
        return configRow.length > 0 ? configRow : []
    },

    getAvailableBySpecialityRow(state) {
        return state.availableBySpecialityRow
    },

    getDeleteAvailableDoctor(state) {
        return state.deleteAvailableDoctor
    },

    getLoadingAvailableList(state) {
        return state.loadingAvailableList
    },

    // Available Create Form
    getCreateAvailableForm(state) {
        return state.createAvailableForm
    },

    // available list create
    getCurrentCreatePage(state) {
        return state.currentCreatePage
    },
    getTotalCreatePage(state) {
        return state.totalCreatePage
    },
    getCreateTo(state) {
        return state.createTo
    },

    //reducer type
    getUnavailableInfo(state) {
        return state.unavailableInfo
    },


    getAvailableReducerType(state) {
        return state.availableReducerType
    },
    getAvailableInfo(state) {
        return state.availableInfo
    },
    getDeleteAvailableId(state) {
        return state.deleteAvailableId
    },

    // available detail table
    getLoadingAvailableTable(state) {
        return state.loadingAvailableTable
    },

    // unavailable detail table
    getLoadingUnavailableTable(state) {
        return state.loadingUnavailableTable
    },

    getInfoAvailableCard(state) {
        return state.infoAvailableCard
    },


    getAvailableViewRow(state) {
        const configRow = [...state.availableViewRow].map(available => {
            const type = available.type.name
            const date = available.date
            const timeIni = available.initial_hour + '.Hrs'
            const timeEnd = available.final_hour + '.Hrs'
            const totalQuote = available.total_quota
            const availableQuote = available.quota
            const id = available.id
            return { type, date, timeIni, timeEnd, totalQuote, availableQuote, id }
        })
        return configRow
    },

    getAvailableViewCurrentPage(state) {
        return state.availableViewCurrentPage
    },
    getAvailableViewLastPage(state) {
        return state.availableViewLastPage
    },

    getUnavailableViewRow(state) {
        const unavailableListRow = [...state.unavailableViewRow].map(unavailable => ({
            type: 'No disponible',
            userId: unavailable.user_id,
            id: unavailable.id,
            dateAndTimeIni: `${unavailable.date} / ${unavailable.initial_hour} .hrs`,
            dateAndTimeEnd: `${unavailable.final_date} / ${unavailable.final_hour} .hrs`,
            infoDecline: `${unavailable.reason}`,
            trackInfo: `${unavailable.observation === null ? 'Sin observaciones' : unavailable.observation}`,
        }))

        return unavailableListRow
    },

    getUnavailableViewCurrentPage(state) {
        return state.unavailableViewCurrentPage
    },
    getUnavailableViewLastPage(state) {
        return state.unavailableViewLastPage
    },

    // available update edit
    getAvailableUpdateEditInfo(state) {
        return state.availableUpdateEditInfo
    },

    getAvailableUpdateEditView(state) {
        return state.availableUpdateEditView

    },

    getLoadAvailablePage(state) {
        return state.loadAvailablePage
    }
}
export default getters;