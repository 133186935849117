import { Module } from 'vuex';
import { StateInterface } from '../index';

import state, { Resolution2175of2015State } from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';


const resolution2175of2015Module: Module<Resolution2175of2015State, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default resolution2175of2015Module;