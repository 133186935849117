import { ActionTree } from "vuex";

import { StateInterface } from "../index";
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";
import {
  cancelRIPSReport,
  ripsApi,
} from "@/apis/managment-reports/RIPS/ripsApi";
import { specialtyByCenter } from "@/apis/specialtesApi";
import { BodyRipsRequestReportUI } from "@/composables/ManagementReports/RIPS/useRequestRIPS";
import { IErrors } from "@/interfaces/customs/IShowErrors";
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { RipsUI } from "@/interfaces/customs/IRipsCustom";
import {
  RowRips,
  SingleRipReportUI,
  SpecialtiesRipsUI,
} from "@/interfaces/global/IRips";
import { useAlertV2 } from "@/composables/useAlertV2";
import { useFormatDate } from "@/composables/useFormatDate";

const { openMainLoader } = useMainLoader();
const { showAlert } = useAlertV2();

const actions: ActionTree<RipsUI, StateInterface> = {
  async RipsAllReports(
    { commit },
    { careCenterId, goToPage = 1 }: { careCenterId: number; goToPage: number }
  ) {
    openMainLoader(true);
    const { formaISOtoYYYYMMDDhhmm } = useFormatDate();
    const { showCatchError } = showErrorInTS();

    if (!careCenterId) return;
    // loader
    try {
      const allRipsReports = await ripsApi().get(
        `/${careCenterId}?page=${goToPage}`
      );
      const infoData = allRipsReports.data.data.data.map(
        (reportInfo: RowRips) => ({
          id: reportInfo.id,
          dateFrom: reportInfo.to_date.slice(0, 10),
          dateTo: reportInfo.from_date.slice(0, 10),
          generationDate:
            formaISOtoYYYYMMDDhhmm(reportInfo.created_at) + "Hrs.",
          requestBy: reportInfo.user.name,
          careCenter: reportInfo.care_center.name,
          careCenterId: reportInfo.care_center.id,
          format: reportInfo.format,
          status: reportInfo.status,
          url: reportInfo.url,
        })
      );

      const rowAllRipsReportsMessage = allRipsReports.data.message;

      commit("setRipsTableCurrentPage", allRipsReports.data.data.current_page);
      commit("setRipsTableLastPage", allRipsReports.data.data.last_page);

      commit("setRowRips", infoData);

      // loader
      openMainLoader(false);

      // alert
      showAlert(`¡${rowAllRipsReportsMessage}!`, "success");
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setRowRips", []);
      // loader
      openMainLoader(false);
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async CreateRIPSReport({}, bodyRipsRequestReport: BodyRipsRequestReportUI) {
    openMainLoader(true);
    // loader
    const { showCatchError } = showErrorInTS();
    try {
      const requestRip = await ripsApi().post(`/`, bodyRipsRequestReport);

      const requestRipMessage = requestRip.data.message;
      // loader
      openMainLoader(false);

      // alert
      showAlert(`¡${requestRipMessage}!`, "success");
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      // loader
      openMainLoader(false);
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async CancelRIPSReport(
    { commit, dispatch },
    {
      token,
      reportId,
      careCenterId,
    }: { token: string; reportId: number; careCenterId: number }
  ) {
    commit("general/setPopupLoadingMessage", `Cancelando RIP#${reportId}`, {
      root: true,
    });
    commit("general/setPopupLoading", true, { root: true });
    // loader
    const { showCatchError } = showErrorInTS();
    try {
      const ripCancel = await cancelRIPSReport.post(
        `/${reportId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const ripReportsCancel = ripCancel.data.message;
      dispatch("RipsAllReports", { token, careCenterId });

      commit("general/setPopupOpen", false, { root: true });
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });

      // alert
      showAlert(`¡${ripReportsCancel}!`, "success");
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      commit("general/setPopupOpen", false, { root: true });
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      // loader

      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async GetRIPSSIngleReport({ commit }, userId: number) {
    openMainLoader(true);

    // loader
    const { showCatchError } = showErrorInTS();
    try {
      const ripsSingleReport = await ripsApi().get(`/get/${userId}`);

      const reportRef: SingleRipReportUI = ripsSingleReport.data.data;
      const infoReportData = {
        id: reportRef.id,
        dateFrom: reportRef.from_date.slice(0, 10),
        dateTo: reportRef.to_date.slice(0, 10),
        generationDate:
          new Date(reportRef.created_at).toLocaleString("es-ES", {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
          }) + "Hrs.",
        requestBy: reportRef.user.name,
        centerRef: reportRef.reference_center.name,
        centerRefId: reportRef.reference_center.id,
        careCenter: reportRef.care_center.name,
        careCenterId: reportRef.care_center.id,
        status: reportRef.status,
        url: reportRef.url,
        regimenId: reportRef.regime.id,
        regimenName: reportRef.regime.name,
        consultType: reportRef.consult_type,
        specialties: reportRef.specialties
          .map((specialty) => specialty.description)
          .join(", "),
        eps: reportRef.eps.name,
        epsId: reportRef.eps.id,
        format: reportRef.format,
      };

      const ripReportsCancel = ripsSingleReport.data.message;
      commit("setViewRips", infoReportData);
      commit("setDownloadReportRipURL", infoReportData.url);

      // loader
      openMainLoader(false);

      // alert
      showAlert(`¡${ripReportsCancel}!`, "success");
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      // loader
      openMainLoader(false);
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async LoadSpecialtyByCenter(
    { commit },
    { token, centerRefId }: { token: string; centerRefId: number }
  ) {
    openMainLoader(true);
    // loader
    const { showCatchError } = showErrorInTS();
    if (!centerRefId) return;
    try {
      const specialtiesLoad = await specialtyByCenter.get(`/${centerRefId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const specialtieRef: SpecialtiesRipsUI[] = specialtiesLoad.data.data;

      const specialtiesOptions = !specialtieRef
        ? []
        : specialtieRef.map((specialtie) => ({
            value: specialtie.id,
            label: specialtie.description,
            description: specialtie.description,
          }));

      const specialtieRefMessage = specialtiesLoad.data.message;

      commit("setSpecialtiesRips", specialtiesOptions);

      // loader
      openMainLoader(false);

      // alert
      showAlert(`¡${specialtieRefMessage}!`, "success");
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      // loader
      openMainLoader(false);
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },
};

export default actions;
