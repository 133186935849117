import { useStore } from "vuex";
import { StateInterface } from '@/store/index';
import { computed } from "vue";

export const useMedicinesReport = () => {

    const store = useStore<StateInterface>();

    return {
        // State - Getter
        listMedicinesReport: computed( () => store.state.medicinesReport.dataTable.data.data),
        currentPage: computed( () => store.state.medicinesReport.dataTable.current_page),
        lastPage: computed( () => store.state.medicinesReport.dataTable.last_page),
        // Actions
        isGetListMedicinesReport: (data:any) => store.dispatch('medicinesReport/getListMedicinesReport', data),
        isCreateMedicinesReport: (data:any) => store.dispatch('medicinesReport/createMedicinesReport', data),
        isCancelResolutionStatus: (data:any) => store.dispatch('medicinesReport/cancelResolutionStatus', data),
        isViewMedicinesReport: (data:any) => store.dispatch('medicinesReport/viewMedicinesReport', data),
        isGetSpecialityList: (data:any) => store.dispatch('medicinesReport/getSpecialityList', data),
        isGetProfessionalList: (data:any) => store.dispatch('medicinesReport/getProfessionalList', data),
        //Mutations
        isSetCurrentPage: (data:number) => store.commit('medicinesReport/setListMedicinesReportCurrentPage', data),
        isSetLastPage: (data:number) => store.commit('medicinesReport/setListMedicinesReportLastPage', data)
    }
}