import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { isAuthenticatedGuard } from "./auth-guard";
import authRouter from "@/store/auth/router";
import surveyRouter from "@/store/surveys/router";
import usersRouter from "@/store/users/router";
import medicinesRouter from "@/store/medicines/router";
import specialtesRouter from "@/store/specialtes/router";
import contractsRouter from "@/store/contracts/router";
import availableRouter from "@/store/available/router";
import proceduresRouter from "@/store/procedures/router";
import diagnosticsRouter from "@/store/diagnostics/router";
import referenceCentersRouter from "@/store/reference-centers/router";
import teleexpertisesRouter from "@/store/teleexpertises/router";
import schedulesRouter from "@/store/schedules/router";
import careProgramsRouter from "@/store/care-programs/router";
import atentionCentersRouter from "@/store/atentioncenter/router";
import patientsRouter from "@/store/patients/router";
import medicalRecordsRouter from "@/store/medical-records/router";
import healthcareManagement from "@/store/healthcareManagement/router";
import foliosRouter from "@/store/folios/router";
import campaignsRouter from "@/store/campaigns/router";
import managementReports from "@/store/RIPS/router";
import unificationMedicalRecordsRouter from "@/store/unification-medical-records/router";
import explanatoryNotesRouter from "@/store/explanatory-notes/router";
import CDASRouter from "@/store/cdas/router";
import nursingNotesRouter from "@/store/nursingNotes/router";
import scheduleReportsRouter from "@/store/schedule-reports/router";
import telesupportsRouter from "@/store/telesupport/router";
import indicatorsRouter from "@/store/indicators/router";
import liquidControlRouter from "@/store/liquidControl/router";
import resolutionReportsRouter from "@/store/resolution-reports/router";
import oxygenControlRouter from "@/store/oxygenControl/router";
import triageRouter from "@/store/triage/router";
import initialAttentionsRouter from "@/store/initial-attentions/router";
import emergenciesRouter from "@/store/emergencies/router";
import indicators256Router from "@/store/indicators-256/router";
import telemedicineRouter from "@/store/telemedicine/router";
import highCostAccounts2463Router from "@/store/high-cost-accounts-2463/router";
import appointmentConfirmationRouter from "@/store/appointment-confirmation/router";
import resolution4725 from "@/store/resolution-4725/router";
import absences from "@/store/absences/router";
import proceduresReportRouter from "@/store/procedures-report/router";
import resolution2175of2015 from "@/store/resolution2175of2015/router";
import medicinesReportRouter from "@/store/medicines-report/router";
import resolution1604of2013 from "@/store/resolution-1604-2013/router";
import controlBoardsRouter from "@/store/control-boards/router";
import notificationsRouter from "@/store/notifications/router";
import medicalAdministratorRouter from "@/store/medication-administrator/router";
import digitalSignatureRouter from "@/store/digital-signature/router";
import timeInactivityRouter from "@/store/time-inactivity/router";
import cancellationReasonRouter from "@/store/cancellation-reasons/router";
import physicalExamTemplatesRouter from "@/store/physical-exam-templates/router";
import supportRouter from "@/store/support/router";
import advanceDirectivesRouter from "@/store/advance-directives/router";
import backgroundsTemplateRouter from "@/store/background-templates/router";
import prescriptionProceduresRouter from "@/store/prescription-procedures-templates/router";
import prescriptionMedicinesRouter from "@/store/prescription-medicines-templates/router";
import administratorRouter from "@/store/administrators/router";
import preparationsRouter from "@/store/preparations/router";
import certificatesRouter from "@/store/certificates/router";
import packagesRouter from "@/store/packages/router";
import notesRouter from "@/store/notes/router";
import tutorialViedeoRouter from "@/store/tutorial-video/router";
import banksRouter from "@/store/banks/router";
import levelsRouter from "@/store/levels/router";
import warehouseRouter from "@/store/warehouses/router";
import HRRouter from "@/store/human-resourses/router";
import numerationsRouter from "@/store/numerations/router";
import telemedicineReportRouter from "@/store/telemedicine-report/router";
import advanceDirectiveReportRouter from "@/store/advance-directives-report/router";
import specialtiesRouter from "@/store/specialties/router";
import vaccinesRouter from "@/store/vaccines/router";
import loginsRouter from "@/store/logins/router";
import invoicingRouter from "@/store/invoicing/router";
import rateManualRouter from "@/store/rate-manual/router";

const routes: RouteRecordRaw[] = [
  ...surveyRouter,
  {
    path: "/",
    name: "start",
    component: () => import("@/views/start-view/StartView.vue"),
  },
  {
    path: "/auth",
    ...authRouter,
  },
  {
    path: "/correo",
    name: "MailView",
    component: () =>
      import(
        /* webpackChunkName: "Email Layout" */ "@/views/mail-view/MailView.vue"
      ),
    children: [...appointmentConfirmationRouter],
  },
  {
    path: "/video-llamada/:url",
    name: "MeetResponse",
    component: () => import("@/views/meet-response/MeetResponse.vue"),
  },
  {
    path: "/auth",
    redirect: "/dashboard",
    beforeEnter: [isAuthenticatedGuard],
    name: "home",
    component: () =>
      import(/* webpackChunkName: "Home" */ "@/views/home-view/HomeView.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        beforeEnter: [isAuthenticatedGuard],
        component: () =>
          import(
            /* webpackChunkName: "Login View" */ "@/views/dashboard-view/DashboardView.vue"
          ),
      },
      ...usersRouter,
      ...availableRouter,
      ...medicinesRouter,
      ...contractsRouter,
      ...proceduresRouter,
      // ...specialtesRouter,
      ...atentionCentersRouter,
      ...diagnosticsRouter,
      ...patientsRouter,
      ...referenceCentersRouter,
      ...teleexpertisesRouter,
      ...schedulesRouter,
      ...medicalRecordsRouter,
      ...healthcareManagement,
      ...foliosRouter,
      ...careProgramsRouter,
      ...campaignsRouter,
      ...managementReports,
      ...unificationMedicalRecordsRouter,
      ...explanatoryNotesRouter,
      ...CDASRouter,
      ...nursingNotesRouter,
      ...scheduleReportsRouter,
      ...telesupportsRouter,
      ...indicatorsRouter,
      ...liquidControlRouter,
      ...resolutionReportsRouter,
      ...oxygenControlRouter,
      ...triageRouter,
      ...initialAttentionsRouter,
      ...emergenciesRouter,
      ...indicators256Router,
      ...telemedicineRouter,
      ...highCostAccounts2463Router,
      ...resolution4725,
      ...absences,
      ...proceduresReportRouter,
      ...resolution2175of2015,
      ...medicinesReportRouter,
      ...resolution1604of2013,
      ...controlBoardsRouter,
      ...notificationsRouter,
      ...medicalAdministratorRouter,
      ...digitalSignatureRouter,
      ...timeInactivityRouter,
      ...cancellationReasonRouter,
      ...physicalExamTemplatesRouter,
      ...supportRouter,
      ...advanceDirectivesRouter,
      ...backgroundsTemplateRouter,
      ...prescriptionProceduresRouter,
      ...prescriptionMedicinesRouter,
      ...administratorRouter,
      ...preparationsRouter,
      ...certificatesRouter,
      ...packagesRouter,
      ...notesRouter,
      ...tutorialViedeoRouter,
      ...HRRouter,
      ...banksRouter,
      ...numerationsRouter,
      ...telemedicineReportRouter,
      ...specialtiesRouter,
      ...vaccinesRouter,
      ...loginsRouter,
      ...advanceDirectiveReportRouter,
      ...invoicingRouter,
      ...levelsRouter,
      ...warehouseRouter,
      ...rateManualRouter,
    ],
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/not-found-404/NotFound404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    window.sessionStorage.getItem("isAuthenticated") == null &&
    to.query.token
  ) {
    next("/cambiar-contrasenia");
  }

  if (
    window.sessionStorage.getItem("isAuthenticated") == "1" &&
    to.query.token
  ) {
    next("/cambiar-contrasenia");
  }

  if (to.fullPath === "/dashboard") {
    if (window.sessionStorage.getItem("isAuthenticated") === "0") {
      if (to.query.token) {
        next("/cambiar-contrasenia");
      }
    }
    if (window.sessionStorage.getItem("isAuthenticated") === "1") {
      if (window.sessionStorage.getItem("first_login") === "true") {
        next("/asignar-contrasenia");
      }
    }
  }
  next();
});

export default router;
