import {
  IAvailableSelectedCustom,
  ICreateAvailableDoctorBySpeciality,
} from "@/interfaces/customs/AvailablesCustomUI";
import {
  IAvailableList,
  IUnavailableByDoctor,
} from "@/interfaces/global/Availables";

export interface AvailableUI {
  //availableReditect
  goRedirectTo: string;

  //reducer type

  unavailableInfo: {
    availableCancelId: number;
    userId: number
  } | undefined

  availableReducerType: string;
  deleteAvailableId: {
    userId: number | null;
    availableId: number | null;
  };

  // alert general ini
  showAlert: boolean;
  alertMessage: string;
  alertTitle: string;
  alertType: string;

  // popup
  popupOpen: boolean;
  popupLoading: boolean;
  popupLoadingMessage: string;
  popupTitle: string;
  showContinue: boolean;
  showClose: boolean;
  popupMessage: string;
  //BreadCrumbs
  currentBreadcrumb: {
    title: string;
    path: {
      label: string;
      path: string;
    }[];
    btn: string;
    navigate: string;
  };

  //general
  centerRefOptions: {
    id: number;
    created_at: null;
    updated_at: string;
    name: string;
    nit: string;
    address: string;
    phone: string;
    email: string;
    logo: null;
    contact_name: string;
    contact_phone: string;
    status: string;
    centers_types_id: null;
  }[];
  centerRefSelected: string;
  // available view
  currentPage: number;
  totalPage: number;
  to: number;

  // available list
  currentAvailableCreatePage: number;
  totalAvailableCreatePage: number;
  currentAvailablePage: number;
  totalAvailablePage: number;
  availableTo: number;

  // available list create
  currentCreatePage: number;
  totalCreatePage: number;
  createTo: number;

  //render available table
  deleteAvailableDoctor: {
    centerRefId: number | null;
    name: string;
    id: number | null;
  };

  loadingAvailableList: false;
  availableRow: IAvailableList[];
  availableBySpecialityRow: ICreateAvailableDoctorBySpeciality[];

  //render table users
  loadingAvailableCreateList: boolean;
  availableCreateRow: {
    id: number;
    user_types_id: number;
    get_user_details: {
      names: string;
      surnames: string;
    };
    get_user_specialties: [
      {
        description: string;
      }
    ];
    get_user_centers: {
      id: number;
      name: string;
      centers_types_id: number;
    };
  }[];
  availableSelectedDoctor: {
    name: string;
    speciality: string;
    id: number | null;
    url: string;
    centerRef?: {
      id?: number;
      name?: string;
      centers_types_id?: number;
    };
  };
  //create users form
  createAvailableForm: {
    user_id: string;
    initial_date: string;
    final_date: string;
    first_date: string;
    last_date: string;
    interval: string;
    type: string;
  } | null;
  //available View
  availableInfo: {
    centerRef: string;
    id: number | null;
    name: string;
    service: string;
    timeIni: string;
    timeEnd: string;
    interval: string;
    dateIni: string;
    dateEnd: string;
  };
  // available detail table
  loadingAvailableTable: boolean;
  loadingUnavailableTable: boolean;
  infoAvailableCard: {
    id: number | null;
    final_date: string;
    initial_date: string;
    created_at: string;
    updated_at: string;
    date: string;
    first_date: string;
    last_date: string;
    interval: string;
    total_quota: string;
    quota: string;
    type: string;
    status: string;
    user_id: number | null;
  };
  unavailableViewRow: IUnavailableByDoctor[];
  unavailableViewCurrentPage: number;
  unavailableViewLastPage: number;

  availableViewRow: {
    id: number;
    created_at: string;
    updated_at: string;
    date: string;
    initial_hour: string;
    final_hour: string;
    interval: string;
    total_quota: string;
    quota: string;
    status: string;
    user_id: number;
    type: {
      id: number,
      created_at: null,
      updated_at: null,
      name: string,
      type: string,
      model: string,
      status: string,
      scheduleable: number
    },

    final_date: string,
    category: string,
    reason: string | null,
    observation: string | null,

    created_by: number,
    type_id: number

  }[];
  availableViewCurrentPage: number;
  availableViewLastPage: number;
  // available update edit

  availableSelectedEdit: IAvailableSelectedCustom | undefined;

  availableUpdateEditInfo: {
    centerRef: string;
    centerRefId: number | null;
    name: string;
    id: number | null;
    speciality: string;
  };

  availableUpdateEditView: {
    date: string;
    final_date: string;
    last_date: string;
    id: number | null;
    initial_date: string;
    first_date: string;
    interval: number | null;
    quota: string | number | null;
    status: string;
    total_quota: string | number | null;
    type: string;
    updated_at: string;
    user_id: number | null;
    centerRef: string;
    centerRefId: number | null;
    name: string;
    userId: number | null;
    speciality: string;
  };
  loadAvailablePage: string;
}

function state(): AvailableUI {
  return {
    //availableReditect
    goRedirectTo: "",
    //reducer type
    unavailableInfo: undefined,
    availableReducerType: "standby",
    deleteAvailableId: {
      userId: null,
      availableId: null,
    },
    //availableCreate, availableEdit, availableDelete, availableUpdate

    //alert
    showAlert: false,
    alertTitle: "",
    alertMessage: "",
    alertType: "success",

    // popup
    popupOpen: false,
    popupLoading: false,
    popupLoadingMessage: "Cargando...",
    popupTitle: "Accediendo al Modulo",
    popupMessage: "en proceso...",
    showContinue: false,
    showClose: false,

    //header
    currentBreadcrumb: {
      title: "Disponibilidad",
      path: [
        {
          label: "Disponibilidad",
          path: "",
        },
        {
          label: "Lista de disponibilidad",
          path: "lista-de-disponibilidad",
        },
      ],
      btn: "Crear Disponibilidad",
      navigate: "availableCreateView",
    },

    //general options
    centerRefOptions: [],
    centerRefSelected: "",

    // available list create pagination
    currentPage: 0,
    totalPage: 0,
    to: 0,

    // available list pagination
    currentAvailableCreatePage: 0,
    totalAvailableCreatePage: 0,
    currentAvailablePage: 0,
    totalAvailablePage: 0,
    availableTo: 0,

    //render available table

    deleteAvailableDoctor: {
      centerRefId: null,
      name: "",
      id: null,
    },

    loadingAvailableList: false,
    availableRow: [],
    availableBySpecialityRow: [],

    //render available table create users
    loadingAvailableCreateList: false,
    availableCreateRow: [],
    availableSelectedDoctor: {
      name: "",
      speciality: "",
      id: null,
      url: "",
    },

    //create  users form
    createAvailableForm: null,

    // available list create
    currentCreatePage: 0,
    totalCreatePage: 0,
    createTo: 0,

    //available View
    availableInfo: {
      centerRef: "",
      id: null,
      name: "",
      service: "",
      timeIni: "",
      timeEnd: "",
      interval: "",
      dateIni: "",
      dateEnd: "",
    },
    // available detail table
    loadingAvailableTable: false,
    loadingUnavailableTable: false,
    infoAvailableCard: {
      id: null,
      final_date: "",
      initial_date: "",
      created_at: "",
      updated_at: "",
      date: "",
      first_date: "",
      last_date: "",
      interval: "",
      total_quota: "",
      quota: "",
      type: "",
      status: "",
      user_id: null,
    },
    unavailableViewRow: [],
    unavailableViewCurrentPage: 0,
    unavailableViewLastPage: 0,
    availableViewRow: [],
    availableViewCurrentPage: 0,
    availableViewLastPage: 0,

    // available update edit
    availableSelectedEdit: undefined,

    availableUpdateEditInfo: {
      centerRef: "",
      centerRefId: null,
      name: "",
      id: null,
      speciality: "",
    },
    availableUpdateEditView: {
      date: "",
      final_date: "",
      last_date: "",
      id: null,
      initial_date: "",
      first_date: "",
      interval: null,
      quota: null,
      status: "",
      total_quota: null,
      type: "",
      updated_at: "",
      user_id: null,
      centerRef: "",
      centerRefId: null,
      name: "",
      userId: null,
      speciality: "",
    },
    loadAvailablePage: "standby",
  };
}

export default state;
