import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/indicadores-256",
    name: "Indicators256List",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Indicators 256 List" */ "@/views/indicators-256/Indicators256List.vue"
      ),
  },
  {
    path: "/indicadores-256/ver-indicadores-256/:id(\\d+)",
    name: "Indicators256View",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Indicators 256 View" */ "@/views/indicators-256/sub-pages/indicators-256-view/Indicators256View.vue"
      ),
  },
  {
    path: "/indicadores-256/crear-indicadores-256",
    name: "Indicators256Create",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Indicators 256 Create" */ "@/views/indicators-256/sub-pages/indicators-256-create/Indicators256Create.vue"
      ),
  },
];
