import { GetterTree } from "vuex";
import { DisabilityUI } from "./state";
import { StateInterface } from "../../index";

const getters: GetterTree<DisabilityUI, StateInterface> = {
  getDisabilitiesTableInfo(state) {
    return state.disabilitiesTableInfo;
  },

  getDesabilitiesLastPage(state) {
    return state.desabilitiesLastPage;
  },
  getDesabilitiesCurrentPage(state) {
    return state.desabilitiesCurrentPage;
  },

  getCurrentPatientSearch(state) {
    return state.currentPatientSearch;
  },

  getDiagnosticOptions(state) {
    return state.diagnosticOptions;
  },
  getDisabilityInfoById(state) {
    return state.disabilityInfoById;
  },
};
export default getters;
