import axios from "axios";
import apiBase from "@/apis/AxiosIntance";


// updates availables
export const ripsApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/documents/reports/rips`;
  return apiBase;
};

// Cancel RIPS Report
export const cancelRIPSReport = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/documents/reports/rips/cancel`,
})

// Get RIPS SIngle Report
export const getRIPSSIngleReport = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/documents/reports/rips/get`,
})


// Create RIPS Report
export const createRIPSReport = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/documents/reports/rips`,
})





