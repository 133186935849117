import { IPages } from "@/interfaces/customs/Pages";
import {
  IHighCostAccounts2463,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { StateInterface } from "@/store/index";
import {
  HighCostAccounts2463View,
  HighCostAccounts2463Data,
  HighCostAccounts2463List,
  HighCostAccounts2463Pages,
} from "@/store/high-cost-accounts-2463/state";
import { computed } from "vue";
import { useStore } from "vuex";

export const useHighCostAccounts2463Store = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    highCostAccounts: computed<IHighCostAccounts2463[]>(
      () => store.state.highCostAccounts2463.data.highCostAccounts
    ),
    highCostAccountsPages: computed<IPages>(
      () => store.state.highCostAccounts2463.pages.highCostAccounts
    ),
    highCostAccount: computed<IHighCostAccounts2463 | null>(
      () => store.state.highCostAccounts2463.data.highCostAccount
    ),
    diseaseTypes: computed<Record<string, string> | null>(
      () => store.state.highCostAccounts2463.data.diseaseTypes
    ),
    data: computed<HighCostAccounts2463Data>(() => store.state.highCostAccounts2463.data),
    pages: computed<HighCostAccounts2463Pages>(() => store.state.highCostAccounts2463.pages),
    response: computed<IRequestResponse>(
      () => store.state.highCostAccounts2463.response
    ),

    // Getters
    getHighCostAccountsList: computed<HighCostAccounts2463List[]>(
      () => store.getters["highCostAccounts2463/getHighCostAccountsList"]
    ),
    getHighCostAccountView: computed<HighCostAccounts2463View | null>(
      () => store.getters["highCostAccounts2463/getHighCostAccountView"]
    ),
    getDiseaseTypes: computed<Record<"name" | "value" | "description", string>[]>(
      () => store.getters["highCostAccounts2463/getDiseaseTypes"]
    ),

    // Actions
    loadHighCostAccounts: (
      params: { 
        reference_center?: number;
        care_center?: number;
        eps?: number;
        from_date: string;
        to_date: string;
        status: string;
        page: number
      }
    ) =>
      store.dispatch("highCostAccounts2463/loadHighCostAccounts", 
        params,
      ),
    loadHighCostAccount: (id: number) =>
      store.dispatch("highCostAccounts2463/loadHighCostAccount", id),
    createHighCostAccount: (bodyRequest: {
      reference_center_id: number | null;
      care_center_id: number | null;
      from_date: string;
      to_date: string;
      eps_id: number | null;
      regime_id: number | null;
      disease_type: string | null;
    }) => store.dispatch("highCostAccounts2463/createHighCostAccount", bodyRequest),
    cancelHighCostAccount: (id: number) =>
      store.dispatch("highCostAccounts2463/cancelHighCostAccount", id),
    loadDiseaseTypes: () =>
      store.dispatch("highCostAccounts2463/loadDiseaseTypes"),

    //Mutations
    setHighCostAccounts: () => store.commit("highCostAccounts2463/setHighCostAccounts"),
    setHighCostAccount: (data?: IHighCostAccounts2463) =>
      store.commit("highCostAccounts2463/setHighCostAccount", data),
    setDiseaseTypes: () => store.commit("highCostAccounts2463/setDiseaseTypes"),
  };
};
