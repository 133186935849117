import { GetterTree } from 'vuex';
import { UsersState } from './state';
import { StateInterface } from '../index';


const getters: GetterTree<UsersState, StateInterface> = {
    getDataInfoTrend(state) {
      return  state.dataInfoTrend
      },

    getTrendsInfo(state) {
       return state.trendInfo;
      },
}



export default getters;