import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/medicamentos",
    name: "MedicinesList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Medicines List" */ "@/views/medicines/MedicinesList.vue"
      ),
  },
  {
    path: "/medicamentos/crear-medicamentos",
    name: "CreateMedicineView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Medicines Create" */ "@/views/medicines/sub-pages/medicines-create/v4/CreateMedicineView.vue"
      ),
  },
  {
    path: "/medicamentos/editar-medicamentos",
    name: "UpdateMedicineView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Medicines Edit" */ "@/views/medicines/sub-pages/medicines-edit/v4/UpdateMedicineView.vue"
      ),
  },
  {
    path: "/medicamento/ver-medicamento/:id",
    name: "ReadMedicineView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Medicines Edit" */ "@/views/medicines/sub-pages/medicines-read/ReadMedicineView.vue"
      ),
  },
];
