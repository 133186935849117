import { GetterTree } from 'vuex';
import { MedicinesReportState } from './state';
import { StateInterface } from '../index';


const getters: GetterTree<MedicinesReportState, StateInterface> = {
    someGetter( /* state */ ) {
        // return true;
    }
}

export default getters;