import { Module } from "vuex";
import { StateInterface } from "../index";
import actions from "./actions";
import state from "./state";
import { ICancellationReason } from "@/interfaces/global/ICancellationReason";
import mutations from "./mutations";

const CancellationReasonModule: Module<ICancellationReason, StateInterface> = {
  namespaced: true,
  actions,
  state,
  mutations,
};

export default CancellationReasonModule;
