import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/unificacion-historia-clinica",
    name: "UnificationMedicalRecordsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Unification Medical Records List" */ "@/views/unification-medical-records/UnificationMedicalRecordsList.vue"
      ),
  },
  {
    path: "/unificacion-historia-clinica/ver-unificacion/:id(\\d+)",
    name: "UnificationMedicalRecordsView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Unification Medical Records View" */ "@/views/unification-medical-records/sub-pages/unification-medical-records-view/UnificationMedicalRecordsView.vue"
      ),
  },
  {
    path: "/unificacion-historia-clinica/solicitud-unificacion",
    name: "UnificationMedicalRecordsCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Unification Medical Records Create" */ "@/views/unification-medical-records/sub-pages/unification-medical-records-create/UnificationMedicalRecordsCreate.vue"
      ),
  },
];
