import { GetterTree } from "vuex";
import { InitialAttentionsState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<InitialAttentionsState, StateInterface> = {
  getInitialAttentionsList(state) {
    return state.initialAttentions.length >= 1
      ? state.initialAttentions.map(
          ({
            id,
            patient,
            registered_date_formatted,
            registered_time_formatted,
            status,
            status_formatted: statusFormatted,
          }) => ({
            id,
            patient: `${patient?.name} ${patient?.last_name}`,
            document: patient?.document,
            gender: patient?.gender,
            eps: patient?.eps?.name,
            age: patient?.age,
            date: `${registered_date_formatted} ${registered_time_formatted}`,
            status,
            statusFormatted,
          })
        )
      : [];
  },
  getInitialAttentionView(state) {
    if (state.initialAttention) {
      const {
        condition,
        observation,
        registered_date_formatted: date,
        registered_time_formatted: time,
        patient,
        attendant,
      } = state.initialAttention;

      const {
        document_type,
        document,
        name,
        last_name,
        gender,
        marital_status,
        type_of_population,
        membership,
        regime,
        birthday,
        eps,
        department_name,
        city_name,
        address,
        phone,
        email,
        occupation,
        arl,
        vital_state,
        prepaid,
        care_programs,
        rh_group,
        sex,
        companion_document,
        companion_name,
        companion_phone,
      } = patient;

      return {
        condition,
        observation,
        date,
        time,
        patient: {
          document_type: {
            name: document_type?.name
          },
          document,
          name,
          last_name,
          gender,
          marital_status,
          type_of_population,
          membership: {
            name: membership?.name
          },
          regime: {
            name: regime?.name
          },
          birthday,
          eps: {
            name: eps?.name
          },
          department_name,
          city_name,
          address,
          phone,
          email,
          occupation,
          arl: {
            name: arl?.name
          },
          vital_state,
          prepaid: {
            name: prepaid?.name
          },
          care_programs: [{
            name: care_programs[0]?.name
          }],
          rh_group,
          sex: {
            name: sex?.name
          },
          companion_document,
          companion_name,
          companion_phone,
        },
        attendant,
        isAttendant: !!attendant,
      };
    }

    return null;
  },
};

export default getters;
