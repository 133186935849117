import { IPages } from "@/interfaces/customs/interface";
import {
  IReferenceCenter,
  IRequestResponse,
} from "@/interfaces/global/interfaces";

export interface CenterTypes {
  id: number;
  created_at: null | string;
  updated_at: null | string;
  description: string;
}

export interface CareCenter {
  id: number;
  created_at?: null | Date;
  updated_at?: null | Date;
  name: string;
  nit: string;
  address: string;
  phone: string;
  email: string;
  logo?: null | string;
  contact_name: string;
  contact_phone: string;
  status: string;
  centers_types_id: number;
}

export interface Datum {
  id: number;
  created_at: null | string;
  updated_at: null | string;
  name: string;
  nit: string;
  address: string;
  phone: string;
  email: string;
  logo: null | string;
  contact_name: string;
  contact_phone: string;
  status: string;
  centers_types_id: number;
  center_types: CenterTypes;
}

export interface Links {
  url: null | string;
  label: string;
  active: boolean;
}

export interface Data {
  current_page: number;
  data: Datum[] | CareCenter[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: Links[];
  next_page_url: null | string;
  path: string;
  per_page: number;
  prev_page_url: null | string;
  to: number;
  total: number;
}

export interface Response {
  success: boolean;
  message: string;
}

// Reference Centers V.2
export type ReferenceCentersPages = Record<"referenceCenters", IPages>;

export interface ReferenceCentersList {
  id: IReferenceCenter["id"];
  name: IReferenceCenter["name"];
  address: IReferenceCenter["address"];
  phone: IReferenceCenter["phone"];
  status: IReferenceCenter["status"];
}

export interface ReferenceCenterView {
  name: IReferenceCenter["name"];
  email: IReferenceCenter["email"];
  nit: IReferenceCenter["nit"];
  address: IReferenceCenter["address"];
  phone: IReferenceCenter["phone"];
  contactName: IReferenceCenter["contact_name"];
  contactPhone: IReferenceCenter["contact_phone"];
  logo: IReferenceCenter["logo"];
  ips: IReferenceCenter["ips_code"];
}

export interface ReferenceCenterEdit {
  name: IReferenceCenter["name"];
  email: IReferenceCenter["email"];
  nit: IReferenceCenter["nit"];
  address: IReferenceCenter["address"];
  phone: IReferenceCenter["phone"];
  contact_name: IReferenceCenter["contact_name"];
  contact_phone: IReferenceCenter["contact_phone"];
  url: IReferenceCenter["logo"];
  ips_code: IReferenceCenter["ips_code"];
}

export interface ReferenceCentersData {
  referenceCenters: IReferenceCenter[];
  referenceCenter: IReferenceCenter | null;
}

export interface ReferenceCentersState {
  allReferenceCenters: Datum[] | [];
  careCentersList: CareCenter[] | [];
  referenceCenterLogo: null | string;

  // Reference Centers V.2
  data: ReferenceCentersData;
  pages: ReferenceCentersPages;
  response: IRequestResponse;
}

function state(): ReferenceCentersState {
  return {
    allReferenceCenters: [],
    careCentersList: [],
    referenceCenterLogo: null,

    // Reference Centers V.2
    data: {
      referenceCenters: [],
      referenceCenter: null,
    },
    pages: {
      referenceCenters: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
