import {
  IIndicatorViewCard,
  IIndicatorsAppointSchedules,
  IIndicatorsCustomList,
  IIndicatorsDocsURL,
} from "@/interfaces/customs/IndicatorsCustomsUI";

export interface IndicatorsUI {
  indicatorsLoader: boolean;
  viewIndicatorsURL: IIndicatorsDocsURL;
  viewCardIndicator: IIndicatorViewCard | object;
  rowIndicators: IIndicatorsCustomList[];
  indicatorsCurrentPage: number;
  indicatorsLastPage: number;
  indicatorListLoader: boolean;
  viewIndicators: object | undefined;
  specialtiesIndicators: object | undefined;
  currentIndicatorSelected: object | undefined;
  downloadReportIndicatorsURL: string;

  viewIndicatorListLoader: false;
  rowViewIndicators: IIndicatorsAppointSchedules[];
  viewIndicatorsCurrentPage: number;
  viewIndicatorsTotalPage: number;

  indicatorsYears: string[];
  indicatorsRedirectTo: string | undefined;
}

function state(): IndicatorsUI {
  return {
    indicatorsLoader: false,
    viewIndicatorsURL: {
      pdfURL: undefined,
      excelURL: undefined,
    },
    viewCardIndicator: {},
    rowIndicators: [],
    indicatorsCurrentPage: 0,
    indicatorsLastPage: 0,
    indicatorListLoader: false,
    viewIndicators: undefined,
    specialtiesIndicators: undefined,
    currentIndicatorSelected: undefined,
    downloadReportIndicatorsURL: "",

    viewIndicatorListLoader: false,
    rowViewIndicators: [],
    viewIndicatorsCurrentPage: 0,
    viewIndicatorsTotalPage: 0,

    indicatorsYears: [],
    indicatorsRedirectTo: undefined,
  };
}

export default state;
