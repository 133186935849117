import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { ITriageRequest } from "@/interfaces/global/interfaces";

export const useTriage = () => {
  const store = useStore<StateInterface>();
  return {
    // State
    triageList: computed(() => store.state.triage.triageList),
    triageListCurrentPage: computed(() => store.state.triage.triageCurrentPage),
    triageListLastPage: computed(() => store.state.triage.triageLastPage),
    classifications: computed(() => store.state.triage.classifications),
    states: computed(() => store.state.triage.states),
    types: computed(() => store.state.triage.types),
    destinations: computed(() => store.state.triage.destinations),
    triageData: computed(() => store.state.triage.triageData),
    triageById: computed(() => store.state.triage.triage),
    patientTriage: computed(() => store.state.triage.patientTriage),

    // Actions
    _getListTriages: (filters: object | null, page: number) =>
      store.dispatch("triage/getTriages", {
        filters,
        page,
      }),
    _clasificationTriages: () =>
      store.dispatch("triage/getClasificationTriages"),

    _createTriage: (dataRequest: ITriageRequest | null) =>
      store.dispatch("triage/createTriage", { dataRequest }),

    _getTriageById: (idTriage: number) =>
      store.dispatch("triage/getTriageById", { idTriage }),

    _getPatientDetailByTriageId: (idTriage: number) =>
      store.dispatch("triage/getPatientDetailByTriageId", { idTriage }),

    _withdrawTriage: (idInitialAttention: number, reason: string) =>
      store.dispatch("triage/withdrawTriage", { idInitialAttention, reason }),

    _setInTriage: (idInitialAttention: number) =>
      store.dispatch("triage/setInTriage", { idInitialAttention }),

    // Mutations
    setTriage: (
      data: {
        triage: {
          id: number;
          attention_time: string;
          description: string;
        } | null;
        destination: string;
        readmission: boolean;
      } | null
    ) => {
      store.commit("triage/setTriage", data);
    },
  };
};
