import { executeApi } from "@/apis";
import { ProceduresReportState } from "@/interfaces/global/ProceduresReport";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { IError } from "@/interfaces/customs/interface";
import errorException from "@/functions/ErrorException";

const URL_PATH = "/api/documents/report-procedure";

const actions: ActionTree<ProceduresReportState, StateInterface> = {
  async getListProceduresReport({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().get(URL_PATH, {
        params,
      });
      const { success, message } = data;

      commit("setProceduresReportList", data.data);

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async createProceduresReport({ commit }, { bodyRequest }): Promise<object> {
    try {
      const { data } = await executeApi().post(URL_PATH, bodyRequest);
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async cancelProceduresReport({ commit }, { idRow }): Promise<object> {
    try {
      const { data } = await executeApi().post(`${URL_PATH}/${idRow}/cancel`);
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getByIdProceduresReport({ commit }, { idRow }): Promise<object> {
    try {
      const { data } = await executeApi().get(`${URL_PATH}/${idRow}`);
      const { success, message } = data;

      commit("setProcedureReport", data.data);

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getListSelector({ commit }, { params, urlRequest }): Promise<object> {
    try {
      const { data } = await executeApi().get(`${URL_PATH}/${urlRequest}`, {
        params,
      });
      const { success, message, data: specialtiesList } = data;

      return { success, message, specialtiesList };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
};

export default actions;
