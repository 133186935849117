import { Module } from 'vuex';
import { StateInterface } from '../../index';

import state, { JobListUI } from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const jobListModule: Module<JobListUI, StateInterface> = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state, 
}

export default jobListModule;