import { ActionTree } from "vuex";
import { ExplanatoryNotesState, RequestResponse } from "./state";
import { StateInterface } from "../index";
import { explanatoryNotesApi } from "@/apis";
import errorException from "@/functions/ErrorException";

const actions: ActionTree<ExplanatoryNotesState, StateInterface> = {
  async getExplanatoryNotes(
    { commit },
    {
      token,
      page,
      selects,
    }: {
      token: string;
      page: number;
      selects: { referenceCenter: number; careCenter: number; status: string };
    }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await explanatoryNotesApi.get(
        `/${selects.referenceCenter}/${selects.careCenter}/${selects.status}?page=${page}`,
        config
      );
      const { success, message, data: explanatoryNoteData } = data;

      commit("getExplanatoryNotes", explanatoryNoteData);
      commit("clearExplanatoryNotesData");
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getAllResources({ commit }, { token }): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await explanatoryNotesApi.get(
        "/getAllResources",
        config
      );
      const { success, message, data: allResourceData } = data;

      commit("getAllResources", allResourceData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async cancelExplanatoryNote(
    { commit },
    { token, id }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await explanatoryNotesApi.post(
        `/cancel/${id}`,
        "",
        config
      );
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async createExplanatoryNote(
    { commit },
    { token, bodyRequest }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      : {};

    try {
      const { data } = await explanatoryNotesApi.post("", bodyRequest, config);
      const { success, message } = data;

      commit("clearExplanatoryNotesList");
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getExplanatoryNoteById(
    { commit },
    { token, id }
  ): Promise<RequestResponse> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await explanatoryNotesApi.get(`/${id}`, config);
      const { success, message, data: explanatoryNoteData } = data;

      commit("getExplanatoryNoteById", explanatoryNoteData[0]);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
};

export default actions;
