import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/paquetes",
    name: "PackagesList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Packages List" */ "@/views/packages/PackagesList.vue"
      ),
  },
  {
    path: "/paquetes/crear-paquetes",
    name: "PackagesCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Packages Create" */ "@/views/packages/sub-pages/packages-create/PackagesCreate.vue"
      ),
  },
  {
    path: "/paquetes/editar-paquetes/:id(\\d+)",
    name: "PackagesEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Packages Edit" */ "@/views/packages/sub-pages/packages-edit/PackagesEdit.vue"
      ),
  },
  {
    path: "/paquetes/ver-paquetes/:id(\\d+)",
    name: "PackagesView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Packages View" */ "@/views/packages/sub-pages/packages-view/PackagesView.vue"
      ),
  },
];
