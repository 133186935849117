import { ResourcesState } from "@/interfaces/global/interfaces";

function state(): ResourcesState {
  return {
    documentTypes: [],
    medicines: [],
    procedures: [],
    specialties: [],
    classes: [],
    types: [],
    measurement_units: [],
    dispensing_units: [],
    provision_types: [],
    pharmaceutical_forms: [],
    administration_routes: [],
    tax_types: [],
    manufacturers: [],
    proceduresV2: [],
    subprograms: [],
    user_registered: [],
  };
}

export default state;
