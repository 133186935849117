import apiBase from "@/apis/AxiosIntance";

export const medicalRecordsApi = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/patients/medicalRecord`;
  return apiBase;
};

export const medicalRecordsApiV1 = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/patients/medicalRecord/v1`;
  return apiBase;
};
