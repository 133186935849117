import { IMeetResponse } from "@/interfaces/customs/MeetResponseCustom";
import { ITelemedicine, IDisability } from "@/interfaces/global/interfaces";

export interface TelemedicineState {
  telemedicineList: object[];
  telemedicineCurrentPage: number;
  telemedicineLastPage: number;
  states: object[];
  types: object[];
  telemedicine: ITelemedicine | null;
  queryType: string[];
  gynecologistsBackgroundData: object | null;
  disabilitiesData: IDisability | null;
  informed_consent: boolean;
  notify_send: boolean;
  notify_type: string[] | null;
  data_usage: boolean;
  consent_through_type: string;
  signature: File | null;
  signature_url: string | null;
  telemediceMeetInfo: IMeetResponse | object;
}

function state(): TelemedicineState {
  return {
    telemediceMeetInfo: {},
    telemedicineList: [],
    telemedicineCurrentPage: 1,
    telemedicineLastPage: 1,
    states: [
      { label: "Cancelada", value: "Cancelada" },
      { label: "Realizada", value: "Realizada" },
      { label: "Agendada", value: "Agendada" },
      { label: "Todos", value: "" },
    ],
    types: [
      // { label: "Todos", value: ""
      { label: "Interactiva", value: "Interactiva" },
    ],
    telemedicine: null,
    queryType: [
      "Apoyo en el diagnóstico del paciente",
      "Apoyo en la gestión del riesgo del paciente",
      "Apoyo en la terapéutica del paciente",
      "Apoyo en la rehabilitación del paciente",
    ],
    gynecologistsBackgroundData: null,
    disabilitiesData: null,
    informed_consent: false,
    notify_send: false,
    notify_type: [],
    consent_through_type: "",
    data_usage: false,
    signature: null,
    signature_url: null,
  };
}

export default state;
