import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/gestion-de-agendamiento/disponibilidad",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "avalible View" */
        "@/views/available-view/AvailableIndexView.vue"
      ),
    children: [
      {
        path: "",
        name: "AvailableListView",
        beforeEnter: [isAuthenticatedGuard],
        component: () =>
          import(
            /* webpackChunkName: "avalible list View" */
            "@/views/available-view/available-list-view/AvailableListView.vue"
          ),
      },

      {
        path: "ver-disponibilidad/:id",
        name: "AvailableView",
        beforeEnter: [isAuthenticatedGuard],
        component: () =>
          import(
            /* webpackChunkName: "avalible detail View" */
            "@/views/available-view/available-datail-view/AvailableDetail.vue"
          ),
      },

      {
        path: "editar-disponibilidad/:id",
        name: "AvailableEditView",
        beforeEnter: [isAuthenticatedGuard],
        component: () =>
          import(
            /* webpackChunkName: "avalible edit View" */
            "@/views/available-view/available-edit-view/AvailableEditView.vue"
          ),
      },
      {
        path: "crear-lista-de-disponibilidad",
        beforeEnter: [isAuthenticatedGuard],
        children: [
          {
            path: "",
            name: "AvailableCreateListView",
            component: () =>
              import(
                /* webpackChunkName: "avalible create View" */
                "@/views/available-view/availible-create-view/create-available-list/AvailableCreateList.vue"
              ),
          },
          {
            path: "crear-disponibilidad/:id",
            beforeEnter: [isAuthenticatedGuard],
            children: [
              {
                path: "",
                name: "AvailableCreateView",
                component: () =>
                  import(
                    /* webpackChunkName: "avalible create View" */
                    "@/views/available-view/availible-create-view/available-create/AvailableCreateView.vue"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];
