import { MutationTree } from "vuex";
import {
  PreparationsState,
  IPreparation,
} from "@/interfaces/global/interfaces";
import { IDataTable } from "@/interfaces/customs/interface";

const mutations: MutationTree<PreparationsState> = {
  setPreparation(state: PreparationsState, list: IPreparation): void {
    state.preparationRow = list;
  },
  setPreparationsList(state: PreparationsState, list: IDataTable): void {
    state.preparationsList = list?.data;

    state.preparationsCurrentPage = list?.current_page || 1;
    state.preparationsLastPage = list?.last_page || 1;
  },
  setDataForm(
    state: PreparationsState,
    data: {
      reference_center_id: number;
      care_center_id: number;
      type: string;
      preparationable_id: string | number;
      name: string;
    } | null
  ): void {
    state.data_form = data;
  },
  setDataTable(state: PreparationsState, data: object[]): void {
    state.data_table = data;
  },
  setPreparationable(state: PreparationsState, value: string | number | null): void {
    if (state.data_form) {
      state.data_form.preparationable_id = value;
    }
  },
};

export default mutations;
