import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/atencion-inicial",
    name: "InitialAttentionsList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Initial Attentions List" */ "@/views/initial-attentions/InitialAttentionsList.vue"
      ),
  },
  {
    path: "/atencion-inicial/ver-atencion-inicial/:id(\\d+)",
    name: "InitialAttentionsView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Resolution Reports View" */ "@/views/initial-attentions/sub-pages/initial-attentions-view/InitialAttentionsView.vue"
      ),
  },
  {
    path: "/atencion-inicial/crear-atencion-inicial",
    name: "InitialAttentionsCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Initial Attentions Create" */ "@/views/initial-attentions/sub-pages/initial-attentions-create/InitialAttentionsCreate.vue"
      ),
  },
];
